import React from "react";

export const BloodPressureIcon = (props) => {
  const { className = "", style = {}, disabled = false, onClick } = props;

  return (
    <svg
      className={className}
      style={style}
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="30" cy="30" rx="30" ry="30" fill="#F5F5F5" />
      <path
        d="M35.0146 20.3276C35.9075 20.3276 36.7548 20.5103 37.5272 20.8343C38.2955 21.1582 38.9932 21.6317 39.5788 22.2172C40.1602 22.8028 40.6337 23.4922 40.9618 24.2688C41.2857 25.0372 41.4685 25.8885 41.4685 26.7773C41.4685 27.666 41.2857 28.5174 40.9618 29.2899C40.6337 30.0582 40.1602 30.7559 39.5788 31.3415C38.9932 31.9229 30.7744 39.3485 30.0061 39.6766C29.2336 39.3485 21.0189 31.9229 20.4333 31.3415C19.8477 30.7559 19.3784 30.0623 19.0503 29.2899C18.7264 28.5174 18.5437 27.6702 18.5437 26.7773C18.5437 25.8844 18.7264 25.0413 19.0503 24.2688C19.3743 23.4964 19.8477 22.8028 20.4333 22.2172C21.0189 21.6317 21.7083 21.1624 22.4849 20.8343C23.2532 20.5103 24.1046 20.3276 24.9933 20.3276C25.8821 20.3276 26.7335 20.5519 27.5059 20.9464C28.2742 21.3368 28.972 21.9016 29.5575 22.5744C29.7154 22.7613 29.869 22.9523 30.0102 23.1558C30.1514 22.9565 30.3051 22.7613 30.4671 22.5744C31.0526 21.9016 31.7462 21.341 32.5187 20.9464C33.2911 20.5519 34.1383 20.3276 35.0271 20.3276M35.0146 18.4546C33.8559 18.4546 32.7263 18.7328 31.6548 19.2769C31.0692 19.5759 30.5169 19.9455 30.0019 20.3899C29.4869 19.9497 28.9346 19.5759 28.349 19.2769C27.2817 18.7328 26.1479 18.4546 24.985 18.4546C23.8222 18.4546 22.7756 18.6747 21.7498 19.1108C20.7614 19.5261 19.8727 20.1283 19.1043 20.8924C18.336 21.6607 17.738 22.5495 17.3185 23.5379C16.8866 24.5679 16.6665 25.656 16.6665 26.7731C16.6665 27.8903 16.8866 28.9784 17.3185 30.0125C17.7421 31.0092 18.3402 31.898 19.1002 32.6621C19.1832 32.7452 27.9586 40.8395 29.2627 41.396C29.4994 41.4957 29.7486 41.5455 29.9978 41.5455C30.2469 41.5455 30.5003 41.4957 30.737 41.396C32.0577 40.8312 40.8164 32.7452 40.8912 32.6663C41.6553 31.898 42.2534 31.0134 42.6728 30.025C43.1131 28.9825 43.3332 27.8944 43.3332 26.7773C43.3332 25.6601 43.1131 24.572 42.677 23.5421C42.2575 22.5536 41.6595 21.6649 40.8995 20.9007C40.1353 20.1366 39.2424 19.5344 38.254 19.1149C37.2199 18.6789 36.1276 18.4587 35.0105 18.4587L35.0146 18.4546Z"
        fill="#113E57"
      />
      <path
        d="M30.2455 32.7345C30.8693 32.6854 31.3614 32.2163 31.468 31.634L36.1664 27.8749L30.5768 30.1082C30.4108 30.0495 30.2202 30.0197 30.033 30.0344C29.2845 30.0933 28.7262 30.747 28.7851 31.4955C28.844 32.244 29.4977 32.8023 30.2462 32.7434L30.2455 32.7345Z"
        fill="#113E57"
      />
      <path
        d="M34.1436 31.2471H34.6436"
        stroke="#113E57"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M25.8369 31.2471H26.3369"
        stroke="#113E57"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M26.6284 27.0068L27 27.3414"
        stroke="#113E57"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M33.3716 27.0068L33 27.3414"
        stroke="#113E57"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M30 25.5068V26.0068"
        stroke="#113E57"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};
