import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Radio } from "antd";
import NewCustomButton from "../Common/NewCustomButton";
import { NewCloseIcon, NewUpdateIcon } from "../Common/Icons";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { ButtonTypes } from "../Common/CustomButton";
const style = {
  display: "flex",
  flexDirection: "column",
  gap: 8,
};
const RecurringTaskModal = ({
  isModalOpen = true,
  onConfirm = () => {},
  onCancel = () => {},
  modalHeader=""
}) => {
  const trans = useSelector(translator);
  const [value, setValue] = useState(3);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <div className="">
      <Modal
        title={modalHeader}
        centered
        open={isModalOpen}
        footer={null}
        closable={false}
        className="task-modal-conatiner  recurring-modal"
      >
        <div className="taskmodal">
          <div className="taskmodaldescription" style={{ fontWeight : "400", alignItems: "center"}}>
            <Radio.Group
              style={style}
              onChange={onChange}
              value={value}
              options={[
                {
                  value: 1,
                  label: "This occurrence only",
                  disabled: true,
                },
                {
                  value: 2,
                  label: "This and all future occurrences",
                  disabled: true,
                },
                {
                  value: 3,
                  label: "All occurrences",
                },
              ]}
            />
          </div>
          <div className="taskmodalbutton">
            <NewCustomButton
              className="cancel"
              onClick={onCancel}
              label={trans("CANCEL")}
              icon={<NewCloseIcon className="cancel-icon" />}
            />
            <NewCustomButton
              className="confirm"
              type={ButtonTypes.primary}
              label={trans("YES")}
              icon={<NewUpdateIcon className={"update-icon-enabled"} />}
              onClick={onConfirm}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

RecurringTaskModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default RecurringTaskModal;
