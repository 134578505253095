import React from "react";
import WellnessIconByTitle from "./WellnessIconByTitle"
import "./Wellness.less";
import titleData from "./statistic-title.json";
import PropTypes from "prop-types";
import { WellnessViewHistoryArrowIcon } from "../Common/Icons";



const WellnessViewHistory = ({title, showViewHistoryModal,showSupportDeviceModal, deviceBrand, deviceModel, eventName}) => {
    return (
        <>
            <div className="wellness-item-header">
                    <div className="wellness-item-header-title" >
                        <WellnessIconByTitle title={title} style={{ width:title === 'glucose_meter' ? "119px" : "99px" , height: title === 'glucose_meter' ? "85px" : "99px" }} />
                        <label>{titleData['device_title'][title]}</label>
                    </div>
                    <div className="wellness-item-header-actions">
                        <button className="wellness-item-header-view-history-btn" onClick={()=>{ showViewHistoryModal(title,deviceBrand,deviceModel,eventName)}} >
                            <label>View history</label>
                            <WellnessViewHistoryArrowIcon />
                        </button>
                    </div>
                </div>
        </>
    )
}

WellnessViewHistory.propTypes = {
    showViewHistoryModal: PropTypes.func,
    title: PropTypes.string,
    deviceBrand: PropTypes.string,
    deviceModel: PropTypes.string,
    eventName: PropTypes.string
}

export default WellnessViewHistory;