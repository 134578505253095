import React, { useEffect, useState, useMemo } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import PropTypes from "prop-types";

dayjs.extend(utc);
dayjs.extend(timezone);

const TaskCustomCalendar = ({
  taskDate,
  taskStatus,
  selectedSchedulelistDate,
  fetchTaskData,
  timeZone,
}) => {
  const daysOfWeek = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const [selectedDayjsObject, setSelectedDayjsObject] = useState(null);

  useEffect(() => {
    if (selectedSchedulelistDate) {
      const dayjsObj = dayjs(selectedSchedulelistDate);
      setSelectedDayjsObject(dayjsObj);
    }
  }, [selectedSchedulelistDate]);

  // Convert month name to month number
  const months = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  // Extract year and month from selectedSchedulelistDate
  const selectedYear = selectedDayjsObject?.year();
  const selectedMonth = selectedDayjsObject?.month(); // 0-indexed
  const selectedMonthNumber =
    selectedMonth !== null ? (selectedMonth + 1).toString().padStart(2, "0") : null;

  // Function to get the month name from the month number
  function getMonthName(monthNumber) {
    for (const [key, value] of Object.entries(months)) {
      if (value === monthNumber) {
        return key;
      }
    }
    return null;
  }

  const monthName = getMonthName(selectedMonthNumber);
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const isToday = (year, month, day) => {
    const today = new Date();
    return (
      today.getFullYear() === year &&
      today.getMonth() === month &&
      today.getDate() === day
    );
  };

  const processedTaskData = useMemo(() => {
    return fetchTaskData.map((task) => {
      const taskDate = task?.eventTimestamp
        ? dayjs(task?.eventTimestamp).tz(timeZone)
        : dayjs(`${task?.startDate?.split("T")[0]}T${task?.startTime}Z`).tz(timeZone);
      return {
        date: taskDate,
        status: task?.taskStatus,
      };
    });
  }, [fetchTaskData, timeZone]);

  const isTaskDate = (year, month, day) => {
    const date = dayjs(new Date(year, month, day));
    return processedTaskData.some(
      (task) =>
        date.isValid() &&
        task?.date.isValid() &&
        date.isSame(task?.date, "day")
    );
  };

  const getTaskStatus = (year, month, day) => {
    const date = dayjs(new Date(year, month, day));
    const task = processedTaskData.find(
      (task) =>
        date.isValid() &&
        task?.date.isValid() &&
        date.isSame(task?.date, "day")
    );
    return task ? task?.status : "";
  };

  const calendarDays = useMemo(() => {
    if (!selectedYear || selectedMonth === null) return null;

    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    const firstDay = getFirstDayOfMonth(selectedYear, selectedMonth);
    const days = [];

    // Fill in the blanks for days before the first day of the month
    for (let i = 0; i < (firstDay === 0 ? 6 : firstDay - 1); i++) {
      days.push(
        <div key={`blank-${i}`} className="task-calendar-day blank"></div>
      );
    }

    // Fill in the days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const status = getTaskStatus(selectedYear, selectedMonth, day);
      days.push(
        <div
          key={day}
          className={`task-calendar-day ${
            isTaskDate(selectedYear, selectedMonth, day)
              ? `task-date-${status}`
              : ""
          }  ${isToday(selectedYear, selectedMonth, day) ? "today" : ""}`}
        >
          {day}
        </div>
      );
    }

    return days;
  }, [selectedYear, selectedMonth, processedTaskData]);

  return (
    <div className="task-calendar-container">
      {selectedDayjsObject && (
        <>
          <div className="task-calendar-header">
            <div className="selectyearmonth">{selectedYear}</div>
            <div className="selectyearmonth">{monthName}</div>
          </div>
          <div className="task-calendar-date-container">
            <div className="task-calendar-grid">
              {daysOfWeek.map((day) => (
                <div key={day} className="task-calendar-day-header">
                  {day}
                </div>
              ))}
              {calendarDays}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

TaskCustomCalendar.propTypes = {
  taskDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  taskStatus: PropTypes.string,
  selectedSchedulelistDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  fetchTaskData: PropTypes.arrayOf(PropTypes.object),
  timeZone: PropTypes.string.isRequired,
};

TaskCustomCalendar.defaultProps = {
  taskDate: null,
  taskStatus: "",
  selectedSchedulelistDate: null,
  fetchTaskData: [],
};

export default TaskCustomCalendar;
