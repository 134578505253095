import "../../styles/HSDayPicker.less";
import React from "react";

const shortWeekDays = ["sun", "mon", "tues", "wed", "thurs", "fri", "sat"];
const weekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export const HSDayPicker = (props) => {
  const { days = [], onDayChange, disabled = false, allowMultiple = true } = props;

  const selectedDays = Array.isArray(days)
    ? days
        .map((day) => {
          const idx = weekDays.indexOf(day.toLowerCase());
          return idx >= 0 ? shortWeekDays[idx] : null;
        })
        .filter(Boolean)
    : [];

  const handleClick = (index) => {
    const day = shortWeekDays[index];
    let newSelected;
    if (allowMultiple) {
      newSelected = selectedDays.includes(day)
        ? selectedDays.filter((item) => item !== day)
        : [...selectedDays, day];
    } else {
      newSelected = selectedDays.includes(day) ? [] : [day];
    }
    const fullDays = newSelected.map((s) => weekDays[shortWeekDays.indexOf(s)]);
    onDayChange(fullDays);
  };

  return (
    <div className={`hs-daypicker ${disabled ? "list-item-disabled" : ""}`}>
      {shortWeekDays.map((value, index) => (
        <div
          key={index}
          onClick={() => handleClick(index)}
          className={selectedDays.includes(value) ? "list-item-selected" : "list-item"}
          style={disabled ? { cursor: "not-allowed", pointerEvents: "none" } : {}}
        >
          {value.charAt(0)}
        </div>
      ))}
    </div>
  );
};
