import React from "react";
import { useSelector } from "react-redux";
import { Button, Divider, Input, Modal, Tooltip, Spin, message } from "antd";
import {
  CloseIcon,
  ContactImage,
  NewCancelIcon,
  NewUpdateIcon,
} from "../../Common/Icons";
import ContactSelectionForStaff from "./ContactSelectionForStaff";
import "./NewAssignStaff.less";
import Roles from "../../Roles/Roles";
import {
  PhotoResolution,
  RoleType,
} from "@commscopemycloud/humaui/Utilities/Constants";
import {
  getProfilePicUrl,
  getUsername,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";

const AssignStaffModal = ({
  isFirstModalOpen,
  handleCloseFirstModal,
  handleAddStaff,
  mOrderedStaffList,
  fetchingStaffList,
  handleRoleSelection,
  handleInputChange,
  formData,
  selectedUser = "",
  selectedStaff,
  handleAssignStaff,
  handleOpenSecondModal,
  handleCloseSecondModal,
  isSecondModalOpen,
  setSelectedStaff,
  role,
  hubRecord,
  setSearchNameApplied,
  searchNameApplied,
  setSearchNameText,
  searchNameText,
}) => {
  const truncateName = (name, length = 20) => {
    if (!name) return "";
    return name.length > length ? `${name.substring(0, length)}...` : name;
  };
  const renderFormItem = ({
    name,
    label,
    disabled,
    placeholder,
    className,
  }) => (
    <div className={`form-item form-item-verticle ${className ?? ""}`}>
      <label className="assign-staff-relationship-title">{label}</label>
      <div className="assign-staff-form-input-box">
        <Input
          className="assign-staff-input-content"
          name={name}
          placeholder={placeholder}
          value={formData[name]}
          onChange={handleInputChange}
          disabled={disabled}
        />
      </div>
    </div>
  );

  // Render role selection
  const renderRole = ({ label, className }) => {
    return (
      <div className={`form-item form-item-verticle ${className ?? ""}`}>
        <label>{label}</label>
        <Roles
          contact={true}
          type={RoleType.user}
          onHandleSelection={handleRoleSelection}
          isAssignStaff={true}
          currentRole={role}
          key={role}
        />
      </div>
    );
  };

  const AssignStaffHeaderModal = () => {
    return (
      <div className="assign-staff-modal-container">
        <div className="assign-staff-modal-header-title">
          <span className="assign-staff-modal-title">Assign staff</span>
        </div>
        <div
          className="assign-staff-modal-close-button"
          onClick={
            isSecondModalOpen ? handleCloseSecondModal : handleCloseFirstModal
          }
        >
          <CloseIcon className="assign-staff-modal-close-icon" />
        </div>
      </div>
    );
  };

  const resourcesMap = useSelector(getProfilePics());
  const url = getProfilePicUrl(
    selectedStaff?.useruuid,
    resourcesMap,
    PhotoResolution.R64
  );
  const image =
    (selectedStaff?.profilePics &&
      selectedStaff?.profilePics[PhotoResolution.R64]) ??
    url;

  const AssignStaffFormItemModal = () => {
    return (
      <>
        <div className="assign-staff-select-contact">
          <div className="assign-staff-contact-block">
            <div className="selected-assign-staff-profile">
              {image ? (
                <img
                  src={image}
                  className="user-profile-pic user-profile-image"
                  alt="pic"
                />
              ) : (
                <ContactImage className="photo-container" />
              )}
            </div>
            <div className="selected-assign-staff-name-role-container">
              <span className="selectedcontact">
                {selectedStaff && getUsername(selectedStaff)}
              </span>
              <span className="rolename">{selectedStaff && selectedStaff?.staffrole}</span>
            </div>
          </div>
        </div>
        <Divider className="assign-staff-divider" />
        <div className="assign-staff-contact-details-container">
          <div className="assign-staff-form-container">
            <div className="assign-staff-form-row">
              {renderFormItem({
                name: "targetrelationship",
                label: (
                  <>
                    <span>
                        {selectedStaff && (
                          <span>{`${selectedStaff?.firstname}’s `}</span>
                        )}
                      {`relationship with `}
                      <Tooltip
                      title={selectedUser.length > 20 ? selectedUser : ""}>
                       <span>{truncateName(selectedUser)}</span>
                       </Tooltip>
                    </span>
                  </>
                ),
              })}
            </div>
            <div className="assign-staff-form-row">
              {renderFormItem({
                name: "sourcerelationship",
                label: (
                  <>
                    <span>
                      <Tooltip
                      title={`${hubRecord?.firstname} ${hubRecord?.lastname}`}
                      >
                        {selectedUser && <span>{`${truncateName(selectedUser)}’s `}</span>}
                      </Tooltip>
                      {selectedStaff && (
                        <span>{`relationship with ${selectedStaff?.firstname}`}</span>
                      )}
                    </span>
                  </>
                ),
              })}
            </div>
          </div>
          <div className="assign-staff-role-container">
            <span className="assign-staff-role-title">
              {`What role does this Staff member have with ${selectedUser}?*`}
            </span>
            <div className="roles-list">{renderRole({})}</div>
          </div>
        </div>
      </>
    );
  };

  const AssignStaffModalFooter = ({ onClick, isDisabled, buttonText }) => (
    <div className="assign-staff-modal-footer">
      <Button
        className="cancel-button"
        onClick={
          isSecondModalOpen ? handleCloseSecondModal : handleCloseFirstModal
        }
      >
        <NewCancelIcon />
        <span className="cancel-text">Cancel</span>
      </Button>
      <Button
        onClick={onClick}
        disabled={isDisabled}
        className={`submit-button ${isDisabled ? "disabled-button" : ""}`}
      >
        <NewUpdateIcon
          className={`submit-icon ${isDisabled ? "disabled-icon" : ""}`}
        />
        <span className={`submit-text ${isDisabled ? "disabled-text" : ""}`}>
          {buttonText}
        </span>
      </Button>
    </div>
  );

  return (
    <>
      <Modal
        open={isFirstModalOpen}
        onCancel={handleCloseFirstModal}
        footer={null}
        closable={false}
        centered
        className="assign-staff-modal-main first"
      >
        {AssignStaffHeaderModal()}
        <ContactSelectionForStaff
          fetchingContacts={fetchingStaffList}
          selectedStaff={selectedStaff}
          selectionContactList={mOrderedStaffList}
          onAddStaff={handleAddStaff}
          selectedUser={selectedUser}
          setSelectedStaff={setSelectedStaff}
          hubRecord={hubRecord}
          setSearchNameApplied={setSearchNameApplied}
          searchNameApplied={searchNameApplied}
          setSearchNameText={setSearchNameText}
          searchNameText={searchNameText}
        />
        <AssignStaffModalFooter
          onClick={handleOpenSecondModal}
          isDisabled={!selectedStaff}
          buttonText="Select staff"
        />
      </Modal>

      {/* //second modal */}
      <Modal
        open={isSecondModalOpen}
        onCancel={handleCloseSecondModal}
        footer={null}
        closable={false}
        centered
        className="assign-staff-modal-main second"
      >
        {AssignStaffHeaderModal()}
        {AssignStaffFormItemModal()}
        <AssignStaffModalFooter
          onClick={handleAssignStaff}
          isDisabled={!role}
          buttonText="Assign staff"
        />
      </Modal>
    </>
  );
};

export default AssignStaffModal;
