import React from "react";

export const LimitedAccessIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_30445_1143)">
        <path
          d="M21.2716 10.897H12.5196C12.4446 10.4848 12.316 10.0838 12.1446 9.69383C11.8768 9.09222 11.5233 8.55746 11.0627 8.08955C10.0557 7.04231 8.72737 6.47412 7.30262 6.47412C5.87788 6.47412 4.56026 7.05345 3.55329 8.08955C2.54633 9.13679 2 10.5183 2 12C2 13.4817 2.55704 14.8632 3.55329 15.9104C4.56026 16.9577 5.88859 17.5259 7.31334 17.5259C8.73808 17.5259 10.0664 16.9465 11.0734 15.9104C11.5233 15.4425 11.8875 14.8966 12.1553 14.3062C12.3267 13.9162 12.4446 13.5151 12.5303 13.1029H14.9941V14.0833C14.9941 14.4955 15.3155 14.8298 15.7118 14.8298H16.3867C16.7831 14.8298 17.1044 14.4955 17.1044 14.0833V13.1029H18.572V15.2531C18.572 15.6653 18.8934 15.9996 19.2898 15.9996H19.9647C20.361 15.9996 20.6824 15.6653 20.6824 15.2531V13.1029H21.2823C21.6786 13.1029 22 12.7687 22 12.3565V11.6546C22 11.2424 21.6786 10.9082 21.2823 10.9082L21.2716 10.897ZM7.31334 15.3311C5.5458 15.3311 4.11034 13.8382 4.11034 12C4.11034 10.1617 5.5458 8.66887 7.31334 8.66887C9.08088 8.66887 10.5163 10.1617 10.5163 12C10.5163 13.8382 9.08088 15.3311 7.31334 15.3311Z"
          fill="#1C1C1C"
        />
      </g>
      <defs>
        <clipPath id="clip0_30445_1143">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
