/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import MainPage from "../../MainPage";
import { Divider, Empty, Input, message, Skeleton, Tooltip } from "antd";
import {
  AccountIcon,
  CallIcon,
  ContactImage,
  EditIcon,
  ScheduleNewIcon,
  SearchIcon,
  AddPlusIcon,
  FilterIcon,
  CloseIcon,
  AddNewScheduleIcon,
  EditNewIcon,
  PendingIcon,
  ResendEmailIcon,
} from "../Common/Icons";
import { getUsername, formatString } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import {
  Actions,
  PhotoResolution,
  StringFormats,
} from "@commscopemycloud/humaui/Utilities/Constants";
import CustomDropdown from "../Common/CustomDropdown";
import {
  KnownActionsList,
  isStaffActionAllowed,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
import useUserInfo from "../../Components/Hooks/useUserInfo";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";

const ListStaff = ({
  parentBread,
  staffList,
  fetchingStaffList,
  onActionClick,
}) => {
  const header = parentBread || [];
  const trans = useSelector(translator);
  const permissions = useSelector(getPermissions());
  const [filteredList, setFilteredList] = useState(staffList);

  const FilterItems = {
    staffAndStaffAdmin: trans("STAFFANDSTAFFADMIN"),
    staffAdmin: trans("STAFFADMIN"),
    staff: trans("STAFF"),
  };

  const [isSearchOn, setIsSearchOn] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [searchApplied, setSearchApplied] = useState(null);
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState([]);

  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const userApi = useSelector((state) => state.apis.userApi);

  const allowCallStaff = useMemo(
    () =>
      isStaffActionAllowed(
        currentUser?.permission_level,
        KnownActionsList.call_staff,
        permissions
      ),
    [currentUser.permission_level]
  );
  const allowCreateStaffEvent = useMemo(
    () =>
      isStaffActionAllowed(
        currentUser?.permission_level,
        KnownActionsList.create_staff_events,
        permissions
      ),
    [currentUser.permission_level]
  );
  const allowManageAccounts = useMemo(
    () =>
      isStaffActionAllowed(
        currentUser?.permission_level,
        KnownActionsList.manage_accounts,
        permissions
      ),
    [currentUser.permission_level]
  );

  useEffect(() => {
    handleSearchApply();
  }, [staffList]);

  const handleSearchToggle = () => {
    if (isSearchOn) {
      setSearchText(null);
      handleSearchClear();
    }
    setIsSearchOn((value) => !value);
  };

  const handleSearchApply = (filter) => {
    if (!!searchText) {
      const newList = staffList.filter((item) => {
        const name = getUsername(item)?.toLowerCase();
        if (name?.includes(searchText.toLowerCase())) {
          return true;
        }
        return false;
      });
      setFilteredList(newList);
      setSearchApplied(true);
      setAppliedFilter([]);
    } else {
      handleSearchClear();
    }
    if (filter === FilterItems.staffAndStaffAdmin) {
      const newList = staffList.filter(
        (item) =>
          item.permission_level === "Staff" ||
          item.permission_level === "Staff Admin"
      );
      setFilteredList(newList);
    } else if (filter === FilterItems.staffAdmin) {
      const newList = staffList.filter(
        (item) => item.permission_level === "Staff Admin"
      );
      setFilteredList(newList);
    } else if (filter === FilterItems.staff) {
      const newList = staffList.filter(
        (item) => item.permission_level === "Staff"
      );
      setFilteredList(newList);
    }
  };

  const handleSearchClear = () => {
    setSearchApplied(false);
    setSearchText(null);
    setFilteredList(staffList);
  };

  const handleFilterToggle = (e) => {
    // Stop the event propagation to prevent it from reaching the parent div
    e.stopPropagation();
    setIsFilterOn((value) => !value);
  };

  const handleFilterChange = (e) => {
    const key = e;
    // Clear search when filter is applied
    setSearchText(null);
    if (appliedFilter.includes(key)) {
      setAppliedFilter([]);
      setFilteredList(staffList);
    } else {
      setAppliedFilter([key]);
      handleSearchApply(key);
    }
    setIsFilterOn(false);
  };
  const FilterMenuItems = Object.values(FilterItems).map((item) => {
    return { key: item, label: item };
  });

  const renderHeaderActions = (
    <div className="header-action-container">
      {!!appliedFilter.length && (
        <div className="applied-filter-container">
          {appliedFilter.map((item) => (
            <div key={"filter_" + item} className="applied-filter">
              <span className="text">{item}</span>
              <CloseIcon
                className="remove-icon"
                onClick={() => handleFilterChange(item)}
              />
            </div>
          ))}
        </div>
      )}
      {isSearchOn && (
        <div className="search-container">
          <Input
            autoFocus
            className="search-input"
            value={searchText}
            onChange={(e) => setSearchText(e?.target?.value)}
            onPressEnter={handleSearchApply}
            suffix={
              searchApplied ? (
                <CloseOutlined
                  onClick={handleSearchClear}
                  title="Clear Search"
                />
              ) : (
                <span />
              )
            }
          />
          <span className="search-helper-text">
            {trans("PRESSENTERTOSEARCH")}
          </span>
        </div>
      )}
      <div onClick={handleSearchToggle}>
        <SearchIcon className="search-icon" />
      </div>
      <Divider type="vertical" className="divider" />
      <div className="filter-container">
        {isFilterOn ? (
          <CustomDropdown
            open={true}
            menuItems={FilterMenuItems}
            selectedKeys={appliedFilter}
            handleChange={handleFilterChange}
            onClick={handleFilterToggle}
          >
            <>
              <FilterIcon className="filter-icon" />
              <span className="text">{trans("FILTERS")}</span>
            </>
          </CustomDropdown>
        ) : (
          <FilterIcon className="filter-icon" onClick={handleFilterToggle} />
        )}
      </div>
    </div>
  );

  const renderItemCounter = (
    <div className="item-count-container" style={{ padding: "10px", cursor:"default" }}>
      <span className="item-count">
        {searchApplied ? `${filteredList?.length ?? 0} found | ` : ""}
        {fetchingStaffList ? <LoadingOutlined /> : staffList.length ?? 0}{" "}
        {trans("TOTAL")}
      </span>
    </div>
  );

  const staff_role_translated = (role) => {
    switch (role) {
      case "Staff":
        return trans("STAFF");
      case "Staff Admin":
        return trans("STAFFADMIN");
      default:
        return role;
    }
  };

  const onResendEmailHandler = (staffuseruuid) => {
    const errorCallback = (error) => {
      const msg = `Error Sending Email details`;
      console.error(msg, error);
      message.error(msg);
    };
    const successCallback = (data) => {
      const msg = `Email Sent`;
      console.debug("onSuccess:", msg, data);
      message.success(msg);
      onActionClick(null, null, true)();
    };
    try {
      const user = {
        useruuid: staffuseruuid,
        updateprofile: true,
      };
      userApi.updateUser(
        user,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const renderStaff = (staff) => {
    const staffrole = staff.permission_level
      ? staff.permission_level
      : staff.rolename;
      const translatedRole = staff_role_translated(staffrole);
    return (
      <div key={staff.useruuid} className="item-container staff-container">
        <div className="container-1">
          <div className="user-info-container">
            <div className="image-container">
              {staff.profilePics[PhotoResolution.R64] ? (
                <img
                  src={staff.profilePics[PhotoResolution.R64]}
                  className="user-profile-pic"
                  alt=""
                />
              ) : (
                <ContactImage className="user-profile-pic" />
              )}
            </div>
            <div className="info-container">
              <div className="username">{getUsername(staff)}</div>
            </div>
          </div>
        </div>
        <div className="container-2">
          <div className="role" style={{display: "flex", alignItems: "left", cursor:"default"}}>
            <span
              className={
                translatedRole === trans("STAFF") ? "staffBadge" : "staffAdminBadge"
              }
            >
              {translatedRole}
            </span>
          </div>
          <div>
          <span className="provider">
            {formatString(staff.staffrole, StringFormats.pascal)}
          </span>
          </div>
          <div className="relation">{staff.relationship}</div>
          <div className="list-association-count-container">
            <AccountIcon className="count-icon" style={{color:"black"}}/>
            <div className="count-container">
              <span className="count-text">{staff.numofaccounts ?? 0}</span>
            </div>
          </div>
          <div className="pending-action-container">
            {staff?.emailVerified === false ? (
              <Tooltip title={trans("PENDINGINVITE")}>
                <div className="action">
                  <PendingIcon />
                </div>
              </Tooltip>
            ) : (
              <div className="empty-action" />
            )}</div>

          <Divider type="vertical" />
          <div className="actions-container">
            {staff?.emailVerified === false ? (
              <Tooltip title={trans("RESENDEMAIL")}>
                <div className="action">
                  <ResendEmailIcon
                    onClick={() => onResendEmailHandler(staff.useruuid)}
                  />
                </div>
              </Tooltip>
            ) : (
              <div className="empty-action" />
            )}
            {currentUser.useruuid !== staff.useruuid && allowCallStaff && staff?.emailVerified === true ? (
              <Tooltip title={trans("VIDEOCALL")}>
                <div
                  className="action"
                  onClick={() =>
                    dispatch(
                      startVideoCall({ callees: [staff], isStaff: true })
                    )
                  }
                >
                  <CallIcon />
                </div>
              </Tooltip>
            ) : (
              <div className="empty-action" />
            )}
            {allowCreateStaffEvent && staff?.emailVerified === true ? (
              <Tooltip title={trans("SCHEDULEEVENT")}>
                <div className="action">
                  <AddNewScheduleIcon
                    onClick={onActionClick(Actions.addEvent, staff)}
                  />
                </div>
              </Tooltip>
            ) : (
              <div className="empty-action" />
            )}
            {allowManageAccounts ? (
              <Tooltip title={trans("EDIT")}>
                <div className="action">
                  <EditNewIcon className="edit-icon" onClick={onActionClick(Actions.editStaff, staff)} />
                </div>
              </Tooltip>
            ) : (
              <div className="empty-action" />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <MainPage hasSider={true} header={header}>
      <div className="liststaff-wrapper page-content-wrapper">
        <div className="page-header-container">
          <span className="page-header">{trans("STAFF")}</span>
          {renderHeaderActions}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "10px 50px 20px 50px",
          }}
        >
          {isStaffActionAllowed(
            currentUser?.permission_level,
            KnownActionsList.manage_accounts,
            permissions
          ) && (
            <AddPlusIcon
              style={{ width: "60px", height: "60px", cursor:"pointer" }}
              onClick={onActionClick(Actions.addStaff, null)}
            />
          )}
          {renderItemCounter}
        </div>

        <div className="item-list-container staff-list-container">
          {fetchingStaffList ? (
            <div className="item-container skeleton-container">
              <Skeleton active avatar paragraph={false} />
            </div>
          ) : !filteredList.length ? (
            <Empty />
          ) : (
            filteredList.map(renderStaff)
          )}
        </div>
      </div>
    </MainPage>
  );
};

ListStaff.propTypes = {
  parentBread: PropTypes.arrayOf(PropTypes.object),
  staffList: PropTypes.arrayOf(PropTypes.object),
  fetchingStaffList: PropTypes.bool,
  onActionClick: PropTypes.func,
};

export default ListStaff;
