import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import "./Wellness.less";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment-timezone";
import titleData from "./statistic-title.json";
import PropTypes from "prop-types";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

function parseHour(formattedTime) {
  const [hm, ampm = "AM"] = formattedTime.split(" ");
  if (!hm) return 0;
  const [hh, mm = "0"] = hm.split(":");
  let hour = parseInt(hh, 10);
  const minute = parseInt(mm, 10) || 0;

  if (ampm.toUpperCase() === "PM" && hour < 12) {
    hour += 12;
  } else if (ampm.toUpperCase() === "AM" && hour === 12) {
    hour = 0;
  }
  // For finer spacing, do hour + minute/60
  return hour;
}

const WellnessHistoryInsightChart = ({
  modalData = [],
  userMeasurement = "blood_pressure",
  modalTitle = "blood_pressure",
  timezone = "America/New_York",
  selectedRange, 
  setSelectedRange,
}) => {
  const [summaryData, setSummaryData] = useState({});
  const [graphData, setGraphData] = useState(null);
  const [graphOptions, setGraphOptions] = useState({});
  const [loading, setLoading] = useState(true);    // Spinner control
  const [dataValues, setDataValues] = useState([]);
  const [maxDataValue, setMaxDataValue] = useState(0);

  /**
   * Format numeric values to a max of 5 characters.
   */
  const formatValue = (value) => {
    if (value === null || value === undefined) return "-";
    if (typeof value === "number") {
      const roundedValue = Math.round(value * 100) / 100; // round to 2 decimal places
      return roundedValue.toString().length > 5
        ? roundedValue.toFixed(2).slice(0, 5)
        : roundedValue.toString();
    }
    return value.toString().slice(0, 5);
  };

  const userTimezone = timezone;
    const todayStart = moment().tz(userTimezone).startOf("day").valueOf();
    const todayEnd = moment().tz(userTimezone).endOf("day").valueOf();
    const yesterdayStart = moment()
      .tz(userTimezone)
      .subtract(1, "days")
      .startOf("day")
      .valueOf();
    const yesterdayEnd = moment()
      .tz(userTimezone)
      .subtract(1, "days")
      .endOf("day")
      .valueOf();
      const lastWeekStart = moment().tz(userTimezone).subtract(6, "days").startOf("day").valueOf();
      const lastWeekEnd = moment().tz(userTimezone).endOf("day").valueOf();
      const lastMonthStart = moment().tz(userTimezone).subtract(29, "days").startOf("day").valueOf();
      const lastMonthEnd = moment().tz(userTimezone).endOf("day").valueOf();

  useEffect(() => {
    if (!modalData || !modalData.length) {
      // no data => no summary
      const r = {};
      if (modalTitle === "glucose_meter") {
        r["glucose_concentration"] = "-";
      }
      if (modalTitle === "weight_scale") {
        r["weight"] = "-";
      }
      if (modalTitle === "thermometer") {
        r["temperature"] = "-";
      }
      if (modalTitle === "pulse_oximeter") {
        r["oxygen"] = "-";
        r["pulse_rate"] = "-";
      }
      if (modalTitle === "blood_pressure") {
        r["systolic"] = "-";
        r["diastolic"] = "-";
        r["pulse"] = "-";
      }
      setSummaryData(r);
      return;
    }

    const todayData = modalData.filter((item) => {
      const ts = moment(item.utc_timestamp_inms).tz(userTimezone).valueOf();
      return (
        ts >= todayStart &&
        ts <= todayEnd &&
        item.data &&
        typeof item.data === "object"
      );
    });

    const yesterdayData = modalData.filter((item) => {
      const ts = moment(item.utc_timestamp_inms).tz(userTimezone).valueOf();
      return (
        ts >= yesterdayStart &&
        ts <= yesterdayEnd &&
        item.data &&
        typeof item.data === "object"
      );
    });

    const lastWeekData = modalData.filter((item) => {
      const ts = moment(item.utc_timestamp_inms).tz(userTimezone).valueOf();
      return (
        ts >= lastWeekStart &&
        ts <= lastWeekEnd &&
        item.data &&
        typeof item.data === "object"
      );
    });

    const lastMonthData = modalData.filter((item) => {
      const ts = moment(item.utc_timestamp_inms).tz(userTimezone).valueOf();
      return (
        ts >= lastMonthStart &&
        ts <= lastMonthEnd &&
        item.data &&
        typeof item.data === "object"
      );
    });

    const metrics =
      modalData[0] && modalData[0].data
        ? Object.keys(modalData[0].data)
        : [];

    const results = {};

    if (selectedRange === "today") {
      const arr = todayData.map((d) => d.data);
      metrics.forEach((m) => {
        const vals = arr.map((o) => o[m]).filter((v) => v != null);
        if (vals.length) {
          results[m] = {
            HIGH: formatValue(Math.max(...vals)),
            LOW: formatValue(Math.min(...vals)),
            AVG: formatValue(
              Math.round((vals.reduce((x, y) => x + y, 0) / vals.length) * 100) /
              100
            ),
          };
        } else {
          results[m] = "-";
        }
      });
    } else if (selectedRange === "yesterday") {
      const arr = yesterdayData.map((d) => d.data);
      metrics.forEach((m) => {
        const vals = arr.map((o) => o[m]).filter((v) => v != null);
        if (vals.length) {
          results[m] = {
            HIGH: formatValue(Math.max(...vals)),
            LOW: formatValue(Math.min(...vals)),
            AVG: formatValue(
              Math.round((vals.reduce((x, y) => x + y, 0) / vals.length) * 100) /
              100
            ),
          };
        } else {
          results[m] = "-";
        }
      });
    } else if (selectedRange === "lastweek"){
      const arr = lastWeekData.map((d) => d.data);
      metrics.forEach((m) => {
        const vals = arr.map((o) => o[m]).filter((v) => v != null);
        if (vals.length) {
          results[m] = {
            HIGH: formatValue(Math.max(...vals)),
            LOW: formatValue(Math.min(...vals)),
            AVG: formatValue(
              Math.round((vals.reduce((x, y) => x + y, 0) / vals.length) * 100) /
              100
            ),
          };
        } else {
          results[m] = "-";
        }
      });
    }
    else if (selectedRange === "lastmonth"){
      const arr = lastMonthData.map((d) => d.data);
      metrics.forEach((m) => {
        const vals = arr.map((o) => o[m]).filter((v) => v != null);
        if (vals.length) {
          results[m] = {
            HIGH: formatValue(Math.max(...vals)),
            LOW: formatValue(Math.min(...vals)),
            AVG: formatValue(
              Math.round((vals.reduce((x, y) => x + y, 0) / vals.length) * 100) /
              100
            ),
          };
        } else {
          results[m] = "-";
        }
      });
    }
    else {
      metrics.forEach((m) => {
        results[m] = "-";
      });
    }
    setSummaryData(results);
  }, [modalData, timezone, modalTitle, selectedRange]);

  useEffect(() => {
    // If no data => skip
    if (!modalData || !modalData.length) {
      setLoading(false);   // Done loading, but no data
      setGraphData({ labels: [], datasets: [] });
      setDataValues([]);
      setMaxDataValue(0);
      return;
    }

    let filteredData = [...modalData];
    const userTimezone = timezone;

    if (selectedRange === "today") {
      filteredData = modalData.filter(item => {
        const ts = moment(item.utc_timestamp_inms).tz(userTimezone).valueOf();
        return ts >= todayStart && ts <= todayEnd;
      });
    } else if (selectedRange === "yesterday") {
      filteredData = modalData.filter(item => {
        const ts = moment(item.utc_timestamp_inms).tz(userTimezone).valueOf();
        return ts >= yesterdayStart && ts <= yesterdayEnd;
      });
    } else if (selectedRange === "lastweek") {
      filteredData = modalData.filter(item => {
        const ts = moment(item.utc_timestamp_inms).tz(userTimezone).valueOf();
        return ts >= lastWeekStart && ts <= lastWeekEnd;
      });
    } else if (selectedRange === "lastmonth") {
      filteredData = modalData.filter(item => {
        const ts = moment(item.utc_timestamp_inms).tz(userTimezone).valueOf();
        return ts >= lastMonthStart && ts <= lastMonthEnd;
      });
    }

    // Sort data ascending by timestamp
    const sorted = filteredData.sort(
      (a, b) =>
        new Date(a.utc_timestamp_inms).getTime() -
        new Date(b.utc_timestamp_inms).getTime()
    );

    // Generate 7 days from Today in ascending order
    let dateRange = [];

    if (selectedRange === "today") {
      const start = moment(todayStart).tz(userTimezone);
      dateRange = [start.format("ddd M/D")];
    } else if (selectedRange === "yesterday") {
      const start = moment(yesterdayStart).tz(userTimezone);
      dateRange = [start.format("ddd M/D")];
    } else if (selectedRange === "lastweek") {
      for (let i = 6; i >= 0; i--) {
        dateRange.push(
          moment().tz(userTimezone).subtract(i, "days").format("ddd M/D")
        );
      }
    } else if (selectedRange === "lastmonth") {
      for (let i = 29; i >= 0; i--) {
        dateRange.push(
          moment().tz(userTimezone).subtract(i, "days").format("ddd M/D")
        );
      }
    }

    // Find the maximum timestamp in modalData
    const maxTimestamp = Math.max(
      ...modalData.map(item => new Date(item.utc_timestamp_inms).getTime())
    );

    // Use maxTimestamp as the reference date
    const latestDate = moment(maxTimestamp).tz(timezone).add(1, "day");
    let last7Days = [];
    if (selectedRange === "lastweek") {
      for (let i = 6; i >= 0; i--) {
        last7Days.push(
          moment().tz(timezone).subtract(i, "days").format("ddd M/D")
        );
      }
    } else if (selectedRange === "today") {
      last7Days = [moment().tz(timezone).format("ddd M/D")];
    } else if (selectedRange === "yesterday") {
      last7Days = [moment().tz(timezone).subtract(1, "day").format("ddd M/D")];
    } else if (selectedRange === "lastmonth") {
      for (let i = 29; i >= 0; i--) {
        last7Days.push(
          moment().tz(timezone).subtract(i, "days").format("ddd M/D")
        );
      }    } 

    // Find relevant metrics
    const relevantMetrics = Object.keys(titleData[userMeasurement] || {});
    const metrics = relevantMetrics.filter((m) =>
      sorted.some((item) => item.data && item.data.hasOwnProperty(m))
    );
    // Fallback if none found
    if (!metrics.length && sorted[0]?.data) {
      metrics.push(...Object.keys(sorted[0].data));
    }

    // Convert each item to consistent date format
    const consistentData = sorted.map((entry) => {
      const dayStr = moment(entry.utc_timestamp_inms)
        .tz(timezone)
        .format("ddd M/D");
      return {
        ...entry,
        consistentDate: dayStr,
      };
    });

    // Build datasets
    const datasets = metrics.map((metric) => {
      const dailyVals = last7Days.map((dayStr) => {
        const sameDayItems = consistentData.filter(
          (item) => item.consistentDate === dayStr
        );
        if (!sameDayItems.length) return null;
        const latest = sameDayItems[sameDayItems.length - 1];
        return latest.data[metric] ?? null;
      });

      function getRandomDarkColor() {
        // Keep each RGB component between 0 and 127 for a darker shade
        const r = Math.floor(Math.random() * 128);
        const g = Math.floor(Math.random() * 128);
        const b = Math.floor(Math.random() * 128);

        return `rgb(${r}, ${g}, ${b})`;
      }

      return {
        label: titleData[userMeasurement][metric] || metric,
        data: dailyVals,
        borderColor:
          metric === "systolic"
            ? "#FF6B6B"
            : metric === "diastolic"
              ? "#36A2EB"
              : metric === "pulse"
                ? "#4BC0C0"
                : getRandomDarkColor(),
        backgroundColor: "white",
        borderWidth: 1.5,
        pointRadius: 5,
        pointHoverRadius: 6,
        tension: 0.4,
      };
    });

    // Build graph data
    const newGraphData = {
      labels: last7Days,
      datasets,
    };
    setGraphData(newGraphData);

    // max y-value
    const allVals = [];
    datasets.forEach((ds) => {
      ds.data.forEach((val) => {
        if (val != null && !isNaN(val)) {
          allVals.push(val);
        }
      });
    });
    setDataValues(allVals);
    setMaxDataValue(allVals.length ? Math.max(...allVals) : 0);

    // Now done loading, we have valid data
    setLoading(false);
  }, [modalData, userMeasurement, timezone, selectedRange]);

  /************************************************************
   * 3) Final transform: Build x-axis in hours from day index
   ************************************************************/
  useEffect(() => {
    if (
      !graphData ||
      !Array.isArray(graphData.labels) ||
      !Array.isArray(graphData.datasets)
    ) {
      setGraphOptions({});
      return;
    }

    const finalLabels = graphData.labels;
    const finalDatasets = graphData.datasets;

    // dayIndexMap => "Mon 5/01" => 0..6
    const dayIndexMap = {};
    finalLabels.forEach((dayStr, idx) => {
      dayIndexMap[dayStr] = idx;
    });

    // Build dayTimeMap => { "Mon 5/01": { hour, formattedTime } }
    const sorted = [...modalData].sort(
      (a, b) =>
        new Date(a.utc_timestamp_inms).getTime() -
        new Date(b.utc_timestamp_inms).getTime()
    );
    const dayTimeMap = {};
    sorted.forEach((entry) => {
      const dayStr = moment(entry.utc_timestamp_inms)
        .tz(timezone)
        .format("ddd M/D");
      if (!dayIndexMap.hasOwnProperty(dayStr)) return;
      const hh = parseHour(entry.formatted_time || "");
      dayTimeMap[dayStr] = {
        hour: hh,
        formattedTime: entry.formatted_time,
      };
    });

    // Convert each dataset => x,y
    const newDatasets = finalDatasets.map((ds) => {
      const newData = ds.data.map((val, i) => {
        const dayStr = finalLabels[i];
        if (val == null) {
          return { x: i * 24, y: null, day: dayStr, time: "" };
        }
        const dtObj = dayTimeMap[dayStr] || { hour: 0, formattedTime: "" };
        return {
          x: i * 24 + dtObj.hour,
          y: val,
          day: dayStr,
          time: dtObj.formattedTime,
        };
      });
      return {
        ...ds,
        data: newData,
        showLine: true,
        spanGaps: false,
      };
    });

    const finalChartData = {
      datasets: newDatasets,
    };

    // x-range => (7-1)*24 = 144
    const maxX = (selectedRange === "lastweek" || selectedRange === "lastmonth") ? (finalLabels.length - 1) * 24 : 24;

    const finalOptions = {
      responsive: true,
      maintainAspectRatio: false,
      // animation: false,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            font: {
              size: 14,
              family: "Arial",
            },
            color: "#005D98",
            usePointStyle: true,
          },
        },
        tooltip: {
          enabled: true,
          callbacks: {
            // Title => day label
            title: (items) => {
              const p = items[0]?.raw;
              return p?.day || "";
            },
            // Label => "Systolic mmHg (10:58 AM): 129"
            label: (ctx) => {
              const p = ctx.raw;
              if (p?.y == null) return `${ctx.dataset.label}: N/A`;
              return `${ctx.dataset.label} (${p.time}): ${formatValue(p.y)}`;
            },
          },
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        x: {
          type: "linear",
          min: 0,
          max: maxX,
          grid: {
            color: "#005D98",
            drawTicks: false,
          },
          ticks: {
            stepSize: 24,
            color: "#005D98",
            font: {
              size: 12,
              family: "Arial",
            },
            callback: function (value) {
              if (selectedRange === "lastweek") {
                const i = Math.floor(value / 24);
                return finalLabels[i] || "";
              } else if(selectedRange === "lastmonth") {
                const i = Math.floor(value / 24);
                return i % 3 === 0 ? finalLabels[i] || "" : "";
              } else {
                return finalLabels[0]; // Only one label for today/yesterday
              }
            },
          },
          border: {
            color: "#005D98",
            dash: [3, 5],
          },
        },
        y: {
          grid: {
            color: "#005D98",
            lineWidth: 1,
            drawTicks: false,
            z: 100,
          },
          ticks: {
            color: "#005D98",
            font: {
              size: 12,
              family: "Arial",
            },
          },
          border: {
            dash: [3, 5],
          },
          beginAtZero: true,
          suggestedMax: maxDataValue + 11,
        },
      },
    };

    setGraphOptions(finalOptions);
    setGraphData(finalChartData);
  }, [graphData, modalData, maxDataValue, selectedRange]);

  return (
    <div className="modal-insight-chart-tab-content">
      <div className="modal-insight-chart-container">
            <div className="wellness-insight-chart-view-options-container">
        <div className="wellness-insight-chart-view-block">
          <div className={`wellness-insight-chart-view-option ${selectedRange === "today" ? "selected-option" : ""}`} onClick={() => setSelectedRange("today")}>
            <span className={`wellness-insight-chart-view-option-label ${selectedRange === "today" ? "selected" : ""}`}>Today</span>
          </div>
          <div className={`wellness-insight-chart-view-option ${selectedRange === "lastweek" ? "selected-option" : ""}`} onClick={() => setSelectedRange("lastweek")}>
            <span className={`wellness-insight-chart-view-option-label ${selectedRange === "lastweek" ? "selected" : ""}`}>Last week</span>
          </div>
          <div className={`wellness-insight-chart-view-option ${selectedRange === "lastmonth" ? "selected-option" : ""}`} onClick={() => setSelectedRange("lastmonth")}>
            <span className={`wellness-insight-chart-view-option-label ${selectedRange === "lastmonth" ? "selected" : ""}`}>Last month</span>
          </div>
        </div>
      </div>
        {/* SUMMARY CARD */}
        <div className="modal-insight-chart-summary-card">
          <div className="modal-insight-chart-summary-title">
          Summary
          </div>
          <div className="modal-insight-chart-summary-row">
            <div className="modal-insight-chart-summary-item">
              <div className="modal-insight-chart-summary-item-label">
                <div className="modal-insight-chart-label" />
              </div>
              <div className="modal-insight-chart-summary-item-value-header">
                <div className="modal-insight-chart-value avg-header">Avg</div>
                <div className="modal-insight-chart-value low-header">Low</div>
                <div className="modal-insight-chart-value high-header">High</div>
              </div>
            </div>
            {Object.keys(summaryData).map((m) => {
              const item = summaryData[m];
              if (typeof item === "object") {
                return (
                  <div className="modal-insight-chart-summary-item" key={m}>
                    <div className="modal-insight-chart-summary-item-label">
                      <div className="modal-insight-chart-label">
                        {titleData[userMeasurement][m]}
                      </div>
                    </div>
                    <div className="modal-insight-chart-summary-item-value">
                      <div className="modal-insight-chart-value avg">
                        {item["AVG"] ?? item}
                      </div>
                      <div className="modal-insight-chart-value low">
                        {item["LOW"] ?? item}
                      </div>
                      <div className="modal-insight-chart-value high">
                        {item["HIGH"] ?? item}
                      </div>
                    </div>
                  </div>
                );
              }
              // if string => no data
              return (
                <div className="modal-insight-chart-summary-item" key={m}>
                  <div className="modal-insight-chart-summary-item-label">
                    <div className="modal-insight-chart-label">
                      {titleData[userMeasurement][m]}
                    </div>
                  </div>
                  <div className="modal-insight-chart-summary-item-value">
                    <div className="modal-insight-chart-value avg">{item}</div>
                    <div className="modal-insight-chart-value low">{item}</div>
                    <div className="modal-insight-chart-value high">{item}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* CHART AREA */}
        <div className="modal-insight-chart-chart-card">
          {/* Loader if still loading or if modalData is not ready */}
          {loading || !graphData ? (
            <Spin spinning={true} style={{ margin: "70px -20px" }} />
          ) : (
            <Line data={graphData} options={graphOptions} />
          )}
        </div>
      </div>
    </div>
  );
};

WellnessHistoryInsightChart.propTypes = {
  modalData: PropTypes.array,
  userMeasurement: PropTypes.string,
  modalTitle: PropTypes.string,
  timezone: PropTypes.string,
}

export default WellnessHistoryInsightChart;
