import React from "react";

export const NewRemoveIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1338 9.69141C9.62244 9.69141 9.21338 10.1005 9.21338 10.6118V16.1751C9.21338 16.6864 9.62244 17.0955 10.1338 17.0955C10.6451 17.0955 11.0542 16.6864 11.0542 16.1751V10.6118C11.0542 10.1005 10.6451 9.69141 10.1338 9.69141Z"
        fill="#005D98"
      />
      <path
        d="M13.8462 9.69141C13.3348 9.69141 12.9258 10.1005 12.9258 10.6118V16.1751C12.9258 16.6864 13.3348 17.0955 13.8462 17.0955C14.3575 17.0955 14.7666 16.6864 14.7666 16.1751V10.6118C14.7666 10.1005 14.3575 9.69141 13.8462 9.69141Z"
        fill="#005D98"
      />
      <path
        d="M19.4194 5.98086H15.6969C15.6969 4.44687 14.4492 3.19922 12.9255 3.19922H11.0745C9.54046 3.19922 8.30304 4.44687 8.30304 5.97063H4.58055C4.06922 5.97063 3.66016 6.3797 3.66016 6.89103C3.66016 7.40236 4.06922 7.81142 4.58055 7.81142H5.5214V18.0278C5.5214 19.5618 6.76905 20.7992 8.29281 20.7992H15.7173C17.2513 20.7992 18.4887 19.5516 18.4887 18.0278V7.82165H19.4194C19.9307 7.82165 20.3398 7.41258 20.3398 6.90125C20.3398 6.38992 19.9307 5.98086 19.4194 5.98086ZM10.1336 5.97063C10.1336 5.4593 10.5529 5.02978 11.0745 5.02978H12.9255C13.4368 5.02978 13.8663 5.44907 13.8663 5.97063H10.1438H10.1336ZM16.6377 18.038C16.6377 18.5494 16.2184 18.9687 15.6969 18.9687H8.27236C7.76103 18.9687 7.34174 18.5494 7.34174 18.038V7.82165H16.6377V18.038Z"
        fill="#005D98"
      />
    </svg>
  );
};
