import React, { useState } from "react";
import PropTypes from "prop-types";
import { AddIcon, CloseIcon, NewContactsIcon } from "./Icons";
import Loading from "./Loading";
import Contact from "./Contact";
import { getUsername } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { Divider, Input, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const getSelectedListStyle = (onlySelected) => {
  return {
    ...(onlySelected ? {} : { maxHeight: "50%" }),
  };
};

const ContactSelectList = ({
  style,
  className,
  fetchingContacts = false,
  displayOnlySelected = false,
  selectedListHeader = "Included Contacts",
  selectionListHeader = "Add Contacts",
  selectedContactList = [],
  selectionContactList = [],
  showAddIcon = true,
  showRemoveIcon = true,
  onAddClick = () => {},
  onRemoveClick = () => {},
  onAddAllContacts = () =>{},
  selectedContactProps = {},
  selectionContactProps = {},
  showRelationship = true,
  showAddIconCondition = () => true,
  showRemoveIconCondition = () => true,
  assignStaffAction = false,
  selectedUser = "",
  hubRecord = {},
  starteventAction = false,
}) => {
  const isEmpty = assignStaffAction ? selectedContactList !== null : (Array.isArray(selectedContactList) && selectedContactList.length !== 0);
  const [showAllContacts, setShowAllContacts] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [searchApplied, setSearchApplied] = useState(false);
  const [filteredContacts, setFilteredContacts] = useState(selectionContactList);

  // Determine the list of contacts to display
    const displayedContacts = selectionContactList;

  // Handle search input and filter contacts
  const handleSearchApply = () => {
    if (!searchText.trim()) {
      setFilteredContacts(selectionContactList);
      setSearchApplied(false);
      return;
    }

    const filtered = selectionContactList.filter((contact) =>
      getUsername(contact).toLowerCase().includes(searchText.toLowerCase())
    );

    setFilteredContacts(filtered);
    setSearchApplied(true);
  };
// Clear search input and reset contact list
const handleClearSearch = () => {
  setSearchText("");
  setFilteredContacts(selectionContactList);
  setSearchApplied(false);
};
  return (
    <div style={style} className={`contact-selection-list-wrapper ${className ?? ""}`}>
      {/* Add Participants label with close icon */}
      {showAllContacts && (
        <div className="add-participant">
          <span className="add-participant-label">Add other participants</span>
          <span className="remove-expanded-list">
            <CloseIcon
            className="close-icon"
            onClick={() => {
              setShowAllContacts(false); // Revert to showing only 4 contacts
            }}
          /></span>
        </div>
      )}

      {/* Selected List Header */}
      {selectedListHeader && <label className="selected-header">{!showAllContacts?selectedListHeader: "Participants added"}</label>}      
      <div  className={`${ isEmpty ? "selected-list-container" :"selected-list-empty"}`}
      style={getSelectedListStyle(displayOnlySelected)}>
        {selectedContactList?.map((item) => (
          <Contact
            key={"selected_" + item.useruuid}
            className="primary"
            contact={item}
            hubRecord={hubRecord}
            starteventAction={starteventAction}
            actionIcon={
              showRemoveIcon &&
              showRemoveIconCondition(item) && (
                <CloseIcon className="close-icon" onClick={() => onRemoveClick(item)} />
              )
            }
            showRelationship={true}
            {...selectedContactProps}
          />
        ))}
      </div>

      {starteventAction && <Divider className="contacts-divider" />}
      
      {!displayOnlySelected && (
        <>          
          {selectionListHeader && <label className="selection-list-header">{selectionListHeader}</label>}
          
          {fetchingContacts ? (
            <Loading />
          ) : (
            <div className="selection-list-container">
              <div className="account-name">
                <NewContactsIcon />
                <span className="username">
                  <span className="username-truncate">
                    <Tooltip
                      title={selectedUser}
                    >{`From ${selectedUser}s’ contacts`}</Tooltip>
                  </span>
                </span>
              </div>
              {/* "Find a contact" label and search input */}
              <div className="contact-search-container">
                <label className="find-a-contact">Find a contact</label>
                <div className="contact-input-search">
                  <Input
                    className="search-input"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onPressEnter={() => handleSearchApply()} // Apply search on Enter key
                    placeholder="Search a contact" // Placeholder text for the search input
                    suffix={
                      searchApplied ? (
                        <CloseOutlined
                          onClick={handleClearSearch}
                          title="Clear Search"
                        />
                      ) : (
                        <></>
                      )
                    }
                  />
                </div>
              </div>
              <div className="contact-list-scroll">
              {searchApplied && filteredContacts.length === 0 ? (
                <div className="no-filtered-contacts-container">
                <span className="no-contact-available">
                  No contacts found
                </span>
              </div>
              ) : (
              (searchApplied ? filteredContacts : displayedContacts).map((item) => (
                <Contact
                  key={"contact_" + item.useruuid}
                  onClick={!item.disabled && (item.onAddIconClick ?? onAddClick)}
                  contact={item}
                  hubRecord={hubRecord}
                  starteventAction={starteventAction}
                  actionIcon={
                    item.addIcon ??
                    (showAddIcon && showAddIconCondition(item) && (
                      <AddIcon className="add-icon" />
                    ))
                  }
                  showRelationship={true}
                  {...selectionContactProps}
                />
              )))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

ContactSelectList.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  fetchingContacts: PropTypes.array,
  displayOnlySelected: PropTypes.bool,
  selectedListHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  selectionListHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  selectedContactList: PropTypes.arrayOf(PropTypes.object),
  selectionContactList: PropTypes.arrayOf(PropTypes.object),
  showAddIcon: PropTypes.bool,
  showRemoveIcon: PropTypes.bool,
  onAddClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  selectedContactProps: PropTypes.object,
  selectionContactProps: PropTypes.object,
  showRelationship: PropTypes.bool,
  onAddAllContacts: PropTypes.func,  
};

export default ContactSelectList;
