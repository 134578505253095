class DesktopNotification {
  constructor() {
    this.isSupported = "Notification" in window;
    this.enabled = false;
    this.notifications = new Map();
  }

  requestPermission = () => {
    if (!this.isSupported) {
      console.warn("NOTIFICATIONS: Desktop notifications not supported!");
      return;
    }
    if (Notification?.permission === "denied") {
      console.info("NOTIFICATIONS: Desktop notifications are not allowed!");
      this.enabled = false;
    } else if (Notification?.permission === "granted") {
      this.enabled = true;
      console.info("NOTIFICATIONS: Desktop notifications are allowed!");
    } else {
      Notification?.requestPermission().then((permission) => {
        if (permission === "granted") {
          this.enabled = true;
          console.info("NOTIFICATIONS: Desktop notifications are allowed!");
        } else {
          this.enabled = false;
          console.info("NOTIFICATIONS: Desktop notifications are not allowed!");
        }
      });
    }
  };

  showNotification = ({ key, title, options, timeout }, params) => {
    if (!this.isSupported) {
      console.warn("NOTIFICATIONS: Desktop notifications not supported!");
      return;
    }
    if (!this.enabled) {
      console.info("NOTIFICATIONS: Desktop notifications disabled!");
      return;
    }

    /* remove existing notification */
    if (this.notifications.has(key)) {
      this.notifications.get(key).close();
      this.notifications.delete(key);
    }
    const { onClick, focusOnClick = true, onClose } = params ?? {};
    const notification = new Notification(title, {
      ...options,
      tag: options.tag ?? key + "_" + Date.now(),
    });

    notification.onclick = () => {
      console.debug("Notification clicked:", title, key);
      if (focusOnClick && !window.document.hasFocus()) {
        window.focus();
      }
      if (onClick && typeof onClick === "function") {
        onClick();
      }
    };
    notification.onclose = () => {
      console.debug("Notification closed:", title, key);
      if (onClose && typeof onClose === "function") {
        onClose();
      }
    };
    if (timeout > 0) {
      setTimeout(() => {
        notification.close();
        this.notifications.delete(key);
        console.debug("Notification timedout: ", title, key);
      }, timeout);
    }
    this.notifications.set(key, notification);
  };

  closeNotification = (key) => {
    if (this.notifications.has(key)) {
      this.notifications.get(key).close();
      this.notifications.delete(key);
    } else {
      console.debug("No notification found for key:", key);
    }
  };

  closeAllNotification = () => {
    this.notifications.forEach((n) => n.close());
    this.notifications.clear();
  };
}

const desktopNotification = new DesktopNotification();

export default desktopNotification;
