import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input, Tooltip, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { getUsername } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import Contact from "../../Common/Contact";
import Loading from "../../Common/Loading";

const ContactSelectionForStaff = ({
  fetchingContacts = false,
  selectionContactList = [],
  onAddStaff = () => {},
  setSelectedStaff,
  selectedStaff,
  hubRecord,
  setSearchNameApplied,
  searchNameApplied,
  setSearchNameText,
  searchNameText,
}) => {  
  const [filteredContacts, setFilteredContacts] =
    useState(selectionContactList);
  const handleSearchApply = () => {
    if (!searchNameText.trim()) {
      setFilteredContacts(selectionContactList);
      setSearchNameApplied(false);
      return;
    }

    const filtered = selectionContactList.filter((contact) =>
      getUsername(contact).toLowerCase().includes(searchNameText.toLowerCase())
    );
    setFilteredContacts(filtered);
    setSearchNameApplied(true);
  };

  const handleClearSearch = () => {
    setSearchNameText("");
    setFilteredContacts(selectionContactList);
    setSearchNameApplied(false);
  };

  const handleSelectContact = (contact) => {
    setSelectedStaff(contact);
    onAddStaff(contact);
  };

  return (
    <div className="new-contact-selection-main-container">
      <div className="new-contact-search-container">
        <label className="select-a-staff-label">Select a Staff member</label>
        <div className="new-contact-search-input">
          <Input
            className="new-search-input"
            value={searchNameText}
            onChange={(e) => setSearchNameText(e.target.value)}
            onPressEnter={handleSearchApply}
            placeholder="Search by name"
            suffix={
              searchNameApplied ? (
                <CloseOutlined onClick={handleClearSearch} />
              ) : null
            }
          />
        </div>
      </div>

      <div className="new-contact-list-container">
        {fetchingContacts ? (
          <Loading />
        ) : searchNameApplied && filteredContacts.length === 0 ? (
          <div className="no-filtered-contacts-container">
            <span className="no-contact-available">No contacts found</span>
          </div>
        ) : (
          (searchNameApplied ? filteredContacts : selectionContactList).map(
            (item) => {
              const isSelected =
              selectedStaff?.useruuid === item.useruuid;
              return (
                <div
                  key={item.useruuid}
                  onClick={() => handleSelectContact(item)}
                >
                  <Contact
                    contact={item}
                    assignstaff={true}
                    className={`${isSelected ? "selected-staff-contact" : "new-clickable-item"}`}
                    hubRecord={hubRecord}
                  />
                </div>
              );
            }
          )
        )}
      </div>
    </div>
  );
};

ContactSelectionForStaff.propTypes = {
  fetchingContacts: PropTypes.bool,
  selectedStaff: PropTypes.array,
  selectionContactList: PropTypes.array,
  onAddStaff: PropTypes.func.isRequired,
  selectedUser: PropTypes.string,
};

export default ContactSelectionForStaff;
