import React from "react";
import "./Wellness.less";
import { Modal, Tabs, Spin } from "antd";
import unitTitleData from "./statistic-title.json";

const WellnessHistoryTableData = ({ modalLoading, modalData, userMeasurement, deviceObj }) => {
  console.log('wellness__modal__data',modalData);
  return (
    <>
      {(modalLoading ) ? <Spin spinning={modalLoading} style={{ margin: "20px" }} > </Spin > :  modalData.length === 0 ? <div className="tab-content"> <p className="no_data"> No Data Found</p> </div> : <div className="tab-content">
        <div className="modal-wellness-item-content">
          <div className="modal-wellness-item-content-header">
            <div className="modal-wellness-item-content-day-info">{modalData[0]['formatted_date']}</div>
            <div className="modal-wellness-item-content-divider">
              <div className="vertical-divider"></div>
            </div>
            <div className="modal-wellness-item-content-time-info">{`${modalData[0]['formatted_time']} ( ${modalData[0]['timeDiffText']} )` } </div>
          </div>
          <div className="modal-wellness-item-content-statistic">
            {Object.keys(modalData[0]['data']).map((item,index) => {
              return (
                <div className="modal-wellness-item-content-statistic-info" key={index}>
                  <div className="modal-wellness-item-content-statistic-info-label">{unitTitleData[userMeasurement][item]}</div>
                  <div className="modal-wellness-item-content-statistic-info-value">{String(modalData[0]['data'][item]).slice(0, 5)}</div>
                </div>
              )
            })}
          </div>
          <div className="wellness-item-content-device">
            <div className="wellness-item-content-device-title">Device used</div>
            <div className="wellness-item-content-device-model-info">
              <div className="wellness-item-content-device-model-title">{deviceObj.deviceBrand}</div>
              <svg width="2" height="32" viewBox="0 0 2 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0V56" stroke="#C9C9C9" />
              </svg>
              <div className="wellness-item-content-device-model-model"> {deviceObj.deviceModel}</div>
            </div>
          </div>
        </div>
        <div className="modal-wellness-card-container">
          <div className="modal-horizontal-divider"></div>
          <div className="modal-table-data-history-container">

            {modalData.map((items, index) => {
              return (
                <div key={index} className="modal-wellness-list-item-content">
                  <div className="modal-wellness-item-content-header">
                    <div className="modal-wellness-item-content-day-info">{items['formatted_date']}</div>
                    <div className="modal-wellness-item-content-divider">
                      <div className="vertical-divider"></div>
                    </div>
                    <div className="modal-wellness-item-content-time-info">{`${items['formatted_time']} `}</div>
                  </div>
                  <div className="modal-wellness-item-content-statistic">
                    {Object.keys(items.data).map((item) => {
                      return (
                        <div className="modal-wellness-item-content-statistic-info">
                          <div className="modal-wellness-item-content-statistic-info-value">{String(modalData[index]['data'][item]).slice(0, 5)}</div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="wellness-item-content-device">
                    <div className="wellness-item-content-device-title"></div>
                    <div className="wellness-item-content-device-model-info">
                      <div className="wellness-item-content-device-model-title">{deviceObj.deviceBrand}</div>
                      <svg width="2" height="32" viewBox="0 0 2 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 0V56" stroke="#C9C9C9" />
                      </svg>
                      <div className="wellness-item-content-device-model-model">{deviceObj.deviceModel}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>}


    </>
  );
};

export default WellnessHistoryTableData;
