import React from "react";
import { DeviceUnpairIcon, NewClearAllHistoryIcon } from "../Common/Icons";
import { useUserRole, hasStaffPrivileges } from "./helpers";
import { getConfirmationMessage } from "./DeviceInventoryModal";
import { Tooltip } from "antd";
import { StatusInfo } from "./DeviceInventoryList";

const MedicalDeviceModalInfo = ({
  device,
  performAction,
  actionsData,
  hubRecord,
  hideModal,
  ConfirmationModal,
  isActionModalVisible,
  activeDeviceAction,
  handleDeviceActionClick,
  deviceStatus,
  timezone,
  loginUserTimeformat
}) => {
  const userRole = useUserRole();

  const handleUnpairMedicalDevice = (device) => {
    let data = {
      ...actionsData.unpair_medical_device,
      deviceUuid: hubRecord?.deviceuuid,
    };
    const params =
    actionsData.unpair_medical_device.action === "perform_unPairAccessories"
      ? {
          params: [
            { name: "devicemodel", value: device?.modelnumber },
            { name: "macaddress", value: device?.basemacaddress },
          ],
        }
      : {};
    performAction(data, params);
    hideModal("unpair_medical_device");
  };

  const handleClearAllHistory = (device) => {
    let data = {
      ...actionsData.unpair_medical_device,
      deviceUuid: hubRecord?.deviceuuid,
    };
    performAction(data);
    hideModal("unpair_medical_device");
  };

  return (
    <>
      {hasStaffPrivileges(userRole) && device?.paired_status !== "UNPAIRED" && (
        <div
          className={`device-actions-container ${
            device?.paired_status === "UNPAIRED" ? "device-details-transparent" : ""
          }`}
        >
          <div className="device-action-block">
            <div className="device-action-command-description">
              <div className="device-action-label">Actions</div>
              <div className="device-action-description-block">
                {/* <div className="medical-action-description-label">
                  <div className="medical-action-title">
                    {`  “Clear all History”`}
                    <span className="medical-action-desc">{` : clear all data  by clicking on the button`}</span>
                  </div>
                </div> */}
                <div className="medical-action-description-label">
                  <div className="medical-action-title">
                    {` “Unpair”`}
                    <span className="medical-action-desc">{` : unpair the sensor by clicking on the unpair button`}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="medical-actions-container">
            <Tooltip
                title={
                  deviceStatus === "disconnected" ? (
                    <StatusInfo
                      lastConnectTime={device?.lastconnecttime}
                      lastDisconnectTime={device?.lastdisconnecttime}
                      device={device}
                      timezone={timezone}
                      loginUserTimeformat={loginUserTimeformat}
                      showDuration={false}
                      tooltipMode={true}
                    />
                  ) : (
                    ""
                  )
                }
              >
              {/* <div
                className="clear-all-history-icon-label"
                onClick={() => handleDeviceActionClick("factory_reset_hc200")}
              >
                <span>
                  <NewClearAllHistoryIcon className="new-factory-reset-icon" />
                </span>
                <div className="unpair-action-label">Clear all History</div>
              </div> */}
              <span>
                <div
                  className={`device-action-icon-label ${
                    deviceStatus === "disconnected"
                      ? "device-page-actions-disabled"
                      : ""
                  }`}
                  onClick={() =>
                    deviceStatus !== "disconnected" &&
                    handleDeviceActionClick("unpair_medical_device")
                  }
                >
                  <DeviceUnpairIcon
                    className="unpair-icon"
                    strokeColor={
                      deviceStatus === "disconnected" ? "#808080" : "#005d98"
                    }
                    fillColor={
                      deviceStatus === "disconnected" ? "#808080" : "#0072BC"
                    }
                  />
                  <div className="unpair-action-label">Unpair</div>
                </div>
              </span>
              </Tooltip>
            </div>
          </div>
        </div>
      )}

      <ConfirmationModal
        visible={isActionModalVisible[activeDeviceAction]}
        title={
          <span className="custom-modal-title">
            {getConfirmationMessage(actionsData[activeDeviceAction]?.title?.toLowerCase())}
          </span>
        }
        description={actionsData[activeDeviceAction]?.description}
        onConfirm={() =>
          activeDeviceAction === "unpair_medical_device"
            ? handleUnpairMedicalDevice(device)
            : handleClearAllHistory(device)
        }
        onCancel={() => hideModal(activeDeviceAction)}
        customLabel={actionsData[activeDeviceAction]?.title}
        customIcon={
          activeDeviceAction === "unpair_medical_device" ? (
            <DeviceUnpairIcon fillColor="#113E57" strokeColor="#113E57" />
          ) : (
            <NewClearAllHistoryIcon />
          )
        }
        activeDeviceAction={activeDeviceAction}
      />
    </>
  );
};

export default MedicalDeviceModalInfo;