import React from "react";

export const ProceedCallIcon = (props) => {
    const { className = "", disabled = false, onClick, style = {} } = props;
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9995 16.3879V13.9793C18.0093 13.5855 17.8737 13.2019 17.6185 12.9014C16.8991 12.0539 14.4315 11.5118 13.4534 11.72C12.691 11.8823 12.1575 12.6478 11.6359 13.1684C9.6219 12.0254 7.95429 10.3611 6.80906 8.35095C7.33058 7.83049 8.09767 7.29811 8.2603 6.53715C8.46857 5.56247 7.92723 3.11066 7.08562 2.3883C6.78959 2.13422 6.41108 1.99624 6.02066 2.00008H3.60721C2.68762 2.00094 1.92256 2.82573 2.00628 3.75039C2 11.72 8.22226 18 16.2457 17.9937C17.1753 18.0776 18.0032 17.3087 17.9995 16.3879Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.7862 3.28292C13.6177 3.1886 13.4615 3.07371 13.3218 2.94149C13.0396 2.67426 12.8152 2.33238 12.6548 1.92549L12.4213 1.33301L12.1878 1.92554C12.0274 2.33248 11.803 2.67436 11.5208 2.94149C11.3805 3.07436 11.2243 3.18935 11.0567 3.28322L10.6666 3.50182L11.0567 3.72042C11.2244 3.81439 11.3805 3.92933 11.5208 4.06215C11.8029 4.32923 12.0273 4.67106 12.1878 5.07805L12.4213 5.67059L12.6548 5.07805C12.8152 4.67116 13.0396 4.32928 13.3218 4.0621C13.4621 3.92928 13.6184 3.81424 13.7863 3.72017L14.1767 3.50152L13.7862 3.28292Z" fill="white" />
            <path d="M17.8861 5.87767C17.7149 5.78175 17.5561 5.66506 17.4143 5.53085C17.1247 5.25663 16.8935 4.90371 16.7274 4.48204L16.2602 3.29688L15.7931 4.48204C15.6269 4.90366 15.3958 5.25658 15.1061 5.53085C14.9636 5.66576 14.8051 5.78255 14.6348 5.87792L13.8546 6.31512L14.6349 6.75227C14.8052 6.84769 14.9637 6.96448 15.1061 7.09934C15.3958 7.37366 15.627 7.72653 15.7932 8.14815L16.2603 9.33331L16.7274 8.14815C16.8936 7.72648 17.1248 7.37361 17.4145 7.09929C17.557 6.96433 17.7158 6.84744 17.8862 6.75202L18.6667 6.31477L17.8861 5.87767Z" fill="white" />
        </svg>

    );
};
