/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import {
  AccountIcon,
  BadgeIcon,
  ContactImage,
  DevicesNewIcon,
  GearIcon,
  HomeSightNewLogo,
  NewHelpIcon,
  NewLogoutIcon,
  NewScheduleIcon,
  ContactsIcon,
  CallIcon,
  DeviceInventoryIcon,
} from "./Components/Common/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserInfo,
  getProfilePics,
} from "@commscopemycloud/humaui/Store/dataStore";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import useUserInfo from "./Components/Hooks/useUserInfo";
import {
  formatString,
  getUsername,
  getProfilePicUrl,
  getName,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import {
  NavigationKeys,
  StringFormats,
  UserPermissionLevelDisplay,
  UserTypes,
  PhotoResolution,
  sessionType,
  UserRoles,
  UserPermissionLevel,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { getNotificationMiniBannerMessage } from "@commscopemycloud/humaui/Store/notificationStore";
import { setProviderData } from "@commscopemycloud/humaui/Store/providerDataStore";
import moment from "moment";
import useSchedule from "./Components/Hooks/useSchedule";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import { Tooltip } from "antd";
import NotificationMiniBanner from "./Components/Notifications/NotificationMiniBanner";
import { onLogout } from "@commscopemycloud/humaui/Services/Common";
import { MotionActivityIcon } from "./Components/Common/Icons/MotionActivityIcon";
import { EnvironmentalIcon } from "./Components/Common/Icons/EnvironmentalIcon";
import { useMainContext } from "./MainContext";
import { WellnessIcon } from "./Components/Common/Icons/WellnessIcon";

const CHECK_INTERVAL_MS = 20000;
const SHOW_STATUS_BEFORE_MINUTES = 15;
const SHOW_JOIN_STATUS_AFTER_MINUTES = 15;
const ONGOING_MEETING_DISPLAY_TIME_HOURS = 4;
const SHOW_JOIN_STATUS_TILL_MINUTES = ONGOING_MEETING_DISPLAY_TIME_HOURS * 60;

const getDateTimeFromWebSocket = (wsEvent) => {
  if (wsEvent == null) return;
  let dateTime = null;
  if ("event_timestamp" in wsEvent) dateTime = moment(+wsEvent.event_timestamp);
  else {
    dateTime = moment(wsEvent.event_time, "HH:mm");
    dateTime.add(dateTime.utcOffset(), "minutes");
  }
  return dateTime;
};

const SVGImage = ({ imageUrl, className }) => {
  return (
    <img
      src={imageUrl}
      alt="PNG Image"
      className={className}
      style={{ maxWidth: "156px", maxHeight: "69px", aspectRatio: "2.2609" }}
    />
  );
};

export const MainSider = ({
  readOnlyHeader,
  openSubMenu,
  activeKey,
  onChange,
  hubUserRole,
  buttonSelected,
  onActionClick,
  navigationKey,
  offset,
  limit,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { handleMainMenuClick } = useMainContext();
  const path = location.pathname.split("/")[1]?.toLowerCase();
  const currentUser = useSelector(getCurrentUser);
  const useruuid = useMemo(() => currentUser?.useruuid, [currentUser]);
  const userRole = useUserInfo({ useruuid })[0].staffrole;
  const trans = useSelector(translator);
  const dashboardApi = useSelector((state) => state.apis.dashboardApi);
  const [, scheduleList, fetchSchedule] = useSchedule();
  const userInfo = useSelector(getUserInfo(useruuid));
  const photoUrl = getProfilePicUrl(
    useruuid,
    useSelector(getProfilePics()),
    PhotoResolution.R256
  );
  const username = useMemo(() => getUsername(userInfo), [userInfo]);

  const firstname = useMemo(() => getName(userInfo), [userInfo]);
  const userData = useSelector((user) => user.data.userInfo);
  const updatedUserInfo = userData[useruuid];
  const firstname_updated = getName(updatedUserInfo);

  const serviceProviderApi = useSelector(
    (state) => state.apis.serviceProviderApi
  );
  const providerId = useSelector(getCurrentUser)?.providerid;
  // const providerName = currentUser.provider_display_name;

  const permission_level = useMemo(
    () => currentUser?.permission_level,
    [currentUser]
  );

  // Define a function to get translated permission level
  const getTranslatedPermissionLevel = (permission_level) => {
    switch (permission_level) {
      case "Staff":
        return trans("STAFF");
      case "Staff Admin":
        return trans("STAFFADMIN");
      case "Account Principal":
        return trans("ACCOUNTPRINCIPAL");
      case "Social":
        return trans("SOCIAL");
      default:
        return permission_level;
    }
  };

  const [selectedMenu, setSelectedMenu] = useState(
    navigationKey ? NavigationKeys.accounts : path || NavigationKeys.accounts
  );
  const [stats, setStats] = useState({});
  const [fetchingStats, setFetchingStats] = useState({});
  const [status, setStatus] = useState(null);
  const createSearchParams = () => {
    const currentSearchParams = new URLSearchParams(location.search);
    const paramsMap = new Map(currentSearchParams.entries());
    const uniqueSearchParams = new URLSearchParams(paramsMap);
    return uniqueSearchParams.toString();
  };

  const renderHelpIcon = (
    <div className="action non-clickable-item" title="Help">
      <NewHelpIcon
        className="help-icon"
        style={{ height: "30px", width: "30px" }}
      />
    </div>
  );
  const renderLogoutIcon = (
    <div className="action non-clickable-item" title="Logout">
      <NewLogoutIcon
        className="logout-icon"
        style={{ height: "26px", width: "26px" }}
      />
    </div>
  );

  const renderGearIcon = (
    <div className="action non-clickable-item" title="Edit Profile">
      <GearIcon
        className="gear-icon"
        style={{ height: "26px", width: "26px" }}
      />
    </div>
  );

  const handleSubMenuClick = (e, submenuKey) => {
    e.preventDefault();
    onChange(submenuKey);
  };

  const menuClassname = (key) =>
    `new-ui-menu-item ${selectedMenu === key ? "new-ui-active-menu-item" : ""}`;

  useEffect(() => {
    fetchStats();
    fetchProviderData();
    fetchSchedule(useruuid, new Date(new Date().setHours(0, 0, 0, 0)));
  }, []);

  const [eventInfo, setEventInfo] = useState(null);
  const webSocketMessage = useSelector(getNotificationMiniBannerMessage);

  const menuItems = () => [
    {
      key: NavigationKeys.accounts,
      icon: <AccountIcon className="new-ui-icon" />,
      label:
        permission_level === "Staff Admin"
          ? trans("ACCOUNTS")
          : trans("MYACCOUNTS"),
      count: fetchingStats ? <LoadingOutlined /> : stats?.accounts?.total,
      submenu: [
        {
          key: "schedule",
          label: trans("SCHEDULE"),
          icon: (
            <NewScheduleIcon
              className={`${
                activeKey === "schedule"
                  ? "active-calendar-icon"
                  : "inactive-calendar-icon"
              }`}
            />
          ), // Label for the submenu item
          access: [
            UserRoles.manage,
            UserRoles.care,
            UserRoles.social,
            UserRoles.empty,
          ],
        },
        {
          key: "contacts",
          label: trans("CONTACTS"),
          icon: (
            <ContactsIcon
              className={`${
                activeKey === "contacts"
                  ? "active-contact-icon"
                  : "inactive-contact-icon"
              }`}
            />
          ),
          access: [
            UserRoles.manage,
            UserRoles.care,
            UserRoles.social,
            UserRoles.empty,
          ],
        },
        {
          key: "callActivity",
          label: trans("CALLACTIVITY"),
          icon: (
            <CallIcon
              className={`${
                activeKey === "callActivity"
                  ? "active-call-icon"
                  : "inactive-call-icon"
              }`}
            />
          ),
          access: [UserRoles.manage, UserRoles.care, UserRoles.social],
        },
        {
          key: "motionactivity",
          label: trans("MOTIONACTIVITY"),
          icon: (
            <MotionActivityIcon
              className={`${
                activeKey === "motionactivity" ? "active-icon" : "inactive-icon"
              }`}
            />
          ),
          access: [UserRoles.manage, UserRoles.care],
        },
        {
          key: "environmental",
          label: "Environmental",
          icon: (
            <EnvironmentalIcon
              className={`${
                activeKey === "environmental" ? "active-icon" : "inactive-icon"
              }`}
            />
          ),
          access: [UserRoles.manage, UserRoles.care],
        },
        {
          key: "wellness",
          label: 'Wellness',
          icon: (
            <WellnessIcon
              className={`${
                activeKey === "wellness"
                  ? "active-icon"
                  : "inactive-icon"
              }`}
            />
          ),
          access: [UserRoles.manage, UserRoles.care],
        },
        {
          key: "deviceinventory",
          label: trans("DEVICES"),
          icon: (
            <DeviceInventoryIcon
              className={`${
                activeKey === "deviceinventory"
                  ? "active-icon"
                  : "inactive-icon"
              }`}
            />
          ),
          access: [UserRoles.manage, UserRoles.care],
        },
      ].filter((submenu) => {
        if (submenu.access && !submenu.access.includes(hubUserRole)) {
          return false; // Remove submenu if hubUserRole does not have required access
        }
        return true; // Keep the submenu if it satisfies access conditions
      }),
    },
    {
      key: NavigationKeys.schedule,
      icon: <NewScheduleIcon className="new-ui-icon" />,
      label: trans("MYSCHEDULE"),
    },
    {
      key: NavigationKeys.staff,
      icon: <BadgeIcon className="new-ui-icon" />,
      label: trans("STAFF"),
      count: fetchingStats ? <LoadingOutlined /> : stats?.staff?.total,
      access: [UserTypes.providerAdmin, UserTypes.staff],
    },
    {
      key: NavigationKeys.devices,
      icon: <DevicesNewIcon className="new-ui-icon" />,
      label: trans("INVENTORY"),
      count: fetchingStats ? <LoadingOutlined /> : stats?.devices?.total,
      // access: [UserTypes.providerAdmin, UserTypes.staff],
      access_perm_level: [UserPermissionLevel.staffAdmin],
    },
  ];

  const checkForOnGoingEvent = (scheduleList, wsEvent) => {
    let now = moment().subtract(ONGOING_MEETING_DISPLAY_TIME_HOURS, "hour");
    let schEvent = scheduleList.reverse().find((event) => {
      let time = moment(event.display_event_time, "HH:mm:ss");
      if ("display_day" in event) {
        if (event.display_day !== now.format("dddd")) return;
      }
      if (time.isAfter(now)) return event;
    });
    let event = schEvent;

    if (wsEvent != null) {
      let dateTime = getDateTimeFromWebSocket(wsEvent);
      if (schEvent === undefined) {
        if (dateTime.isAfter(now)) event = wsEvent;
      } else {
        if (dateTime.isAfter(moment(schEvent.display_event_time, "HH:mm:ss")))
          event = wsEvent;
      }
    }
    return event;
  };

  useEffect(() => {
    // Find scheduled event after current time if any.
    // Meetings which are past SHOW_JOIN_STATUS_AFTER_MINUTES thier scheduled time are also to be considered.
    let now = moment().subtract(SHOW_JOIN_STATUS_AFTER_MINUTES, "minute");
    let schEvent = scheduleList.find((event) => {
      let time = moment(event.display_event_time, "HH:mm:ss");
      if ("display_day" in event) {
        if (event.display_day !== now.format("dddd")) return;
      }
      if (time.isAfter(now)) return event;
    });
    const wsEvent = webSocketMessage?.schedule_event;
    let event = schEvent;

    // Check for web socket message cases compared to scheduled event
    if (wsEvent != null) {
      if (wsEvent.action?.includes("delete")) {
        setEventInfo(null);
        fetchSchedule(useruuid, new Date(new Date().setHours(0, 0, 0, 0)));
        return;
      }
      let dateTime = getDateTimeFromWebSocket(wsEvent);
      if (
        dateTime != null &&
        dateTime.isAfter(now) &&
        (schEvent === undefined ||
          moment(schEvent.display_event_time, "HH:mm:ss").isAfter(dateTime))
      )
        event = wsEvent;
    }

    if (event == null) {
      event = checkForOnGoingEvent(scheduleList, webSocketMessage);
      if (event == null) return;
    }

    const eventInfo = {
      eventInfo: event,
      eventId: event.schedule_eventuuid,
      time: "",
      label: event.event_name,
    };
    if ("display_event_time" in event) {
      let dateTime = moment(event.display_event_time, "HH:mm:ss");
      eventInfo.time = dateTime.format("h:mm A");
    } else if ("event_timestamp" in event) {
      let dateTime = moment(+event.event_timestamp);
      eventInfo.time = dateTime.format("h:mm A");
    } else {
      let time = moment(event.event_time, "HH:mm");
      time.add(time.utcOffset(), "minutes");
      eventInfo.time = time.format("h:mm A");
    }
    setEventInfo(eventInfo);
  }, [webSocketMessage, scheduleList]);

  useEffect(() => {
    let interval;
    if (eventInfo !== null) {
      updateStatus();
      interval = setInterval(() => {
        updateStatus();
      }, CHECK_INTERVAL_MS);
    }

    return () => {
      if (interval !== undefined) clearInterval(interval);
    };
  }, [eventInfo]);

  const updateStatus = () => {
    const now = moment();
    const eventTime = moment(eventInfo.time, "h:mm A");
    const diff = eventTime.diff(now, "minute");
    if (diff <= SHOW_STATUS_BEFORE_MINUTES && diff >= 1)
      setStatus(trans("UPCOMING"));
    // else if (diff < 1 && diff >= -SHOW_JOIN_STATUS_TILL_MINUTES) {
    //   eventInfo?.eventInfo?.event_type === sessionType.zoom
    //     ? setStatus("Zoom Call")
    //     : setStatus("Join");
    // } else if (diff < -SHOW_JOIN_STATUS_TILL_MINUTES) setEventInfo(null);
    else setStatus(null);
  };

  useEffect(() => {
    const nextDate = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
      date: moment().date() + 1,
    });
    setTimeout(() => {
      fetchSchedule(useruuid, new Date(new Date().setHours(0, 0, 0, 0)));
    }, nextDate.diff(moment()) + 2000);
  }, []);

  const fetchStats = () => {
    const errorCallback = (error) => {
      setFetchingStats(false);
      console.error("Error fetching user stats:", error);
    };
    const successCallback = (data) => {
      setFetchingStats(false);
      console.info("UserStats: fetch result", data);
      setStats(data || {});
    };
    try {
      const currentdatetime = new Date().toISOString();
      const startdatetime = new Date(
        new Date().setHours(0, 0, 0, 0)
      ).toISOString();
      setFetchingStats(true);
      dashboardApi.getAdminDashboardByDateTime(
        currentdatetime,
        startdatetime,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const storedData = localStorage.getItem("providerData");

  const fetchProviderData = () => {
    const errorCallback = (error) => {
      console.error(error);
    };
    const successCallback = (data) => {
      // Save the logo URL to local storage
      if (!!storedData) return;
      const providerData = {
        displayname: data?.serviceProvider?.displayname,
        dark_logo_url: data?.serviceProvider?.dark_logo_url,
        light_logo_url: data?.serviceProvider?.light_logo_url,
      };
      localStorage.setItem("providerData", JSON.stringify(providerData));
      dispatch(
        setProviderData({
          supportedlocales: data?.serviceProvider?.supportedlocales,
          supportedtimezones: data?.serviceProvider?.supportedtimezones,
        })
      );
      console.log("TIMEZONES-> ", data?.serviceProvider?.supportedtimezones);
    };

    try {
      serviceProviderApi.getServiceproviderById(
        providerId,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const providerData = useMemo(
    () => (storedData ? JSON.parse(storedData) : null),
    [storedData]
  );

  return (
    <div className="new-ui-mainsider-wrapper">
      <div className="new-ui-top-container">
        <div className="new-ui-main-logo-div">
          {!providerData ? (
            // Render an empty div if data is not fetched
            <div className="empty-logo-div"></div>
          ) : providerData.light_logo_url ? (
            // Data is fetched, and light_logo_url is available
            <div className="main-logo-div">
              <SVGImage
                imageUrl={providerData.light_logo_url}
                className="custom-svg-class"
              />
            </div>
          ) : (
            // Data is fetched, but light_logo_url is not available
            <div className="main-logo-div">
              <HomeSightNewLogo />
            </div>
          )}
        </div>
        <div className="new-ui-provider-name-container">
          <span className="new-ui-provider-name-text">
            {/* showing provider name from ServiceProviderAPI, fallback to token value in case of API error */}
            {providerData
              ? providerData.displayname
              : currentUser.provider_display_name}
          </span>
        </div>

        {menuItems().map((menu) => {
          if ((menu.access && !menu.access.includes(userInfo.usertype)) || menu.access_perm_level && !menu.access_perm_level.includes(userInfo.permission_level)) {
            return null;
          }
          return (
            <Link
              to={{
                pathname: "/" + menu.key,
                search: "?" + createSearchParams(),
              }}
              key={menu.key}
              className="new-ui-menu-container"
              state={{ count: menu.count }}
            >
              <div className={menuClassname(menu.key)}>
                <div
                  className="new-ui-menu-item-header"
                  onClick={(e) => {
                    if (
                      (menu.key === NavigationKeys.accounts || navigationKey) &&
                      openSubMenu
                    ) {
                      if (navigationKey) {
                        onActionClick(null, null, offset, limit, true)();
                      } else {
                        onActionClick(null, null, false, true)();
                      }
                    } else {
                      e.preventDefault();
                      navigate("/" + menu.key + "?" + createSearchParams());
                    }
                    handleMainMenuClick(menu.key);
                  }}
                >
                  <div className="new-ui-menu-icon-label-container">
                    <div className="new-ui-menu-icon">{menu.icon}</div>
                    <div className="new-ui-menu-label">{menu.label}</div>
                  </div>
                  <div className="new-ui-menu-count-container">
                    <div className="new-ui-menu-count">{menu.count}</div>
                  </div>
                  {menu.key === "schedule" && (
                    <div className="new-ui-menu-item-body">
                      <div className="new-ui-body-row-1">
                        {status && (
                          <div className="new-ui-status">{status}</div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {openSubMenu && menu.submenu && (
                  <>
                    <div className="submenu-container">
                      {menu.submenu.map((submenuItem) => (
                        <div
                          key={submenuItem.key}
                          className={`submenu-item ${
                            activeKey === submenuItem.key
                              ? "active"
                              : "inactive"
                          }`}
                          onClick={(e) =>
                            handleSubMenuClick(e, submenuItem.key)
                          }
                        >
                          <div className="submenu-icon-label-container">
                            <div className={`sub-menu-icon`}>
                              {submenuItem.icon}
                            </div>
                            <div
                              className={`sub-menu-label ${
                                activeKey === submenuItem.key
                                  ? "active-label"
                                  : "inactive-label"
                              }`}
                            >
                              {submenuItem.label}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </Link>
          );
        })}
      </div>
      <div className="new-ui-bottom-container">
        <div className="new-ui-userinfo-container">
          <div className="new-ui-image-container">
            {photoUrl == null ? (
              <ContactImage className="new-ui-user-profile-pic" />
            ) : (
              <img className="new-ui-user-profile-pic" alt="" src={photoUrl} />
            )}
            <div className="new-ui-info-container">
              <span className="new-ui-name" title={firstname_updated}>
                {firstname_updated.length > 12
                  ? firstname_updated.substring(0, 12) + "..."
                  : firstname_updated}
              </span>
            </div>
            <div className="new-ui-permission-level">
              {permission_level && (
                <span
                  className={
                    permission_level === "Staff"
                      ? "new-ui-staffColor"
                      : permission_level === "Staff Admin"
                      ? "new-ui-staffAdminColor"
                      : "new-ui-accountPrincipalSocialRole"
                  }
                >
                  {getTranslatedPermissionLevel(permission_level)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="new-ui-logout-gear-notification-help-icons">
          <div
            className={`new-ui-notification-container ${
              buttonSelected === "notification" ? "active-icon" : ""
            }`}
          >
            <NotificationMiniBanner
              isSelected={buttonSelected === "notification"}
              readOnly={readOnlyHeader}
              searchParams={createSearchParams}
            />
          </div>
          <div
            className={`new-ui-gear-container ${
              buttonSelected === "gear" ? "active-icon" : "inactive-icon"
            }`}
          >
            {readOnlyHeader ? (
              renderGearIcon
            ) : (
              <Link
                to={{
                  pathname: "/" + NavigationKeys.profile,
                  search: "?" + createSearchParams(),
                }}
              >
                <div className="action" title="Edit Profile">
                  <GearIcon style={{ height: "30px", width: "30px" }} />
                </div>
              </Link>
            )}
          </div>
          <div
            className={`new-ui-help-container ${
              buttonSelected === "help" ? "active-icon" : "inactive-icon"
            }`}
          >
            {readOnlyHeader ? (
              renderHelpIcon
            ) : (
              <Link
                to={{
                  pathname: "/" + NavigationKeys.about,
                  search: "?" + createSearchParams(),
                }}
              >
                <div className="action" title="Help">
                  <NewHelpIcon style={{ height: "30px", width: "30px" }} />
                </div>
              </Link>
            )}
          </div>
          <div className="new-ui-logout-container">
            {readOnlyHeader ? (
              renderLogoutIcon
            ) : (
              <div
                title="Logout"
                className="action"
                onClick={readOnlyHeader ? null : onLogout}
              >
                <NewLogoutIcon
                  className="logout-icon"
                  style={{ height: "26px", width: "26px" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

MainSider.propTypes = {};

export default MainSider;
