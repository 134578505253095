import React from "react";

export const NewRedirectIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3512 17.1886C24.6187 17.1886 24.0356 17.7717 24.0356 18.5042V24.0207H7.96455V7.99446H13.5109C14.2435 7.99446 14.8265 7.41141 14.8265 6.67887C14.8265 5.94633 14.2435 5.36328 13.5109 5.36328H7.96455C7.23201 5.36328 6.61906 5.61743 6.09582 6.14067C5.58752 6.64897 5.33337 7.27686 5.33337 7.99446V24.0207C5.33337 24.7383 5.58752 25.3662 6.09582 25.8745C6.60411 26.3828 7.23201 26.6369 7.9496 26.6369H24.0207C24.7383 26.6369 25.3662 26.3828 25.8745 25.8745C26.3828 25.3662 26.6519 24.7383 26.6519 24.0058V18.4893C26.6519 17.7567 26.0688 17.1737 25.3363 17.1737L25.3512 17.1886Z"
        fill="#3FA9F5"
      />
      <path
        d="M25.3061 14.6172C24.5885 14.6023 24.0204 14.0192 24.0204 13.3017V9.83329L17.3528 16.5009C16.8445 17.0092 16.0073 17.0092 15.499 16.5009C14.9907 15.9926 14.9907 15.1554 15.499 14.6471L22.1816 7.99446H18.7132C17.9956 7.99446 17.4126 7.42636 17.3977 6.72372C17.3827 5.97623 17.9807 5.36328 18.7132 5.36328H26.6666V13.3166C26.6666 14.0491 26.0536 14.6471 25.3211 14.6322L25.3061 14.6172Z"
        fill="#3FA9F5"
      />
    </svg>
  );
};