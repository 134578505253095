import React from "react";

export const MissedCallStatusIcon = (props) => {
    const { className = "", style = {} } = props;

    return (
        <svg className={className} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <rect width="30" height="30" rx="15" fill="#F03B3B" />
            <path d="M18.8317 7.86133L15.4192 11.6387L11.1709 7.86133" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.0155 11.1979L10.5459 7.19922H14.3591" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24.9564 19.5244L23.8987 20.656C22.9637 21.6562 21.7012 21.7046 20.4323 20.9095C20.4237 20.9049 20.4173 20.898 20.4086 20.8911C19.2884 20.1121 18.4504 19.5866 18.4504 19.5866L18.4224 17.3834C18.4224 17.3834 16.9466 16.8279 14.9517 16.9132C12.9568 16.9985 11.5608 17.5539 11.5608 17.5539L11.5156 19.5498L9.72746 20.7136C9.72746 20.7136 9.71454 20.7298 9.70592 20.7344C8.35085 21.6816 7.17889 21.6586 6.22883 20.6399L5.04395 19.3723C5.04395 19.3723 7.35339 13.9355 15.4688 13.9355C22.5867 13.9355 24.9564 19.5221 24.9564 19.5221V19.5244Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}