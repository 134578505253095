import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ProgressBar from "../Common/ProgressBar";
import {
  CheckIcon,
  CloseIcon,
  NewCareIcon,
  NewManageIcon,
  NewUpdateIcon,
  SocialNewIcon,
} from "../Common/Icons";
import { HSButtonType } from "../Common/HSButton";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
import { RoleType } from "@commscopemycloud/humaui/Utilities/Constants";
import CustomButton from "../Common/CustomButton";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import "./Roles.less";

const Roles = ({
  type,
  onHandleSelection,
  currentRole,
  disabled = false,
  contact = false,
  allowRoleChange = false,
}) => {
  // State to track the currently selected role index
  const [currentSelection, setCurrentSelection] = useState(contact ? null : 0);
  const trans = useSelector(translator);

  const initialState = useSelector(getPermissions());
  const state = useMemo(() => {
    const {
      social,
      manage,
      care,
      staff,
      "staff admin" : staff_admin,
    } = initialState;
    const currState = [];
    if (type === RoleType.user) {
      currState.push({
        buttonType: HSButtonType.social,
        data: social,
        roledescription: "Communication only",
      });
      currState.push({
        buttonType: HSButtonType.care,
        data: care,
        roledescription: "Visibility into activity",
      });
      currState.push({
        buttonType: HSButtonType.manage,
        data: manage,
        roledescription: "Access to everything",
      });
    } else {
      currState.push({ buttonType: HSButtonType.staff, data: staff });
      currState.push({
        buttonType: HSButtonType.staff_admin,
        data: staff_admin,
      });
    }
    for (const [i, val] of currState.entries()) {
      if (val.data?.rolename === currentRole) {
        setCurrentSelection(i);
        break;
      }
    }
    return currState;
  }, [initialState, type, currentRole]);

  const progressMultiplier = useMemo(() => 100 / (state?.length ?? 0), [state]);

  // function to determine the icon based on the button type
  const getButtonIcon = (buttonType) => {
    switch (buttonType) {
      case "MANAGE":
        return <NewManageIcon className="roles-icon" />;
      case "CARE":
        return <NewCareIcon className="roles-icon" />;
      case "SOCIAL":
        return <SocialNewIcon className="roles-icon" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (typeof onHandleSelection !== "undefined") {
      onHandleSelection(state[currentSelection]?.data?.rolename);
    }
  }, [currentSelection, onHandleSelection, state]);

  // Component to render the "Selected" label and icon
  const SelectedLabel = () => {
    return (
      <>
        <div className="selected-label-container">
          <NewUpdateIcon className="selected-icon" />
          <span className="selected-label">Selected</span>
        </div>
      </>
    );
  };

  // Function to render the button and its corresponding role description
  const renderButtonLabel = (role, index) => {
    const isButtonSelected = index === currentSelection;
    const isDescHighlighted = index <= currentSelection;
    const icon = getButtonIcon(role?.buttonType);

    // For contact, render in card-like format
    if (contact) {
      return (
        <>
          <div
            className={`role-card ${isButtonSelected ? "selected" : ""}`}
            onClick={() => !disabled && setCurrentSelection(index)} // Set selection on click
            disabled={disabled}
          >
            {/* Show selected label if the button is selected */}
            {isButtonSelected && <SelectedLabel />}

            <div className="content-container">
              <div className="header-content">
                <div className="roles-icon">{icon}</div>
                <div className="role-label">{trans(role?.buttonType)}</div>
                <div className="role-description">{role?.roledescription}</div>
              </div>
              {/* Render role permissions */}
              {renderPermissions(role, index)}
            </div>
          </div>
        </>
      );
    } else {
      // Render in default format if contact prop is not passed
      return (
        <div className="column">
          <CustomButton
            type={isButtonSelected && "primary"}
            className={`action ${
              isButtonSelected && !disabled ? "button-selected" : allowRoleChange ? "allow-role-change-button-selected" : ""
            }`}
            label={trans(role?.buttonType)}
            icon={icon}
            onClick={() => (!(disabled 
              || allowRoleChange)) && setCurrentSelection(index)} // Set selection on click
            disabled={disabled}
          />
          <p className={"desc " + (isDescHighlighted ? "desc-selected" : "")}>
            {role?.roledescription}
          </p>
        </div>
      );
    }
  };

  // Function to render permissions based on the current role selection
  const renderPermissions = (role, index) => {
    const isAllowed = index <= currentSelection;
    if (contact) {
      // For contact, render permissions in card-like format
      return (
        <div className="privileges-container">
          {role?.data?.permissions.map((data, i) => (
            <div key={i} className="privileges-content">
              <CheckIcon className="check-icon" />
              <span className="privilege-display-label">{data.privilegedisplayname}</span>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="column">
          {role?.data?.permissions.map((data) => {
            return (
              <div className="permissions-item">
                <div className="icon">
                  {isAllowed && <CheckIcon className="tick" />}
                  {!isAllowed && <CloseIcon />}
                </div>
                <p>{data.privilegedisplayname}</p>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <>
      {/* Main container for role selection */}
      <div className={contact ? "roles-list" : "role-container"}>
        <div className={contact ? "roles-row" : "row"}>
          {/* Render role buttons */}
          {state.map((role, index) => {
            return renderButtonLabel(role, index);
          })}
        </div>
        {/* when contact prop is passed do not show the progress bar */}
        {!contact && (
          <>
            <ProgressBar
              percent={(currentSelection + 1) * progressMultiplier}
            />
            <div className="row">
              {/* Render role permissions */}
              {state.map((role, index) => {
                return renderPermissions(role, index);
              })}
            </div>
          </>
        )}
      </div>
      {/* when role is not selected then show the below warning  */}
      {contact && currentSelection === null && (
        <div className="warning-text">
          You need to select an option to confirm your entry
        </div>
      )}
    </>
  );
};

Roles.propTypes = {
  type: PropTypes.string,
  currentRole: PropTypes.string,
  onHandleSelection: PropTypes.func,
  contact: PropTypes.bool,
  allowRoleChange: PropTypes.bool, // If true, role change is not allowed
};

export default React.memo(Roles);
