import React from 'react';
import { useSelector } from "react-redux";
import { Actions } from "@commscopemycloud/humaui/Utilities/Constants";
import { Divider, Tooltip } from "antd";
import moment from "moment";
import {
    ScheduleContactIcon,
    ScheduleNewContactIcon
} from "../Common/Icons";
import {
    formatEventTimes,
    getTimeFormat,
    getTimeZoneFormat,
} from "@commscopemycloud/humaui/Utilities/DateTime";
import PropTypes from "prop-types";

const TaskListItem = ({
    item,
    onActionClick,
    renderEventType,
    renderInvitedList,
    categoryOptions,
    loginUserTimeformat,
    userInfo,
    getRelativeTime,
    EventDaysInfo,
    DaysOfWeekRow,
    openSubMenu,
    hubRecord,
    renderButtonStatus,
    currentUser
}) => {

    const currentUserData = useSelector((user) => user.data.userInfo);
    // Note: useruuid is not defined in the snippet.
    // Assuming useruuid should be obtained from hubRecord or elsewhere.
    const useruuid = currentUser?.useruuid; // Adjust as needed.
    const currentUserDataInfo = currentUserData[openSubMenu ? hubRecord?.useruuid : useruuid];
    const userTimezone = getTimeZoneFormat(currentUserDataInfo?.timezone);

    return (
        <>
            <div
                key={item?.schedule_eventuuid}
                className="schedule-container-newui"
                style={{ cursor: "pointer" }}
                onClick={item?.event_type === "tasks" ? onActionClick(Actions.viewTask, item) : onActionClick(Actions.startEvent, item)}
            >
                <div className="schedule-list-row1-newui">
                    <div className="container-1">
                        <div className="eventype-container">
                            {renderEventType(item?.event_type?.toLowerCase())}
                        </div>
                        {(item?.eventType === "schedule" || item?.eventType === "zoom") ? (
                            <ScheduleNewContactIcon />
                        ) : (
                            <div className="empty-action" style={{ width: "30px", marginLeft: "10px" }}>
                                {(() => {
                                    const option = categoryOptions.find(
                                        (opt) => opt.value === item?.category
                                    );
                                    return option ? option.icon : "";
                                })()}
                            </div>
                        )}
                        <div className="schedule-eventname-newui">
                            {item?.event_name}
                        </div>
                    </div>
                    <div className="container-2">
                        <div
                            title={userTimezone}
                            className="schedule-eventtime-newui"
                        >
                            {item?.event_type !== "tasks" ? (
                                formatEventTimes(
                                    item?.event_startdate,
                                    item?.event_starttime,
                                    item?.event_startdate,
                                    item?.event_endtime,
                                    userTimezone,
                                    currentUserDataInfo?.is24hoursformat
                                )
                            ) : currentUserDataInfo && currentUserDataInfo?.is24hoursformat ? (
                                getTimeFormat(
                                    item?.event_timestamp,
                                    userTimezone,
                                    currentUserDataInfo?.is24hoursformat
                                )
                            ) : (
                                getTimeFormat(
                                    item?.event_timestamp,
                                    userTimezone,
                                    currentUserDataInfo?.is24hoursformat
                                )
                            )}
                        </div>
                        {item?.event_type === "tasks" && (
                            <div className={`task-status-${item?.task_status} task-status`}>
                                {item?.task_status === "done"
                                    ? "Completed"
                                    : item?.task_status === "expired"
                                        ? "Missed"
                                        : item?.task_status === "ongoing"
                                            ? "In Progress"
                                            : item?.task_status === "scheduled"
                                                ? "Scheduled"
                                                : item?.task_status.charAt(0).toUpperCase() + item?.task_status.slice(1).toLowerCase()}
                            </div>
                        )}
                    </div>
                </div>
                <div className="schedule-list-row2-newui">
                    <div className="schedule-list-row2-container1">
                        {(item?.event_type === "schedule" || item?.event_type === 'zoom') ? <>
                            <div className="schedule-meeting-count">
                                <ScheduleContactIcon className="account-icon" />
                                {renderInvitedList(item?.invited_contacts).count > 0 && (
                                    <div className="invited-count">
                                        {renderInvitedList(item?.invited_contacts).count}
                                    </div>
                                )}
                            </div>
                            <Divider type="vertical" />
                            <div className="invited-list">
                                {
                                    renderInvitedList(item?.invited_contacts)
                                        .invitedContactsData
                                }
                                {renderInvitedList(item?.invited_contacts).count > 3 && (
                                    <div className="user-image-container user-more">
                                        + {renderInvitedList(item?.invited_contacts).count - 3}
                                    </div>
                                )}
                            </div>
                            <Divider type="vertical" />
                        </> : null}
                        {
                            getRelativeTime(item?.event_timestamp, userTimezone) && (
                            <>
                                <div className="reminder_data">
                                    <span>Reminders:</span>
                                    <span className="hour_data">{getRelativeTime(item?.event_timestamp, userTimezone)}</span>
                                </div>
                                <Divider type="vertical" />
                            </>
                            )
                        }
                        {item && (
                            <>
                                <div className="event_days_info">
                                    <span>Repeats:</span>
                                    <Tooltip title={EventDaysInfo(item?.days_of_week)} placement="bottom">
                                        <>{DaysOfWeekRow(item?.days_of_week, item)}</>
                                    </Tooltip>
                                </div>
                            </>
                        )}
                    </div>
                    {item?.schedule_eventuuid &&
                        moment(item?.event_timestamp).isSame(moment(), "day") && (
                            <div className="schedule-list-row2-container2" onClick={(e) => { e.stopPropagation(); }}>{renderButtonStatus(item)}</div>
                        )}
                </div>
            </div>
        </>
    );
};

TaskListItem.propTypes = {
    item: PropTypes.shape({
        schedule_eventuuid: PropTypes.string.isRequired,
        event_type: PropTypes.string,
        eventType: PropTypes.string,
        event_name: PropTypes.string,
        event_startdate: PropTypes.string,
        event_starttime: PropTypes.string,
        event_enddate: PropTypes.string,
        event_endtime: PropTypes.string,
        event_timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        task_status: PropTypes.string,
        category: PropTypes.string,
        invited_contacts: PropTypes.array,
        days_of_week: PropTypes.array,
    }).isRequired,
    onActionClick: PropTypes.func.isRequired,
    renderEventType: PropTypes.func.isRequired,
    renderInvitedList: PropTypes.func.isRequired,
    categoryOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    loginUserTimeformat: PropTypes.shape({
        is24hoursformat: PropTypes.bool,
    }),
    userInfo: PropTypes.shape({
        is24hoursformat: PropTypes.bool,
    }),
    getRelativeTime: PropTypes.func.isRequired,
    EventDaysInfo: PropTypes.func.isRequired,
    DaysOfWeekRow: PropTypes.func.isRequired,
    openSubMenu: PropTypes.bool,
    hubRecord: PropTypes.object,
    renderButtonStatus: PropTypes.func.isRequired,
    currentUser: PropTypes.object
};

export default TaskListItem;
