import React, { useEffect, useMemo, useState } from "react";
import "./DeviceInventory.less";
import {
  AddPlusIcon,
  CloseIcon,
  DeviceInventoryIcon,
  DeviceUnpairIcon,
  ErrorIcon,
  HC200Icon,
  NewUpdateIcon,
  NewCircleIcon,
} from "../Common/Icons";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import DeviceInventoryList from "./DeviceInventoryList";
import DeviceInventoryModal from "./DeviceInventoryModal";
import { Button, Input, message, Modal, Spin } from "antd";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
import { devicesInventory } from "../../utilities/data";
import useConfig from "../Hooks/useConfig";
import LocalStorage from "../../../@commscopecloud/humaui/Utilities/LocalStorage";
import { AppStorageKeys } from "../../../@commscopecloud/humaui/Utilities/Constants";
import { useAssignSmartCameraHandler } from "./useAssignSmartCameraHandler";
import AssignSmartCameraModal from "./AssignSmartCameraModal";

const { device_types } = devicesInventory;

const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getDeviceInfo = (device) => {
  const isUnpaired = device?.paired_status === "UNPAIRED";
  if (device_types?.sensor_devices?.models?.includes(device?.modelnumber)) {
    return [
      {
        key: "Location Name",
        value: device?.device_location || "-",
      },
      {
        key: "Serial Number",
        value: device?.device_serial_number || "-",
      },
      { key: "HW Version", value: device?.device_hardware_version || "-" },
      { key: "SW Version", value: device?.device_software_version || "-" },
      {
        key: "Signal Level",
        value:
          !isUnpaired && device?.device_signal_strength
            ? `${device.device_signal_strength} dBm`
            : "-",
      },
    ];
  }
  if (
    device_types?.smart_camera_devices?.models?.includes(device?.modelnumber)
  ) {
    return [
      {
        key: "Software version",
        value: device?.softwareversion || "-",
      },
      {
        key: "Model",
        value: device?.modelnumber || "-",
      },
    ];
  }
  if (
    device_types?.remote_control_devices?.models?.includes(device?.modelnumber)
  ) {
    return [
      { key: "Model", value: device?.device_name || "-" },
      { key: "SW Version", value: device?.device_software_version || "-" },
      {
        key: "Signal level",
        value: (!isUnpaired && device?.device_signal_level) || "-",
      },
      { key: "MAC Address", value: device?.device_mac_address || "-" },
    ];
  }

  if (
    device_types?.medical_devices?.models?.includes(device?.devicetype) ||
    (device?.devicetype &&
      ![
        ...device_types?.sensor_devices?.models,
        ...device_types?.smart_camera_devices?.models,
        ...device_types?.remote_control_devices?.models,
      ].includes(device?.modelnumber))
  ) {
    return [
      {
        key: "Manufacturer",
        value: device?.device_manufacturer || "-",
      },
      {
        key: "Model",
        value: device?.modelname || "-",
      },
      { key: "Serial Number", value: device?.device_serial_number || "-" },
      { key: "MAC", value: device?.device_mac_address || "-" },
      { key: "HW version", value: device?.device_hardware_revision || "-" },
      { key: "SW version", value: device?.device_software_version || "-" },
    ];
  }

  return [];
};

const mapEventData = (device) => {
  if (
    device_types?.remote_control_devices?.models?.includes(device?.device_name)
  ) {
    return {
      connectionStatus: device?.device_connection_status
        ? "CONNECTED"
        : "DISCONNECTED",
    };
  } else {
    return {
      connectionStatus: device?.device_connected ? "CONNECTED" : "DISCONNECTED",
    };
  }
};

const removeDevice = (
  userApi,
  hubRecord,
  device,
  trans,
  fetchDevices,
  refetch,
  setHeaderRefreshKey,
  queryParams,
  setDeviceLocation,
  setDeviceStatus,
  setStatusTitle,
  setHasDevice
) => {
  const errorCallback = (error) => {
    console.error("Error removing device:", error);
    message.error("Error removing device" + ": " + error.message);
  };

  const successCallback = (data) => {
    console.log("Device removed successfully:", data);
    message.success("Device removed successfully");
    refetch();
    setHasDevice(false);
    fetchDevices(queryParams, (fetchedDevices) => {
      console.log("Updated devices fetched:", fetchedDevices);
      if (!fetchedDevices.find((d) => d.deviceuuid === hubRecord.deviceuuid)) {
        setDeviceStatus(""); // Clear device status
        setDeviceLocation(null); // Clear device location
        setStatusTitle(""); // Clear status title
        setHeaderRefreshKey("remove_device");
      }
    });
  };

  try {
    // Prepare the request payload
    const userDisAssociation = {
      deviceuuid: device.deviceuuid,
    };

    // Make the API call
    userApi.disassociateDeviceFromUser(
      hubRecord.useruuid,
      userDisAssociation,
      apiCallback({
        translator: trans,
        failCallback: errorCallback,
        errorCallback,
        successCallback,
      })
    );
  } catch (error) {
    errorCallback(error);
  }
};

const actionsData = (device) => {
  const UnpairDescription = () => (
    <div>
      <div>
        <span className="custom-hint-label">The device will be unpaired.</span>
        {` Data from the device will remain associated with the Account until cleared.`}
      </div>
      <div className="unpair-action-info-container">
        <span>
          <ErrorIcon fillColor="#808080" />
        </span>
        <div>
          <span className="unpair-custom-information-header">Information:</span>
          <span className="unpair-custom-information-label">
            {` The device can be repaired by initiating the pairing sequence on the device (follow manufacturer specific instructions) and selecting the Wellness devices menu on the Smart Camera to reinitiate the scan and pairing.`}
          </span>
        </div>
      </div>
    </div>
  );
  if (
    device_types?.smart_camera_devices?.models?.includes(device?.modelnumber)
  ) {
    return {
      restart_hc200: {
        title: "Restart",
        description: (
          <div>
            <span className="custom-hint-label">Hint : </span>HC200 will
            immediately restart
          </div>
        ),
        action: "device_reboot",
      },
      factory_reset_hc200: {
        title: "Factory Reset",
        description: (
          <div>
            <span className="custom-hint-label">Hint : </span> HC200 will
            immediately clear all user data and restore factory defaults and
            restart,
            <span style={{ fontSize: "12px" }}>
              <br /> Warning: If any EM200s are paired with this HC200, use the
              UNPAIR EM200 action first
              <br />
              (To manually unpair an EM200 that was associated with an HC200
              that was factory reset remove and reinsert the EM200 batteries 3
              times within 10 seconds each time)
            </span>
          </div>
        ),
        action: "factory_reset",
      },
      check_for_updates_hc200: {
        title: "Check for Updates",
        description: (
          <div>
            <span className="custom-hint-label">Hint : </span>HC200 will check
            for any updates and immediately download and install
          </div>
        ),
        action: "perform_checkforupdates",
      },
      remove_device: {
        title: "Remove device",
        description: (
          <div>
            <span className="custom-hint-label">Hint : </span> This will remove
            device from user
          </div>
        ),
      },
    };
  }

  if (device_types?.sensor_devices?.models?.includes(device?.modelnumber)) {
    return {
      unpair_em200: {
        title: "Unpair",
        description: <UnpairDescription />,
        action: "perform_unPairAccessories",
        message: "Signal sent to device to unpair EM200",
      },
    };
  }

  if (
    device_types?.remote_control_devices?.models?.includes(device?.modelnumber)
  ) {
    return {
      unpair_rcu: {
        title: "Unpair",
        description: <UnpairDescription />,
        action: "perform_unPairRCU",
        message: "Signal sent to device to unpair RCU",
      },
      find_me: {
        title: "Find me",
        description: (
          <div>
            <span className="custom-hint-label">Hint : </span>Subscriber should
            hear a tone on their Remote Control and they can press any key on
            the Remote to the Alert.
          </div>
        ),
        action: "perform_findRCU",
        message: "Find Remote Alert Sent",
      },
    };
  }

  // Check if device.devicetype is in medicalDeviceTypes
  if (
    device_types?.medical_devices?.models?.includes(device?.devicetype) ||
    (device?.devicetype &&
      ![
        ...device_types?.sensor_devices?.models,
        ...device_types?.smart_camera_devices?.models,
        ...device_types?.remote_control_devices?.models,
      ].includes(device?.modelnumber))
  ) {
    return {
      unpair_medical_device: {
        title: "Unpair",
        description: <UnpairDescription />,
        action: "perform_unPairAccessories",
        message: "Signal sent to device to unpair medical device",
      },
    };
  }

  return {};
};

const performAction = (
  deviceManagementApi,
  data,
  providerid,
  trans,
  params
) => {
  const errorCallback = (error) => {
    console.log(error);
    message.error("Error performing: " + data.title + " " + error.message);
  };
  const successCallback = (info) => {
    console.log("Device data", info);
    if (data.message) {
      message.success(data.message);
    } else {
      message.success("Signal sent to Device for: " + data.title);
    }
  };

  try {
    deviceManagementApi.performCommand(
      data.action,
      providerid,
      data.deviceUuid,
      params ? { source: "System", ...params } : { source: "System" },
      apiCallback({
        translator: trans,
        failCallback: errorCallback,
        errorCallback,
        successCallback,
      })
    );
  } catch (error) {
    errorCallback(error);
  }
};

const DeviceInventory = ({
  hubRecord,
  timezone,
  deviceStatus,
  providerid,
  loginUserTimeformat,
  fetchDevices,
  setHeaderRefreshKey,
  queryParams,
  setStatusTitle,
  setDeviceStatus,
  setDeviceLocation,
  hasDevice,
  setHasDevice,
}) => {
  const {
    isSmartCameraModalOpen,
    setIsSmartCameraModalOpen,
    readyToAssignDevices,
    searchText,
    setSearchText,
    searchApplied,
    selectedSmartCameraDevice,
    readyToAssignloading,
    handleDeviceSmartCameraSelect,
    handleSearchApply,
    handleClearSearch,
    handleAssignSmartCamera,
    closeModal,
    assignDevice,
    refetch,
    dataFetched,
    accessoriesData,
    setSearchApplied,
  } = useAssignSmartCameraHandler(fetchDevices, hubRecord);

  const trans = useSelector(translator);
  const userEventsApi = useSelector((state) => state.apis.userEventsApi);
  const userApi = useSelector((state) => state.apis.userApi);
  const deviceManagementApi = useSelector(
    (state) => state.apis.deviceManagementApi
  );
  const [userConfig] = useConfig({ useruuid: hubRecord.useruuid });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [activeTab, setActiveTab] = useState("All");

  const videoCallNotificationModalVisible = useSelector(
    (state) => state.notification.videoCallNotificationModalVisible
  );

  useEffect(() => {
    if (videoCallNotificationModalVisible) {
      handleModalClose();
    }
  }, [videoCallNotificationModalVisible]);

  const filteredAccessoriesData = useMemo(() => {
    return accessoriesData
      .filter(
        (device) =>
          !device_types?.tv_devices?.models?.includes(device?.modelnumber)
      )
      .sort((a, b) => {
        // Define primary order for each main category (Smart Camera, Remote, Sensors, Medical)
        const getDeviceOrder = (device) => {
          if (
            device_types?.smart_camera_devices?.models?.includes(
              device.modelnumber
            )
          )
            return 1; // Smart Camera
          if (
            device_types?.remote_control_devices?.models?.includes(
              device.modelnumber
            )
          )
            return 2; // Remote Control
          if (
            device_types?.sensor_devices?.models?.includes(device?.modelnumber)
          )
            return 3; // Sensors
          return 4; // Medical devices
        };
        // Define secondary order: Paired devices first, then unpaired
        const pairedOrder = (device) =>
          device.paired_status === "PAIRED" ? 0 : 1;

        // Define tertiary order for alphabetical sorting
        const alphabeticalOrder = (device) => {
          if (getDeviceOrder(device) === 4) {
            // For medical devices, use devicetype for alphabetical order
            return device.devicetype?.toLowerCase() || "";
          }
          return (
            device.device_location?.toLowerCase() ||
            device.device_name?.toLowerCase() ||
            ""
          );
        };

        // Compare devices based on all three sorting conditions
        const orderA = [
          getDeviceOrder(a),
          pairedOrder(a),
          alphabeticalOrder(a),
        ];
        const orderB = [
          getDeviceOrder(b),
          pairedOrder(b),
          alphabeticalOrder(b),
        ];
        // Apply the sorting logic in sequence
        for (let i = 0; i < orderA.length; i++) {
          if (orderA[i] < orderB[i]) return -1;
          if (orderA[i] > orderB[i]) return 1;
        }
        return 0;
      });
  }, [accessoriesData]);

  const showModal = (device) => {
    setSelectedDevice(device);
    setIsModalVisible(true);
    handleClearSearch();
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedDevice(null);
    handleClearSearch();
  };

  return (
    <div style={{ padding: "40px 40px 0px 40px" }}>
      {!hasDevice && (
        <div className="device-page-no-devices-banner">
          <div className="device-page-banner-container-1">
            <div>
              <DeviceUnpairIcon
                className="unpair-icon"
                strokeColor="#FFA500"
                fillColor="#FFA500"
              />
            </div>
            <span className="device-banner-text">
              <span className="device-banner-bold">
                {`The account is almost fully setup: `}
              </span>
              {` please review`}
            </span>
          </div>
          <div className="device-page-banner-container-1">
            <label className="radio-container" style={{ visibility: "hidden" }}>
              <NewCircleIcon />
              <span className="radio-label">Review Account Profile</span>
            </label>
            <label className="radio-container">
              {hasDevice ? (
                <NewUpdateIcon className="completed-selected-icon" />
              ) : (
                <NewCircleIcon />
              )}
              <span
                className={`radio-label ${
                  hasDevice ? "assigned-radio-label" : ""
                }`}
              >
                Assign Smart Camera
              </span>
            </label>
            <label className="radio-container" style={{ visibility: "hidden" }}>
              <NewCircleIcon />
              <span className="radio-label">Assign Staff</span>
            </label>
          </div>
        </div>
      )}
      <div className="device-header-container">
        <div className="device-header-icon-title">
          <div className="device-header-icon">
            <DeviceInventoryIcon
              style={{ fill: "#113E57", width: "29px", height: "32px" }}
            />
          </div>
          <div className="device-header-title">
            {trans("DEVICES")} ({filteredAccessoriesData.length})
          </div>
        </div>
        {dataFetched && filteredAccessoriesData.length === 0 && (
          <div
            className="device-page-assign-camera-container"
            onClick={handleAssignSmartCamera}
          >
            <AddPlusIcon className="smart-camera-add-icon" />
            <span className="assign-smart-camera-title">
              Assign Smart Camera
            </span>
          </div>
        )}
      </div>
      <Spin spinning={!dataFetched} style={{ margin: "70px -20px" }}>
        {dataFetched && (
          <>
            <div style={{ padding: "16px" }}>
              {filteredAccessoriesData.length === 0 ? (
                <div className="no-devices-container">
                  <div className="no-devices-card">
                    <div className="no-devices-info">
                      <div className="label">No camera assigned yet</div>
                      <div className="description">
                        Once you have assigned a camera it will be found here
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                filteredAccessoriesData.map((device, index) => {
                  const isExpanded = device?.modelnumber === "HC200";
                  const key = device?.deviceId || index;

                  return (
                    <DeviceInventoryList
                      key={key}
                      device={device}
                      index={index}
                      isExpanded={isExpanded}
                      showModal={showModal}
                      deviceStatus={deviceStatus}
                      getDeviceInfo={getDeviceInfo}
                      timezone={
                        timezone ? timezone?.split(" ")[0] : LOCAL_TIMEZONE
                      }
                      connectionStatus={mapEventData(device).connectionStatus}
                      loginUserTimeformat={loginUserTimeformat}
                      userConfig={userConfig}
                      selectedLanguage={
                        LocalStorage.get(AppStorageKeys.language) || "en"
                      }
                    />
                  );
                })
              )}
            </div>
          </>
        )}
      </Spin>
      {isModalVisible && (
        <DeviceInventoryModal
          isModalVisible={isModalVisible}
          handleOk={handleModalClose}
          handleCancel={handleModalClose}
          device={selectedDevice}
          selectedDeviceInfo={getDeviceInfo(selectedDevice)}
          deviceStatus={deviceStatus}
          timezone={timezone ? timezone?.split(" ")[0] : LOCAL_TIMEZONE}
          connectionStatus={mapEventData(selectedDevice).connectionStatus}
          hubRecord={hubRecord}
          performAction={(data, params) =>
            performAction(deviceManagementApi, data, providerid, trans, params)
          }
          actionsData={actionsData(selectedDevice)}
          loginUserTimeformat={loginUserTimeformat}
          accessoriesData={accessoriesData}
          userConfig={userConfig}
          removeDevice={(device) => {
            removeDevice(
              userApi,
              hubRecord,
              device,
              trans,
              fetchDevices,
              refetch,
              setHeaderRefreshKey,
              queryParams,
              setDeviceLocation,
              setDeviceStatus,
              setStatusTitle,
              setHasDevice
            );
          }}
          selectedLanguage={LocalStorage.get(AppStorageKeys.language) || "en"}
          setIsSmartCameraModalOpen={setIsSmartCameraModalOpen}
        />
      )}

      {isSmartCameraModalOpen && (
        <AssignSmartCameraModal
        isSmartCameraModalOpen={isSmartCameraModalOpen}
        closeModal={closeModal}
        searchText={searchText}
        setSearchText={setSearchText}
        searchApplied={searchApplied}
        handleSearchApply={handleSearchApply}
        handleClearSearch={handleClearSearch}
        readyToAssignDevices={readyToAssignDevices}
        readyToAssignloading={readyToAssignloading}
        selectedSmartCameraDevice={selectedSmartCameraDevice}
        handleDeviceSmartCameraSelect={handleDeviceSmartCameraSelect}
        assignDevice={assignDevice}
        hubRecord={hubRecord}
        fetchDevices={fetchDevices}
        setHasDevice={setHasDevice}
        queryParams={queryParams}
        setDeviceLocation={setDeviceLocation}
        setDeviceStatus={setDeviceStatus}
        setStatusTitle={setStatusTitle}
        setHeaderRefreshKey={setHeaderRefreshKey}
        refetch={refetch}
        />
      )}
    </div>
  );
};

export default DeviceInventory;
