import React from "react";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";
import { AppStorageKeys } from "@commscopemycloud/humaui/Utilities/Constants";
import { HomeSightNewLogo } from "../Common/Icons";
const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);

const AboutCardInfo = () => {
  const trans = useSelector(translator);
  return (
    <>
      <div className="help-page-card-header">{trans("ABOUT")}</div>
      <HomeSightNewLogo />
      <div className="help-page-about-data-container">
        <div><p className="help-page-about-data version">{trans("WEBPORTALVERSION")} {ENV_VARS.VERSION}</p></div>
        <div>
          <span className="help-page-about-data copyright">{trans("COPYWRITETEXT")}</span>
        </div>
      </div>
    </>
  );
};

export default AboutCardInfo;
