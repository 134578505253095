import moment from "moment";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";
import { AppStorageKeys } from "@commscopemycloud/humaui/Utilities/Constants";

const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);

export const MINIMUM_MOTION_COUNT_PERHOUR =
  ENV_VARS.MINIMUM_MOTION_COUNT_PERHOUR;

export const timeArray = [
  "12 AM",
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
];

export const get_utc_offset = (timeZone, DefaultTimezone) => {
  timeZone = timeZone?.split(" ")[0] || DefaultTimezone;
  const timeZoneName = Intl.DateTimeFormat(navigator.language, {
    timeZoneName: "longOffset",
    timeZone,
  })
    .formatToParts()
    .find((i) => i.type === "timeZoneName").value;

  return timeZoneName === "GMT" ? "+00:00" : timeZoneName.replace("GMT", "");
};

export const localTimeOffset = (timezoneOffset) => {
  const offsetSign = timezoneOffset.startsWith("+") ? 1 : -1;
  const [hours, minutes] = timezoneOffset.substring(1).split(":").map(Number);
  return [hours * offsetSign, minutes * offsetSign];
};

export const filterBySelectedDate = (data, date) => {
  const filteredData = data.filter((obj) => {
    return obj.start_time.split("T")[0] === date;
  });
  return filteredData;
};

export const filterBySelectedDateEnv = (data, date) => {
  const filteredData = data.filter((obj) => {
    return obj.utc_timestamp_inms.split("T")[0] === date;
  });
  return filteredData;
};

const dataAccumulatedByHour = (data, timezone, loginUserTimeformat) => {
  return data.reduce(
    (acc, obj) => {
      const hour = moment.tz(obj.start_time, timezone.split(" ")[0]).hour();

      const sumCounts = obj.detection_counts
        .split(",")
        .map(Number)
        .reduce((sum, count) => sum + count, 0);

      acc.hourlyData[hour] = (acc.hourlyData[hour] || 0) + sumCounts;

      const timeFormat = loginUserTimeformat?.is24hoursformat
        ? "HH:mm"
        : "h:mm A";

      // Convert timestamps to the appropriate format and update time ranges
      const formattedTime = moment
        .tz(obj.start_time, timezone.split(" ")[0])
        .format(timeFormat);

      // Track first and last timestamps for each hour
      if (!acc.timeRanges[hour]) {
        acc.timeRanges[hour] = {
          firstReportedTime: formattedTime,
          lastReportedTime: formattedTime,
        };
      } else {
        acc.timeRanges[hour].lastReportedTime = formattedTime;
      }

      // Update first reported time if needed
      if (
        moment(obj.start_time)
          .tz(timezone.split(" ")[0])
          .isBefore(
            moment.tz(
              acc.timeRanges[hour].firstReportedTime,
              timezone.split(" ")[0]
            )
          )
      ) {
        acc.timeRanges[hour].firstReportedTime = formattedTime;
      }

      return acc;
    },
    {
      hourlyData: {},
      timeRanges: {},
    }
  );
};

export const convertToTimezoneOffset = (timestamp, offset, timezoneOffset) => {
  const date = new Date(timestamp);
  date.setUTCMinutes(date.getUTCMinutes() + offset);
  return date.toISOString().replace("Z", timezoneOffset);
};

const sortEventsByTime = (events) =>
  events.sort(
    (a, b) => new Date(a.utc_timestamp_inms) - new Date(b.utc_timestamp_inms)
  );

const totalLightPerHour = (data, timezone) => {
  const result = {};

  for (const [deviceuuid, deviceData] of Object.entries(data)) {
    result[deviceuuid] = deviceData.reduce((acc, obj) => {
      const hour = moment
        .tz(obj.utc_timestamp_inms, timezone.split(" ")[0])
        .hour();

      const ambientLight = obj.ambient_light;

      acc[hour] = (acc[hour] || 0) + ambientLight;
      return acc;
    }, {});
  }

  return result;
};

export const separateEnvironmentalDeviceData = (sortedData) => {
  const deviceEnvData = {};
  sortedData.forEach((item) => {
    const deviceuuid = item.deviceuuid;
    if (!deviceEnvData[deviceuuid]) {
      deviceEnvData[deviceuuid] = [];
    }
    deviceEnvData[deviceuuid].push(item);
  });
  return deviceEnvData;
};

export const processPastEnvironmentalData = (
  data,
  dateToDisplay,
  timezone,
  timezoneOffset,
  setLightData
) => {
  try {
    const eventsData = data.user_events;
    const eventsDataPrincipalTimezone = updateEventsData(
      eventsData,
      timezoneOffset,
      "environmental"
    );
    const dateFormatted = formatDate(dateToDisplay);
    const dataFilteredByDate = filterBySelectedDateEnv(
      eventsDataPrincipalTimezone,
      dateFormatted
    );
    const sortedData = sortEventsByTime(dataFilteredByDate);
    console.log("sorted light data", sortedData);
    const devicesEnvData = separateEnvironmentalDeviceData(sortedData);
    const lightDataCombined = totalLightPerHour(devicesEnvData, timezone);
    console.log("light data combined", lightDataCombined);
    setLightData(lightDataCombined);
  } catch (error) {
    console.error("Error processing light data:", error);
    setLightData({});
  }
};

export const updateEventsData = (eventsData, timezoneOffset, type) => {
  const [localHourOffset, localMinuteOffset] = localTimeOffset(timezoneOffset);
  const offset =
    localHourOffset * 60 +
    (localHourOffset < 0 ? -localMinuteOffset : localMinuteOffset);

  if (type === "environmental") {
    return eventsData.map((eventData) => ({
      ...eventData,
      utc_timestamp_inms: convertToTimezoneOffset(
        eventData.utc_timestamp_inms,
        offset,
        timezoneOffset
      ),
    }));
  }

  return eventsData.map((eventData) => ({
    ...eventData,
    start_time: convertToTimezoneOffset(
      eventData.start_time,
      offset,
      timezoneOffset
    ),
    end_time: convertToTimezoneOffset(
      eventData.end_time,
      offset,
      timezoneOffset
    ),
    utc_timestamp_inms: convertToTimezoneOffset(
      eventData.utc_timestamp_inms,
      offset,
      timezoneOffset
    ),
  }));
};

export const separateDeviceData = (data) => {
  const map = {};
  data.forEach((obj) => {
    if (!map[obj.detection_device]) {
      map[obj.detection_device] = [];
    }
    map[obj.detection_device].push(obj);
  });
  return map;
};

export const formatDate = (date) => {
  const d = new Date(date);
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const day = d.getDate().toString().padStart(2, "0");
  return `${d.getFullYear()}-${month}-${day}`;
};

export const sortEventsByStartTime = (events) =>
  events.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));

export const accumulateDataPerHour = (
  devicesData,
  allDevicesFetched,
  timezone,
  loginUserTimeformat
) => {
  return Object.entries(devicesData).reduce((acc, [deviceuuid, events]) => {
    const { detection_type, location } = events[0];
    const device = allDevicesFetched[deviceuuid];

    acc[deviceuuid] = {
      location,
      type: detection_type,
      hourlyData: dataAccumulatedByHour(events, timezone, loginUserTimeformat),
      batteryLevel: device?.battery_level,
      connectionStatus: device?.connectionStatus,
      model: device?.modelnumber,
      serialNumber: device?.serialnumber,
    };

    return acc;
  }, {});
};

export const getAllDevicesDataPerHour = (
  combinedDataPerHour,
  allDevicesFetched
) => {
  if (!Object.keys(allDevicesFetched).length) return;

  const devicesDataUuid = Object.keys(combinedDataPerHour);
  const allDevicesUuid = Object.keys(allDevicesFetched);

  const missingDevices = allDevicesUuid.filter(
    (key) => !devicesDataUuid.includes(key)
  );
  for (const deviceuuid of missingDevices) {
    const deviceData = allDevicesFetched[deviceuuid];

    combinedDataPerHour[deviceuuid] = {
      location: deviceData?.location,
      type: "default",
      hourlyData: {
        hourlyData: {},
        timeRanges: {},
      },
      batteryLevel: deviceData?.battery_level,
      connectionStatus: deviceData?.connectionStatus,
      model: deviceData?.modelnumber,
      serialNumber: deviceData?.serialnumber,
    };
  }

  return combinedDataPerHour;
};

export const processHistMotionData = async (
  historicalData,
  timezone,
  timezoneOffset,
  allDevicesFetched,
  setPastDataPerHour,
  setPastDataLoaded,
  loginUserTimeformat
) => {
  const histDataPerHour = {};

  for await (const [key, data] of Object.entries(historicalData)) {
    try {
      const eventsData = data.user_events;
      const eventsDataPrincipalTimezone = updateEventsData(
        eventsData,
        timezoneOffset,
        "motion"
      );
      const dateFormatted = key;
      const dataFilteredByDate = filterBySelectedDate(
        eventsDataPrincipalTimezone,
        dateFormatted
      );
      const sortedData = sortEventsByStartTime(dataFilteredByDate);
      const devicesData = separateDeviceData(sortedData);

      const combinedDataPerHour = Object.keys(devicesData).length
        ? accumulateDataPerHour(
            devicesData,
            allDevicesFetched,
            timezone,
            loginUserTimeformat
          )
        : {};

      const updatedData = ensureLastHourInData(combinedDataPerHour);

      const allDevicesDataPerHour = getAllDevicesDataPerHour(
        updatedData,
        allDevicesFetched
      );

      histDataPerHour[dateFormatted] = allDevicesDataPerHour;
      console.log({ date: dateFormatted, data: allDevicesDataPerHour });
    } catch (error) {
      console.error("Error processing historical motion data:", error);
      setPastDataLoaded(false);
    }
  }
  setPastDataPerHour(histDataPerHour);
  console.log("Final Data Historical Motion -> ", histDataPerHour);
  setPastDataLoaded(true);
};
export const processMotionData = (
  data,
  dateToDisplay,
  timezone,
  timezoneOffset,
  allDevicesFetched,
  setDataPerHour,
  setDataLoaded,
  loginUserTimeformat
) => {
  try {
    const eventsData = data.user_events;
    const eventsDataPrincipalTimezone = updateEventsData(
      eventsData,
      timezoneOffset,
      "motion"
    );
    const dateFormatted = formatDate(dateToDisplay);
    const dataFilteredByDate = filterBySelectedDate(
      eventsDataPrincipalTimezone,
      dateFormatted
    );
    const sortedData = sortEventsByStartTime(dataFilteredByDate);
    const devicesData = separateDeviceData(sortedData);

    const combinedDataPerHour = Object.keys(devicesData).length
      ? accumulateDataPerHour(
          devicesData,
          allDevicesFetched,
          timezone,
          loginUserTimeformat
        )
      : {};

    const updatedData = ensureLastHourInData(combinedDataPerHour);

    const allDevicesDataPerHour = getAllDevicesDataPerHour(
      updatedData,
      allDevicesFetched
    );

    setDataPerHour(allDevicesDataPerHour);
    console.log(allDevicesDataPerHour);
    setDataLoaded(true);
  } catch (error) {
    console.error("Error processing motion data:", error);
    setDataLoaded(false);
  }
};

export const ensureLastHourInData = (combinedDataPerHour) => {
  const userIds = Object.keys(combinedDataPerHour);
  const hourlyData = userIds.map((id) => combinedDataPerHour[id].hourlyData);

  const maxHours = hourlyData.map((obj) =>
    Math.max(...Object.keys(obj).map(Number))
  );
  const maxHour = Math.max(...maxHours);

  userIds.forEach((id) => {
    const obj = combinedDataPerHour[id];
    const lastHour = Math.max(...Object.keys(obj.hourlyData).map(Number));
    if (lastHour < maxHour) {
      obj.hourlyData[maxHour] = 0;
    }
  });

  return combinedDataPerHour;
};

export const sortByModelAndLocation = (data) => {
  return Object.keys(data).sort((a, b) => {
    const itemA = data[a];
    const itemB = data[b];

    if (itemA.model === "HC200") return -1;
    if (itemB.model === "HC200") return 1;

    const locationA = itemA.location || "";
    const locationB = itemB.location || "";
    return locationA.localeCompare(locationB);
  });
};

export const dayORNight = (hour) => {
  let time = hour.split(" ")[0];
  let period = hour.split(" ")[1];
  if (time === "12") time = 0;
  if ((period === "AM" && time < 6) || (period === "PM" && time >= 6)) {
    return "night";
  } else {
    return "day";
  }
};

export const convertTo24HourFormat = (timeString, render = false) => {
  const [time, period] = timeString.split(" ");
  let hour = parseInt(time, 10);

  if (period === "AM" && hour === 12) {
    hour = 0;
  } else if (period === "PM" && hour !== 12) {
    hour += 12;
  }

  if (render) {
    // If render is true, ensure hour is within the range 00 to 24.
    return hour === 24 ? "00" : hour.toString().padStart(2, "0");
  }
  return hour.toString();
};
