import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

const TaskCustomCalendar = ({
  taskDate = null,
  taskStatus = "",
  selectedSchedulelistDate = null,
}) => {
  console.debug("selectedSchedulelistDate :", selectedSchedulelistDate); 
  console.log('taskDate-->10',taskDate)
  console.log('taskStatus-->11',taskStatus)
  const daysOfWeek = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const [selectedDayjsObject, setSelectedDayjsObject] = useState(null);

  useEffect(() => {
    if (selectedSchedulelistDate) {
      const dayjsObj = dayjs(selectedSchedulelistDate);
      setSelectedDayjsObject(dayjsObj);
    }
  }, [selectedSchedulelistDate]);

  // Convert month name to month number
  const months = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  // Extract year and month from selectedSchedulelistDate
  const selectedYear = selectedDayjsObject?.$y;
  const selectedMonth = selectedDayjsObject?.$M; // $M is 0-indexed

  // Convert the selected month number to a 2-digit string
  const selectedMonthNumber = selectedMonth !== null ? (selectedMonth + 1).toString().padStart(2, "0") : null;

  // Function to get the month name from the month number
  function getMonthName(monthNumber) {
    for (const [key, value] of Object.entries(months)) {
      if (value === monthNumber) {
        return key;
      }
    }
    return null; // Return null if the month number is not found
  }

  const monthName = getMonthName(selectedMonthNumber);
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };
  const isToday = (year, month, day) => {
    const today = new Date();
    return (
      today.getFullYear() === year &&
      today.getMonth() === month &&
      today.getDate() === day
    );
  };

  const isTaskDate = (year, month, day) => {
    if (!taskDate) return false;
    const date = dayjs(new Date(year, month, day));

    // Convert the taskDate to ISO format
    const cleanDate = taskDate.replace(
      /^\d{1,2}:\d{2} [APM]+ [a-zA-Z]+, (\w+) (\d+), (\d{4})$/,
      "$3-$1-$2"
    );
    const dateParts = cleanDate.split("-");
    let monthNumber;
    if (dateParts.length === 1) {
      // Handle recurring task format
      const recurringDateParts = taskDate.match(/(\w+) (\d+), (\d{4})$/);
      monthNumber = months[recurringDateParts[1]];
    } else {
      // Handle non-recurring task format
      monthNumber = months[dateParts[1]];
    }
    const isoDate = `${dateParts[0]}-${monthNumber}-${dateParts[2]}`;

    const taskDateFormatted = dayjs(isoDate, "YYYY-MM-DD");  
    return (
      date.isValid() &&
      taskDateFormatted.isValid() &&
      date.isSame(taskDateFormatted, "day")
    );
  };

  const renderCalendar = () => {
    if (!selectedYear || !selectedMonth) return null;

    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    const firstDay = getFirstDayOfMonth(selectedYear, selectedMonth);
    const calendarDays = [];

    // Fill in the blanks for days before the first day of the month
    for (let i = 0; i < (firstDay === 0 ? 6 : firstDay - 1); i++) {
      calendarDays.push(
        <div key={`blank-${i}`} className="task-calendar-day blank"></div>
      );
    }

    // Fill in the days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      calendarDays.push(
        <div
          key={day}
          className={`task-calendar-day ${
            isTaskDate(selectedYear, selectedMonth, day)
              ? `task-date-${taskStatus}`
              : ""
          }  ${isToday(selectedYear, selectedMonth, day) ? "today" : ""}`}
        >
          {day}
        </div>
      );
    }

    return calendarDays;
  };

  return (
    <div className="task-calendar-container">
      {selectedDayjsObject && (
        <>
          <div className="task-calendar-header">
            <div className="selectyearmonth">{selectedYear}</div>       
            <div className="selectyearmonth">{monthName}</div>
          </div>
          <div className="task-calendar-date-container">
            <div className="task-calendar-grid">
              {daysOfWeek.map((day) => (
                <div key={day} className="task-calendar-day-header">
                  {day}
                </div>
              ))}
              {renderCalendar()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TaskCustomCalendar;