import React from "react";
import { Modal, Button, Input, Spin, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { CloseIcon, HC200Icon, NewCancelIcon, NewUpdateIcon } from "../Common/Icons";

const AssignSmartCameraModal = ({
  isSmartCameraModalOpen,
  closeModal,
  searchText,
  setSearchText,
  searchApplied,
  handleSearchApply,
  handleClearSearch,
  readyToAssignDevices,
  readyToAssignloading,
  selectedSmartCameraDevice,
  handleDeviceSmartCameraSelect,
  assignDevice,
  hubRecord,
  trans,
  fetchDevices,
  setHasDevice,
  queryParams,
  setDeviceLocation,
  setDeviceStatus,
  setStatusTitle,
  setHeaderRefreshKey,
  refetch,
}) => {
  return (
    <Modal
      open={isSmartCameraModalOpen}
      onCancel={closeModal}
      footer={null}
      closable={false}
      centered
      className={`device-page-assign-smart-camera-modal
          ${readyToAssignDevices.length > 0 ? "large" : "small"}`}
    >
      <div className="device-page-modal-container">
        <div className="device-header-icon-title">
          <span className="assign-smart-camera-title">Assign Smart Camera</span>
        </div>
        <div className="modal-close-button" onClick={closeModal}>
          <CloseIcon className="modal-close-icon" />
        </div>
      </div>
      <div
        className={`device-page-assign-smart-camera-row-2 ${
          readyToAssignDevices.length > 0 ? "large" : "small"
        }`}
      >
        <div className="device-page-search-container">
          <div className="search-label">Select a Smart Camera</div>
          <div className="device-page-assign-smart-camera-search">
            <Input
              className="search-input"
              value={searchText || ""}
              onChange={(e) => setSearchText(e.target.value)}
              onPressEnter={() => handleSearchApply()} // Apply search on Enter key
              placeholder="Search a serial number" // Placeholder text for the search input
              suffix={
                searchApplied ? (
                  <CloseOutlined
                    onClick={handleClearSearch}
                    title="Clear Search"
                  />
                ) : (
                  <></>
                )
              }
            />
          </div>
        </div>
        <div
          className={`device-list-scrollable-container ${
            readyToAssignDevices.length > 0 ? "large" : "small"
          }`}
        >
          {readyToAssignloading ? (
            <Spin
              spinning={readyToAssignloading}
              size="large"
              style={{ margin: "30px 200px" }}
            />
          ) : readyToAssignDevices.length > 0 ? (
            readyToAssignDevices.map((device, index) => {
              // Check if the current device is selected
              const isSelected =
                selectedSmartCameraDevice?.deviceid === device.deviceid;
              return (
                <li
                  key={index}
                  className={`device-page-assign-camera-column ${
                    isSelected ? "smart-camera-selected-device" : ""
                  }`}
                  onClick={() => handleDeviceSmartCameraSelect(device)}
                >
                  <div className="device-page-assign-camera-content">
                    <span className="device-page-camera-title">
                      <span className="ready-to-assign-id">
                        {device.deviceid}
                      </span>
                    </span>
                    <span className="new-hc200-icon-container">
                      <HC200Icon
                        status={device?.deviceconnectstatus?.toLowerCase()}
                        className="new-hc200-icon-container"
                      />
                    </span>
                  </div>
                </li>
              );
            })
          ) : (
            <div className="no-ready-to-assign-devices-container">
              <span className="no-device-available">No device available</span>
              <span className="no-device-message">
                Come back later to find a device to assign
              </span>
            </div>
          )}
        </div>
      </div>
      {/* Footer with Cancel and Perform It buttons */}
      {readyToAssignDevices.length > 0 ? (
        <div className="assign-smart-camera-modal-footer">
          <Button className="custom-no-button" onClick={closeModal}>
            <NewCancelIcon />
            <span className="custom-no-label">Cancel</span>
          </Button>

          <Button
            className={`custom-yes-button ${
              !selectedSmartCameraDevice ? "disabled-button" : ""
            }`}
            onClick={() => {
              if (selectedSmartCameraDevice) {
                assignDevice(
                  hubRecord,
                  selectedSmartCameraDevice,
                  trans,
                  fetchDevices,
                  setHasDevice,
                  closeModal,
                  refetch,
                  queryParams,
                  setDeviceLocation,
                  setDeviceStatus,
                  setStatusTitle,
                  setHeaderRefreshKey
                );
              } else {
                message.warning("Please select a device to assign.");
              }
            }}
          >
            <NewUpdateIcon
              className={`assigned-selected-icon ${
                !selectedSmartCameraDevice ? "disabled-icon" : ""
              }`}
            />
            <span
              className={`custom-yes-label ${
                !selectedSmartCameraDevice ? "disabled-label" : ""
              }`}
            >
              Assign Smart Camera
            </span>
          </Button>
        </div>
      ) : (
        <div className="assign-smart-camera-modal-footer">
          <Button className="custom-yes-button" onClick={closeModal}>
            <span className="custom-yes-label">I will come back later</span>
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default AssignSmartCameraModal;