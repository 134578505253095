import React, { useEffect, useState, } from "react";
import "./Wellness.less";
import WellnessList from "./WellnessList";
import WellnessHistoryModal from "./WellnessHistoryModal";
import WellnessSupportDeviceModal from "./WellnessSupportDeviceModal";
import WellnessHeader from "./WellnessHeader";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { getUserInfo } from "@commscopemycloud/humaui/Store/dataStore";
import {
  celsiusToFahrenheit,
  fahrenheitToCelsius,
  kilogramsToPounds,
  poundsToKilograms,
  mgTommol,
  mmolTomg
} from "../../utilities/helpers/commonHelper";
import { message } from "antd";
import moment from 'moment-timezone';
import WellnessListDataModel from "./wellnessList.json";
import PropTypes from "prop-types";
import titleData from "./statistic-title.json";

let unitTitleData;
let wellnessListModelData;
const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const Wellness = ({ hubRecord, timezone, deviceStatus, providerid }) => {

  const userTimezone = timezone ? timezone?.split(" ")[0] : LocalTimezone;
  // const currentStartDateTime = moment().utcOffset("+00:00").format("YYYY-MM-DDT00:00:00Z");
  // const currentEndDateTime = moment().utcOffset("+00:00").format("YYYY-MM-DDT23:59:29Z");
  const userInfo = useSelector(getUserInfo(hubRecord?.useruuid));
  const trans = useSelector(translator);
  const userEventsApi = useSelector((state) => state.apis.userEventsApi);
  const deviceModelApi = useSelector((state) => state.apis.deviceModelApi);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showSupportDeviceModal, setShowSupportDeviceModal] = useState(false);
  const [wellnessListData, setWellnessListData] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [supportDeviceModalLoading, setSupportDeviceModalLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [supportDeviceData, setSupportDeviceData] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [supportDeviceModalTitle, setSupportDeviceModalTitle] = useState('');
  const [currentDevice, setCurrentDevice] = useState({});
  const [wellnessListModel, setWellnessListModel] = useState(null);
  const [startDatetime, setStartDatetime] = useState("")
  const [endDatetime, setEndDatetime] = useState("");
  const [allDeviceModels, setAllDeviceModels] = useState([]);
  const [activeSupportDevicesTab, setActiveSupportDevicesTab] = useState(
    Object.keys(allDeviceModels || {}).length > 0 ? Object.keys(allDeviceModels || {})[0] : ""
  );  const [selectedRange, setSelectedRange] = useState("today");

  useEffect(() => {
    if (!unitTitleData) {
      import("./statistic-title.json")
        .then((data) => {
          unitTitleData = data.default || data;
        })
        .catch((err) => console.error("Error loading statistic-title.json:", err));
    }
  }, []);

  useEffect(() => {
    deviceModelApi.getDeviceModelByType(
      "all",
      apiCallback({
        translator: trans,
        successCallback: (allData) => {
          const modelsByType = {};
          allData?.devicemodels?.forEach(device => {
            const type = device.devicetype;
            if (!modelsByType[type]) modelsByType[type] = [];
            modelsByType[type].push(device);
          });
          setAllDeviceModels(modelsByType);
        },
        errorCallback: (error) => {
          console.error("Error fetching ALL device models:", error);
        },
      })
    );
  }, [deviceModelApi, trans]);
  
  useEffect(() => {
    if (!wellnessListModel) {
      const modelData = { ...WellnessListDataModel }; // Ensure a deep copy for safe updates.
      wellnessListModelData = modelData;
      setWellnessListModel(modelData);
    }
  }, []); // Ensure it runs only once during the component lifecycle.

  function getStatisticUnitTitle(val) {
    const valKey = val.toLowerCase();
    const userDefinedMeasurement = userInfo?.measurement || '';
    return unitTitleData?.[userDefinedMeasurement]?.[valKey] || "";
  }

  const formatValue = value => {
    if (value === null || value === undefined) return "-";
    if (typeof value === "number") {
      const roundedValue = Math.round(value * 100) / 100; // Round to 2 decimal places
      return roundedValue.toString().length > 5
        ? roundedValue.toFixed(2).slice(0, 5) // Truncate if longer than 5 characters
        : roundedValue.toString();
    }
    return value.toString().slice(0, 5); // For non-numeric values
  };

  function getUserDefinedUnitVal(value, key) {
    if (!value || !key) {
      console.error('Invalid arguments provided.');
      return null;
    }

    const userDefinedMeasurement = userInfo?.measurement || '';
    const valueUnitType = value.unit_type || '';

    if (!userDefinedMeasurement || userDefinedMeasurement === '' || valueUnitType === '') {
      return value.value;
    }

    if (String(userDefinedMeasurement) === String(valueUnitType)) {
      return value.value;
    }

    if (userDefinedMeasurement === 'metric' && valueUnitType === 'imperial') {
      if (key === 'weight_scale') return poundsToKilograms(value.value);
      if (key === 'thermometer') return fahrenheitToCelsius(value.value);
      // [HOMESIGHTVTV-9271] Removed glucose meter conversion
      // if (key === 'glucose_meter') return mgTommol(value.value);
    }

    if (userDefinedMeasurement === 'imperial' && valueUnitType === 'metric') {
      if (key === 'weight_scale') return kilogramsToPounds(value.value);
      if (key === 'thermometer') return celsiusToFahrenheit(value.value);
      // [HOMESIGHTVTV-9271] Removed glucose meter conversion
      // if (key === 'glucose_meter') return mmolTomg(value.value);
    }

    return value.value;
  }

  function formatTimestamp(timestamp, timezone) {

    if (timestamp == null || timestamp == undefined) {
      return { day: '-', time: '-', difference: '-' };
    }
    const now = moment().tz(timezone);
    const inputTime = moment.tz(timestamp, timezone);
    const userDefinedTimeFormat = userInfo?.is24hoursformat;

    let time = '';
    if (userDefinedTimeFormat)
      time = inputTime.format('HH:mm:ss'); // Format time as '09:40'
    else
      time = inputTime.format('h:mm:ss A'); // Format time as '9:40 AM'


    const difference = moment.duration(inputTime.diff(now));
    let differenceText;

    if (difference.asMilliseconds() < 0) {
      // Past data
      const pastDifference = moment.duration(now.diff(inputTime));
      differenceText =
        pastDifference.asMinutes() < 1
          ? 'Just now'
          : pastDifference.asMinutes() < 60
            ? `${Math.round(pastDifference.asMinutes())} min ago`
            : pastDifference.asHours() < 24
              ? `${Math.round(pastDifference.asHours())} hours ago`
              : `${Math.round(pastDifference.asDays())} days ago`;
    } else {
      // Future data
      differenceText =
        difference.asMinutes() < 1
          ? 'Later'
          : difference.asMinutes() < 60
            ? `In ${Math.round(difference.asMinutes())} min`
            : difference.asHours() < 24
              ? `In ${Math.round(difference.asHours())} hours`
              : `In ${Math.round(difference.asDays())} days`;
    }

    let day;
    if (now.isSame(inputTime, 'day')) {
      day = 'Today';
    } else if (now.subtract(1, 'day').isSame(inputTime, 'day')) {
      day = 'Yesterday';
    } else {
      day = inputTime.format('ddd M/D'); // Format as 'Sun 12/1'
    }

    return {
      day,
      time,
      difference: differenceText,
    };
  }

  function processRawData(data) {
    const processedData = [];
    if (!data || Object.keys(data).length === 0) {
      setWellnessListData([])
      return processedData;
    }

    if (!wellnessListModel || !wellnessListModel['medical_accessories']) {
      console.error("wellnessListModel or medical_accessories is null or undefined.");
      return processedData;
    }
    const wellnessModel = JSON.parse(JSON.stringify(wellnessListModel));
    const allowedKeys = [];

    Object.keys(data).forEach((key) => {
      const entry = data[key][0];
      allowedKeys.push(key);

      if (!entry) {
        console.warn(`No entry found for key: ${key}`);
        return;
      }

      let dateTimeObj = formatTimestamp(entry.utc_timestamp_inms, userTimezone);
      let day = dateTimeObj['day'];
      let time = dateTimeObj['time'];
      let timeDifference = dateTimeObj['difference'];

      if (!wellnessModel['medical_accessories'][key]) {
        console.warn(`No medical_accessories data for key: ${key}`);
        wellnessModel['medical_accessories'][key] = {};
      }

      const statisticDataArray = Object.keys(wellnessModel['medical_accessories'][key]['data'] || []);
      const FinalStatisticDataArray = statisticDataArray.map((item) => {
        const newObj = {};
        const getUserDefinedMeasurementValue = entry?.devicetypeid ? getUserDefinedUnitVal(entry?.data?.[item], entry?.devicetypeid) : '-'
        newObj['display_label'] = getStatisticUnitTitle(String(item));
        newObj['display_value'] = `${formatValue(getUserDefinedMeasurementValue)}${+ entry?.data?.[item]?.unit != undefined && (entry?.data?.[item]?.unit === "F") ? '°' : ''}`;
        return newObj;
      });
      wellnessModel['medical_accessories'][key]['title'] = key;
      wellnessModel['medical_accessories'][key]['day'] = day;
      wellnessModel['medical_accessories'][key]['time'] = time;
      wellnessModel['medical_accessories'][key]['timeDiff'] = timeDifference;
      wellnessModel['medical_accessories'][key]['data'] = FinalStatisticDataArray;
      wellnessModel['medical_accessories'][key]['deviceManufacture'] = entry.device_manufacturer;
      wellnessModel['medical_accessories'][key]['deviceModel'] = entry.device_model;
      wellnessModel['medical_accessories'][key]['device_uuid'] = entry.deviceuuid;
      wellnessModel['medical_accessories'][key]['eventName'] = entry.eventname;
    });
    const mappedData = JSON.parse(JSON.stringify(wellnessModel));
    const medicalAccessories = mappedData.medical_accessories;
    Object.keys(medicalAccessories).forEach(key => {
      if (!allowedKeys.includes(key)) {
        delete medicalAccessories[key];
      }
    });
    setWellnessListData(mappedData);
    return mappedData;
  }

  useEffect(() => {
    let payload = {};
    if (startDatetime && endDatetime) {
      payload = {
        "userMedicalAccessories": {
          'startdatetime': startDatetime,
          'enddatetime': endDatetime
        }
      }
    }

    if (wellnessListModel !== null && wellnessListModel !== undefined) {
      setLoading(true);
      const useruuid = hubRecord.useruuid ?? '';
      const fetchUserMedicalAccessories = () => {
        const errorCallback = (errors) => {
          console.error("Error fetching wellness api information:", errors);

        };
        const successCallback = (data) => {
          const response = data['user_accessories'];
          processRawData(response);
          setLoading(false);
        };

        try {
          if (!useruuid) return;
          userEventsApi.getUserMedicalAccessories(
            useruuid,
            payload,
            apiCallback({
              translator: trans,
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
        } catch (error) {
          errorCallback(error);
        }
      };
      fetchUserMedicalAccessories();
      return () => {
        setLoading(false); // Ensure loading is set to false if component unmounts
      };
    }
  }, [userEventsApi, hubRecord, trans, userInfo?.measurement, wellnessListModelData, startDatetime, endDatetime]);

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedRange("today");
  };

  function getUserDefinedMeasurementValue(value, title, itemObj = {}) {
    const userDefinedMeasurement = userInfo?.measurement || '';
    if (title === 'thermometer') {
      if (userDefinedMeasurement === "metric" && itemObj.temperature_unit.toLowerCase() === "f") {
        return fahrenheitToCelsius(value);
      } else if (userDefinedMeasurement === "imperial" && itemObj.temperature_unit.toLowerCase() === "c") {
        return celsiusToFahrenheit(value);
      } else
        return value;
    } 
    // [HOMESIGHTVTV-9271] Removed glucose meter conversion
    // else if (title === 'glucose_meter') {  
    //   if (userDefinedMeasurement === "metric") {
    //     return mgTommol(value);
    //   } else
    //     return value;
    // }
    else if (title === 'weight_scale') {
      if (userDefinedMeasurement === "metric" && itemObj.weight_unit.toLowerCase() === "lbs") {
        return poundsToKilograms(value);
      } else if (userDefinedMeasurement === "imperial" && ["kgs", "kg"].includes(itemObj.weight_unit.toLowerCase())) {
        return kilogramsToPounds(value);
      } else
        return value;
    } else {
      return value;
    }
  }

  function processUserEvents(userEvents, title) {
    return userEvents.sort((a, b) => new Date(b.utc_timestamp_inms) - new Date(a.utc_timestamp_inms)).map(event => {
      const outputObj = {};
      let data = {};
      const wellnessModel = JSON.parse(JSON.stringify(wellnessListModel));
      const timeobj = formatTimestamp(event.utc_timestamp_inms, userTimezone);
      outputObj['useruuid'] = event['useruuid'];
      outputObj['deviceuuid'] = event['deviceuuid'];
      outputObj['formatted_date'] = timeobj['day'];
      outputObj['formatted_time'] = timeobj['time'];
      outputObj['modelnumber'] = event['modelnumber'];
      outputObj['timeDiffText'] = timeobj['difference'];
      outputObj['utc_timestamp_inms'] = event['utc_timestamp_inms'];
      outputObj['manufacturer'] = event['manufacturer'];
      outputObj['modelnumber'] = event['modelnumber'];

      const statisticDataArray = Object.keys(wellnessModel['medical_accessories'][title]['data'] || []);
      statisticDataArray.map((item) => {
        if (item === 'pulse') {
          data['pulse'] = event['pulse_rate']
        } else if (item === 'oxygen') {
          data['oxygen'] = event['spo2']
        } else {
          data[item] = getUserDefinedMeasurementValue(event[item], title, event);
        }
      });
      outputObj['data'] = data;
      return outputObj;
    });
  }

  const handelShowModal = (title, deviceBrand, deviceModel, eventType) => {
    const currentDeviceObj = { deviceBrand, deviceModel };
    setCurrentDevice(currentDeviceObj);
    const errorCallback = (error) => {
      message.error("Error fetching medical history!");
      console.error("Error fetching medical history:", error);
    };
    const successCallback = (data) => {
      const finalData = processUserEvents(data['user_events'], title);
      setModalData(finalData);
      setModalTitle(title);
      setModalLoading(false);
    };

    const endMoment = endDatetime ? moment.utc(endDatetime) : moment.utc();
    const endDate = endMoment
      .clone()
      .set({ hour: 23, minute: 59, second: 59 })
      .format("YYYY-MM-DD[T]HH:mm:ss[Z]");

    const startDate = endMoment
      .clone()
      .subtract(30, "days")
      .startOf("day")
      .format("YYYY-MM-DD[T]HH:mm:ss[Z]");

    try {
      userEventsApi.userEventsByTypesAndTimeRange(
        hubRecord.useruuid,
        startDate,
        endDate,
        {
          eventtypes: [eventType],
          details:true
        },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
    setShowModal(true);
    setModalLoading(true);
  };

  const handelSupportDeviceModal = (device_type) => {
    console.log('Support Device Modal:', device_type);
    setShowSupportDeviceModal(true);
    setSupportDeviceModalTitle(device_type);
    setActiveSupportDevicesTab(device_type);
    setSupportDeviceModalLoading(true)

    const errorCallback = (error) => {
      message.error("Error fetching support device data!");
      console.error("Error fetching support device data:", error);
    };
    const successCallback = (data) => {
      console.log('Support Device Data:', data);
      setSupportDeviceData(data['devicemodels']);
      setSupportDeviceModalLoading(false);
    };

    try {
      deviceModelApi.getDeviceModelByType(
        device_type,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }

  }

  const setDateHandler = (startDatetime, endDateTime) => {
    setStartDatetime(startDatetime);
    setEndDatetime(endDateTime);
  }

  return (
    <>
      <WellnessHistoryModal
        showHistoryModal={showModal}
        modalLoading={modalLoading}
        modalData={modalData}
        modalTitle={modalTitle}
        closeHistoryModal={handleModalClose}
        userMeasurement={userInfo?.measurement}
        deviceObj={currentDevice}
        timezone={userTimezone}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
      />
      <WellnessSupportDeviceModal
        showSupportDeviceModal={showSupportDeviceModal}
        closeSupportDeviceModal={() => { setShowSupportDeviceModal(false) }}
        supportDeviceModalLoading={supportDeviceModalLoading}
        supportDeviceData={supportDeviceData}
        supportDeviceModalTitle={supportDeviceModalTitle}
        activeSupportDevicesTab={activeSupportDevicesTab}
        setActiveSupportDevicesTab={setActiveSupportDevicesTab}
        handelSupportDeviceModal={handelSupportDeviceModal}
        wellnessListData={wellnessListData}
        allDeviceModels={allDeviceModels}
      />
      <div style={{ padding: "40px 40px 0px 40px" }}>
        <WellnessHeader />
        <WellnessList
          showViewHistoryModal={handelShowModal}
          showSupportDeviceModal={handelSupportDeviceModal}
          mappedData={wellnessListData}
          loading={loading}
          timeZone={timezone}
          setDateHandler={setDateHandler}
          activeSupportDevicesTab={activeSupportDevicesTab}
          allDeviceModels={allDeviceModels}
        />
      </div>
    </>
  );
};

Wellness.propTypes = {
  hubRecord: PropTypes.object,
  timezone: PropTypes.string,
  deviceStatus: PropTypes.object,
  providerid: PropTypes.string
}

export default Wellness;
