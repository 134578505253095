import React from "react";
import { CloseIcon } from "../Common/Icons";
import "./Wellness.less";
import { Modal, Tabs } from "antd";
import WellnessHistoryTableData from "./WellnessHistoryTableData";
import WellnessHistoryInsightChart from "./WellnessHistoryInsightChart";
import WellnessIconByTitle from "./WellnessIconByTitle";
import titleData from "./statistic-title.json";
import PropTypes from "prop-types";

const WellnessHistoryModal = ({
  showHistoryModal,
  closeHistoryModal,
  modalLoading,
  modalData,
  modalTitle,
  userMeasurement,
  deviceObj,
  timezone,
}) => {
  return (
    <Modal
      open={showHistoryModal}
      onOk={closeHistoryModal}
      onCancel={closeHistoryModal}
      footer={null}
      closable={false}
      centered
      className="wellness-modal-container"
    >
      <div className="modal-container">
        {/* Header Section */}
        <div className="wellness-modal-header-container">
          <div className="wellness-header-icon-title">
            <div className="wellness-header-icon">
              <WellnessIconByTitle
                title={modalTitle}
                style={{ width: "55px", height: "55px" }}
              />
            </div>
            <div className="wellness-modal-header-info">
              <div className="wellness-header-title">
                {titleData["device_title"][modalTitle]} History
              </div>
              <div className="wellness-header-sub-title">Health Reading</div>
            </div>
          </div>
          <div className="close-button" onClick={closeHistoryModal}>
            <CloseIcon className="close-icon" />
          </div>
        </div>

        {/* Tabs Section */}
        <div className="tabs-container">
          <Tabs
            defaultActiveKey="1"
            className="wellness-tabs"
            tabPosition="top"
            items={[
              {
                label: "Table Data",
                key: "1",
                children: (
                  <WellnessHistoryTableData
                    modalLoading={modalLoading}
                    modalData={modalData}
                    userMeasurement={userMeasurement}
                    deviceObj={deviceObj}
                  />
                ),
              },
              {
                label: "Insights and Chart",
                key: "2",
                children: (
                  <WellnessHistoryInsightChart
                    modalLoading={modalLoading}
                    modalData={modalData}
                    userMeasurement={userMeasurement}
                    modalTitle={modalTitle}
                    timezone={timezone}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </Modal>
  );
};

WellnessHistoryModal.prototype ={
  showHistoryModal: PropTypes.bool,
  closeHistoryModal: PropTypes.func,
  modalLoading: PropTypes.bool,
  modalData: PropTypes.array,
  modalTitle: PropTypes.string,
  userMeasurement: PropTypes.string,
  deviceObj: PropTypes.object,
  timezone: PropTypes.string,
}

export default WellnessHistoryModal;
