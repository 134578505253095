/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { AppStore, GooglePlay, ProviderKey, HomeSightLogoKey } from "../Common/Icons";

const MobileAppCardInfo = ({isDataFetched}) => {
    return (
    <>
      <h1 className="help-page-card-header">Mobile App</h1>
      <div className="help-page-card-data-group">
        < HomeSightLogoKey className="hslogo" />
        <p className="help-page-info-header">Download the HomeSight Mobile app</p>
      </div>
      <div>
        <div className="help-page-card-data-group">
          <p className="help-page-info-code">Provider Code</p>
        </div>
        <div className="help-page-card-data-group">
          <ProviderKey /> <p className="help-page-providerpin">{isDataFetched?.providerPIN}</p>
        </div>
        <div className="help-page-card-data-group">
          <a href="https://apps.apple.com/us/app/homesight/id6469586386" target="_blank" rel="noopener noreferrer">
            <AppStore className="help-page-app-store-button"/>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.homesight.mobile" target="_blank" rel="noopener noreferrer">
            <GooglePlay className="help-page-app-store-button" />
          </a>
        </div>
      </div>
    </>
  );
};

export default MobileAppCardInfo;