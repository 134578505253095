import React, { useEffect, useState } from "react";
import MainPage from "../../MainPage";
import { useSelector } from "react-redux";
import { Divider, Form, Input, Spin } from "antd";
import {
  CloseIcon,
  AddTaskIcon,
  NewCloseIcon,
  NewUpdateIcon,
  SaveChangesIcon,
  DeleteIcon,
  TaskMedicationIcon,
  EditIcon,
} from "../Common/Icons";
import PropTypes from "prop-types";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import "../../styles/Task.less";
import TaskCustomCalendar from "./TaskCustomCalendar";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import RecurringTaskModal from "./RecurringTaskModal";
import NonRecurringTaskModal from "./NonRecurringTaskModal";
import { Actions } from "@commscopemycloud/humaui/Utilities/Constants";
import {
  getTimeZoneFormat,
  toDateTimeByTimeZone,
  formatEventTimes
} from "@commscopemycloud/humaui/Utilities/DateTime";

dayjs.extend(utc);
dayjs.extend(timezone);
const ViewTask = (props) => {
  console.log("view task :", props);
  const {
    onlyContent,
    action,
    hubRecord,
    userInfo,
    taskRecord,
    onActionClick,
    openSubMenu = false,
    showHeader = false,
    loginUserTimeformat,
    eventRecord,
    userHubsData,
    selectedSchedulelistDate,
  } = props;
  
  const [form] = Form.useForm();
  const trans = useSelector(translator);
  const taskApi = useSelector((state) => state.apis.taskApi);
  // Track initial form data (to compare changes)
  const [formData, setFormData] = useState("");
  const [activeTab, setActiveTab] = useState("schedule");
  const [processing, setProcessing] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const handleEditClick = () => {
    setIsEditModalVisible(true); // Show the delete confirmation modal
  };
  const timeZone = getTimeZoneFormat(userInfo.timezone);
  const taskDate = toDateTimeByTimeZone(
    eventRecord.event_timestamp,
    timeZone,
    loginUserTimeformat?.is24hoursformat
  ).format("MM/DD/YY");
  const handleEditModalConfirm = () => {
    setIsEditModalVisible(false); // Close the modal
    onActionClick(Actions.editTask, {
      ...eventRecord,
      userHubsData,
    })();
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false); // Simply close the modal
  };
  const fetchData = () => {
    const errorCallback = (error) => {
      // message.error("Error fetching user information!");
      console.error("Error fetching user information:", error);
    };
    const successCallback = (data) => {
      console.log("UserIFetchtask: fetch result", data);
    };
    try {
      if (!eventRecord?.schedule_eventuuid) return;
      taskApi.fetchTask(
        eventRecord?.schedule_eventuuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const FormItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
    onActionClick(null, null, key)();
  };
  useEffect(() => {
    fetchData();
  }, [eventRecord?.schedule_eventuuid]);
  const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formatEventDates = (
    startDateStr,
    endDateStr = null,
    timeZone = null
  ) => {
    const startDate = timeZone
      ? dayjs(startDateStr).tz(timeZone)
      : dayjs(startDateStr);
    const endDate = endDateStr
      ? timeZone
        ? dayjs(endDateStr).tz(timeZone)
        : dayjs(endDateStr)
      : null;

    const options = { weekday: "long", month: "long", day: "numeric" };
    const formattedStartDate = startDate.format("dddd, MMMM D");
    const formattedEndDate = endDate ? endDate.format("dddd, MMMM D") : null;

    return endDate
      ? `${formattedStartDate} - ${formattedEndDate}`
      : formattedStartDate;
  };


  const renderEditModal = () => {
    if (!isEditModalVisible) return null;
    return eventRecord?.repeat &&
      ["daily", "custom", "weekly"].includes(eventRecord.repeat) ? (
      <RecurringTaskModal
        isModalOpen={isEditModalVisible}
        onConfirm={handleEditModalConfirm}
        onCancel={handleEditModalCancel}
        modalHeader={"Modify periodic task"}
      />
    ) : (
      <NonRecurringTaskModal
        isModalOpen={isEditModalVisible}
        onConfirm={handleEditModalConfirm}
        onCancel={handleEditModalCancel}
        modalHeader={"Modify task"}
        modalText="modify"
      />
    );
  };

  const render = (
    <div className="view-taskform-container">
      <Spin size="large" spinning={processing} tip={trans("PROCESSING")}>
        {renderEditModal()}
        <Form
          form={form}
          initialValues={formData}
          {...FormItemLayout}
          className="view-taskform-container"
        >
          <div className="view-taskform-card">
            <div className="view-task-title">
              <div className="view-form-title">
                <TaskMedicationIcon />
                <span>{eventRecord?.event_name}</span>
              </div>
              <div className="view-task-action">
                <EditIcon
                  style={{
                    height: "23px",
                    width: "23px",
                    cursor: "pointer",
                    fill: "#005D98",
                    stroke: "#005D98",
                  }}
                  onClick={handleEditClick}
                />
                <CloseIcon
                  className="icon-style"
                  style={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                    fill: "#005D98",
                    stroke: "#005D98",
                  }}
                  onClick={onActionClick(null, null)}
                />
              </div>
            </div>
            <Divider type="horizontal" className="divider" />
            <div className="view-task-information">
              <div className="view-task-info-title">Task details</div>
              <div className="view-task-info-data">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    {formatEventDates(
                      eventRecord?.event_startdate,
                      eventRecord?.event_enddate
                    )}
                  </span>
                  <span>
                    {formatEventTimes(
                      eventRecord?.event_startdate,
                      eventRecord?.event_starttime,
                      eventRecord?.event_enddate,
                      eventRecord?.event_endtime,
                      timeZone,
                      loginUserTimeformat?.is24hoursformat
                    )}
                  </span>
                </div>
                <div className="view-task-info-header">
                  Repeats:
                  <span className="view-task-infovalue">
                    {eventRecord ? eventRecord?.repeat : "Doesn't Repeat"}
                  </span>
                </div>
                <div className="view-task-info-header">
                  Reminder:
                  <span className="view-task-infovalue">
                    {eventRecord?.reminder / 60} minutes
                  </span>
                </div>
              </div>
            </div>
            <div className="view-task-status-info">
              <div className="view-task-info-title">Status Information</div>
              <div className="view-task-status-calendar">
                <TaskCustomCalendar
                  selectedSchedulelistDate={selectedSchedulelistDate}
                  taskDate={taskDate}
                  taskStatus={eventRecord?.task_status}
                />
                <div className="view-task-statushead">
                  {" "}
                  Legend:
                  <span
                    className="view-task-status"
                    style={{ background: "#4BD37B" }}
                  >
                    Completed
                  </span>
                  <span
                    className="view-task-status"
                    style={{ background: "#808080" }}
                  >
                    Scheduled
                  </span>
                  <span
                    className="view-task-status"
                    style={{ background: "#F9C030" }}
                  >
                    In progress
                  </span>
                  <span
                    className="view-task-status"
                    style={{ background: "#F46363" }}
                  >
                    Missed
                  </span>
                </div>
              </div>
            </div>
            <div className="view-task-decription">
              <div className="view-task-info-title">Task description</div>
              <Input.TextArea
                placeholder={"ADDDESCRIPTION"}
                name="event_description"
                defaultValue={eventRecord?.event_description}
                className="task-description"
                // maxLength={100}
              />
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );

  return onlyContent ? (
    render
  ) : (
    <MainPage
      hasSider={showHeader ? false : true}
      activeKey={activeTab}
      openSubMenu={openSubMenu}
      onChange={handleTabChange}
      hubUserRole={hubRecord?.rolename}
    >
      {render}
    </MainPage>
  );
};

ViewTask.propTypes = {
  onlyContent: PropTypes.bool,
  action: PropTypes.string,
  eventRecord: PropTypes.object,
  userInfo: PropTypes.object,
  contacts: PropTypes.arrayOf(PropTypes.object),
  onActionClick: PropTypes.func,
};

export default ViewTask;