import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "antd";
import NewCustomButton from "../Common/NewCustomButton";
import { NewCloseIcon, NewUpdateIcon } from "../Common/Icons";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { ButtonTypes } from "../Common/CustomButton";

const NonRecurringTaskModal = ({
  isModalOpen = false,
  onConfirm = () => {},
  onCancel = () => {},
  modalType = "",
  modalHeader = "",
  showDeleteMsg = false,
  actionType = "",
  saveChangesAction = false,
  className=""
}) => {
  const trans = useSelector(translator);
  return (
    <div className="">
      <Modal
        title={modalHeader}
        centered
        open={isModalOpen}
        footer={null}
        closable={false}
        className="task-modal-conatiner"
      >
        <div className="taskmodal">
          <div
            className={`${
              showDeleteMsg ? "" : "taskmodifydescription"
            } taskmodaldescription`}
          >
            {saveChangesAction ? (
              <p>
                <span style={{ fontWeight: "400" }}>
                  Changes have been{" "}
                  <span style={{ fontWeight: "700" }}>made</span> but{" "}
                  <span style={{ fontWeight: "700" }}>not saved.</span>
                </span>{" "}
              </p>
            ) : (
              <p>
                <span style={{ fontWeight: "400" }}>
                  You are about to {actionType} this
                </span>{" "}
                {modalType}.
                <br />
                {showDeleteMsg && (
                  <p style={{ fontWeight: "400" }}>
                    It will be removed from the{" "}
                    <span style={{ fontWeight: "700" }}>schedule.</span>
                  </p>
                )}
              </p>
            )}

            {saveChangesAction ? (
              <span>Are you sure you want to discard changes?</span>
            ) : (
              <span>Are you sure?</span>
            )}
          </div>
          <div className={`taskmodalbutton ${className}`}>
            <NewCustomButton
              className="cancel"
              onClick={onCancel}
              label={trans("CANCEL")}
              icon={<NewCloseIcon className="cancel-icon" />}
            />
            <NewCustomButton
              className="confirm"
              type={ButtonTypes.primary}
              label={trans("YES")}
              icon={<NewUpdateIcon className={"update-icon-enabled"} />}
              onClick={onConfirm}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

NonRecurringTaskModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default NonRecurringTaskModal;
