import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import moment from "moment";

export const postalCodeStatus = {
  empty: "EMPTY",
  invalid: "INVALID",
  valid: "VALID",
  noLocationKey: "No location key",
};

export const convertTemperature = (value, currentUnit, targetUnit) => {
  if (value === undefined) return null;
  if (currentUnit === targetUnit) return value;
  const toCelsius = (fahrenheit) => ((fahrenheit - 32) * 5) / 9;
  const toFahrenheit = (celsius) => (celsius * 9) / 5 + 32;

  return targetUnit === "C" ? toCelsius(value) : toFahrenheit(value);
};

export const sortByModelAndLocation = (a, b) => {
  const locationA = a[1]?.location ?? "";
  const locationB = b[1]?.location ?? "";

  if (locationA === null || locationA === undefined) return -1;
  if (locationB === null || locationB === undefined) return 1;

  return locationA.localeCompare(locationB);
};

export const mapEventData = (eventData) => ({
  batteryLevel: eventData.battery_level,
  temperature: eventData.environment_status__temperature,
  tempUnit: eventData.environment_status__temperature_unit,
  humidity: eventData.environment_status__humidity,
  connectionStatus: eventData.connected ? "CONNECTED" : "DISCONNECTED",
  ambientLight: eventData.environment_status__ambient_light,
  location: eventData.location,
  timeStamp: eventData.utc_timestamp_inms,
  deviceId: eventData.environment_status__serial_number,
  modelNumber: eventData.modelnumber,
});

export const filterEventsData = (eventsData) =>
  eventsData.filter((event) => event.modelnumber !== "HC200");

export const sortEventsData = (filteredEventsData) =>
  filteredEventsData.sort((a, b) => {
    const dateA = new Date(a.utc_timestamp_inms);
    const dateB = new Date(b.utc_timestamp_inms);
    return dateB - dateA;
  });

export const determineTempTrend = (sortedEventsData) => {
  if (sortedEventsData.length > 1) {
    const tempCurrent = sortedEventsData[0].environment_status__temperature;
    const tempPrevious = sortedEventsData[1].environment_status__temperature;
    return tempCurrent > tempPrevious ? "up" : "down";
  }
  return null;
};

export const checkAllDevicesProcessed = (
  updatedData,
  userAccessoriesDeviceuuid
) =>
  Object.keys(userAccessoriesDeviceuuid).every((deviceUuid) =>
    Object.keys(updatedData).includes(deviceUuid)
  );

export const processWeatherData = (data, tempUnit, setOutdoorWeather) => {
  if (!data.length) return;
  const weatherData = data[0];
  console.log("Weather Data", weatherData);

  const isFahrenheit = tempUnit === "F";
  const temperature = isFahrenheit
    ? Math.floor(weatherData.temperature.imperial.value)
    : Math.floor(weatherData.temperature.metric.value);
  const unit = isFahrenheit ? "F" : "C";

  setOutdoorWeather({
    temp: temperature,
    unit: unit,
    prec: weatherData.hasprecipitation,
    icon: weatherData.weathericon,
    humidity: weatherData.relativehumidity,
    time: weatherData.localobservationdatetime,
  });
};

const applyTimezoneOffset = (date, timezoneOffset) =>
  date.replace(/Z|(\+|-)\d\d:\d\d$/, timezoneOffset);

export const fetchPastTrendData = (
  useruuid,
  deviceUuid,
  userEventsApi,
  trans,
  setPastDataProcessed,
  timezoneOffset,
  setDeviceUuidToShowTrend,
  selectedRange,
  convertTemperature,
  userTempUnit
) => {
  const successCallback = (data) => {
    processPastTrendData(
      data["user_events"],
      setPastDataProcessed,
      setDeviceUuidToShowTrend,
      deviceUuid,
      timezoneOffset,
      convertTemperature,
      userTempUnit
    );
  };
  const errorCallback = (error) => {
    console.error("Error while getting past data: ", error);
  };

  const today = new Date();
  const rangeDays = selectedRange === "today" ? 1 : selectedRange === "weekly" ? 7 : selectedRange === "monthly" ? 30 : 30;
  const endDate = applyTimezoneOffset(today.toISOString(), timezoneOffset);
  const startDate = applyTimezoneOffset(
    new Date(today.setDate(today.getDate() - rangeDays)).toISOString(),
    timezoneOffset
  );

  userEventsApi.userEventsByTypesAndTimeRange(
    useruuid,
    startDate,
    endDate,
    { eventtypes: ["environment_status"] },
    apiCallback({
      translator: trans,
      failCallback: errorCallback,
      errorCallback,
      successCallback,
    })
  );
};

export const fetchPastOutdoorData = (
  weatherApi,
  trans,
  setPastDataProcessed,
  timezoneOffset,
  useruuid,
  selectedRange,
  convertTemperature,
  userTempUnit
) => {
  const successCallback = (data) => {
    console.info("Past outdoor data is: ", data);
    processPastOutdoorData(data["historical"], setPastDataProcessed, timezoneOffset, convertTemperature, userTempUnit);
  };
  const errorCallback = (error) => {
    console.error("Error while getting past outdoor data: ", error);
  };

  const today = new Date();
  const rangeDays = selectedRange === "today" ? 1 : selectedRange === "weekly" ? 7 : selectedRange === "monthly" ? 30 : 30;
  const endDate = applyTimezoneOffset(today.toISOString(), timezoneOffset);
  const startDate = applyTimezoneOffset(
    new Date(today.setDate(today.getDate() - rangeDays)).toISOString(),
    timezoneOffset
  );

  weatherApi.getHistoricalByTimeRange(
    startDate,
    endDate,
    { useruuid: useruuid },
    apiCallback({
      translator: trans,
      failCallback: errorCallback,
      errorCallback,
      successCallback,
    })
  );
};

const processPastOutdoorData = (data, setPastDataProcessed, timezoneOffset, convertTemperature, userTempUnit) => {
  const extractDate = (datetime) => datetime.split("T")[0];

  const dailyData = {};

  data.forEach((entry) => {

    const localMoment = moment(entry.localobservationdatetime).utcOffset(timezoneOffset);

    const date = localMoment.format("YYYY-MM-DD");
    const hour = localMoment.format("HH");
    const deviceId = "outdoor";

    if (!dailyData[date]) {
      dailyData[date] = {};
    }

    if (!dailyData[date][deviceId]) {
      dailyData[date][deviceId] = {
        environData: {
          hourlyTemp: {},
          hourlyHumidity: {},
          hourlyLight: {},
        },
        serialNumber: "outdoor",
      };
    }

    if (!dailyData[date][deviceId].environData.hourlyTemp[hour]) {
      dailyData[date][deviceId].environData.hourlyTemp[hour] = 0;
      dailyData[date][deviceId].environData.hourlyHumidity[hour] = 0;
      dailyData[date][deviceId].environData.hourlyLight[hour] = 0;
      dailyData[date][deviceId].environData.hourlyTemp[hour + "_count"] = 0;
      dailyData[date][deviceId].environData.hourlyHumidity[hour + "_count"] = 0;
      dailyData[date][deviceId].environData.hourlyLight[hour + "_count"] = 0;
    }



    dailyData[date][deviceId].environData.hourlyTemp[hour] +=
      userTempUnit === 'F' ? entry.temperature.imperial.value : entry.temperature.metric.value;
    dailyData[date][deviceId].environData.hourlyHumidity[hour] +=
      entry.relativehumidity;
    dailyData[date][deviceId].environData.hourlyLight[hour] += 0;
    dailyData[date][deviceId].environData.hourlyTemp[hour + "_count"] += 1;
    dailyData[date][deviceId].environData.hourlyHumidity[hour + "_count"] += 1;
    dailyData[date][deviceId].environData.hourlyLight[hour + "_count"] += 1;
  });

  Object.keys(dailyData).forEach((date) => {
    Object.keys(dailyData[date]).forEach((deviceId) => {
      const deviceData = dailyData[date][deviceId];
      Object.keys(deviceData.environData.hourlyTemp).forEach((hour) => {
        if (!hour.includes("_count")) {
          const count = deviceData.environData.hourlyTemp[hour + "_count"];
          deviceData.environData.hourlyTemp[hour] = parseFloat(
            (deviceData.environData.hourlyTemp[hour] / count).toFixed(1)
          );
          deviceData.environData.hourlyHumidity[hour] = parseFloat(
            (deviceData.environData.hourlyHumidity[hour] / count).toFixed(1)
          );
          deviceData.environData.hourlyLight[hour] = parseFloat(
            (deviceData.environData.hourlyLight[hour] / count).toFixed(1)
          );
          delete deviceData.environData.hourlyTemp[hour + "_count"];
          delete deviceData.environData.hourlyHumidity[hour + "_count"];
          delete deviceData.environData.hourlyLight[hour + "_count"];
        }
      });
    });
  });

  console.info("Daily outdoor averages: ", dailyData);
  setPastDataProcessed((prevState) => ({
    ...prevState,
    outdoorDataFetched: dailyData,
  }));
  return dailyData;
};

const processPastTrendData = (
  data,
  setPastDataProcessed,
  setDeviceUuidToShowTrend,
  deviceUuid,
  timezoneOffset,
  convertTemperature,
  userTempUnit
) => {
  const dailyData = {};

  data.forEach((event) => {
    const localMoment = moment(event.utc_timestamp_inms).utcOffset(timezoneOffset);

    const date = localMoment.format("YYYY-MM-DD");
    const hour = localMoment.format("HH");
    const deviceId = event.deviceuuid;

    // console.log('event.utc_timestamp_inms',event.utc_timestamp_inms, 'date',date,'hour',hour ,'262');
    

    if (!dailyData[date]) {
      dailyData[date] = {};
    }

    if (!dailyData[date][deviceId]) {
      dailyData[date][deviceId] = {
        environData: {
          hourlyTemp: {},
          hourlyHumidity: {},
          hourlyLight: {},
        },
        serialNumber: event.serial_number,
      };
    }

    if (!dailyData[date][deviceId].environData.hourlyTemp[hour]) {
      dailyData[date][deviceId].environData.hourlyTemp[hour] = 0;
      dailyData[date][deviceId].environData.hourlyHumidity[hour] = 0;
      dailyData[date][deviceId].environData.hourlyLight[hour] = 0;
      dailyData[date][deviceId].environData.hourlyTemp[hour + "_count"] = 0;
      dailyData[date][deviceId].environData.hourlyHumidity[hour + "_count"] = 0;
      dailyData[date][deviceId].environData.hourlyLight[hour + "_count"] = 0;
    }

    dailyData[date][deviceId].environData.hourlyTemp[hour] += convertTemperature( event.temperature, event.temperature_unit, userTempUnit);
    // console.log()
    dailyData[date][deviceId].environData.hourlyHumidity[hour] +=
      event.humidity;
    dailyData[date][deviceId].environData.hourlyLight[hour] +=
      event.ambient_light;
    dailyData[date][deviceId].environData.hourlyTemp[hour + "_count"] += 1;
    dailyData[date][deviceId].environData.hourlyHumidity[hour + "_count"] += 1;
    dailyData[date][deviceId].environData.hourlyLight[hour + "_count"] += 1;
  });

  Object.keys(dailyData).forEach((date) => {
    Object.keys(dailyData[date]).forEach((deviceId) => {
      const deviceData = dailyData[date][deviceId];
      Object.keys(deviceData.environData.hourlyTemp).forEach((hour) => {
        if (!hour.includes("_count")) {
          const count = deviceData.environData.hourlyTemp[hour + "_count"];
          deviceData.environData.hourlyTemp[hour] = parseFloat(
            (deviceData.environData.hourlyTemp[hour] / count).toFixed(1)
          );
          deviceData.environData.hourlyHumidity[hour] = parseFloat(
            (deviceData.environData.hourlyHumidity[hour] / count).toFixed(1)
          );
          deviceData.environData.hourlyLight[hour] = parseFloat(
            (deviceData.environData.hourlyLight[hour] / count).toFixed(1)
          );
          delete deviceData.environData.hourlyTemp[hour + "_count"];
          delete deviceData.environData.hourlyHumidity[hour + "_count"];
          delete deviceData.environData.hourlyLight[hour + "_count"];
        }
      });
    });
  });

  console.info("Daily averages: ", dailyData);
  setPastDataProcessed((prevState) => ({
    ...prevState,
    trendDataFetched: dailyData,
  }));
  setDeviceUuidToShowTrend(deviceUuid);
};

export const onTrendIconClick = (
  useruuid,
  deviceUuid,
  userEventsApi,
  weatherApi,
  trans,
  setPastDataProcessed,
  timezoneOffset,
  setDeviceUuidToShowTrend,
  setIsTrendGraphLoading, // New parameter to handle loading state
  setShowPastTrend, 
  selectedRange,
  convertTemperature,
  userTempUnit
) => {
  setIsTrendGraphLoading(true); // Start the loader
  setShowPastTrend(true);
  fetchPastTrendData(
    useruuid,
    deviceUuid,
    userEventsApi,
    trans,
    // setPastDataProcessed,
    (data) => {
      setPastDataProcessed(data);
      setIsTrendGraphLoading(false); // Stop the loader once data is fetched
      setDeviceUuidToShowTrend(deviceUuid);
    },
    timezoneOffset,
    setDeviceUuidToShowTrend,
    selectedRange,
    convertTemperature,
    userTempUnit
  );
  fetchPastOutdoorData(weatherApi, trans, (data) => {
    setPastDataProcessed(data);
    setIsTrendGraphLoading(false); // Ensure loader stops if this is the last fetch
  }, timezoneOffset, 
     useruuid, 
     selectedRange, 
     convertTemperature,
     userTempUnit);
};
