import React from "react";
import "./Wellness.less";
import { WellnessIcon } from "../Common/Icons/WellnessIcon";



const WellnessHeader = () => {
    return (
        <>
            <div className="wellness-header-container">
                <div className="wellness-header-icon-title">
                    <div className="wellness-header-icon">
                        <WellnessIcon
                            style={{ fill: "#113E57", width: "32px", height: "35px" }}
                        />
                    </div>
                    <div className="wellness-header">
                        <div className="wellness-header-title">
                            {'Wellness'}
                        </div>
                        <div className="wellness-header-subtitle">
                            Complete a health reading on a connected device and it will appear here for easy viewing
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default WellnessHeader;