export function celsiusToFahrenheit(celsius) {
    return (celsius * 9) / 5 + 32;
}

export function fahrenheitToCelsius(fahrenheit) {
    return ((fahrenheit - 32) * 5) / 9;
}


export function kilogramsToPounds(kilograms) {
    return kilograms * 2.20462; // 1 kilogram = 2.20462 pounds
}


export function poundsToKilograms(pounds) {
    return pounds / 2.20462; // 1 pound = 0.453592 kilograms
}