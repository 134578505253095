import React from "react";

export const MessageIcon = (props) => {
  const { className = "", disabled = false, onClick, style = {} } = props;
  return (
    <svg
      style={style}
      onClick={disabled ? null : onClick}
      className={className}
      width="72"
      height="56"
      viewBox="0 0 72 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="71" height="55" rx="27.5" fill="white" />
      <rect x="0.5" y="0.5" width="71" height="55" rx="27.5" stroke="#005D98" />
      <g clip-path="url(#clip0_30904_5453)">
        <path
          d="M44.0526 18.8H27.947C27.3183 18.8 26.7998 19.3185 26.7998 19.9473V32.6C26.7998 33.2288 27.3183 33.7473 27.947 33.7473H31.3998V37.2L36.1432 33.7473H44.0526C44.6813 33.7473 45.1998 33.2288 45.1998 32.6V19.9473C45.1998 19.3185 44.6813 18.8 44.0526 18.8ZM43.4128 31.8499H28.5979V20.7526H43.4017"
          fill="#005D98"
        />
        <path
          d="M40.5115 23.4001H31.3115V24.5474H40.5115V23.4001Z"
          fill="#005D98"
        />
        <path
          d="M40.5115 25.7056H31.3115V26.8528H40.5115V25.7056Z"
          fill="#005D98"
        />
        <path d="M37.0588 28H31.3115V29.1472H37.0588V28Z" fill="#005D98" />
      </g>
      <defs>
        <clipPath id="clip0_30904_5453">
          <rect
            width="18.4"
            height="18.4"
            fill="white"
            transform="translate(26.7998 18.8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
