/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Divider, Dropdown, Skeleton, Tooltip } from "antd";
import {
  Actions,
  DateFormat,
  PhotoResolution,
  sessionType,
} from "@commscopemycloud/humaui/Utilities/Constants";
import MainPage from "../../MainPage";
import {
  ScheduleContactIcon,
  ScheduleNewContactIcon,
  SocialIcon,
  CallIcon,
  TaskIcon,
  ScheduleAddPlusIcon,
  CheckIcon,
  ScheduleListNewIcon,
  RightArrowIcon,
  LeftArrowIcon,
  JoinCallIcon,
  ErrorIcon,
  NewFilterIcon
} from "../Common/Icons";
import {
  formatEventTimes,
  getDateFormat,
  getTimeFormat,
  getTimeZoneFormat,
} from "@commscopemycloud/humaui/Utilities/DateTime";
import {
  getProfilePicUrl,
  getUsername,
  isSocial,
  isCare,
  isManage,
  objectSort,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { getContacts } from "./Contacts";
import { SingleDatePicker } from "../Common/HSWidget";
import { UserImage } from "./UserImage";
import { getProfilePics, getUserHubs } from "@commscopemycloud/humaui/Store/dataStore";
import { useDispatch, useSelector } from "react-redux";
import {
  Access,
  KnownActionsList,
  checkRoleAccess,
  isActionAllowed,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import moment from "moment";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
import { Tabs } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import TaskListItem from "../Task/TaskListItem";
import CustomDropdownCheckBox from "../Common/CustomDropdownCheckBox";

dayjs.extend(utc);
dayjs.extend(timezone);
const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CHECK_INTERVAL_MS = 20000;
const SHOW_STATUS_BEFORE_MINUTES = 15;
const ONGOING_MEETING_DISPLAY_TIME_HOURS = 4;
const SHOW_JOIN_STATUS_TILL_MINUTES = ONGOING_MEETING_DISPLAY_TIME_HOURS * 60;

const currentSiteIconZip = state => state.data.siteIconFiles;
const currentSiteIconManifest = state => state.data.siteIconManifest; 

const ListSchedule = (props) => {
  const {
    onlyContent,
    parentBread,
    selectedDate,
    setSelectedDate,
    scheduleList,
    fetchingScheduleList,
    onActionClick,
    contacts,
    userInfo,
    userHubsData,
    rolename,
    hubRecord,
    openSubMenu = false,
    loginUserTimeformat,
    setParentSelectedTab, // Callback prop to set selectedTab in Schedule
  } = props;

  const trans = useSelector(translator);
  const dispatch = useDispatch();
  const resourcesMap = useSelector(getProfilePics());
  const header = parentBread || [];
  const currentUser = useSelector(getCurrentUser);
  const useruuid = useMemo(() => currentUser?.useruuid, [currentUser]);
  const permissions = useSelector(getPermissions());
  const currentUserData = useSelector((user) => user.data.userInfo);
  const currentUserDataInfo = currentUserData[openSubMenu ? hubRecord?.useruuid : useruuid];
  const userTimezone = getTimeZoneFormat(currentUserDataInfo.timezone);
  const userHubs = useSelector(getUserHubs(useruuid));
  const userUuid = hubRecord?.useruuid ?? currentUser?.useruuid;
  const newRecord = userHubs.find((a) => a.useruuid === userUuid);
  const record = hubRecord?.rolename ? hubRecord : newRecord;
  const isCareOrManageRole = isCare(record) || isManage(record);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const siteIconZip = useSelector(currentSiteIconZip);
  const siteIconManifest = useSelector(currentSiteIconManifest);
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState([]);
  const [originalFilteredData, setOriginalFilteredData] = useState({
    data: [],
    count: 0,
  });
  const LeftFilterItems = [
    {
      "key": "status",
      "type": "group",
      label : "Status",
      "children": [
        {
          "key": "non_recc_all",
          label : "All"
        },
        {
          "key": "scheduled",
          label : "Scheduled",
        },
        {
          "key": "ongoing",
          label : "In progress",
        },
        {
          "key": "done",
          label : "Completed",
        },
        {
          "key": "expired",
          label : "Missed",
        }
      ]
    }
  ]

  const RightFilterItems = [
    {
      "key": "recurrence",
      "type": "group",
      label : "Recurrence",
      "children": [
        {
          "key": "recc_all",
          label : "All"
        },
        {
          "key": "recc_onetime",
          label : "One-time",
        },
        {
          "key": "daily",
          label : "Daily",
        },
        {
          "key": "weekly",
          label : "Weekly",
        },
        {
          "key": "custom",
          label : "Monthly",
        }
      ]
    }
  ]

  const handleFilterToggle = (e) => {
    e.stopPropagation();
    setIsFilterOn((value) => !value);
  };
  
  const handleFilterChange = (action, closeDropdown) => {
    if (action === "reset") {
      setAppliedFilter([]);
      setFilteredEventListonTab(originalFilteredData); // Reset to the original data for the selected tab
      closeDropdown();
    } else if (action === "cancel") {
      closeDropdown();
    } else if (action === "apply") {
      filterItemsOnTabList(appliedFilter);
      closeDropdown();
    } else {
      setAppliedFilter(action);
    }
  };
  
  const filterItemsOnTabList = (filters) => {
    const statusFilters = filters.filter((filter) => filter.groupKey === "status");
    const recurrenceFilters = filters.filter((filter) => filter.groupKey === "recurrence");

    const filteredListData = originalFilteredData?.data?.map((group) =>
      group.filter((item) => {
        const statusMatch = statusFilters.length === 0 || statusFilters.some((filter) => {
          if (filter.itemKey === "non_recc_all") {
            return true;
          }
          return item.task_status === filter.itemKey;
        });

        const recurrenceMatch = recurrenceFilters.length === 0 || recurrenceFilters.some((filter) => {
          if (filter.itemKey === "recc_all") {
            return true;
          }
          if (filter.itemKey === "recc_onetime") {
            return !item.repeat;
          }
          return item.repeat === filter.itemKey;
        });
        return statusMatch && recurrenceMatch;
      })
    );

    const totalFilteredCount = filteredListData.flat().length;

    setFilteredEventListonTab({
        data: filteredListData,
        count: totalFilteredCount,
    });
  };

  const items = useMemo(() => {
    const tabItems = [
      { key: "all", label: "All" },
      { key: "call", label: "Calls" },
      { key: "reminder", label: "Reminder" },
    ];

    // Add "Tasks" Tab only if user is Manage/Care
    if (isCareOrManageRole) {
      tabItems.push({ key: "task", label: "Tasks" });
    }

    return tabItems.sort(objectSort("label"));
  }, [isCareOrManageRole]);

  const [selectedTab, setSelectedTab] = useState("all");

  useEffect(() => {
    if (setParentSelectedTab) setParentSelectedTab(selectedTab);
  }, [selectedTab, setParentSelectedTab]);

  const getRelativeTime = (eventTimestamp, timeZone) => {
    const now = timeZone ? dayjs().tz(timeZone) : dayjs();
    const eventTime = timeZone ? dayjs(eventTimestamp).tz(timeZone) : dayjs(eventTimestamp);

    const diffInMinutes = now.diff(eventTime, "minute");

    if (diffInMinutes < 0) return null; // Event is in the future
    if (diffInMinutes < 60) return `${diffInMinutes} min${diffInMinutes > 1 ? "s" : ""} Before`;

    const diffInHours = now.diff(eventTime, "hour");
    if (diffInHours < 24) return `${diffInHours} HR Before`;

    return null; // Older than 24 hours
  };

  const allowAddEvent = useMemo(
    () =>
      (record?.rolename === undefined ||
        isActionAllowed(
          record?.rolename,
          KnownActionsList.create_events,
          permissions
        )) &&
      hubRecord,
    [record, permissions, hubRecord]
  );

  const allowAddTask = useMemo(
    () => checkRoleAccess(Access.beta.labsPreview) && hubRecord,
    [hubRecord]
  );

  const dropdown = [
    !fetchingScheduleList &&
      allowAddEvent && {
        label: (
          <div onClick={onActionClick(Actions.addEvent, userInfo)}>
            Add Call
          </div>
        ),
        key: "addevent",
      },
    !fetchingScheduleList &&
      allowAddEvent && {
        label: (
          <div onClick={onActionClick(Actions.addReminderEvent, userInfo)}>
            Add Reminder
          </div>
        ),
        key: "editevent",
      },
    // Add "Add Task" only for Manage/Care roles & Non-Social Hubs
    isCareOrManageRole && {
      label: (
        <div onClick={onActionClick(Actions.addTask, userInfo)}>Add Task</div>
      ),
      key: "addtask",
    },
  ].filter(Boolean);

  const formatDateToDisplayEventDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatList = useCallback(
    (scheduleList) => {
      const selectedDateTime = new Date(selectedDate);

      let filteredList = scheduleList.filter((event) => {
        const eventDate = new Date(event.event_timestamp);
        if (isNaN(eventDate.getTime())) {
          console.error("Invalid event timestamp:", event.event_timestamp);
          return false;
        }
        const isMatchingDate =
          event.display_event_date === formatDateToDisplayEventDate(selectedDateTime);

        return isMatchingDate;
      });

      const list = filteredList.reduce((acc, event) => {
        const dateObj = getDateFormat(event.event_timestamp, userTimezone);
        const date = dateObj.date;

        if (!acc[date]) {
          acc[date] = [];
        }

        const duplicate = acc[date].some(
          (e) => e.schedule_eventuuid === event.schedule_eventuuid
        );

        if (!duplicate) {
          acc[date].push(event);
        }

        return acc;
      }, {});

      return Object.values(list).sort((a, b) => {
        return new Date(a[0]?.event_timestamp) - new Date(b[0]?.event_timestamp);
      });
    },
    [selectedDate, userTimezone]
  );

  const formattedList = useMemo(() => formatList(scheduleList), [scheduleList, formatList]);

  const [filteredEventListonTab, setFilteredEventListonTab] = useState({
    data: [],
    count: 0,
  });

  const filteredListonTab = (selectedTab) => {
    let filteredListData;

    setFilteredEventListonTab({
      data: [],
      count: 0,
    });

    if (selectedTab === "call") {
      filteredListData = formattedList.map((group) =>
        group.filter(
          (item) =>
            item?.event_type === "schedule" || item?.event_type === "zoom"
        )
      );
    } else if (selectedTab === "task") {
      filteredListData = formattedList.map((group) =>
        group.filter((item) => item?.event_type === "tasks")
      );
    } else if (selectedTab === "reminder") {
      filteredListData = formattedList.map((group) =>
        group.filter((item) => item?.event_type === "reminder")
      );
    } else {
      filteredListData = formattedList.map((group) =>
        isSocial(hubRecord) || !isCareOrManageRole
          ? group.filter((item) => item?.event_type !== "tasks") // Exclude tasks for social and no role
          : group // Include all if not social
      );
    }
    const totalFilteredCount = filteredListData.flat().length;
  
    setOriginalFilteredData({
      data: filteredListData,
      count: totalFilteredCount,
    });
  
    setFilteredEventListonTab({
      data: filteredListData,
      count: totalFilteredCount,
    });
  };

  const handleTabChange = (selectedTab) => {
    setSelectedTab(selectedTab);
    setFilteredEventListonTab({
      data: [],
      count: 0,
    });
    filteredListonTab(selectedTab);
  };

  useEffect(() => {
    async function buildCategoryOptions() {
      if (!siteIconZip || !siteIconManifest || typeof siteIconZip.file !== "function") {
        console.error("Invalid Zip file.");
        return;
      }
      const options = [];
      for (const mainCat of Object.keys(siteIconManifest)) {
        const subcats = siteIconManifest[mainCat];
        for (const subcat of Object.keys(subcats)) {
          const fileName = subcats[subcat];
          const file = siteIconZip.file(fileName);
          if (!file) {
            console.error(`File ${fileName} not found in zip for subcategory "${subcat}"`);
            continue;
          }
          try {
            const blob = await file.async("blob");
            let blobToUse = blob;
            if (!blob.type || blob.type === "") {
              blobToUse = new Blob([blob], { type: "image/svg+xml" });
            }
            const url = URL.createObjectURL(blobToUse);
            options.push({
              value: `${subcat}`,
              icon: (
                <img
                  src={url}
                  alt={subcat}
                  style={{
                    width: 30,
                    height: 36,
                    verticalAlign: "middle",
                  }}
                />
              ),
            });
          } catch (error) {
            console.error(`Error loading file ${fileName} for subcategory "${subcat}":`, error);
          }
        }
      }
      options.sort((a, b) => a.value.localeCompare(b.value));
      setCategoryOptions(options);
    }
    buildCategoryOptions();
  }, [siteIconZip, siteIconManifest]);

  useEffect(() => {
    if (formattedList.length > 0) {
      filteredListonTab(selectedTab);
    }
  }, [selectedTab, formattedList, selectedDate, isCareOrManageRole, hubRecord]);

  const renderEventType = (eventType) => {
    switch (eventType) {
      case "schedule":
      case "zoom":
        return (
          <div className="event-type">
            <span style={{ justifyContent: "center", display: "flex" }}>
              <CallIcon className="event-type-icon event-icon-color" />{" "}
            </span>
            <span>Call</span>
          </div>
        );
      case "tasks":
        return (
          isCareOrManageRole && (
            <div className="event-type">
              <TaskIcon className="event-type-icon" />
              <span>Task</span>
            </div>
          )
        );
      case "reminder":
        return (
          <div className="event-type">
            <SocialIcon className="event-type-icon event-icon-color" />{" "}
            Reminder
          </div>
        );
      default:
        return null;
    }
  };

  const handleDateSelect = (date) => {
    if (!date || !date.isValid()) {
      console.error("Invalid date passed to handleDateSelect:", date);
      return;
    }
    const selectedDate = date.startOf("day").toDate();
    setSelectedDate(selectedDate);

    setFilteredEventListonTab({
      data: [],
      count: 0,
    });
    filteredListonTab(selectedTab);
  };

  const contactImage = useCallback(
    (contact) => {
      let displayName = getUsername(contact);
      const url = getProfilePicUrl(contact.useruuid, resourcesMap, PhotoResolution.R64);
      return <UserImage name={displayName} url={url} />;
    },
    [resourcesMap]
  );

  const renderDateListItem = useCallback(
    (item) => {
      let dateObj = getDateFormat(item.event_timestamp, userTimezone);
      return (
        <div
          key={dateObj.dayDateformat}
          className={
            dateObj.dayDateformat.startsWith("Today")
              ? "schedule-list-date-newui schedule-list-today-newui"
              : "schedule-list-date-newui"
          }
        >
          <div className="schedule-date-newui">{dateObj.dayDateformat}</div>
        </div>
      );
    },
    [userTimezone]
  );

  const renderInvitedList = useCallback(
    (invitedUseruuid) => {
      let invitedContacts = getContacts(invitedUseruuid, [
        ...(contacts ?? []),
        ...(userHubsData ?? []),
        ...(userInfo ? [userInfo] : []),
      ]);

      const count = invitedContacts.length;

      const invitedContactsData = invitedContacts
        .slice(0, 3)
        .map((contact, index) => (
          <div key={index} className="user-image-container">
            {contact && contactImage(contact)}
          </div>
        ));
      return { invitedContactsData, count };
    },
    [contacts, userHubsData, userInfo, contactImage]
  );

  const handleButtonClick = useCallback(
    (item) => {
      if (item.event_type?.toLowerCase() === sessionType.zoom) {
        window.open(item.meeting_url);
      } else {
        dispatch(
          startVideoCall({
            meeting_id: item.schedule_eventuuid,
            eventInfo: item,
          })
        );
      }
    },
    [dispatch]
  );

  const DaysOfWeekRow = (daysOfWeek, item) => {
    return (
      <div className="days_of_week">
        {["schedule", "zoom", "reminder" ,"tasks"].includes(item?.event_type) ? (
          item?.repeat === "daily" ? (
            <div className="day_info">
              {item?.repeat.charAt(0).toUpperCase() + item?.repeat.slice(1).toLowerCase()}
            </div>
          ) : item?.repeat === "weekly" ? (
            daysOfWeek?.map((day, index) => (
              <div key={index} className="day_info">
                {day.charAt(0).toUpperCase() + day.slice(1, 3).toLowerCase()}
              </div>
            ))
          ) : item?.repeat === "custom" ? (
            daysOfWeek?.map((day, index) => (
              <div key={index} className="day_info">
                {day.charAt(0).toUpperCase() + day.slice(1, 3).toLowerCase()}
              </div>
            ))
          ) : (
            <div className="day_info">Doesn't repeat</div>
          )
        ) : (
          daysOfWeek?.map((day, index) => (
            <div key={index} className="day_info">
              {day.charAt(0).toUpperCase() + day.slice(1, 3).toLowerCase()}
            </div>
          ))
        )}
      </div>
    );
  };

  const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  const EventDaysInfo = (daysOfWeek, item) => {
    if (!daysOfWeek || daysOfWeek.length === 0) return "";
    const capitalizedDays = daysOfWeek.map(capitalizeFirstLetter);

    if (capitalizedDays.length === 1)
      return `Repeats ${capitalizedDays[0]}`;
    if (capitalizedDays.length === 2)
      return `Repeats ${capitalizedDays[0]} and ${capitalizedDays[1]}`;

    const lastDay = capitalizedDays[capitalizedDays.length - 1];
    const otherDays = capitalizedDays.slice(0, -1).join(", ");
    return `Repeats ${otherDays} and ${lastDay}`;
  };

  const renderButtonStatus = useCallback(
    (item) => {
      if (item.event_type?.toLowerCase() === "reminder") {
        return null;
      }
      if (!item.invited_contacts.includes(useruuid)) {
        return null;
      }

      const now = moment();
      const eventTime = moment(item.event_timestamp);
      const diff = eventTime.diff(now, "minute");

      if (diff > 5 && diff <= SHOW_STATUS_BEFORE_MINUTES) {
        return (
          <div className="status" onClick={() => handleButtonClick(item)}>
            About to Start
          </div>
        );
      } else if (diff <= 5 && diff >= -SHOW_JOIN_STATUS_TILL_MINUTES) {
        return (
          <div className="join_btn" onClick={() => handleButtonClick(item)}>
            <JoinCallIcon style={{ width: "120px", cursor: "pointer" }} />
          </div>
        );
      } else {
        return null;
      }
    },
    [handleButtonClick, useruuid]
  );

  const renderNoList = useCallback(() => {
    let currentDate = getDateFormat(moment(), userTimezone);
    return (
      <div
        className={
          currentDate.dayDateformat.startsWith("Today")
            ? "schedule-list-date-newui schedule-list-today-newui"
            : "schedule-list-date-newui"
        }
      >
        {/* {formattedList.length === 0 && ( */}
          {/* // <div className="no-events">
          //   {`${trans("TOTAL")}${" ( " + formattedList?.length + " )"}`}
          // </div> */}
          <div className="no-events-container">
            <div className="no-events-card">
              <div className="no-events-column">
                <ErrorIcon className="error-icon" fillColor="#3FA9F5" />
                <div className="no-events-information-content">Information</div>
              </div>
              <div className="no-events-column">
              <div className="no-events-description-content">No upcoming events</div></div>
            </div>
          </div>
        {/* )} */}
      </div>
    );
  }, [formattedList, userTimezone, trans]);

  const renderScheduleList = useCallback(() => {
    return (
      <div className="schedule-list-container-newui">
        <div className="tab_view">
          <Tabs defaultActiveKey="1" items={items} onChange={handleTabChange} />
          <div className="schedule-filter-dropdown">
            <Dropdown
              menu={{
                items: dropdown,
              }}
              trigger={["click"]}
              placement="bottomRight"
              overlayClassName="event_dropdown"
              overlayStyle={{ minWidth: "120px !important" }}
            >
              <a onClick={(e) => e.preventDefault()}>
                {openSubMenu && record?.rolename !== "" && (
                  <ScheduleAddPlusIcon />
                )}
              </a>
            </Dropdown>
            {openSubMenu && record?.rolename !== "" && (
              <div className="filter-container">
                <CustomDropdownCheckBox
                  open={isFilterOn}
                  leftMenuItems={LeftFilterItems}
                  rightMenuItems={RightFilterItems}
                  selectedKeys={appliedFilter}
                  handleChange={(action, closeDropdown) => handleFilterChange(action, closeDropdown)}
                  onClick={handleFilterToggle}
                  className={"listschedule-filter"}
                  showActionButtons={true}
                >
                  <>
                    <NewFilterIcon className="filter-icon" />
                    <span className="text filter-text">Filter by</span>
                  </>
                </CustomDropdownCheckBox>
              </div>
            )}
          </div>         
        </div>
        {fetchingScheduleList ? (
          <div className="schedule-container-newui skeleton-container-newui">
            <Skeleton active avatar paragraph={false} />
          </div>
        ) : (filteredEventListonTab?.count === 0) ? (
          renderNoList()
        ) : (
          filteredEventListonTab?.data?.map((list, index) => (
            <div key={"schedule_day_" + index} className="schedule-list-data">
              <div className="filtered_event_count">
                {`${trans("TOTAL")}${" ( " + filteredEventListonTab?.count + " )"}`}
              </div>
              {list.map((item) => (
                <TaskListItem
                  key={item.schedule_eventuuid}
                  item={item}
                  onActionClick={onActionClick}
                  renderEventType={renderEventType}
                  renderInvitedList={renderInvitedList}
                  categoryOptions={categoryOptions}
                  loginUserTimeformat={loginUserTimeformat}
                  getRelativeTime={getRelativeTime}
                  EventDaysInfo={EventDaysInfo}
                  DaysOfWeekRow={DaysOfWeekRow}
                  openSubMenu={openSubMenu}
                  hubRecord={hubRecord}
                  renderButtonStatus={renderButtonStatus}
                  userInfo={userInfo}
                  currentUser={currentUser}
                />
              ))}
            </div>
          ))
        )}
      </div>
    );
  }, [
    fetchingScheduleList,
    allowAddEvent,
    allowAddTask,
    formattedList,
    onActionClick,
    renderDateListItem,
    renderButtonStatus,
    renderInvitedList,
    handleDateSelect,
    trans,
    userInfo,
    selectedDate,
    userTimezone,
    filteredEventListonTab,
    renderNoList,
  ]);

  const headerSection = useCallback(() => {
    return (
      <div className="schedule-header-newui">
        <div className="schedule-title-newui">
          <div className="schedule-text-newui">
            {openSubMenu ? (
              <div className="hub-schedule-header">
                <div className="hub-icon">
                  <ScheduleListNewIcon className="hub-schedule-icon" />
                </div>
                <div className="hub-schedule-title">{trans("SCHEDULE")}</div>
              </div>
            ) : (
              trans("MYSCHEDULE")
            )}
          </div>
        </div>
        <div className="date-picker-container">
          <SingleDatePicker
            DateFormat={DateFormat}
            timeZone={userTimezone}
            defaultValue={selectedDate}
            onDateSelect={handleDateSelect}
            value={selectedDate ? dayjs(selectedDate).tz(userTimezone, true) : null}
          />
        </div>
      </div>
    );
  }, [openSubMenu, trans, selectedDate, userTimezone]);

  return onlyContent ? (
    <div className="list-schedule-container-newui page-content-wrapper">
      {headerSection()}
      <div className={`${!openSubMenu ? "schedule-content-newui" : ""}`}>
        {renderScheduleList()}
      </div>
    </div>
  ) : (
    <MainPage hasSider={!openSubMenu} header={header} noModals={!!openSubMenu}>
      <div className="list-schedule-container-newui page-content-wrapper">
        {headerSection()}
        <div className={`${!openSubMenu ? "schedule-content-newui" : ""}`}>
          {renderScheduleList()}
        </div>
      </div>
    </MainPage>
  );
};

ListSchedule.propTypes = {
  onlyContent: PropTypes.bool,
  parentBread: PropTypes.arrayOf(PropTypes.object),
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  scheduleList: PropTypes.array.isRequired,
  fetchingScheduleList: PropTypes.bool.isRequired,
  onActionClick: PropTypes.func.isRequired,
  contacts: PropTypes.array,
  userInfo: PropTypes.object,
  userHubsData: PropTypes.array,
  rolename: PropTypes.string,
  hubRecord: PropTypes.object,
  openSubMenu: PropTypes.bool,
  loginUserTimeformat: PropTypes.object,
  setParentSelectedTab: PropTypes.func,
  currentUser: PropTypes.object,
};

ListSchedule.defaultProps = {
  onlyContent: false,
  parentBread: [],
  openSubMenu: false,
};

export default ListSchedule;
