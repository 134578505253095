import React from "react";

export const SupportedDevicesIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_31634_21042)">
        <path
          d="M12 15.3604C12.9091 15.3604 13.6818 15.0337 14.3182 14.3804C14.9545 13.7271 15.2727 12.9337 15.2727 12.0004C15.2727 11.0671 14.9545 10.2737 14.3182 9.62039C13.6818 8.96706 12.9091 8.64039 12 8.64039C11.0909 8.64039 10.3182 8.96706 9.68182 9.62039C9.04545 10.2737 8.72727 11.0671 8.72727 12.0004C8.72727 12.9337 9.04545 13.7271 9.68182 14.3804C10.3182 15.0337 11.0909 15.3604 12 15.3604ZM12 14.0164C11.4545 14.0164 10.9909 13.8204 10.6091 13.4284C10.2273 13.0364 10.0364 12.5604 10.0364 12.0004C10.0364 11.4404 10.2273 10.9644 10.6091 10.5724C10.9909 10.1804 11.4545 9.98439 12 9.98439C12.5455 9.98439 13.0091 10.1804 13.3909 10.5724C13.7727 10.9644 13.9636 11.4404 13.9636 12.0004C13.9636 12.5604 13.7727 13.0364 13.3909 13.4284C13.0091 13.8204 12.5455 14.0164 12 14.0164ZM12 17.6004C10.2273 17.6004 8.61818 17.0964 7.16364 16.0791C5.70909 15.0617 4.65455 13.7084 4 12.0004C4.65455 10.2924 5.70909 8.93906 7.16364 7.92172C8.61818 6.90439 10.2273 6.40039 12 6.40039C13.7727 6.40039 15.3818 6.90439 16.8364 7.92172C18.2909 8.93906 19.3455 10.2924 20 12.0004C19.3455 13.7084 18.2909 15.0617 16.8364 16.0791C15.3818 17.0964 13.7727 17.6004 12 17.6004ZM12 16.1071C13.3727 16.1071 14.6273 15.7337 15.7727 14.9964C16.9182 14.2591 17.7909 13.2604 18.4 12.0004C17.7909 10.7404 16.9182 9.74172 15.7727 9.00439C14.6273 8.26706 13.3727 7.89372 12 7.89372C10.6273 7.89372 9.37273 8.26706 8.22727 9.00439C7.08182 9.74172 6.20909 10.7404 5.6 12.0004C6.20909 13.2604 7.08182 14.2591 8.22727 14.9964C9.37273 15.7337 10.6273 16.1071 12 16.1071Z"
          fill="#4E758E"
        />
      </g>
      <defs>
        <clipPath id="clip0_31634_21042">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
