/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { AddEvent } from "./AddEvent";
import {
  Actions,
  UserRoles,
  UserTypes,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { useSelector } from "react-redux";
import useContacts from "../Hooks/useContacts";
import ListSchedule from "./ListSchedule";
import { StartEvent } from "./StartEvent";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import useUserInfo from "../Hooks/useUserInfo";
import { DashboardIcon } from "../Common/Icons";
import useStaffList from "../Hooks/useStaffList";
import useSchedule from "../Hooks/useSchedule";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { useLocation } from "react-router-dom";
import Task from "../Task/Task";
import { getTimeZoneFormat } from "@commscopemycloud/humaui/Utilities/DateTime";

const Schedule = (props) => {
  // Destructure props
  const {
    onlyContent,
    parentBread,
    hubRecord,
    eventRecord,
    userHubsData,
    rolename,
    onActionClick: parentActionClick,
    openSubMenu,
    showHeader,
    activeKey,
    userHubCall,
    handleTabChange,
    loginUserTimeformat,
    selectedUser, // default provided via defaultProps
    action, // added this prop since it's used in the component
    fetchDevices,
  } = props;

  const trans = useSelector(translator);
  const currentUser = useSelector(getCurrentUser);
  const [selectedTab, setSelectedTab] = useState("all"); // Manage selectedTab state in Schedule
  const [fetchingScheduleList, scheduleList, fetchSchedule] = useSchedule(
    selectedTab // Pass selectedTab to useSchedule
  );
  const handleSelectedTabChange = (tab) => {
    setSelectedTab(tab); // Update state when tab changes
  };

  const isStaff = useMemo(
    () =>
      hubRecord
        ? hubRecord.rolename === UserRoles.staff || hubRecord.staffadmin
        : currentUser?.usertype === UserTypes.staff,
    [hubRecord]
  );

  const useruuid = hubRecord?.useruuid ?? currentUser?.useruuid;
  const [contacts, fetchingContacts] = useContacts({ useruuid });
  const [userInfo] = useUserInfo({ useruuid });
  const [staffList, fetchingStaffList] = useStaffList({
    useruuid: isStaff ? useruuid : null,
  });

  const userData = useSelector((user) => user.data.userInfo);
  const updatedUserInfo = userData[useruuid];
  const currentUserDataInfo = userData[openSubMenu ? hubRecord?.useruuid : useruuid];
  const userTimezone = getTimeZoneFormat(currentUserDataInfo.timezone);

  // Fallback to userInfo if loginUserTimeformat is undefined
  const loginUserTimeformatCheck = loginUserTimeformat || {
    is24hoursformat: updatedUserInfo?.is24hoursformat,
  };

  const [currentAction, setAction] = useState(action);
  const [record, setRecord] = useState(eventRecord);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [reFetchListKey, setReFetchListKey] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );

  useEffect(() => setAction(action), [action]);
  useEffect(() => setRecord(eventRecord), [eventRecord]);

  useEffect(() => {
    if (selectedDate) fetchSchedule(useruuid, selectedDate, 0);
  }, [selectedDate, selectedTab, reFetchListKey]);



  const reFetchList = () => {
    setReFetchListKey((prev) => !prev);
    setAction(null);
  };

  useEffect(() => {
    if (
      currentAction === Actions.viewTask ||
      currentAction === Actions.addTask ||
      currentAction === Actions.editTask ||
      currentAction === Actions.addReminderEvent ||
      currentAction === Actions.editReminderEvent ||
      currentAction === Actions.addEvent ||
      currentAction === Actions.editEvent ||
      currentAction === Actions.startEvent
    )
      setShowModal(true);
  }, [eventRecord, record, currentAction], action);

  let { state } = useLocation();
  useEffect(() => {
    if (state != null) {
      state.action && setAction(state.action);
      state.eventRecord && setRecord(state.eventRecord);
    }
  }, [state]);

  const onActionClick =
    (action, record, refresh = false) =>
      () => {
        setAction(action);
        setRecord(record);
        setRefresh(refresh);
        if (
          action === Actions.viewTask ||
          action === Actions.addTask ||
          action === Actions.editTask ||
          action === Actions.addReminderEvent ||
          action === Actions.editReminderEvent ||
          action === Actions.addEvent ||
          action === Actions.editEvent ||
          action === Actions.startEvent
        )
          setShowModal(true);
        refresh && fetchSchedule(useruuid, selectedDate, 0);
      };

  const closeModalHandler = (actionClose = false) => {   
    setShowModal(false);
    if(actionClose){
      onActionClick(null, null, true)()
    }
    // setRecord(null);
    };
  const header = (parentBread || []).concat(
    parentActionClick
      ? {
        label: trans("SCHEDULE"),
        onClick: parentActionClick(null, null),
      }
      : {
        label: trans("DASHBOARD"),
        icon: <DashboardIcon />,
        onClick: onActionClick(null, null),
      }
  );

  return (
    <>
      {!currentAction ||
        currentAction === Actions.viewTask ||
        currentAction === Actions.addTask ||
        currentAction === Actions.editTask ||
        currentAction === Actions.addReminderEvent ||
        currentAction === Actions.editReminderEvent ||
        currentAction === Actions.addEvent ||
        currentAction === Actions.editEvent ||
        currentAction === Actions.startEvent ? (
        <ListSchedule
          hubRecord={hubRecord}
          parentBread={header}
          onlyContent={onlyContent}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          scheduleList={scheduleList}
          contacts={contacts}
          userInfo={userInfo}
          fetchingScheduleList={fetchingScheduleList}
          onActionClick={(activeKey ? null : parentActionClick) || onActionClick}
          rolename={rolename}
          openSubMenu={openSubMenu}
          loginUserTimeformat={loginUserTimeformatCheck}
          setParentSelectedTab={handleSelectedTabChange}
          fetchDevices={fetchDevices}
          selectedUser={selectedUser}
        />
      ) : null}
      <Task
        onlyContent={currentAction === Actions.addEvent || currentAction === Actions.editEvent || currentAction === Actions.addTask || currentAction === Actions.editTask || currentAction === Actions.viewTask || currentAction === Actions.addReminderEvent || currentAction === Actions.editReminderEvent}
        action={currentAction}
        hubRecord={hubRecord}
        userInfo={userInfo}
        onActionClick={(activeKey ? null : parentActionClick) || onActionClick}
        loginUserTimeformat={loginUserTimeformatCheck}
        eventRecord={record}
        userHubsData={userHubsData}
        selectedSchedulelistDate={selectedDate}
        showModal={showModal}
        closeModalHandler={closeModalHandler}
        fetchSchedule={fetchSchedule}
        useruuid={useruuid}
        userTimezone={userTimezone}
        reFetchList={reFetchList}
        contacts={contacts}
        fetchingContacts={fetchingContacts}
        fetchingStaffList={fetchingStaffList}
        selectedUser={selectedUser}
        currentUserDataInfo={currentUserDataInfo}
        rolename={rolename}
        openSubMenu={openSubMenu}
      />
    </>
  );
};

Schedule.defaultProps = {
  onlyContent: false,
  parentBread: [],
  selectedUser: "",
};

Schedule.propTypes = {
  onlyContent: PropTypes.bool,
  parentBread: PropTypes.arrayOf(PropTypes.object),
  hubRecord: PropTypes.object,
  eventRecord: PropTypes.object,
  userHubsData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  rolename: PropTypes.string,
  onActionClick: PropTypes.func,
  openSubMenu: PropTypes.bool,
  showHeader: PropTypes.bool,
  activeKey: PropTypes.string,
  userHubCall: PropTypes.bool,
  handleTabChange: PropTypes.func,
  loginUserTimeformat: PropTypes.object,
  selectedUser: PropTypes.string,
  action: PropTypes.string,
};

export default Schedule;
