import React from "react";

export const WellnessIcon = (props) => {
  const { className = "", style = {}, disabled = false, onClick } = props;
  return (
    <svg width="24" 
         height="24" 
         viewBox="0 0 24 24" 
         fill="none" 
         xmlns="http://www.w3.org/2000/svg" 
         className={className}
         style={style} >
        
          <path d="M21.1021 7.09854C20.7864 6.34086 20.325 5.6565 19.7421 5.06991C19.1592 4.48331 18.4792 4.01893 17.7141 3.70119C16.0991 3.01683 14.2655 3.00461 12.6625 3.83562C12.2861 4.03115 11.9339 4.26334 11.5939 4.51998C11.2539 4.25112 10.9018 4.01893 10.5253 3.83562C8.9224 3.01683 7.07662 3.01683 5.47371 3.70119C4.72082 4.01893 4.0408 4.48331 3.45792 5.06991C2.87504 5.6565 2.4136 6.34086 2.09787 7.11076C1.77 7.9051 1.6 8.73611 1.6 9.60378C1.6 10.4714 1.77 11.3025 2.09787 12.0968C2.42574 12.8667 2.87504 13.5511 3.45792 14.1254C3.51864 14.1865 9.91815 20.1502 10.9503 20.5779C11.1568 20.6635 11.3753 20.7124 11.6061 20.7124C11.8368 20.7124 12.0554 20.6635 12.2618 20.5779C13.2818 20.138 19.6814 14.1865 19.7421 14.1254C20.325 13.5388 20.7743 12.8545 21.1021 12.0968C21.43 11.3025 21.6 10.4592 21.6 9.60378C21.6 8.74833 21.43 7.9051 21.1021 7.11076V7.09854ZM19.6328 9.59155C19.6328 10.1781 19.5114 10.7647 19.2928 11.3147C19.0742 11.8402 18.7585 12.3168 18.3577 12.72C17.8842 13.1967 12.6018 17.9749 11.6061 18.6837C10.6103 17.9749 5.32799 13.1967 4.8544 12.72C4.45367 12.3168 4.13795 11.8402 3.91937 11.3147C3.68864 10.7647 3.57935 10.1904 3.57935 9.59155C3.57935 8.99274 3.70079 8.41837 3.91937 7.88066C4.13795 7.35517 4.45367 6.89079 4.8544 6.47528C5.25513 6.072 5.72872 5.75426 6.25088 5.53429C6.79733 5.3021 7.36806 5.19211 7.95094 5.19211C8.53382 5.19211 9.09241 5.33876 9.63886 5.60762C10.0639 5.82759 10.8046 6.49973 11.0596 6.74414L11.6061 7.28185L12.1525 6.74414C12.4075 6.49973 13.1483 5.82759 13.5733 5.60762C14.6662 5.04547 15.8927 5.08213 16.9613 5.53429C17.4834 5.75426 17.957 6.072 18.3577 6.47528C18.7585 6.87857 19.0742 7.35517 19.2928 7.88066C19.5235 8.43059 19.6328 9.00496 19.6328 9.59155Z"  />
          <path d="M16.1598 11.5344H13.9862C13.7676 11.5344 13.549 11.6443 13.4276 11.8277L12.9297 12.5487L12.1525 8.93135C12.0918 8.6625 11.8732 8.45475 11.6061 8.40586C11.3389 8.35698 11.0596 8.47919 10.9139 8.71138L9.16527 11.5221H7.46521C7.08877 11.5221 6.78518 11.8277 6.78518 12.2065C6.78518 12.5853 7.08877 12.8908 7.46521 12.8908H9.54171C9.77243 12.8908 9.99101 12.7686 10.1246 12.5731L11.1932 10.8622L11.9582 14.3696C12.0189 14.6384 12.2254 14.8462 12.4925 14.895C12.5411 14.895 12.5775 14.9073 12.6261 14.9073C12.8447 14.9073 13.0511 14.7973 13.1847 14.614L14.3747 12.8908H16.1962C16.5727 12.8908 16.8763 12.5853 16.8763 12.2065C16.8763 11.8277 16.5727 11.5221 16.1962 11.5221L16.1598 11.5344Z" />
    </svg>

  );
};
