import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useAssignSmartCameraHandler = (fetchDevices, hubRecord) => {
  const [isSmartCameraModalOpen, setIsSmartCameraModalOpen] = useState(false);
  const [readyToAssignDevices, setReadyToAssignDevices] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchApplied, setSearchApplied] = useState(false);
  const [selectedSmartCameraDevice, setSelectedSmartCameraDevice] =
    useState(null);
  const [readyToAssignloading, setReadyToAssignLoading] = useState(false);
  const userApi = useSelector((state) => state.apis.userApi);
  const userEventsApi = useSelector((state) => state.apis.userEventsApi);
  const trans = useSelector(translator);

  const useFetchUserAccessories = (userEventsApi, hubRecord, trans) => {
    const [accessoriesData, setAccessoriesData] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
  
    const fetchUserAccessories = React.useCallback(() => {
      const errorCallback = (error) => console.error(error);
      const successCallback = (data) => {
        setAccessoriesData(data["user_accessories"] || []);
        setDataFetched(true);
      };
  
      try {
        userEventsApi.getUserAccessories(
          hubRecord.useruuid,
          { eventtypes: ["environment_status"] },
          apiCallback({
            translator: trans,
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        );
      } catch (error) {
        errorCallback(error);
      }
    }, [userEventsApi, hubRecord, trans]);
  
    useEffect(() => {
      fetchUserAccessories();
    }, [fetchUserAccessories]);
    return { accessoriesData, dataFetched, refetch: fetchUserAccessories };
  };
  
  const { accessoriesData, dataFetched, refetch } = useFetchUserAccessories(
    userEventsApi,
    hubRecord,
    trans
  );

  const handleDeviceSmartCameraSelect = (device) => {
    setSelectedSmartCameraDevice(device); // Set the selected device
  };

  const handleSearchApply = () => {
    if (!searchText) {
      setSearchApplied(false);
      fetchDevices(null, (fetchedDevices) => {
        const readyToAssign = fetchedDevices.filter(
          (device) =>
            device.provisioningstatus === "Provisioned" && !device.accountname
        );
        setReadyToAssignDevices(readyToAssign); // Reset to original device list
      });
      return;
    }
    const lowerCaseQuery = searchText.toLowerCase();
    fetchDevices(null, (fetchedDevices) => {
      const readyToAssign = fetchedDevices.filter(
        (device) =>
          device.provisioningstatus === "Provisioned" && !device.accountname
      );
      const filteredDevices = readyToAssign.filter((device) =>
        device.deviceid?.toLowerCase().includes(lowerCaseQuery)
      );
      setReadyToAssignDevices(filteredDevices);
      setSearchApplied(true);
    });
  };

  const handleClearSearch = () => {
    setSearchText(null);
    setSearchApplied(false);
    // Re-fetch the devices and reset the readyToAssignDevices list
    fetchDevices(null, (fetchedDevices) => {
      const readyToAssign = fetchedDevices.filter(
        (device) =>
          device.provisioningstatus === "Provisioned" && !device.accountname
      );
      setReadyToAssignDevices(readyToAssign); // Reset the list with the original devices
    });
  };

  // Function to handle the modal opening
  const handleAssignSmartCamera = () => {
    setIsSmartCameraModalOpen(true);
    handleAssignSmartCameraList();
  };

  // Function to close the modal
  const closeModal = () => {
    setIsSmartCameraModalOpen(false);
    setSearchText(null);  // Clears search input
    setSearchApplied(false);  // Resets search state
    setSelectedSmartCameraDevice(null);
  };
  const handleAssignSmartCameraList = () => {
    setReadyToAssignLoading(true);
    // Call fetchDevices and handle the data
    fetchDevices(null, (fetchedDevices) => {
      setReadyToAssignLoading(false);
      if (!fetchedDevices || fetchedDevices.length === 0) {
        console.log("No devicesData available");
        return;
      }
      // Filter devices ready for assignment
      const readyToAssign = fetchedDevices.filter(
        (device) =>
          device.provisioningstatus === "Provisioned" && !device.accountname
      );
      if (readyToAssign.length === 0) {
        console.log("No devices are ready for assignment");
      } else {
        console.log("Ready to Assign Devices:", readyToAssign);
        setReadyToAssignDevices(readyToAssign); // Store the filtered devices
      }
    });
  };

  const assignDevice = (
    hubRecord,
    device,
    trans,
    fetchDevices,
    setHasDevice,
    closeModal,
    refetch,
    queryParams,
    setDeviceLocation,
    setDeviceStatus,
    setStatusTitle,
    setHeaderRefreshKey
  ) => {
    const errorCallback = (error) => {
      console.error("Error assigning device:", error);
      message.error("Cannot Assign Device to User: " + error.message);
    };
    const successCallback = (data) => {
      console.log("Device assigned successfully:", data);
      message.success("Device assigned successfully");
      setHasDevice(true);
      closeModal();
      // Fetch updated list of devices after successful assignment
      fetchDevices(queryParams, (fetchedDevices) => {
        console.log("Updated devices fetched after assignment:", fetchedDevices);
        if (fetchedDevices?.length > 0) {
          const assignedDevice = fetchedDevices.find(
            (d) => d.deviceuuid === device.deviceuuid
          );
          if (assignedDevice) {
            setDeviceStatus(assignedDevice.deviceconnectstatus.toLowerCase());
            setDeviceLocation(assignedDevice.location);
            setStatusTitle("Smart Camera");
            setHeaderRefreshKey("assign_device", assignedDevice.deviceid);
          }
        }
      });
      refetch();
    };
    try {
      const userAssociation = {
        deviceuuid: device.deviceuuid,
      };
      userApi.associateDeviceToUser(
        hubRecord.useruuid,
        userAssociation,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  return {
    isSmartCameraModalOpen,
    setIsSmartCameraModalOpen,
    readyToAssignDevices,
    searchText,
    setSearchText,
    searchApplied,
    setSearchApplied,
    selectedSmartCameraDevice,
    setSelectedSmartCameraDevice,
    readyToAssignloading,
    handleDeviceSmartCameraSelect,
    handleSearchApply,
    handleClearSearch,
    handleAssignSmartCamera,
    closeModal,
    handleAssignSmartCameraList,
    assignDevice,
    accessoriesData,
    dataFetched,
    refetch,
  };
};