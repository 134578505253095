import React, { useState, useEffect } from "react";
import { DatePicker, Space, ConfigProvider } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/en-gb";
import locale from "antd/es/locale/en_GB";
import { ScheduledIcon } from "./Icons";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const CustomDateRangePicker = ({ onDateChange,  selectedDates, disabled, repeatValue = "" }) => {
  const [open, setOpen] = useState(false); // Control the dropdown visibility
  const [value, setValue] = useState(selectedDates || []); // Holds the selected range of dates

  // Reset selected dates when repeatValue changes
  // useEffect(() => {
  //   setValue([]); // Clear the date range
  //   if (onDateChange) {
  //     onDateChange([]); // Notify the parent to reset form values
  //   }
  // }, [repeatValue]);

  const disabledPastDate = (current) => {
    return current && current < dayjs().startOf("day");
  };
  const handleDateChange = (dates) => {
    setValue(dates || []);
    if (onDateChange) {
      onDateChange(dates); // Pass the selected dates to the parent
    }
  };

  const handleOpenChange = (isOpen) => {
    // If the picker is opened with only the start date selected, keep it open
    if (isOpen && value?.[0] && !value?.[1]) {
      setOpen(true); // Keep the picker open with start date selected
    } else {
      setOpen(isOpen); // Close it when both dates are selected or no start date
    }
  };

  const handleBlur = () => {
    // If only the start date is selected, close the picker and display the start date
    if (value?.[0] && !value?.[1]) {
      setOpen(false); // Close the picker
    }
  };

  return (
    <ConfigProvider locale={locale}>
      <Space direction="vertical" size={12}>
        <div className="custom-rangepicker-container">
          <span className="custom-prefix-icon">
            <ScheduledIcon />
          </span>
          <RangePicker
            value={value}
            onChange={handleDateChange} // Update value on date selection
            format="ddd, MMM Do" // Use standard format for RangePicker internally
            disabledDate={disabledPastDate} // Disable past dates
            onOpenChange={handleOpenChange} // Control dropdown visibility
            open={open}
            allowClear={true} // Allow clearing the selected range
            suffixIcon={null} // Disable suffix icon for cleaner design
            placeholder={["Start Date", "End Date"]} // Placeholder text for input fields
            separator={"to"}
            onBlur={handleBlur} // Ensure the picker closes and the value is not cleared unintentionally
            disabled={disabled}
          />
        </div>
      </Space>
    </ConfigProvider>
  );
};

export default CustomDateRangePicker;

