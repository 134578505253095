import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import WellnessListItem from "./WellnessListItem";
import { SingleDatePicker } from "../Common/SingleDatePicker";
import { DateFormat } from "@commscopemycloud/humaui/Utilities/Constants";
import moment from "moment";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import "./Wellness.less";
import utc from 'dayjs/plugin/utc';
import { SupportedDevicesIcon } from "../Common/Icons";

dayjs.extend(utc);

const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const WellnessList = ({ showViewHistoryModal, showSupportDeviceModal,mappedData, loading, timeZone, setDateHandler, activeSupportDevicesTab, allDeviceModels }) => {
    const [wellnessListData, setWellnessListData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const userTimezone = timeZone ? timeZone?.split(" ")[0] : LocalTimezone;
    const [wellnessTotalCount, setWellnessTotalCount] = useState(0);


    useEffect(() => {
        if (loading === false && allDeviceModels && Object.keys(allDeviceModels).length > 0 ) {
            const WellnessListData = [];
            const allDeviceTypes = Object.keys(allDeviceModels);

            allDeviceTypes.forEach((item) => {
                const accessory = mappedData?.medical_accessories?.[item];
                if (accessory) {
                    const listObj = {
                        title: item,
                        day: accessory?.day || "-",
                        timeDifference: accessory?.timeDiff || "-",
                        time: accessory?.time || "-",
                        data: accessory?.data || "-",
                        device: "Device used",
                        deviceBrand: accessory?.deviceManufacture || "-",
                        deviceModel: accessory?.deviceModel || "-",
                        deviceuuid: accessory?.device_uuid || "-",
                        eventName: accessory?.eventName || "-",
                    };
                    WellnessListData.push(listObj);
                }
                else{
                    WellnessListData.push({
                        title: item,
                        hasData: false,
                })
                }
            });

            setWellnessListData(WellnessListData);
            setWellnessTotalCount(WellnessListData.length)
        }

    }, [mappedData, loading, allDeviceModels]);

    const handleDateSelect = (date) => {
        if (!date || !date.isValid()) {
            console.error('Invalid date passed to handleDateSelect:', date);
            return;
        }
        const selectedDate = date.startOf('day').toDate(); // Convert dayjs to Date
        setSelectedDate(selectedDate);

        const startDateTime = date.startOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        const endDateTime = date.endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        setDateHandler(startDateTime, endDateTime); // Pass formatted strings
    };

    const disabledFutureDate = (current) => {
        return current && current > moment().endOf("day");
    };

    return (
        <>
            <>
                <div className="wellness-count">
                    <div className="wellness-count-title">
                        <label>Total ({wellnessTotalCount})</label>
                    </div>
                    <div className="wellness-actions">
                    <div className="supported-devices-action-container" onClick={() => {
                        const defaultTab = Object.keys(allDeviceModels || {})[0];
                        if (defaultTab) {
                            showSupportDeviceModal(defaultTab);
                        }}}>
                            <SupportedDevicesIcon />
                            <div className="supported-devices-title">Supported devices</div>
                    </div>
                        <SingleDatePicker
                            DateFormat={DateFormat}
                            timeZone={userTimezone}
                            defaultValue={selectedDate}
                            onDateSelect={handleDateSelect}
                            disabledDate={disabledFutureDate}
                            value={selectedDate ? dayjs(selectedDate).tz(userTimezone, true) : null}
                        />
                    </div>
                </div>
                {loading ? (
                    <Spin spinning={loading} style={{ display: "flex", justifyContent: "center", marginTop: "20rem" }} />
                ) : (
                    <div className="wellness-list-container">
                        {wellnessListData.length > 0 ? (
                            wellnessListData.map((item, index) => (
                                <WellnessListItem
                                    key={index}
                                    showViewHistoryModal={showViewHistoryModal}
                                    showSupportDeviceModal={showSupportDeviceModal}
                                    title={item.title}
                                    day={item.day}
                                    timeDifference={item.timeDifference}
                                    time={item.time}
                                    data={item.data}
                                    device={item.device}
                                    deviceBrand={item.deviceBrand}
                                    deviceModel={item.deviceModel}
                                    deviceuuid={item.deviceuuid}
                                    eventName={item.eventName}
                                    showViewHistory={true}
                                    hasData={item.hasData !== false}
                                />
                            ))
                        ) : (
                            <div className="note-box no-events">
                                <span>{"No Wellness Device Found."}</span>
                            </div>
                        )}
                    </div>
                )}
            </>
        </>
    );
};

WellnessList.propTypes = {
    showViewHistoryModal: PropTypes.func,
    mappedData: PropTypes.object,
    loading: PropTypes.bool,
    timeZone: PropTypes.string,
    setDateHandler: PropTypes.func,
}

export default WellnessList;
