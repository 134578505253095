import React from "react";
import { CloseIcon } from "../Common/Icons";
import "./Wellness.less";
import { Modal, Spin } from "antd";
import titleData from "./statistic-title.json";

const WellnessSupportDeviceModal = ({
  showSupportDeviceModal,
  closeSupportDeviceModal,
  supportDeviceModalLoading,
  supportDeviceData,
  supportDeviceModalTitle,
}) => {
  return (
    <Modal
      open={showSupportDeviceModal}
      onOk={closeSupportDeviceModal}
      onCancel={closeSupportDeviceModal}
      footer={null}
      closable={false}
      centered
      className="wellness-modal-container"
    >
      <div className="modal-container">
        {/* Header Section */}
        <div className="wellness-modal-header-container">
          <div className="wellness-header-icon-title">
            <div className="wellness-modal-header-info">
              <div className="wellness-supported-device-header-title">
                {'Currently supported wellness devices'}
              </div>
              <div className="wellness-support-device-header-sub-title">{titleData["device_title"][supportDeviceModalTitle]}</div>
            </div>
          </div>
          <div className="close-button" onClick={closeSupportDeviceModal}>
            <CloseIcon className="close-icon" />
          </div>
        </div>
        {supportDeviceModalLoading ?
          <Spin spinning={supportDeviceModalLoading} style={{ margin: "10rem 9rem" }} /> :
          <div className="custom-table">
            <div className="table-header-row">
              <span className="table-header">Manufacturer</span>
              <span className="table-header">Model</span>
              <span className="table-header">Country</span>
            </div>
            <div className="table-body">
              {supportDeviceData.map((item, index) => (
                <div className="table-row" key={index}>
                  <span className="table-cell">{item?.manufacturer ?? '-'}</span>
                  <span className="table-cell">{item?.modelnumber ?? '-'}</span>
                  <span className="table-cell">{item?.country ?? '-'}</span>
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    </Modal>
  );
};

export default WellnessSupportDeviceModal;
