import React from "react";

export const NewCheckForUpdatesIcon = (props) => {
  const { className = "", disabled = false, onClick, style = {}, fillColor } = props;
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0186 9.12591C10.0186 9.78392 10.2836 10.3871 10.704 10.8441C11.627 11.8402 11.819 11.1913 11.819 12.48V16.7388C11.819 17.278 12.2576 17.7167 12.7877 17.7167C12.806 17.7167 12.8151 17.7167 12.8243 17.7167C12.8425 17.7167 12.8517 17.7167 12.8608 17.7167C13.4 17.7167 13.8387 17.278 13.8387 16.7388V12.4708C13.8387 11.1913 14.0306 11.8402 14.9537 10.8441C15.3832 10.378 15.6483 9.78392 15.6391 9.12591C15.6391 7.99265 14.8714 7.02391 13.793 6.66748V8.76034C13.793 9.2173 13.4183 9.58286 12.9888 9.58286H12.8334H12.6872C12.2485 9.58286 11.8829 9.2173 11.8738 8.76034V6.66748C10.7954 7.02391 10.0277 7.99265 10.0186 9.11677V9.12591Z"
        fill={fillColor}
      />
      <path
        d="M19.3131 5.52479C17.5858 3.7975 15.2827 2.83789 12.8335 2.83789C10.3842 2.83789 8.08111 3.78836 6.35381 5.52479C4.62651 7.26123 3.66691 9.55515 3.66691 12.0044C3.66691 12.2421 3.66691 12.4705 3.69432 12.7082L3.14598 12.306C2.82611 12.0684 2.37829 12.1415 2.14067 12.4523C1.90305 12.7721 1.97617 13.2199 2.2869 13.4576L4.1787 14.8558C4.48029 15.0752 4.92811 15.0204 5.15658 14.737L6.60057 12.9915C6.85646 12.6899 6.81077 12.2329 6.50004 11.977C6.18931 11.7211 5.74149 11.7668 5.48559 12.0776L5.11089 12.5345C5.10175 12.3609 5.09261 12.1781 5.09261 12.0044C5.11089 7.7456 8.57462 4.27273 12.8335 4.27273C17.0923 4.27273 20.5652 7.73646 20.5652 12.0044C20.5652 16.2724 17.1014 19.727 12.8335 19.727C10.7406 19.727 8.77568 18.9045 7.31342 17.4057C7.17633 17.2686 7.00269 17.1955 6.81077 17.1863C6.60971 17.1863 6.43606 17.2594 6.29897 17.3965C6.01566 17.6707 6.01566 18.1277 6.28984 18.411C7.13064 19.27 8.11766 19.9463 9.2235 20.4216C10.3659 20.9151 11.5814 21.1618 12.8335 21.1618C15.2827 21.1618 17.5858 20.2114 19.3131 18.4749C21.0404 16.7385 22 14.4446 22 11.9953C22 9.54601 21.0495 7.24295 19.3131 5.51566V5.52479Z"
        fill={fillColor}
      />
    </svg>
  );
};
