/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import MainPage from "../../MainPage";
import StickyHeader from "../Common/StickyHeader";
import useContacts from "../Hooks/useContacts";
import { useDispatch, useSelector } from "react-redux";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import {
  Actions,
  labelMapping,
  sessionType,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { getContacts } from "./Contacts";
import {
  toDateTimeByTimeZone,
  getTimeZoneFormat,
  formatEventTimes,
} from "@commscopemycloud/humaui/Utilities/DateTime";
import ContactSelectList from "../Common/ContactSelectList";
import {
  KnownActionsList,
  isActionAllowed,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { Divider, message } from "antd";
import { OpenInNewIcon } from "../Common/Icons/OpenInNewIcon";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
import RecurringTaskModal from "../Task/RecurringTaskModal";
import NonRecurringTaskModal from "../Task/NonRecurringTaskModal";
import TaskModal from "../Task/TaskModal";
import { CallIcon, CloseIcon, EditIcon } from "../Common/Icons";
import NewCustomButton from "../Common/NewCustomButton";
import { ButtonTypes } from "../Common/CustomButton";
const Mins_in_millis = 15 * 60 * 1000; // 15 minutes = 900000
const Hours_in_millis = 4 * 60 * 60 * 1000; // 4 hours = 14400000

export const StartEvent = (props) => {
  // console.debug("StartEvent props", props);
  const {
    onlyContent,
    parentBread,
    eventRecord,
    onActionClick,
    userHubsData,
    userInfo,
    rolename,
    hubRecord,
    openSubMenu = false,
    loginUserTimeformat,
    closeModalHandler,
    showModal,
    reFetchList,
    action,
    userTimezone,
    currentUserDataInfo,
  } = props;
  const timeZone = getTimeZoneFormat(userInfo.timezone);
  const dispatch = useDispatch();
  const trans = useSelector(translator);
  const [contacts, fetchingContacts] = useContacts({
    useruuid: eventRecord?.useruuid,
  });
  const [invites, setInvites] = useState([]);
  const header = (parentBread || []).concat({ label: trans("EVENT") });
  const currentUser = useSelector(getCurrentUser);
  const loginUseruuid = useMemo(() => currentUser?.useruuid, [currentUser]);
  const isEventRecurring = useMemo(() => {
    return (
      eventRecord?.repeat &&
      ["daily", "custom", "weekly"].includes(eventRecord?.repeat)
    );
  }, [eventRecord]);
  const permissions = useSelector(getPermissions());
  const [activeTab, setActiveTab] = useState("schedule"); // State to manage the active tab
  const [isJoinButtonVisible, setJoinButtonVisible] = useState(false);
  const [isEventModalVisible, setIsEventModalVisible] = useState(false);
  const handleEventClick = () => {
    setIsEventModalVisible(true);
  };
  const handleModalConfirm = () => {
    setIsEventModalVisible(false);
    modifyEventDetails();
  };
  const handleModalCancel = () => {
    setIsEventModalVisible(false);
  };

  const modifyEventDetails = () => {
    closeModalHandler(false);
    onActionClick(
      eventRecord?.event_type === sessionType.reminder
        ? Actions.editReminderEvent
        : Actions.editEvent,
      {
        ...eventRecord,
        userHubsData,
      }
    )();
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
    onActionClick(null, null, key)();
  };
  const userApi = useSelector((state) => state.apis.userApi);
  const fetchData = (mUseruuid) => {
    const errorCallback = (error) => {
      message.error("Error fetching user information!");
      console.error("Error fetching user information:", error);
    };
    const successCallback = (data) => {
      console.info("UserInfo: fetch result", data);
      setInvites((prevState) =>
        prevState.map((item) => {
          if (item.useruuid === data?.user?.useruuid) return data.user;
          else return item;
        })
      );
    };
    try {
      if (!mUseruuid) return;
      userApi.getUserById(
        mUseruuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(() => {
    let invitedContacts = eventRecord?.invited_contacts;
    let filteredContacts = getContacts(invitedContacts, [
      ...(contacts ?? []),
      ...(userHubsData ?? []),
      ...(userInfo ? [userInfo] : []),
    ]);
    setInvites(filteredContacts);
    if (contacts.length > 0) {
      filteredContacts
        .filter((item) => !("firstname" in item))
        .forEach((item) => {
          fetchData(item.useruuid);
        });
    }
    setJoinButtonVisible(
      checkCallButtonVisibility(
        eventRecord?.event_timestamp,
        eventRecord?.invited_contacts
      ) &&
        eventRecord?.event_type?.toLowerCase() !== sessionType?.zoom &&
        eventRecord?.event_type?.toLowerCase() !== sessionType?.reminder
    );
  }, [contacts, eventRecord]);

  const checkCallButtonVisibility = (datetime, invitedList) => {
    //console.log("datetime",datetime);
    //Disabled call button if contact not exists in invited list.
    let contactExist = invitedList?.includes(loginUseruuid);
    if (!contactExist) {
      return false;
    }
    if (!datetime) return false;
    let currentTime = new Date().getTime();
    let eventTime = new Date(datetime).getTime();
    let beforeEvent = eventTime - currentTime;
    let afterEvent = currentTime - eventTime;
    //console.log("beforeEvent",beforeEvent);
    //console.log("afterEvent",afterEvent);
    //Display the meeting start button before 15 minutes from event starttime
    if (beforeEvent > 0 && beforeEvent <= Mins_in_millis) {
      return true;
    }
    //Display the meeting start button until 4 hours from event starttime
    if (afterEvent >= 0 && afterEvent <= Hours_in_millis) {
      return true;
    }
    return false;
  };

  const checkEditButtonVisibility = (event_originator_id) => {
    if (
      event_originator_id === loginUseruuid ||
      (rolename !== undefined &&
        isActionAllowed(
          rolename,
          KnownActionsList.manage_schedule,
          permissions
        ))
    ) {
      return true;
    }
  };
  const EventCreatedDate = (dateString) => {
    const rearrangeDate = (dateString) => {
      const [year, month, day] = dateString.split("-");
      return `${month}.${day}.${year}`;
    };
    return rearrangeDate(dateString);
  };

  const FormattedReminderDuration = (duration) => {
    const reminderDuration = labelMapping[duration] || `${duration} minutes`;
    return reminderDuration;
  };

  const renderEventModal = () => {
    if (!isEventModalVisible) return null;
    return isEventRecurring ? (
      <RecurringTaskModal
        isModalOpen={isEventModalVisible}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
        showDeleteMsg={true}
        modalHeader={`Modify periodic ${
          eventRecord?.event_type === "reminder"
            ? "reminder"
            : eventRecord?.event_type === "tasks"
            ? "task"
            : "call"
        }`}
      />
    ) : (
      <NonRecurringTaskModal
        isModalOpen={isEventModalVisible}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
        showDeleteMsg={false}
        modalHeader={`Modify ${
          eventRecord?.event_type === "reminder" ? "reminder" : "call"
        }`}
        modalType={eventRecord?.event_type === "reminder" ? "reminder" : "call"}
        actionType={"modify"}
      />
    );
  };
  const headerSection = (
    <div className="event-details-header">
      <div className="event-details-title">
        <CallIcon style={{ fill: "#005D98" }} />
        <span>{eventRecord?.event_name}</span>
      </div>
      <div className="event-details-actions">
        {checkEditButtonVisibility(eventRecord?.event_originator_id) &&
          hubRecord && (
            <div
              className="edit-action"
              onClick={() => {
                handleEventClick();
              }}
            >
              <EditIcon style={{ stroke: "#4E758E", height: "23px" }} />
            </div>
          )}
        <CloseIcon
          style={{
            height: "15px",
            width: "20px",
            cursor: "pointer",
            fill: "#4E758E",
            stroke: "#4E758E",
          }}
          onClick={closeModalHandler}
        />
      </div>
    </div>
  );

  const bodySection = (
    <>
      <div className="event-details-body">
        <div className="event-details">
          <span className="general-info-text">General information</span>
          <div className="events-details-info">
            <div className="event-scheduled-day-info">
              {(loginUserTimeformat && loginUserTimeformat?.is24hoursformat
                ? toDateTimeByTimeZone(
                    eventRecord?.event_timestamp,
                    timeZone,
                    loginUserTimeformat?.is24hoursformat
                  )
                : toDateTimeByTimeZone(
                    eventRecord?.event_timestamp,
                    timeZone,
                    loginUserTimeformat?.is24hoursformat
                  )
              ).format("MM/DD/YY")}
              <div className="event-scheduled-daydata">
                <div className="event-scheduled-repeat-alert">
                  Repeats:
                  <span className="event-scheduled-infovalue">
                    {eventRecord && eventRecord?.repeat
                      ? eventRecord?.repeat.charAt(0).toUpperCase() +
                        eventRecord?.repeat.slice(1).toLowerCase()
                      : "Doesn't Repeat"}
                  </span>
                </div>
                <div className="event-scheduled-repeat-alert">
                  Alert:
                  <span className="event-scheduled-infovalue">
                    {eventRecord?.reminder &&
                      FormattedReminderDuration(eventRecord?.reminder / 60)}
                  </span>
                </div>
              </div>
              {eventRecord?.create_time && eventRecord?.create_by && (
                <div className="event-created-date">
                  Created on {EventCreatedDate(eventRecord?.create_time)}{" "}
                  by {eventRecord?.create_by}
                </div>
              )}
            </div>
            <div
              className={`event-scheduled-date-info ${
                isJoinButtonVisible ? "join-call-exists" : "no-join-call"
              }`}
            >
              <div className="time-text">
                {eventRecord?.event_type !== "tasks" &&
                  formatEventTimes(
                    eventRecord?.event_startdate,
                    eventRecord?.event_starttime,
                    eventRecord?.event_startdate,
                    eventRecord?.event_endtime,
                    userTimezone,
                    currentUserDataInfo?.is24hoursformat
                  )}
              </div>
              {isJoinButtonVisible && (
                <NewCustomButton
                  type={ButtonTypes.primary}
                  label="Join"
                  icon={<CallIcon className="update-icon-enabled" />}
                  onClick={() =>
                    dispatch(
                      startVideoCall({
                        meeting_id: eventRecord?.schedule_eventuuid,
                        eventInfo: eventRecord,
                      })
                    )
                  }
                />
              )}
            </div>
          </div>
          <div className="event-description">
            <div className="label">{trans("DESCRIPTION")}</div>
            <div className="text description">
              {eventRecord?.event_description}
            </div>
            {eventRecord?.meeting_url && (
              <>
                <div className="label">{trans("LINK")}</div>
                <div className="url-link">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={eventRecord?.meeting_url}
                  >
                    {eventRecord?.meeting_url}
                    <OpenInNewIcon className="custom-icon" />
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        <ContactSelectList
          displayOnlySelected={true}
          fetchingContacts={fetchingContacts}
          selectedContactList={invites}
          selectedListHeader={"Participants"}
          selectionListHeader={trans("ADDCONTACTS")}
          selectionContactList={[]}
          showRemoveIcon={false}
          showRelationship={false}
          hubRecord={hubRecord}
          starteventAction={false}
        />
      </div>
    </>
  );

  const render = (
    <div
      className="startevent-page-wrapper flex-column"
      style={{ width: "100%", height: "100%" }}
    >
      {renderEventModal()}
      <StickyHeader>{headerSection}</StickyHeader>
      <Divider style={{ margin: "0px", borderColor: "#C9C9C9" }} />
      {bodySection}
    </div>
  );

  return onlyContent && action === Actions.startEvent ? (
    <TaskModal showModal={showModal} closeModalHandler={closeModalHandler}>
      {render}
    </TaskModal>
  ) : (
    <MainPage
      header={header}
      hasSider={true}
      activeKey={activeTab}
      openSubMenu={openSubMenu}
      onChange={handleTabChange}
      hubUserRole={hubRecord?.rolename}
      noModals={!!openSubMenu}
    >
      {render}
    </MainPage>
  );
};

StartEvent.propTypes = {
  action: PropTypes.string,
  onlyContent: PropTypes.bool,
  parentBread: PropTypes.arrayOf(PropTypes.object),
};

export default StartEvent;
