/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { AppStorageKeys } from "@commscopemycloud/humaui/Utilities/Constants"

import LanguageIcon from "../../../static/icons/language.svg";
import MailIcon from "../../../static/icons/mail.svg";
import CallIcon from "../../../static/icons/call.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { NewRedirectIcon } from "../Common/Icons";
const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);

const SupportCardInfo = ({userConfigData, loading}) => {
  const trans = useSelector(translator);

  return (
    <>
      <h1 className="help-page-card-header">Contact Us</h1>
      <div className="help-page-card-information">
        {loading ?
          <div class="help-page-card-data-group"><LoadingOutlined /></div> : (
            <>
              {userConfigData && userConfigData["website"] ? (
                <div className="help-page-card-data-group">
                  <img alt="care" src={LanguageIcon} />
                  <a href={userConfigData["website"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#3FA9F5", textDecoration:"underline" }}
                    className="help-page-data-label"
                  >{userConfigData["website"]}</a>
                  <NewRedirectIcon />
                </div>) : null}
            </>
          )}
        {loading ?
          <div class="help-page-card-data-group"><LoadingOutlined /></div> : (
            <>
              {userConfigData && userConfigData["email"] ? (
                <div className="help-page-card-data-group">
                  <img alt="mail" src={MailIcon} />
                  <p className="help-page-data-label">{userConfigData["email"]}</p>
                </div>) : null}
            </>
          )}
        {loading ?
          <div class="help-page-card-data-group"><LoadingOutlined /></div> : (
            <>
              {userConfigData && userConfigData["contact"] ? (
                <div className="help-page-card-data-group">
                  <img alt="contact" src={CallIcon} />
                  <p className="help-page-data-label">{userConfigData["contact"]}</p>
                </div>) : null}
            </>
          )}
      </div>
    </>
  );
};

export default SupportCardInfo;
