import React, { useState } from "react";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";
import { AppStorageKeys } from "@commscopemycloud/humaui/Utilities/Constants";
import { Button, message, Modal } from "antd";
import { CloseIcon } from "../Common/Icons";
const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);

const DataExport = () => {
  const trans = useSelector(translator);
  const providerBulkDataApi = useSelector(
    (state) => state.apis.providerBulkDataApi
  );

  const handleDataExportDownload = (providerBulkDataApi) => {
    const errorCallback = (error) => {
      message.error("Failed to download data: " + error.message);
    };
    const successCallback = (data) => {
      const downloadUrl = data.downloadUrl;
      fetch(downloadUrl)
        .then((response) => {
          if (response.ok) return response.blob();
          throw new Error("Network response was not ok.");
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = data.url;
          link.download = data.filename;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.parentNode.removeChild(link);
          message.success("Data downloaded successfully");
        })
        .catch((error) => {
          errorCallback(error);
        });
    };

    try {
      providerBulkDataApi.getProviderBulkData((error, data, response) => {
        if (error) {
          errorCallback(error);
          return;
        }
        if (response.ok) {
          successCallback(data);
        } else {
          errorCallback(
            new Error("Failed to fetch data with status: " + response.status)
          );
        }
      });
    } catch (error) {
      errorCallback(error);
    }
  };

  return (
    <>
      <div className="help-page-card-header">Data Export</div>
      <div className="help-page-card-information">
        <span className="help-page-download-label">Download the most recent 30 days of activity data for all accounts
        </span>
        <a
          href="#"
          onClick={() => handleDataExportDownload(providerBulkDataApi)}
          className="help-page-click-to-download"
        >
          Click to download
        </a>
      </div>
    </>
  );
};

export default DataExport;