import React, { useState } from "react";
import PropTypes from "prop-types";
import AssignSmartCameraModal from "../DeviceInventory/AssignSmartCameraModal";
import AssignStaffModal from "../Staff/NewAssignStaff/AssignStaffModal";

const AccountSetupSection = ({
  handleAssignSmartCamera,
  openAssignStaffModal,
  isSmartCameraModalOpen,
  closeModal,
  searchText,
  setSearchText,
  searchApplied,
  handleSearchApply,
  handleClearSearch,
  readyToAssignDevices,
  readyToAssignloading,
  selectedSmartCameraDevice,
  handleDeviceSmartCameraSelect,
  assignDevice,
  isFirstModalOpen,
  handleCloseFirstModal,
  fetchingStaffList,
  selectedStaff,
  mOrderedStaffList,
  handleAddStaff,
  formData,
  handleInputChange,
  selectedUser,
  handleAssignStaff,
  handleRoleSelection,
  handleOpenSecondModal,
  handleCloseSecondModal,
  isSecondModalOpen,
  setSelectedStaff,
  role,
  setActiveTab,
  hubRecord,
  updatedUserInfo,
  hasDevice,
  handleAssignSmartCameraList,
  setHasDevice,
  fetchDevices,
  setDeviceStatus,
  setDeviceLocation,
  setStatusTitle,
  setHeaderRefreshKey,
  refetch,
  dataFetched,
  accessoriesData,
  setSearchNameApplied,
  searchNameApplied,
  setSearchNameText,
  searchNameText,
}) => {
  
  const isModifiedTimeEmpty =
    !updatedUserInfo?.modified_time || updatedUserInfo?.modified_time.trim() === "";

  // Handle staff assignment and update the state
  const onAssignStaffSuccess = () => {
    handleAssignStaff();
  };

  // Handle staff assignment and update the state
  const onAssignSmartCameraSuccess = () => {
    handleAssignSmartCamera();
  };

  const shouldShowSetupMessage =
  isModifiedTimeEmpty || !hasDevice || !hubRecord?.isstaffassigned;

  return (
    <>
      {shouldShowSetupMessage && (
        <div className="account-setup-container">
          <div className="account-setup-row top">
            <span className="account-setup-title">
              Account almost fully setup
            </span>
            <span className="account-setup-paragraph">
              Some steps are incomplete and requires your attention
            </span>
          </div>
          <div className="account-setup-row bottom">
            {isModifiedTimeEmpty && (
              <div
                className="account-setup-info-block"
                onClick={() => setActiveTab("editProfile")}
              >
                <span className="account-setup-info-label">
                  Review Account Profile
                </span>
              </div>
            )}
            {!hasDevice && (
              <div
                className="account-setup-info-block"
                onClick={onAssignSmartCameraSuccess}
              >
                <span className="account-setup-info-label">
                  Assign Smart Camera
                </span>
              </div>
            )}
            {!hubRecord?.isstaffassigned && (
              <div
                className="account-setup-info-block"
                onClick={openAssignStaffModal}
              >
                <span className="account-setup-info-label">Assign Staff</span>
              </div>
            )}
          </div>
        </div>
      )}

      {isSmartCameraModalOpen && (
        <AssignSmartCameraModal
          isSmartCameraModalOpen={isSmartCameraModalOpen}
          closeModal={closeModal}
          searchText={searchText}
          setSearchText={setSearchText}
          searchApplied={searchApplied}
          handleSearchApply={handleSearchApply}
          handleClearSearch={handleClearSearch}
          readyToAssignDevices={readyToAssignDevices}
          readyToAssignloading={readyToAssignloading}
          selectedSmartCameraDevice={selectedSmartCameraDevice}
          handleDeviceSmartCameraSelect={handleDeviceSmartCameraSelect}
          assignDevice={assignDevice}
          handleAssignSmartCameraList={handleAssignSmartCameraList}
          hubRecord={hubRecord}
          setHasDevice={setHasDevice}
          fetchDevices={fetchDevices}
          setDeviceStatus={setDeviceStatus}
          setDeviceLocation={setDeviceLocation}
          setStatusTitle={setStatusTitle}
          setHeaderRefreshKey={setHeaderRefreshKey}
          refetch={refetch}
          dataFetched={dataFetched}
          accessoriesData={accessoriesData}
        />
      )}
      <AssignStaffModal
        isFirstModalOpen={isFirstModalOpen}
        handleCloseFirstModal={handleCloseFirstModal}
        fetchingStaffList={fetchingStaffList}
        selectedStaff={selectedStaff}
        mOrderedStaffList={mOrderedStaffList}
        handleAddStaff={handleAddStaff}
        formData={formData}
        handleInputChange={handleInputChange}
        selectedUser={selectedUser}
        handleAssignStaff={onAssignStaffSuccess}
        handleRoleSelection={handleRoleSelection}
        handleOpenSecondModal={handleOpenSecondModal}
        handleCloseSecondModal={handleCloseSecondModal}
        isSecondModalOpen={isSecondModalOpen}
        setSelectedStaff={setSelectedStaff}
        role={role}
        hubRecord={hubRecord}
        setSearchNameApplied={setSearchNameApplied}
        searchNameApplied={searchNameApplied}
        setSearchNameText={setSearchNameText}
        searchNameText={searchNameText}
      />
    </>
  );
};

AccountSetupSection.propTypes = {
  handleAssignSmartCamera: PropTypes.func.isRequired,
  openAssignStaffModal: PropTypes.func.isRequired,
  isSmartCameraModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  searchApplied: PropTypes.bool.isRequired,
  handleSearchApply: PropTypes.func.isRequired,
  handleClearSearch: PropTypes.func.isRequired,
  readyToAssignDevices: PropTypes.array.isRequired,
  readyToAssignloading: PropTypes.bool.isRequired,
  selectedSmartCameraDevice: PropTypes.object,
  handleDeviceSmartCameraSelect: PropTypes.func.isRequired,
  assignDevice: PropTypes.func.isRequired,
  isFirstModalOpen: PropTypes.bool.isRequired,
  handleCloseFirstModal: PropTypes.func.isRequired,
  fetchingStaffList: PropTypes.bool.isRequired,
  selectedStaff: PropTypes.object,
  mOrderedStaffList: PropTypes.array.isRequired,
  handleAddStaff: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
  handleAssignStaff: PropTypes.func.isRequired,
  handleRoleSelection: PropTypes.func.isRequired,
  handleOpenSecondModal: PropTypes.func.isRequired,
  handleCloseSecondModal: PropTypes.func.isRequired,
  isSecondModalOpen: PropTypes.bool.isRequired,
  setSelectedStaff: PropTypes.func.isRequired,
  role: PropTypes.string,
};

export default AccountSetupSection;
