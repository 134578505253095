import React from "react";
import "./DeviceInventory.less";
import {
  BatteryLowIcon,
  NewArrowRightIcon,
  NewHC200Icon,
  PairedIcon,
  BatteryHighIcon,
  BatteryMediumIcon,
  ZeroLightIcon,
  LowLightIcon,
  MediumLightIcon,
  HighLightIcon,
  AddIcon,
  UnpairedIcon,
  BatteryZeroIcon,
  RCBatteryHighIcon,
  RCBatteryLowIcon,
  RCBatteryMediumIcon,
  RCBatteryZeroIcon,
  BloodPressureIcon,
  BodyWeightIcon,
  BodyTemperatureIcon,
  PulseOxygenIcon,
  FallbackMedicalIcon,
} from "../Common/Icons";
import environmentalRanges from "../../../environmentalRanges.json";
import { devicesInventory } from "../../utilities/data";

const { device_types } = devicesInventory;

const componentMap = {
  BatteryLowIcon,
  BatteryMediumIcon,
  BatteryHighIcon,
  ZeroLightIcon,
  LowLightIcon,
  MediumLightIcon,
  HighLightIcon,
  RCBatteryHighIcon,
  RCBatteryLowIcon,
  RCBatteryMediumIcon,
  RCBatteryZeroIcon,
  BloodPressureIcon,
  BodyWeightIcon,
  BodyTemperatureIcon,
  PulseOxygenIcon,
  FallbackMedicalIcon,
};

const getIconComponent = (levels, value) => {
  for (const level of levels) {
    if (
      (level.max !== undefined && value <= level.max) ||
      (level.min !== undefined && value >= level.min && level.max === undefined)
    ) {
      return componentMap[level.component];
    }
  }
  return null;
};

const DeviceInfo = ({ device, isModalView = false }) => (
  <div className="device-info">
    {device_types.smart_camera_devices.models.includes(device?.modelnumber) ? (
      <div className="device-name">
        Smart Camera{" "}
        {isModalView && device?.location && `(${device?.location})`}
      </div>
    ) : device_types.remote_control_devices.models.includes(
        device.modelnumber
      ) ? (
      <div className="device-name">
        Remote Control{" "}
        {isModalView && device?.location && `(${device?.location})`}
      </div>
    ) : device_types.sensor_devices.models.includes(device?.modelnumber) ? (
      <div className="device-name">
        Environmental Sensor{" "}
        {isModalView &&
          device?.device_location &&
          `(${device?.device_location})`}
      </div>
    ) : (
      <div className="device-name">
        {" "}
        {["NA", "N/A"].includes(device?.devicetype)
          ? "Unknown"
          : device?.devicetype}
      </div>
    )}
    {!isModalView && (
      <div className="device-location">
        {device_types.smart_camera_devices.models.includes(
          device?.modelnumber
        ) ||
        device_types.remote_control_devices.models.includes(device?.modelnumber)
          ? device?.location
            ? `${device?.location}`
            : ""
          : device_types.sensor_devices.models.includes(device?.modelnumber)
          ? device?.device_location
            ? `${device?.device_location}`
            : ""
          : ""}
      </div>
    )}
    <div className="device-serial-number">{device?.device_serial_number}</div>
  </div>
);

const DeviceConnectionIconInfo = ({
  device,
  deviceStatus,
  connectionStatus,
}) => {
  const battery = (batteryLevel) => {
    const batteryLevels = device_types.remote_control_devices.models.includes(
      device?.modelnumber
    )
      ? environmentalRanges.environmentalRanges.rcBatteryLevels
      : environmentalRanges.environmentalRanges.batteryLevels;
    const IconComponent = getIconComponent(batteryLevels, batteryLevel);
    return IconComponent ? (
      <IconComponent
        connectionStatus={
          device?.paired_status === "UNPAIRED" ? false : connectionStatus
        }
      />
    ) : null;
  };

  const medicalDeviceTypeIconMap =
    devicesInventory?.device_types?.medical_devices?.medical_device_icons;

  const getMedicalDeviceTypeIcon = (devicetype) => {
    const iconKey =
      medicalDeviceTypeIconMap[devicetype] ||
      medicalDeviceTypeIconMap["default"];
    return componentMap[iconKey] || FallbackMedicalIcon;
  };
  const MedicalDeviceTypeIcon = getMedicalDeviceTypeIcon(device?.devicetype);

  return (
    <>
      {device_types.smart_camera_devices.models.includes(
        device?.modelnumber
      ) ? (
        <NewHC200Icon className="device-status-icon" status={deviceStatus} />
      ) : device_types.remote_control_devices.models.includes(
          device.modelnumber
        ) ? (
        device?.device_battery_level === null ||
        device?.device_battery_level === undefined ? (
          <RCBatteryZeroIcon
            className="device-status-icon"
            connectionStatus={
              device?.paired_status === "UNPAIRED" ? false : connectionStatus
            }
          />
        ) : (
          <div className="connection-icon">
            {battery(device?.device_battery_level)}
          </div>
        )
      ) : device_types.sensor_devices.models.includes(device?.modelnumber) ? (
        device?.device_battery_level === null ||
        device?.device_battery_level === undefined ? (
          <BatteryZeroIcon
            className="device-status-icon"
            connectionStatus={
              device?.paired_status === "UNPAIRED" ? false : connectionStatus
            }
          />
        ) : (
          <div className="connection-icon">
            {battery(device?.device_battery_level)}
          </div>
        )
      ) : device?.devicetype ? (
        <MedicalDeviceTypeIcon className="device-status-icon" />
      ) : (
        <div
          className="fallback-icon"
          style={{ width: "63px", height: "60px" }}
        />
      )}
    </>
  );
};

const formatStatusTime = (isoString, timezone) => {
  const date = new Date(isoString);
  return date.toLocaleString("en-US", {
    timeZone: timezone,
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

const calculateTimeDifference = (lastConnectTime) => {
  const currentTime = new Date();
  const lastConnectDate = new Date(lastConnectTime);
  const diffInMilliseconds = currentTime - lastConnectDate;
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const days = Math.floor(diffInHours / 24);
  const hours = diffInHours % 24;

  if (diffInSeconds < 60) return "1 Minute";
  if (diffInMinutes < 60)
    return `${diffInMinutes} Minute${diffInMinutes !== 1 ? "s" : ""}`;
  if (days > 0)
    return `${days} Day${days > 1 ? "s" : ""} ${
      hours > 0 ? `${hours} Hour${hours > 1 ? "s" : ""}` : ""
    }`;
  return `${hours} Hour${hours > 1 ? "s" : ""}`;
};

const StatusInfo = ({
  lastConnectTime,
  lastDisconnectTime,
  device,
  timezone,
  connectionStatus,
  showDuration = true,
  tooltipMode = false,
}) => {
  const getConnectionStatusName = () => {
    if (device_types.sensor_devices.models.includes(device?.modelnumber)) {
      return device?.device_connected ? "Connected" : "Disconnected";
    } else if (
      device_types.remote_control_devices.models.includes(device.modelnumber)
    ) {
      return device?.device_connection_status ? "Connected" : "Disconnected";
    } else if (
      device_types.smart_camera_devices.models.includes(device?.modelnumber)
    ) {
      return device?.deviceconnectstatus
        ? device?.deviceconnectstatus.charAt(0).toUpperCase() +
            device?.deviceconnectstatus.slice(1).toLowerCase()
        : "Disconnected";
    }
    return connectionStatus || "Disconnected";
  };

  const isConnected = getConnectionStatusName() === "Connected";
  const statusTime = isConnected ? lastConnectTime : lastDisconnectTime;

  const renderBatteryLevel = (device) => {
    return (
      <div className="device-battery-info-container">
        <span className="device-battery-level-label">Battery level:</span>
        <span className="device-battery-level">
          {device?.device_battery_level != null &&
          device?.device_battery_level >= 0
            ? `${device?.device_battery_level}%`
            : "-"}
        </span>
      </div>
    );
  };

  const formattedLastUsedTime =
    !device?.device_last_connection_time ||
    ["NA", "N/A"].includes(device?.device_last_connection_time?.toUpperCase())
      ? "Unknown"
      : formatStatusTime(device?.device_last_connection_time, timezone);

  return (
    <>
      {device_types.smart_camera_devices.models.includes(
        device?.modelnumber
      ) ? (
        <div
          className={
            tooltipMode ? "device-page-tooltip-status-info" : "status-info"
          }
        >
          <>
            <div className="device-status-label">
              {isConnected ? "Online" : "Offline"}
              <span className="device-status-time">
                since {formatStatusTime(statusTime, timezone)}
              </span>
            </div>
            {showDuration && (
              <div className="device-status-connection-time">
                For {calculateTimeDifference(statusTime)}
              </div>
            )}
          </>
        </div>
      ) : [
          ...device_types.sensor_devices.models,
          ...device_types.remote_control_devices.models,
        ].includes(device?.modelnumber) ? (
        <div
          className={
            tooltipMode ? "device-page-tooltip-status-info" : "sensor-status"
          }
        >
          <div className="status-content">
            <div className="device-status-label">
              {`${getConnectionStatusName()} `}
              <span className="device-status-time">
                since {formatStatusTime(statusTime, timezone)}
              </span>
            </div>
            {showDuration && (
              <>
              <div className="device-status-connection-time">
                For {calculateTimeDifference(statusTime)}
              </div>
            {renderBatteryLevel(device)}
            </>
        )}
          </div>
        </div>
      ) : (
        <div className={
          tooltipMode ? "device-page-tooltip-status-info" : "sensor-status"
        }>
          <div className="status-content">
            <div className="device-status-label">
              Last used
              <span className="device-status-time">
                {formattedLastUsedTime}
              </span>
            </div>
            {showDuration && (
              <>
               {renderBatteryLevel(device)}</>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const ViewMoreButton = ({ showModal }) => (
  <div className="view-more-info">
    <div className="view-more" onClick={showModal}>
      View more
      <NewArrowRightIcon style={{ marginLeft: "10px" }} />
    </div>
  </div>
);

const DeviceInventoryList = ({
  device,
  isExpanded,
  showModal,
  deviceStatus,
  timezone,
  connectionStatus,
}) => {
  const isDeviceExpanded =
    device_types?.smart_camera_devices?.models?.includes(device?.modelnumber) ||
    isExpanded;

  return (
    <div
      className={`device-card ${
        device_types?.smart_camera_devices?.models?.includes(
          device?.modelnumber
        )
          ? "hc200-device-card"
          : ""
      }`}
      key={device.deviceId}
    >
      {!isDeviceExpanded ? (
        <div className="device-card-body">
          <div className="device-card-left-container">
            <DeviceInfo device={device} />
            <div className="paired-status">
              {device?.paired_status === "PAIRED" ||
              device?.paired_status === null ? (
                <PairedIcon />
              ) : (
                <UnpairedIcon label="Unpaired" />
              )}
            </div>
          </div>
          <StatusInfo
            device={device}
            timezone={timezone}
            connectionStatus={connectionStatus}
            lastConnectTime={device?.lastconnecttime}
            lastDisconnectTime={device?.lastdisconnecttime}
          />
          <div className="device-connection-icon-info">
            <DeviceConnectionIconInfo
              device={device}
              deviceStatus={deviceStatus}
              connectionStatus={connectionStatus}
            />
          </div>
          <ViewMoreButton
            showModal={() => showModal(device)}
            isExpanded={isExpanded}
          />
        </div>
      ) : (
        <div className="device-card-body">
          <DeviceInfo device={device} />
          <div className="device-online-status">
            <StatusInfo
              lastConnectTime={device?.lastconnecttime}
              lastDisconnectTime={device?.lastdisconnecttime}
              device={device}
              timezone={timezone}
            />
            <DeviceConnectionIconInfo
              device={device}
              deviceStatus={deviceStatus}
              connectionStatus={connectionStatus}
            />
            <ViewMoreButton
              showModal={() => showModal(device)}
              isExpanded={isExpanded}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { DeviceInfo, StatusInfo, DeviceConnectionIconInfo };

export default DeviceInventoryList;
