import React, { useEffect, useState } from "react";
import { BloodPressureIcon } from "../Common/Icons";
import "./Wellness.less";
import { Spin } from "antd";
import WellnessListItem from "./WellnessListItem";


const WellnessList = ({showViewHistoryModal, mappedData , loading})=>{

    return (
        <>
        {loading ?
            <Spin spinning={loading} style={{ margin: "70px -20px" }} > </Spin > :
            <>
                <div className="wellness-count">
                    <label> Total ({mappedData.length})</label>
                </div>
                <div className="wellness-list-container">
                    {
                        mappedData.map((item, index)=>{
                            return (
                                <WellnessListItem
                                    key={index} 
                                    showViewHistoryModal={showViewHistoryModal}
                                    title={item.title}
                                    day={item.day}
                                    timeDifference={item.timeDifference}
                                    time={item.time}
                                    data={item.data} 
                                    device={item.device.title}
                                    deviceBrand={item.device.brand}
                                    deviceModel={item.device.model} 
                                    deviceuuid={item.device.uuid}
                                    eventName={item.event_name}
                                    showViewHistory={true}  />
                            )
                        })
                    }
                </div>  
            </>
        }
        </>
    )


}

export default WellnessList;