import React, { useEffect } from "react";
import { CloseIcon } from "../Common/Icons";
import { Modal, Spin, Tabs } from "antd";
import titleData from "./statistic-title.json";
import "./Wellness.less";

const { TabPane } = Tabs;

const WellnessSupportDeviceModal = ({
  showSupportDeviceModal,
  closeSupportDeviceModal,
  supportDeviceModalLoading,
  supportDeviceData,
  supportDeviceModalTitle,
  activeSupportDevicesTab,
  setActiveSupportDevicesTab,
  handelSupportDeviceModal,
  wellnessListData,
  allDeviceModels,
}) => {

  // Fetch new data when tab changes
  const handleTabChange = (key) => {
    setActiveSupportDevicesTab(key);
    handelSupportDeviceModal(key); // Fetch new data when tab changes
  };

  useEffect(() => {
    if (showSupportDeviceModal && activeSupportDevicesTab) {
      handleTabChange(activeSupportDevicesTab);
    }
  }, [showSupportDeviceModal]);

  return (
    <Modal
      open={showSupportDeviceModal}
      onOk={closeSupportDeviceModal}
      onCancel={closeSupportDeviceModal}
      footer={null}
      closable={false}
      centered
      className="wellness-modal-container"
    >
      <div className="support-device-modal-container">
        <div className="wellness-support-modal-block">
          {/* Header Section */}
          <div className="wellness-modal-header-container">
            <div className="wellness-modal-header-info">
              <div className="wellness-supported-device-header-title">
              Currently supported wellness devices
              </div>
            </div>
            <div
              className="wellness-new-close-button"
              onClick={closeSupportDeviceModal}
            >
              <CloseIcon className="close-icon" style={{height: "14px", width: "14px"}} />
            </div>
          </div>

          {/* Tabs Section */}
          <Tabs
            activeKey={activeSupportDevicesTab}
            onChange={handleTabChange}
            className="wellness-support-device-modal-custom-tab"
          >
            {Object.keys(allDeviceModels || {}).map((tabKey) => {
              return(
              <TabPane tab={titleData["device_title"][tabKey]} key={tabKey}>
                {supportDeviceModalLoading ? (
                  <Spin
                    spinning={supportDeviceModalLoading}
                    style={{ marginTop: "190px", width:"1084px" }}
                  />
                ) : (
                  <div className="wellness-support-modal-custom-table">
                    <div className="wellness-support-modal-table-body-block">
                      <div className="wellness-support-modal-table-header-container">
                        <div className="wellness-support-modal-table-header-block">
                          <div className="wellness-support-modal-table-header">
                            <span className="wellness-support-modal-header-title">
                              Manufacturer
                            </span>
                          </div>
                          <div className="wellness-support-modal-table-header">
                            <span className="wellness-support-modal-header-title">
                              Model
                            </span>
                          </div>
                          {/* <div className="wellness-support-modal-table-header">
                            <span className="wellness-support-modal-header-title">
                              Country
                            </span>
                          </div> */}
                        </div>
                      </div>
                      <div className="wellness-support-modal-table-body">
                        {supportDeviceData.map((item, index) => (
                          <div  className="wellness-support-modal-table-row">
                          <div
                            className="wellness-support-modal-table-row-content"
                            key={index}
                          >
                            <span className="wellness-support-modal-table-cell">
                              {item?.manufacturer ?? "-"}
                            </span>
                            <span className="wellness-support-modal-table-cell">
                              {item?.modelnumber ?? "-"}
                            </span>
                            {/* <span className="wellness-support-modal-table-cell">
                              {item?.country ?? "-"}
                            </span> */}
                          </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </TabPane>
              )
            })}
          </Tabs>
        </div>
      </div>
    </Modal>
  );
};

export default WellnessSupportDeviceModal;
