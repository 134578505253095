import React from "react";

export const WellnessViewHistoryArrowIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.6799 7.38972L12.284 11.9938L7.6799 16.6099C7.15508 17.2182 7.15508 18.1247 7.71569 18.7092C8.324 19.3533 9.34979 19.3652 9.97004 18.733L16.7092 11.9938L9.98197 5.26657C9.34979 4.6344 8.33593 4.64633 7.71569 5.29043C7.15508 5.87489 7.15508 6.7814 7.6799 7.38972Z"
        fill="#4E758E"
      />
    </svg>
  );
};
