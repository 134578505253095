import { useState, useMemo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { objectSort } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import useStaffList from "../../Hooks/useStaffList";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";

const RequiredFields = {};
const DefaultValues = {
  sourcerelationship: "",
  targetrelationship: "",
};

export const useAssignStaffHandler = (record, onActionClick) => {

  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [role, setRole] = useState("");
  const [formData, setFormData] = useState(DefaultValues);
  const [isValid, setIsValid] = useState(false);
  const [processing, setProcessing] = useState(false);
  const trans = useSelector(translator);
  const currentUser = useSelector(getCurrentUser);
  const userApi = useSelector((state) => state.apis.userApi);
  const [searchNameText, setSearchNameText] = useState(null);
  const [searchNameApplied, setSearchNameApplied] = useState(false);

  // Fetch staff list
  const [staffList, fetchingStaffList] = useStaffList({
    useruuid: currentUser?.useruuid,
  });

  const remainStaffList = useMemo(() => staffList ?? [], [staffList]);
  const mOrderedStaffList = useMemo(
    () => [...remainStaffList].sort(objectSort("firstname")),
    [remainStaffList]
  );

  useEffect(() => {
    validate(false);
  }, [selectedStaff, formData]);

  const handleAddStaff = (staff) => {
    setSelectedStaff(staff);
  };

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = (showError = true) => {
    let valid = true;
    let error = "";
    let erroredFields = [];

    if (!selectedStaff) {
      error = "Please select a staff member to assign.";
      valid = false;
    }
    for (let field of Object.keys(RequiredFields)) {
      if (!formData[field]) {
        erroredFields.push(RequiredFields[field]);
        valid = false;
      }
    }
    if (erroredFields.length) {
      error += ` Please fill required fields: ${erroredFields.join(", ")}.`;
    }
    if (showError && !valid) {
      message.error(error, 10);
    }
    setIsValid(valid);
    return valid;
  };
  const handleCancel = () => {
    setSelectedStaff(null);
    setFormData(DefaultValues);
    onActionClick(null, null, true)();
  };

  const handleSubmit = () => {
    if (!validate()) return;

    setProcessing(true);
    const userObj = {
      ...formData,
      email: selectedStaff.email,
      rolename: role,
    };

    userApi.inviteContactsToUser(
      record?.useruuid,
      userObj,
      apiCallback({
        translator: trans,
        successCallback: () => {
          setProcessing(false);
          message.success("Staff assigned successfully!");
          onActionClick(null, null, false, true)();
        },
        errorCallback: (error) => {
          setProcessing(false);
          message.error("Error assigning staff");
          console.error("Error assigning staff", error);
        },
      })
    );
  };

  const handleAssignStaff = () => {
    handleSubmit();
    setIsSecondModalOpen(false); // second modal closes
    handleCloseFirstModal(); // first modal closes
  };

  const handleOpenSecondModal = () => {
    setIsSecondModalOpen(true);
    setRole("");
    setIsFirstModalOpen(false);
  };
  const handleCloseSecondModal = () => {
    setFormData(DefaultValues);
    setRole("");
    setIsSecondModalOpen(false);
    setIsFirstModalOpen(false);
  };

  const handleRoleSelection = useCallback((role) => {
    setRole(role);
  }, []);

  const openAssignStaffModal = () => {
    setSelectedStaff(null);
    setRole("");
    setIsFirstModalOpen(true);
  };
  const handleCloseFirstModal = () => {
    setIsFirstModalOpen(false);
    setSelectedStaff(null);
    setSearchNameApplied(false);
    setSearchNameText(null);
  };

  return {
    isFirstModalOpen,
    openAssignStaffModal,
    handleCloseFirstModal,
    selectedStaff,
    handleAddStaff,
    mOrderedStaffList,
    fetchingStaffList,
    role,
    handleRoleSelection,
    handleInputChange,
    formData,
    handleAssignStaff,
    handleOpenSecondModal,
    handleCloseSecondModal,
    processing,
    isValid,
    handleCancel,
    isSecondModalOpen,
    setSelectedStaff,
    setSearchNameApplied,
    searchNameApplied,
    setSearchNameText,
    searchNameText,
  };
};
