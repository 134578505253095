import React from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";

const TaskModal = ({ showModal, children, closeModalHandler }) => {
  // Initialize the state based on the showModal prop
  return (
    <Modal
      open={showModal}
      onOk={closeModalHandler}
      onCancel={closeModalHandler}
      footer={null}
      closable={false}
      centered
      className="task-modal-container"
    >
      <div className="modal-container">
        {children}
      </div>
    </Modal>
  );
};

TaskModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  children: PropTypes.node,
  closeModalHandler: PropTypes.func.isRequired,
};

export default TaskModal;
