import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import {
  ResendInviteDisabled,
  VideoCallStatus,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { ExclamationCircleTwoTone, RedoOutlined } from "@ant-design/icons";
import { CloseIcon, ContactIcon, OfflineIcon } from "../Common/Icons";
import ContactSelectList from "../Common/ContactSelectList";
import CustomButton from "../Common/CustomButton";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { getUsername, objectSort } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { mergeArrays } from "@commscopemycloud/humaui/Utilities/JsonUtilities";

const getHubContact = (hubRecord, trans) => {
  return {
    ...hubRecord,
    ...(hubRecord.deviceconnectstatus !== "CONNECTED"
      ? {
          addIcon: (
            <Tooltip title={trans("DEVICEOFFLINECANTCALLMSG")}>
              <div>
                <OfflineIcon className="device-offline-icon" disabled={true} />
              </div>
            </Tooltip>
          ),
          disabled: true,
        }
      : {}),
  };
};

const InviteParticipantsComponent = ({
  contacts,
  fetchingContacts,
  hubContacts = [],
  fetchingHubContacts,
  sendInvite,
}) => {
  const trans = useSelector(translator);
  const invites = useSelector((state) => state.videoCall.invites);
  const inviteContacts = useSelector((state) => state.videoCall.inviteContacts);
  const { hubRecord } = inviteContacts ?? {};
  const username = useMemo(() => hubRecord ? getUsername(hubRecord) : "", [hubRecord]);

  const userContacts = useMemo(() => {
    const inviteeMapper = invites.reduce((result, inv) => {
      result[inv.useruuid] = inv;
      return result;
    }, {});
    const allContacts = mergeArrays(
      contacts,
      hubContacts,
      "useruuid",
      "useruuid"
    );
    const filteredContacts = allContacts.filter(
      (c) =>
        !inviteeMapper[c.useruuid] &&
        c.emailVerified === true &&
        c.useruuid !== hubRecord?.useruuid
    );
    const hubContact =
      hubRecord && !inviteeMapper[hubRecord.useruuid]
        ? getHubContact(hubRecord, trans)
        : null;
    const fullList = filteredContacts?.concat(hubContact ?? []);
    const sortedList = fullList.sort(objectSort("firstname"));
    return sortedList;
  }, [invites, hubRecord, contacts, hubContacts]);

  const [showAddContacts, setShowAddContacts] = useState(false);

  return (
    <div className="invite-participants-wrapper">
      <ContactSelectList
         style={{ width: '100%' }}
        className="invite-contact-list"
        fetchingContacts={fetchingContacts || fetchingHubContacts}
        displayOnlySelected={!showAddContacts}
        selectedListHeader=""
        selectionListHeader={
          <div className="flex-just-space-between flex-align-center">
            <span>{trans("ADDCONTACTS")}</span>
            <CloseIcon
              className="close-icon clickable-item"
              onClick={() => setShowAddContacts(false)}
            />
          </div>
        }
        selectedContactList={invites}
        selectedUser={username}
        selectionContactList={userContacts}
        showRemoveIcon={false}
        onAddClick={(u) => sendInvite([u])}
        selectedContactProps={{
          renderDetails: [
            (item) => (
              <span
                key={"contact_actions_" + item.useruuid}
                className="status flex-align-center"
              >
                {item.status || " "}
                {item.status === VideoCallStatus.failed && (
                  <Tooltip title={item.error}>
                    <ExclamationCircleTwoTone
                      twoToneColor="red"
                      style={{ marginLeft: "5px" }}
                    />
                  </Tooltip>
                )}
                {!ResendInviteDisabled.includes(item.status) && (
                  <Tooltip title={trans("RESENDINVITE")}>
                    <RedoOutlined
                      style={{ marginLeft: "5px" }}
                      onClick={() => sendInvite([item])}
                    />
                  </Tooltip>
                )}
              </span>
            ),
          ],
        }}
      />
      {!showAddContacts && (
        <div className="invite-contact-button-container">
          <CustomButton
            label={trans("ADDCONTACTS_U")}
            icon={<ContactIcon />}
            onClick={() => setShowAddContacts(true)}
          />
        </div>
      )}
    </div>
  );
};

InviteParticipantsComponent.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object),
  fetchingContacts: PropTypes.bool,
  hubContacts: PropTypes.arrayOf(PropTypes.object),
  fetchingHubContacts: PropTypes.bool,
  sendInvite: PropTypes.func,
};

export default InviteParticipantsComponent;
