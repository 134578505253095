import React from "react";

export const AttachmentLinkIcon = (props) => {
  const { className = "", style = {}, onClick, disabled = false } = props;
  return (
    <svg
      width="18"
      height="9"
      viewBox="0 0 18 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={` ${disabled ? "disabled" : ""} ${className}`}
      onClick={disabled ? null : onClick}
      style={style}
    >
      <path
        d="M5.94329 8.5H4.14752C2.18767 8.5 0.601562 6.86449 0.601562 4.86449V4.13551C0.601562 2.13551 2.19679 0.5 4.14752 0.5H5.94329C6.39907 0.5 6.76369 0.873832 6.76369 1.34112C6.76369 1.80841 6.39907 2.18224 5.94329 2.18224H4.14752C3.09923 2.18224 2.24237 3.06075 2.24237 4.13551V4.86449C2.24237 5.93925 3.09923 6.81776 4.14752 6.81776H5.94329C6.39907 6.81776 6.76369 7.19159 6.76369 7.65888C6.76369 8.12617 6.39907 8.5 5.94329 8.5ZM17.4016 4.86449V4.13551C17.4016 2.12617 15.8063 0.5 13.8556 0.5H11.9596C11.5038 0.5 11.1392 0.873832 11.1392 1.34112C11.1392 1.80841 11.5038 2.18224 11.9596 2.18224H13.8556C14.9039 2.18224 15.7608 3.06075 15.7608 4.13551V4.86449C15.7608 5.93925 14.9039 6.81776 13.8556 6.81776H11.9596C11.5038 6.81776 11.1392 7.19159 11.1392 7.65888C11.1392 8.12617 11.5038 8.5 11.9596 8.5H13.8556C15.8155 8.5 17.4016 6.86449 17.4016 4.86449ZM13.0352 4.5C13.0352 4.03271 12.6706 3.65888 12.2148 3.65888H5.79744C5.34166 3.65888 4.97704 4.03271 4.97704 4.5C4.97704 4.96729 5.34166 5.34112 5.79744 5.34112H12.2148C12.6706 5.34112 13.0352 4.96729 13.0352 4.5Z"
        fill="#005D98"
      />
    </svg>
  );
};
