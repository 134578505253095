import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Radio } from "antd";
const style = {
  display: "flex",
  flexDirection: "column",
  gap: 8,
};
const RecurringTaskModal = ({
  isModalOpen = true,
  onConfirm = () => {},
  onCancel = () => {},
  modalHeader="Delete periodic task"
}) => {
 
  const [value, setValue] = useState(3);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <div className="">
      <Modal
        title={modalHeader}
        centered
        open={isModalOpen}
        footer={null}
        closable={false}
        className="task-modal-conatiner"
      >
        <div className="taskmodal">
          <div className="taskmodaldescription" style={{ fontWeight : "400"}}>
            <Radio.Group
              style={style}
              onChange={onChange}
              value={value}
              options={[
                {
                  value: 1,
                  label: "This occurrence only",
                  disabled: true,
                },
                {
                  value: 2,
                  label: "This and all future occurrences",
                  disabled: true,
                },
                {
                  value: 3,
                  label: "All occurrences",
                },
              ]}
            />
          </div>
          <div className="taskmodalbutton">
            <Button className="custom-no-button" onClick={onCancel}>
              <span className="custom-no-label">Cancel</span>
            </Button>
            <Button className="custom-yes-button" onClick={onConfirm}>
              <span className="custom-yes-label">Ok</span>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

RecurringTaskModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default RecurringTaskModal;
