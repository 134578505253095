import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { getDateFormatISO } from "@commscopemycloud/humaui/Utilities/DateTime";
import { message } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const useSchedule = (selectedTab = "all") => {
  const scheduleApi = useSelector((state) => state.apis.scheduleApi);
  const [scheduleList, setScheduleList] = useState([]);
  const [fetchingScheduleList, setFetchingScheduleList] = useState(false);

  const fetchSchedule = (useruuid, endDate, plusDays = 0) => {
    const errorCallback = (error) => {
      setFetchingScheduleList(false);
      message.error("Error fetching Events");
      console.error("Error fetching Events:", error);
    };
    const successCallback = (data) => {
      setFetchingScheduleList(false);
      console.info("Schedule: fetch result:", data);
      setScheduleList(data.schedule_events);
    };

    const EventType = selectedTab === "call"? "all" : selectedTab;
    const opts = { timezone: LocalTimezone };
    try {
      setFetchingScheduleList(true);
      const end = endDate ? new Date(endDate) : new Date();
      const endDateTime = getDateFormatISO(end, "23:59:00.000Z");
      const start = new Date(end.setDate(end.getDate() - plusDays));
      const startDateTime = getDateFormatISO(start, "00:00:00.000Z");
      console.log('plusDays',plusDays);
      console.log('startDateTime',startDateTime);
      console.log('endDateTime',endDateTime);
      scheduleApi.eventsByEventTypesTimeRange(
        useruuid,
        startDateTime,
        endDateTime,
        EventType,
        opts,
        apiCallback({
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  return [fetchingScheduleList, scheduleList, fetchSchedule];
};

export default useSchedule;
