/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import dayjs from "dayjs";
import MainPage from "../../MainPage";
import { Form, Input, TimePicker, message, Select, Spin } from "antd";
import {
  Actions,
  DateFormat,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { SingleDatePicker, HSDayPicker } from "../Common/HSWidget";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { ButtonTypes } from "../Common/CustomButton";
import { StringFormats } from "@commscopemycloud/humaui/Utilities/Constants";
import { formatString } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import {
  CloseIcon,
  AddTaskIcon,
  NewCloseIcon,
  NewUpdateIcon,
  SaveChangesIcon,
  DeleteIcon,
} from "../Common/Icons";
import ContactSelectList from "../Common/ContactSelectList";
import CustomDateRangePicker from "../Common/CustomDateRangePicker";
import NewCustomButton from "../Common/NewCustomButton";
import NonRecurringTaskModal from "./NonRecurringTaskModal";
import RecurringTaskModal from "./RecurringTaskModal"; 
 
const currentSiteIconZip = state => state.data.siteIconFiles;
const currentSiteIconManifest = state => state.data.siteIconManifest; 
 
export const TaskForm = (props) => {
  const {
    onlyContent,
    action,
    hubRecord,
    userInfo,
    taskRecord,
    onActionClick,
    openSubMenu = false,
    showHeader = false,
    loginUserTimeformat,
    eventRecord,
  } = props;
  const [form] = Form.useForm();
  const trans = useSelector(translator);
  const taskApi = useSelector((state) => state.apis.taskApi);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const siteIconZip = useSelector(currentSiteIconZip);
  const siteIconManifest = useSelector(currentSiteIconManifest);
 
  useEffect(() => {
    async function buildCategoryOptions() {
      if (!siteIconZip || !siteIconManifest || typeof siteIconZip.file !== "function") {
        console.error('Invalid Zip file.')
        return;
      }
      const options = [];
      for (const mainCat of Object.keys(siteIconManifest)) {
        const subcats = siteIconManifest[mainCat];
        for (const subcat of Object.keys(subcats)) {
          const fileName = subcats[subcat];
          const file = siteIconZip.file(fileName);
          if (!file) {
            console.error(`File ${fileName} not found in zip for subcategory "${subcat}"`);
            continue;
          }
          try {
            const blob = await file.async("blob");
            let blobToUse = blob;
            if (!blob.type || blob.type === "") {
              blobToUse = new Blob([blob], { type: "image/svg+xml" });
            }
            const url = URL.createObjectURL(blobToUse);
            const subcatDisplay = formatString(subcat,StringFormats.sentence);
            const mainCatDisplay = formatString(mainCat,StringFormats.sentence);
            options.push({
              value: `${subcat}`,
              label: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={url}
                      alt={subcatDisplay}
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 8,
                        verticalAlign: "middle",
                      }}
                    />
                    <span style={{ fontSize: "16px", fontWeight: 500 }}>
                      {subcatDisplay}
                    </span>
                  </span>
                  <span style={{ fontSize: "14px", color: "#666", marginLeft: 32 }}>
                    {mainCatDisplay}
                  </span>
                </div>
              ),
              displayText: subcatDisplay,
            });
          } catch (error) {
            console.error(`Error loading file ${fileName} for subcategory "${subcat}":`, error);
          }
        }
      }
      options.sort((a, b) => a.displayText.localeCompare(b.displayText));
      setCategoryOptions(options);
    }
    buildCategoryOptions();
  }, [siteIconZip, siteIconManifest]);
 
  const TaskCategories = {
    medication: "Medication",
    exercise: "Exercise",
    diet: "Diet",
  };
 
  const SortedCategories = Object.keys(TaskCategories)
    .map((key) => ({ value: key, label: TaskCategories[key] }));
 
  const ReminderDuration = [5, 10, 15, 30, 60];
 
  const labelMapping = {
    5: "5 minutes",
    10: "10 minutes",
    15: "15 minutes",
    30: "30 minutes",
    60: "1 hour",
  };
 
  const FormattedReminderDuration = ReminderDuration.map((d) => ({
    value: d,
    label: labelMapping[d] || `${d} minutes`, // Use default if the value isn't in the mapping
  }));
 
  const formatDateTime = (dateMomentObj, hours = 0, minutes = 0) => {
    return moment
      .utc()
      .year(dateMomentObj.year())
      .month(dateMomentObj.month())
      .date(dateMomentObj.date())
      .hours(hours)
      .minutes(minutes)
      .seconds(0)
      .milliseconds(0)
      .format();
  };
 
  const FormItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
 
  const isEditForm = useMemo(() => action === Actions.editTask, [action]);
  const isTaskRecurring = useMemo(() => {  // Determine if the task is recurring
    return eventRecord?.repeat && ["daily", "custom", "weekly"].includes(eventRecord?.repeat);
  }, [eventRecord]);
  const [checkedDays, setCheckedDays] = useState(
    isEditForm ? eventRecord?.days_of_week : []
  );
  const TaskRepeats = useMemo(() => {
    return {
      none: "Doesn't repeat",
      daily: "Daily",
      weekly: `Weekly ${checkedDays?.length ? `(${checkedDays[0].charAt(0).toUpperCase() + checkedDays[0].slice(1).toLowerCase()})` : ""}`,
      custom: "Customize",
    };
  }, [checkedDays]);
 
  const SortedRepeats = Object.keys(TaskRepeats)
    .map((key) => ({ value: key, label: TaskRepeats[key] }));
 
  const [processing, setProcessing] = useState(false);
  const [isReoccuring, setIsRecouring] = useState(
    taskRecord?.recurring || false
  );
  const [selectedDate, setSelectedDate] = useState(new Date());
  // Function to convert UTC datetime to user's timezone and extract needed parts
  function convertToUserTimezone(dateString, timeString, timezone, extract) {
    console.log('dateString-->119', dateString);
    // Ensure date is in the correct format
    const datePartOnly = dateString?.split("T")[0];
    const utcDateTimeString = `${datePartOnly}T${timeString}Z`;
 
    const utcDateTime = new Date(utcDateTimeString);
 
    if (isNaN(utcDateTime)) {
      console.error("Invalid Date-Time:", datePartOnly, timeString);
      return "";
    }
    // Convert to user's timezone using Day.js
    const userDateTime = dayjs.utc(utcDateTime).tz(timezone);
 
    // Format date and time
    const formattedDate = userDateTime.format("YYYY-MM-DD");
    const formattedTime = userDateTime.format("HH:mm:ss");
 
    return extract === "date" ? formattedDate : extract === "time" ? formattedTime : { date: formattedDate, time: formattedTime };
  }
 
  // Function to revert UTC to user's timezone
  function revertUTCtoUserTimezone(eventRecord, userTimezone) {
    console.log('revertUTCtoUserTimezone-->142', eventRecord)
    const startDateTime = convertToUserTimezone(eventRecord?.event_startdate, eventRecord?.event_starttime, userTimezone, "full");
    const endDate = convertToUserTimezone(eventRecord?.event_enddate, eventRecord?.event_starttime, userTimezone, "date");
    const endTime = convertToUserTimezone(eventRecord?.event_startdate, eventRecord?.event_endtime, userTimezone, "time");
 
    return {
      startDate: startDateTime.date,
      startTime: startDateTime.time,
      endDate: endDate,
      endTime: endTime
    };
  }
 
  // Convert UTC to User Timezone
  const localEvent = isEditForm ? revertUTCtoUserTimezone(eventRecord, LocalTimezone) : null;
 
  const [formData, setFormData] = useState({
    reminder: isEditForm && eventRecord?.reminder ? eventRecord.reminder / 60 : 30,
    category: isEditForm ? eventRecord?.category : '--Choose Category--',
    repeat: isEditForm ? eventRecord?.recurring ? eventRecord?.repeat : 'none' : TaskRepeats.none,
    event_name: isEditForm ? eventRecord?.event_name : "",
    event_description: isEditForm ? eventRecord?.event_description : "",
    starttime: isEditForm && localEvent
      ? dayjs(localEvent.startTime, "HH:mm:ss").tz(LocalTimezone)
      : null,
    endtime: isEditForm && localEvent
      ? dayjs(localEvent.endTime, "HH:mm:ss").tz(LocalTimezone)
      : null,
  });
  const [activeTab, setActiveTab] = useState("schedule");
  const [selectedRangeDates, setSelectedRangeDates] = useState(() => {
    console.log(' ', localEvent);
    if (isEditForm && localEvent?.startDate) {
      if (localEvent?.endDate) {
        return [
          dayjs(localEvent.startDate, "YYYY-MM-DD").tz(LocalTimezone).startOf("day"),
          dayjs(localEvent.endDate, "YYYY-MM-DD").tz(LocalTimezone).endOf("day"),
        ];
      } else {
        // One-time task: Only start date, no end date
        return [
          dayjs(localEvent.startDate, "YYYY-MM-DD").tz(LocalTimezone).startOf("day"),
          dayjs(localEvent.startDate, "YYYY-MM-DD").tz(LocalTimezone).endOf("day")
 
        ];
      }
    }
    return [];
  });
 
  // Track initial form data (to compare changes)
  const [initialFormData] = useState(formData);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const handleDeleteClick = () => {
    setIsDeleteModalVisible(true); // Show the delete confirmation modal
  };
 
  const handleModalConfirm = () => {
    setIsDeleteModalVisible(false); // Close the modal
    deleteTasks(); // Call deleteTasks when the user confirms
  };
 
  const handleModalCancel = () => {
    setIsDeleteModalVisible(false); // Simply close the modal
  };
  const deleteTasks = () => {
    setProcessing(false);
    const errorCallback = (error) => {
      setProcessing(false);
      message.error("Error deleting Event!");
      console.error("Error deleting Event:", error);
    };
    const successCallback = (data) => {
      setProcessing(false);
      message.success("Event deleted successfully!");
      console.info("Event deleted successfully:", data);
      onActionClick(null, null, activeTab, true)();
    };
    try {
      setProcessing(true);
 
      if (isTaskRecurring) {
        taskApi.cancelAllRecurringTasks(
          eventRecord?.useruuid,
          eventRecord?.schedule_eventuuid,
          apiCallback({
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        );
      } else {
        taskApi.cancelTask(
          eventRecord?.useruuid,
          eventRecord?.schedule_eventuuid,
          apiCallback({
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        );
      }
    } catch (error) {
      errorCallback(error);
    }
  };
 
  const handleTabChange = (key) => {
    setActiveTab(key);
    onActionClick(null, null, key)();
  };
 
  /* Function to get UTC offset abbreviation for a time zone */
  const get_utc_offset = (timeZone) => {
    return Intl.DateTimeFormat(navigator.language, {
      timeZoneName: "longOffset",
      timeZone,
    })
      .formatToParts()
      .find((i) => i.type === "timeZoneName").value;
  };
  const timezonedisplay = LocalTimezone + ` (${get_utc_offset(LocalTimezone)})`;
 
  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  };
 
  const handleDateChange = (dates) => {
    setSelectedRangeDates(dates); // Update the state with selected dates
  };
 
  // useEffect(() => {
  //   if (formData.repeat !== TaskRepeats.none && formData.repeat !== "none" ) {
  //     setSelectedRangeDates([]); // Clear date range when repeat changes
  //     form.setFieldsValue({ date: [] }); // Clear form field for date
  //   }
  // }, [formData.repeat]);
 
  useEffect(() => {
    console.log('event_startdate', eventRecord.event_startdate);
    if (isEditForm)
      setSelectedDate(moment(eventRecord.event_startdate))
  }, [eventRecord])
 
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    form.setFieldsValue({ startDate: date }); // Update Ant Design Form state
  };
 
  const disablePreviousDates = (current) => { // Disable dates before today    
    return current && current.isBefore(dayjs().startOf('day'), 'day');
  };
 
  const renderDatePicker = () => {
    if (formData.repeat === TaskRepeats.none || formData.repeat === "none") {
      // Show SingleDatePicker for single date
      return (
        <Form.Item label={"Date*"} name="date">
          <SingleDatePicker
            DateFormat={DateFormat}
            timeZone={LocalTimezone}
            defaultValue={selectedDate}
            onDateSelect={handleDateSelect}
            disabledDate={disablePreviousDates}
            className="onetimetask"
          />
        </Form.Item>
      );
    } else {
      // Show CustomRangePicker for date range
      return (
        <Form.Item label={"Date*"} name="date" initialValues={{ date: [] }}>
          <CustomDateRangePicker
            onDateChange={handleDateChange}
            selectedDates={selectedRangeDates}
            disabled={isEditForm}
          // repeatValue={formData?.repeat}
          />
        </Form.Item>
      );
    }
  };
 

  useEffect(() => {
    const { event_name, starttime, endtime, repeat } = formData;
 
    // Normalize the repeat value for validation purposes
    const normalizedRepeat = repeat === "Doesn't repeat" ? "none" : repeat || "none";
 
    // Ensure required fields are filled
    const areRequiredFieldsFilled = !!(
      event_name && starttime && endtime
    );
 
    // Validate repeat value
    const isRepeatValid =
      normalizedRepeat === "weekly"
        ? checkedDays?.length === 1 && selectedRangeDates?.length === 2
        : normalizedRepeat === "custom"
          ? checkedDays?.length > 0 && selectedRangeDates?.length === 2
          : normalizedRepeat === "daily"
            ? selectedRangeDates?.length === 2
            : true; // Always valid for "none" or "doesn't repeat"
    setIsCreateButtonDisabled(!(areRequiredFieldsFilled && isRepeatValid));
  }, [formData, selectedRangeDates, checkedDays]);
 
  useEffect(() => {
    // Check if any individual field has changed
    const isFormChanged = Object.keys(formData).some((key) => {
      if ((isEditForm && selectedDate != eventRecord.event_startdate)) {
        return true;
      }
      console.log("formData-->>349, exit");
 
      if (key === "starttime" || key === "endtime") {
        // Special handling for starttime and endtime (Date objects)
        return !dayjs(formData[key]).isSame(dayjs(initialFormData[key]), 'minute');
      } else {
        // For other fields, compare directly
        return formData[key] !== initialFormData[key];
      }
    });
    // Update button state based on changes
    setIsSaveButtonEnabled(isFormChanged);
  }, [formData, initialFormData, selectedDate]);
 
 
  const handleValueChange = (name, value) => {
    console.log('name',name);
    console.log('value',value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
 
  const handleOnDayChange = useCallback((days) => {
    setCheckedDays((prev) => {
      if (
        prev.length === days.length &&
        prev.every((val, idx) => val === days[idx])
      ) {
        return prev;
      }
      return days;
    });
  }, []);
 
  const handleSendClick = () => {
    form
      .validateFields()
      .then(() => onFormSubmit())
      .catch((error) => {
        console.error('Form Field Error', error);
        message.error("Please fill in all required fields")
      });
  };
 
  const onFormSubmit = () => {
    const errorCallback = (error) => {
      setProcessing(false);
      const msg = `Error ${isEditForm ? "Updating" : "Creating"} Task!`;
      message.error(msg);
      console.error(msg, error);
    };
    const successCallback = (data) => {
      setProcessing(false);
      const msg = `Task ${isEditForm ? "Updated" : "Created"} Successfully!`;
      message.success(msg);
      console.info(msg, data);
      onActionClick(null, null, true)();
    };
    try {
      setProcessing(true);
      const userUuid = hubRecord.useruuid;
      // Convert selectedRangeDates to YYYY-MM-DD format
      const formattedDates = selectedRangeDates.map((date) =>
        moment(date.$d).format("YYYY-MM-DD")
      );
      // Convert start and end times to 24-hour format
      const startTime24 = dayjs(formData.starttime.$d).format("HH:mm");
      const endTime24 = dayjs(formData.endtime.$d).format("HH:mm");
 
      const opts = {
        createTask: {
          contacts: [{ useruuid: userUuid }],
          event_name: formData.event_name,
          event_description: formData.event_description,
          category: formData.category,
          ...((formData.repeat && formData.repeat !== TaskRepeats.none && formData.repeat !== "none") && {
            repeat: formData.repeat,
            days_of_week: checkedDays, // Only for repeating tasks
            event_enddate: formattedDates[1], // Include only for repeating tasks
            event_startdate: formattedDates[0],
          }),
          timezone: timezonedisplay,
          reminder: formData.reminder > 0 ? formData.reminder * 60 : formData.reminder, // Convert minutes to seconds
          snoozable: formData.reminder > 0 ? true : false,
          event_startdate: selectedDate ? dayjs(selectedDate).format("YYYY-MM-DD") : null,
          event_starttime: startTime24,
          event_endtime: endTime24,
        },
      };
 
      isEditForm
        ? taskApi.updateTask(
          userUuid,
          eventRecord?.schedule_eventuuid,
          opts,
          apiCallback({
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        )
        : taskApi.createTask(
          userUuid,
          opts,
          apiCallback({
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        );
    } catch (error) {
      errorCallback(error);
    }
  };
  const renderDeleteModal = () => {
    if (!isDeleteModalVisible) return null;
    return isTaskRecurring ? (
      <RecurringTaskModal
        isModalOpen={isDeleteModalVisible}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
      />
    ) : (
      <NonRecurringTaskModal
        isModalOpen={isDeleteModalVisible}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
        showDeleteMsg={true}
      />
    );
  };
  const render = (
    <div className="taskform-container">
      <Spin size="large" spinning={processing} tip={trans("PROCESSING")}>
        {renderDeleteModal()}
        <Form
          form={form}
          initialValues={formData}
          {...FormItemLayout}
          className="taskform-container"
        >
          <div className="taskform-card">
            <div className="task-title">
              <div className="form-title">
                <AddTaskIcon />
                <span>{isEditForm ? "Edit task" : "Create task"}</span>
              </div>
              <div>
                <CloseIcon
                  style={{ height: "20px", width: "20px", cursor: "pointer" }}
                  onClick={onActionClick(null, null)}
                />
              </div>
            </div>
            <div className="form-items-container">
              <div className="column-1">
                <span className="general-info-text">Task Informations</span>
                <div className="form-group">
                  <Form.Item
                    name="category"
                    label="Category*"
                    className="form-group-select"
                  >
                    {/* <Select
                      onChange={(e) => handleValueChange("category", e)}
                      defaultValue={defaultValue={formData.category}}
                      value={formData.category}
                      name="category"
                      className="select-type"
                      options={categoryOptions}
                    /> */}
                    <Select
                      className="select-type"
                      options={categoryOptions}
                      onChange={(e) => handleValueChange("category", e)}
                      value={formData.category}
                      // Optionally set a default value, e.g.:
                      defaultValue={formData.category }
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item
                    label="Name*"
                    name="event_name"
                  >
                    <Input
                      placeholder="Task name"
                      name="event_name"
                      onChange={handleInputChange}
                      defaultValue={formData.event_name}
                    // maxLength={45}
                    />
                  </Form.Item>
                </div>
                <div className="form-row">
                  <Form.Item
                    name="repeat"
                    className="form-group-select"
                    label={"Repeats*"}
                  >
                    <Select
                      onChange={(e) => handleValueChange("repeat", e)}
                      defaultValue={TaskRepeats.none}
                      value={formData.repeat}
                      name="repeat"
                      className="select-type"
                      options={SortedRepeats}
                      disabled={isEditForm}
                    />
                  </Form.Item>
                </div>
                {formData.repeat === "custom" && (
                  <div className="form-row flex-space-between">
                    <div
                      className="form-group flex-row"
                      style={{
                        gap: "16px",
                        alignItems: "baseline",
                        marginBottom: "-30px",
                      }}
                    >
                      <Form.Item name="days_of_week">
                        <HSDayPicker
                          onDayChange={handleOnDayChange}
                          name="days_of_week"
                          days={checkedDays}
                          allowMultiple={true} // Allow multiple selections for custom
                          disabled={isEditForm}
                          repeatKey={formData.repeat}
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
                {formData.repeat === "weekly" && (
                  <div className="form-row flex-space-between">
                    <div
                      className="form-group flex-row"
                      style={{
                        gap: "16px",
                        alignItems: "baseline",
                        marginBottom: "-30px",
                      }}
                    >
                      <Form.Item name="days_of_week">
                        <HSDayPicker
                          onDayChange={handleOnDayChange}
                          name="days_of_week"
                          days={checkedDays}
                          allowMultiple={false} // Restrict to single selection for weekly
                          disabled={isEditForm}
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
                {renderDatePicker()}
                <div className="form-group" style={{ display: "flex", marginTop: "0px", gap: "30px" }}>
                  <Form.Item
                    label={"Start time*"}
                    name="starttime"
                    rules={[
                      {
                        type: "object",
                        message: trans("PLEASESELECTTIME"),
                      },
                    ]}
                  >
                    <TimePicker
                      name="starttime"
                      allowClear={false}
                      required={true}
                      format={loginUserTimeformat?.is24hoursformat ? "HH:mm" : "h:mm a"}
                      size="large"
                      onChange={(value) =>
                        handleValueChange("starttime", value)
                      }
                      minuteStep={15}
                      disabled={isEditForm && isTaskRecurring}
                      suffixIcon={
                        <span style={{ color: isEditForm && isTaskRecurring ? "rgba(0, 0, 0, 0.25)" : "black" }}>
                          {timezonedisplay}
                        </span>
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label={"End time*"}
                    name="endtime"
                    rules={[
                      {
                        type: "object",
                        message: trans("PLEASESELECTTIME"),
                      },
                    ]}
                  >
                    <TimePicker
                      name="endtime"
                      allowClear={false}
                      required={true}
                      format={loginUserTimeformat?.is24hoursformat ? "HH:mm" : "h:mm a"}
                      size="large"
                      onChange={(value) => handleValueChange("endtime", value)}
                      minuteStep={15}
                      disabled={isEditForm && isTaskRecurring}
                      suffixIcon={
                        <span style={{ color: isEditForm && isTaskRecurring ? "rgba(0, 0, 0, 0.25)" : "black" }}>
                          {timezonedisplay}
                        </span>
                      }
                    />
                  </Form.Item>
                </div>
                <div className="form-row ">
                  <Form.Item
                    name="reminder"
                    className="form-group-select"
                    label={"Time between reminders*"}
                  >
                    <Select
                      onChange={(e) => handleValueChange("reminder", e)}
                      value={formData.reminder}
                      name="reminder"
                      className="select-type"
                      options={FormattedReminderDuration}
                    />
                  </Form.Item>
                  <div className="reminder-text">Applies if the user chooses to snooze an alert.</div>
                </div>
                <div className="form-group">
                  <Form.Item name="event_description" label={"Description"}>
                    <Input.TextArea
                      placeholder={trans("ADDDESCRIPTION")}
                      name="event_description"
                      onChange={handleInputChange}
                      defaultValue={formData.event_description}
                      className="form-description"
                    // maxLength={100}
                    />
                  </Form.Item>
                </div>
                <div className="manadatory-text">
                  *These fields are mandatory.
                </div>
              </div>
              <div className="column-2">
                <ContactSelectList
                  displayOnlySelected={true}
                  fetchingContacts={[]}
                  selectedContactList={[hubRecord]}
                  selectedListHeader={`${trans("PARTICIPANTS")}`}
                  selectionListHeader={trans("ADDCONTACTS")}
                  selectionContactList={[]}
                  showRemoveIcon={false}
                  showRelationship={false}
                  hubRecord={hubRecord}
                  starteventAction={false}
                />
              </div>
            </div>
            <div className="taskform-action-button-container">
              {isEditForm ? (
                <>
                  <Form.Item>
                    <NewCustomButton
                      label={"Delete Task"}
                      icon={<DeleteIcon className="delete-icon" />}
                      onClick={handleDeleteClick}
                    />
                  </Form.Item>
                  <Form.Item>
                    <NewCustomButton
                      disabled={!isSaveButtonEnabled}
                      type={ButtonTypes.primary}
                      label={"Save changes"}
                      icon={<SaveChangesIcon className={
                        !isSaveButtonEnabled
                          ? "save-icon-disabled"
                          : "save-icon-enabled"
                      } />}
                      onClick={handleSendClick}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item>
                    <NewCustomButton
                      label={trans("CANCEL")}
                      icon={<NewCloseIcon className="cancel-icon" />}
                      onClick={onActionClick(null, null)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <NewCustomButton
                      disabled={isCreateButtonDisabled}
                      type={ButtonTypes.primary}
                      label={"Create"}
                      icon={
                        <NewUpdateIcon
                          className={
                            !isCreateButtonDisabled
                              ? "update-icon-enabled"
                              : "update-icon-disabled"
                          }
                        />
                      }
                      onClick={handleSendClick}
                    />
                  </Form.Item>
                </>
              )}
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
 
  return onlyContent ? (
    render
  ) : (
    <MainPage
      hasSider={showHeader ? false : true}
      activeKey={activeTab}
      openSubMenu={openSubMenu}
      onChange={handleTabChange}
      hubUserRole={hubRecord?.rolename}
    >
      {render}
    </MainPage>
  );
};
 
TaskForm.propTypes = {
  onlyContent: PropTypes.bool,
  parentBread: PropTypes.arrayOf(PropTypes.object),
  action: PropTypes.string,
  eventRecord: PropTypes.object,
  userInfo: PropTypes.object,
  contacts: PropTypes.arrayOf(PropTypes.object),
  fetchingContacts: PropTypes.bool,
  staffList: PropTypes.arrayOf(PropTypes.object),
  fetchingStaffList: PropTypes.bool,
  onActionClick: PropTypes.func,
};
 
export default React.memo(TaskForm);
 
 