/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import ListStaff from "./ListStaff";
import { useSelector } from "react-redux";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { Actions } from "@commscopemycloud/humaui/Utilities/Constants";
import { DashboardIcon } from "../Common/Icons";
import Schedule from "../Schedule/Schedule";
import StaffForm from "./StaffForm";
import useStaffList from "../Hooks/useStaffList";
import { getStaffList } from "@commscopemycloud/humaui/Store/dataStore";
import { objectSort } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { translator } from "@commscopemycloud/humaui/Store/configStore";

const Staff = ({ parentBread }) => {
  const trans = useSelector(translator);
  const currentUser = useSelector(getCurrentUser);
  const useruuid = useMemo(() => currentUser.useruuid, [currentUser]);
  const [, fetchingStaffList, fetchStaff] = useStaffList({ useruuid });
  const staffList = useSelector(getStaffList(useruuid));

  const staffOrderedList = useMemo(() => {
    return [...staffList].sort(objectSort("firstname"));
  }, [staffList]); 
  const [action, setAction] = useState(null);
  const [record, setRecord] = useState(null);

  const onActionClick =
    (action, record, refresh = false) =>
    () => {
      setAction(action);
      setRecord(record);
      refresh && fetchStaff();
    };

  const header = (parentBread || []).concat({
    label: trans("DASHBOARD"),
    icon: <DashboardIcon />,
    onClick: onActionClick(null, null),
  });

  return !action ? (
    <ListStaff
      parentBread={header}
      staffList={staffOrderedList}
      fetchingStaffList={fetchingStaffList}
      onActionClick={onActionClick}
    />
  ) : action === Actions.addEvent ? (
    <Schedule
      activeKey={"staff"}
      action={action}
      hubRecord={record}
      parentBread={header}
      userHubCall={true}   
      onActionClick={onActionClick}
    />
  ) : action === Actions.addStaff || action === Actions.editStaff ? (
    <StaffForm
      record={record}
      action={action}
      parentBread={header}
      onActionClick={onActionClick}
      staffList={staffOrderedList}
    />
  ) : null;
};

Staff.propTypes = {
  parentBread: PropTypes.arrayOf(PropTypes.object),
};

export default Staff;
