import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Actions } from "@commscopemycloud/humaui/Utilities/Constants";
import TaskForm from "./TaskForm";
import ViewTask from "./ViewTask";
import StartEvent from "../Schedule/StartEvent";

const Task = (props) => {
  const {
    onlyContent,
    hubRecord,
    userInfo,
    onActionClick: parentActionClick,
    loginUserTimeformat,
    eventRecord,
    userHubsData,
    selectedSchedulelistDate,
    closeModalHandler,
    showModal,
    fetchSchedule,
    useruuid,
    userTimezone,
    reFetchList,
    action: propAction,
    contacts,
    staffList,
    fetchingContacts,
    fetchingStaffList,
    selectedUser = "", 
    rolename,
    openSubMenu = false, 
    currentUserDataInfo
  } = props;
console.debug("task form :", props );

  const [action, setAction] = useState(propAction);
  const [record, setRecord] = useState(null);
  const [formKey, setFormKey] = useState(Date.now());

  useEffect(() => {
    setAction(propAction);
  }, [propAction]);

  useEffect(() => {
    // When the action changes to addTask, reset the formKey so TaskForm remounts.
    if (propAction === Actions.addTask) {
      setFormKey(Date.now());
      // Optionally clear record if needed:
      setRecord(null);
    } else {
      setRecord(props.eventRecord);
    }
  }, [propAction, props.eventRecord]);

  // Use a common onActionClick if needed
  const onActionClick = (action, record, refresh = false) => () => {
    setAction(action);
    setRecord(record);
  };

  if (!action) return null;

  if (action === Actions.editTask || action === Actions.editEvent  || action === Actions.editReminderEvent) {
    return (
      <TaskForm
        key={formKey}
        onlyContent={onlyContent}
        action={action}
        hubRecord={hubRecord}
        userInfo={userInfo}
        taskRecord={record}
        onActionClick={parentActionClick || onActionClick}
        loginUserTimeformat={loginUserTimeformat}
        eventRecord={props.eventRecord}
        userHubsData={userHubsData}
        closeModalHandler={closeModalHandler}
        showModal={showModal}
        fetchSchedule={fetchSchedule}
        useruuid={useruuid}
        userTimezone={userTimezone}
        reFetchList={reFetchList}
        contacts={contacts}
        staffList={staffList}
        fetchingContacts={fetchingContacts}
        fetchingStaffList={fetchingStaffList}
        selectedUser = {selectedUser}
      />
    );
  } else if (action === Actions.addTask || action === Actions.addEvent || action === Actions.addReminderEvent) {
    return (
      <TaskForm
        key={formKey} // Changing key forces remount on every Add Task action
        onlyContent={onlyContent}
        action={action}
        hubRecord={hubRecord}
        userInfo={userInfo}
        taskRecord={record}
        onActionClick={parentActionClick || onActionClick}
        loginUserTimeformat={loginUserTimeformat}
        eventRecord={props.eventRecord}
        userHubsData={userHubsData}
        closeModalHandler={closeModalHandler}
        showModal={showModal}
        fetchSchedule={fetchSchedule}
        useruuid={useruuid}
        userTimezone={userTimezone}
        reFetchList={reFetchList}
        contacts={contacts}
        staffList={staffList}
        fetchingContacts={fetchingContacts}
        fetchingStaffList={fetchingStaffList}
        selectedUser = {selectedUser}

      />
    );
  } else if (action === Actions.viewTask) {
    return (
      <ViewTask
        onlyContent={onlyContent}
        action={action}
        hubRecord={hubRecord}
        userInfo={userInfo}
        taskRecord={record}
        onActionClick={parentActionClick || onActionClick}
        loginUserTimeformat={loginUserTimeformat}
        eventRecord={props.eventRecord}
        userHubsData={userHubsData}
        selectedSchedulelistDate={selectedSchedulelistDate}
        closeModalHandler={closeModalHandler}
        showModal={showModal}
        userTimezone={userTimezone}
        contacts={contacts}
        staffList={staffList}
        fetchingContacts={fetchingContacts}
        fetchingStaffList={fetchingStaffList}
        selectedUser = {selectedUser}

      />
    );
  } else if (action === Actions.startEvent) {
    return (
      <StartEvent
        onlyContent={action === Actions.startEvent ? true : false}
        action={action}
        // parentBread={header}
        hubRecord={hubRecord}
        onActionClick={parentActionClick || onActionClick}
        eventRecord={record}
        userHubsData={userHubsData}
        userInfo={userInfo}
        rolename={rolename}
        openSubMenu={openSubMenu}
        loginUserTimeformat={loginUserTimeformat}
        showModal={showModal}
        closeModalHandler={closeModalHandler}
        reFetchList={reFetchList}
        fetchingStaffList={fetchingStaffList}
        selectedUser={selectedUser}
        userTimezone={userTimezone}
        currentUserDataInfo={currentUserDataInfo}
      />
    );
  }
  else {
    return null;
  }
};

Task.defaultProps = {
  onlyContent: false,
};

Task.propTypes = {
  onlyContent: PropTypes.bool,
  hubRecord: PropTypes.object,
  userInfo: PropTypes.object,
  onActionClick: PropTypes.func,
  loginUserTimeformat: PropTypes.shape({
    is24hoursformat: PropTypes.bool,
  }),
  eventRecord: PropTypes.object,
  userHubsData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedSchedulelistDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  closeModalHandler: PropTypes.func,
  showModal: PropTypes.bool,
  fetchSchedule: PropTypes.func,
  useruuid: PropTypes.string,
  userTimezone: PropTypes.string,
  reFetchList: PropTypes.func,
  action: PropTypes.string,
  contacts: PropTypes.arrayOf(PropTypes.object),
  fetchingContacts: PropTypes.bool,
  staffList: PropTypes.arrayOf(PropTypes.object),
  fetchingStaffList: PropTypes.bool,
};

export default Task;