import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Dropdown, Checkbox, Menu, Divider } from "antd";
import { ExpandMoreIcon, ResetIcon } from "./Icons";

const CustomDropdownCheckBox = ({
  className,
  overlayClassName,
  open,
  onClick,
  disabled,
  leftMenuItems = [],
  rightMenuItems = [],
  selectedKeys = [],
  handleChange,
  menuProps = {},
  children,
  showActionButtons = false,
}) => {
  const [isVisible, setIsVisible] = useState(open);
  const dropdownRef = useRef(null);
  const clickInsideRef = useRef(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsVisible(false);
        onClick(event); // Pass event object to onClick callback
      }
    };
    // Only attach the event listener when the dropdown is visible
    if (isVisible) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isVisible]);
 
  const handleDropdownClick = (e) => {
    e.stopPropagation();
    if (!clickInsideRef.current) {
      setIsVisible((prev) => !prev);
    }
    clickInsideRef.current = false;
  };

  const handlefilterChange = (groupKey, itemKey) => {
    const newSelectedKeys = [...selectedKeys];
    const index = newSelectedKeys.findIndex(
      (selected) => selected.groupKey === groupKey && selected.itemKey === itemKey
    );

    if (index === -1) {
      newSelectedKeys.push({ groupKey, itemKey });
    } else {
      newSelectedKeys.splice(index, 1);
    }

    handleChange(newSelectedKeys);
  };

  const handleCheckboxChange = (groupKey, itemKey) => {
    handleChange(groupKey, itemKey);
  };

  const renderMenu = (items) => (
    <Menu>
      {items.map((group) => (
        <Menu.ItemGroup key={group.key} title={group.label}>
          {group.children.map((item) => (
            <Menu.Item key={item.key}>
              <Checkbox
                checked={selectedKeys.some(
                  (selected) =>
                    selected.groupKey === group.key &&
                    selected.itemKey === item.key
                )}
                onChange={() => showActionButtons ? handlefilterChange(group.key, item.key) : handleCheckboxChange(group.key, item.key)}
              >
                <span onClick={(e) => e.stopPropagation()}>{item.label}</span>
              </Checkbox>
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={
        <div style={{ padding: 10 }} className={className}>
          <div style={{ display: "flex" }}>
            <div>{renderMenu(leftMenuItems)}</div>
            {showActionButtons && (
              <Divider
                type="vertical"
                style={{ height: "12rem", border: "1px solid #C9C9C9" }}
              />
            )}
            <div>{renderMenu(rightMenuItems)}</div>
          </div>
          {showActionButtons && (
            <div className="dropdownbuttons">
              <div className="buttonstyle reset-btn" onClick={(e) => { e.stopPropagation(); handleChange("reset", () => setIsVisible(false)); }}>
                <ResetIcon />                
                <span>Reset filters</span>
              </div>
              <div style={{ display: "flex", gap: "20px", alignItems: "center"}}>
                <div
                  className="buttonstyle"
                  onClick={(e) => { e.stopPropagation(); handleChange("cancel", () => setIsVisible(false)); }}                  
                >
                  Cancel
                </div>
                <div className="buttonstyle" onClick={(e) => { e.stopPropagation(); handleChange("apply", () => setIsVisible(false)); }}>
                  Apply
                </div>
              </div>
            </div>
          )}
        </div>
      }
      visible={isVisible}
      disabled={disabled}
      {...menuProps}
    >
      <div
        ref={dropdownRef}
        className={`custom-dropdown ${className ?? ""} ${
          disabled ? "disabled" : ""
        }`}
        onClick={handleDropdownClick}
      >
        <div className="dropdown-label">{children}</div>
        {isVisible ? (
          <ExpandMoreIcon className="status-icon rotate-180" />
        ) : (
          <ExpandMoreIcon className="status-icon" />
        )}
      </div>
    </Dropdown>
  );
};

CustomDropdownCheckBox.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  leftMenuItems: PropTypes.arrayOf(PropTypes.object),
  rightMenuItems: PropTypes.arrayOf(PropTypes.object),
  selectedKeys: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  menuProps: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default CustomDropdownCheckBox;