import React from "react";

export const AppStore = (props) => {
  const { className = "", style = {} } = props;

  return (
    <svg
      className={className}
      style={style}
      width="162"
      height="55"
      viewBox="0 0 162 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_22168_272790)">
        <path
          d="M148.682 0.500175H12.8718C12.3768 0.500175 11.8877 0.500176 11.3939 0.502876C10.9806 0.505576 10.5706 0.513419 10.1533 0.52002C9.24684 0.530688 8.34247 0.610438 7.44808 0.758579C6.55496 0.909945 5.68981 1.19531 4.8819 1.60503C4.07497 2.01821 3.33767 2.5551 2.69672 3.19622C2.05241 3.83554 1.51533 4.5745 1.10612 5.38469C0.69579 6.19327 0.411265 7.05974 0.262373 7.95417C0.112065 8.84748 0.0311828 9.75108 0.0204525 10.6569C0.0079245 11.0708 0.0066015 11.4861 0 11.9V43.1043C0.0066015 43.5235 0.0079245 43.9295 0.0204525 44.3489C0.0311861 45.2546 0.112068 46.1582 0.262373 47.0514C0.410854 47.9464 0.695396 48.8133 1.10612 49.6222C1.51514 50.4298 2.0523 51.1658 2.69672 51.8015C3.33524 52.4455 4.07302 52.9827 4.8819 53.3928C5.6898 53.8036 6.55486 54.0907 7.44808 54.2444C8.34262 54.3913 9.2469 54.4711 10.1533 54.483C10.5706 54.4922 10.9806 54.4975 11.3939 54.4975C11.8877 54.5002 12.3768 54.5002 12.8718 54.5002H148.682C149.167 54.5002 149.66 54.5002 150.145 54.4975C150.557 54.4975 150.979 54.4922 151.39 54.483C152.295 54.4717 153.197 54.3919 154.09 54.2444C154.986 54.0896 155.854 53.8026 156.666 53.3928C157.474 52.9825 158.211 52.4453 158.849 51.8015C159.492 51.1633 160.03 50.4278 160.444 49.6222C160.852 48.8127 161.134 47.9459 161.28 47.0514C161.431 46.158 161.514 45.2547 161.531 44.3489C161.536 43.9295 161.536 43.5235 161.536 43.1043C161.547 42.6138 161.546 42.1261 161.546 41.6277V13.374C161.546 12.8796 161.547 12.3891 161.536 11.9C161.536 11.4861 161.536 11.0708 161.531 10.6568C161.514 9.75095 161.431 8.84754 161.28 7.95412C161.133 7.06021 160.852 6.1938 160.444 5.38464C159.611 3.76069 158.29 2.43881 156.666 1.60491C155.854 1.19619 154.986 0.9109 154.09 0.758458C153.197 0.609664 152.295 0.529886 151.39 0.519831C150.979 0.513244 150.557 0.505333 150.145 0.5027C149.66 0.5 149.167 0.500175 148.682 0.500175Z"
          fill="#808080"
        />
        <path
          d="M11.4005 53.3191C10.9892 53.3191 10.5878 53.3139 10.1798 53.3047C9.33436 53.2937 8.49094 53.2201 7.65642 53.0845C6.87826 52.9505 6.12444 52.7012 5.41982 52.3448C4.72165 51.9914 4.08487 51.5281 3.53387 50.9724C2.97489 50.4234 2.50951 49.7865 2.15618 49.0872C1.79895 48.3833 1.55173 47.6288 1.42313 46.8499C1.28425 46.0131 1.2091 45.1669 1.19835 44.3187C1.18979 44.034 1.17859 43.086 1.17859 43.086V11.9002C1.17859 11.9002 1.19052 10.9668 1.19842 10.6926C1.20872 9.84574 1.28342 9.00087 1.42189 8.16533C1.55073 7.38438 1.79814 6.6277 2.15556 5.92149C2.50759 5.22261 2.97038 4.5853 3.526 4.03426C4.08099 3.47798 4.7198 3.01221 5.41916 2.65392C6.12216 2.29872 6.87456 2.05118 7.65114 1.91961C8.4884 1.78267 9.33475 1.70864 10.1831 1.69812L11.4012 1.68164H150.139L151.371 1.69879C152.212 1.70877 153.051 1.78215 153.88 1.91828C154.665 2.05151 155.425 2.30077 156.136 2.65788C157.537 3.37992 158.677 4.52226 159.396 5.92479C159.748 6.62612 159.992 7.37662 160.119 8.15083C160.259 8.99323 160.337 9.84474 160.353 10.6986C160.357 11.0809 160.357 11.4916 160.357 11.9002C160.368 12.4065 160.368 12.8884 160.368 13.3742V41.6279C160.368 42.1183 160.368 42.5969 160.357 43.0794C160.357 43.5184 160.357 43.9206 160.352 44.3345C160.336 45.1731 160.259 46.0094 160.121 46.8367C159.995 47.6211 159.749 48.3815 159.392 49.0912C159.037 49.783 158.574 50.4143 158.021 50.9619C157.47 51.5205 156.832 51.9866 156.132 52.3423C155.423 52.7014 154.664 52.9516 153.88 53.0845C153.046 53.2208 152.202 53.2944 151.357 53.3047C150.961 53.3139 150.547 53.3191 150.145 53.3191L148.682 53.3218L11.4005 53.3191Z"
          fill="black"
        />
        <path
          d="M33.438 27.9056C33.4525 26.7788 33.7518 25.674 34.308 24.694C34.8643 23.714 35.6594 22.8905 36.6193 22.3004C36.0095 21.4294 35.205 20.7126 34.2696 20.207C33.3343 19.7013 32.294 19.4207 31.2313 19.3875C28.9643 19.1495 26.7666 20.744 25.6114 20.744C24.4338 20.744 22.6552 19.4111 20.7399 19.4505C19.501 19.4905 18.2937 19.8508 17.2354 20.4962C16.1772 21.1416 15.3042 22.0501 14.7014 23.1331C12.0905 27.6535 14.038 34.2969 16.5391 37.9509C17.7904 39.7401 19.2528 41.7387 21.1664 41.6678C23.0389 41.5901 23.7382 40.4738 25.9986 40.4738C28.2379 40.4738 28.894 41.6678 30.8464 41.6227C32.8557 41.5901 34.1217 39.8256 35.3291 38.0194C36.2282 36.7445 36.92 35.3355 37.379 33.8445C36.2116 33.3508 35.2154 32.5243 34.5146 31.4682C33.8138 30.4121 33.4393 29.1731 33.438 27.9056Z"
          fill="white"
        />
        <path
          d="M29.7503 16.9838C30.8458 15.6686 31.3856 13.9782 31.2549 12.2715C29.5811 12.4473 28.035 13.2472 26.9247 14.512C26.3818 15.1298 25.966 15.8486 25.701 16.6272C25.4361 17.4059 25.3272 18.2291 25.3806 19.0498C26.2178 19.0585 27.0461 18.877 27.803 18.5191C28.5599 18.1613 29.2257 17.6363 29.7503 16.9838Z"
          fill="white"
        />
        <path
          d="M57.1081 37.1374H50.718L49.1834 41.6686H46.4768L52.5294 24.9043H55.3414L61.394 41.6686H58.6413L57.1081 37.1374ZM51.3798 35.0465H56.445L53.948 27.6927H53.8781L51.3798 35.0465Z"
          fill="white"
        />
        <path
          d="M74.4655 35.5585C74.4655 39.3567 72.4326 41.7969 69.3648 41.7969C68.5877 41.8376 67.8148 41.6586 67.1347 41.2804C66.4546 40.9022 65.8947 40.3401 65.5192 39.6585H65.4611V45.7125H62.9523V29.4465H65.3807V31.4795H65.4269C65.8197 30.8011 66.389 30.242 67.0743 29.8615C67.7596 29.481 68.5352 29.2934 69.3187 29.3187C72.4207 29.3187 74.4655 31.7708 74.4655 35.5585ZM71.8868 35.5585C71.8868 33.0839 70.608 31.457 68.6568 31.457C66.74 31.457 65.4506 33.1182 65.4506 35.5585C65.4506 38.0212 66.74 39.6704 68.6568 39.6704C70.608 39.6704 71.8868 38.0554 71.8868 35.5585Z"
          fill="white"
        />
        <path
          d="M87.9181 35.5585C87.9181 39.3567 85.8852 41.7969 82.8174 41.7969C82.0403 41.8376 81.2674 41.6586 80.5873 41.2804C79.9071 40.9022 79.3473 40.3401 78.9717 39.6585H78.9137V45.7124H76.4048V29.4465H78.8333V31.4794H78.8794C79.2722 30.8011 79.8415 30.242 80.5268 29.8615C81.2121 29.481 81.9878 29.2934 82.7712 29.3187C85.8733 29.3187 87.9181 31.7708 87.9181 35.5585ZM85.3394 35.5585C85.3394 33.0839 84.0606 31.457 82.1094 31.457C80.1925 31.457 78.9032 33.1182 78.9032 35.5585C78.9032 38.0212 80.1925 39.6704 82.1094 39.6704C84.0606 39.6704 85.3394 38.0554 85.3394 35.5585Z"
          fill="white"
        />
        <path
          d="M96.8091 36.9979C96.995 38.6603 98.61 39.7519 100.817 39.7519C102.932 39.7519 104.453 38.6603 104.453 37.1613C104.453 35.8601 103.535 35.0809 101.363 34.547L99.1901 34.0236C96.1117 33.28 94.6826 31.8404 94.6826 29.5043C94.6826 26.6118 97.2033 24.625 100.783 24.625C104.325 24.625 106.753 26.6118 106.835 29.5043H104.303C104.151 27.8312 102.768 26.8214 100.747 26.8214C98.726 26.8214 97.343 27.8431 97.343 29.3302C97.343 30.5154 98.2263 31.2129 100.387 31.7467L102.234 32.2003C105.674 33.0137 107.103 34.3954 107.103 36.8475C107.103 39.9838 104.605 41.9482 100.631 41.9482C96.9132 41.9482 94.4031 40.03 94.2409 36.9978L96.8091 36.9979Z"
          fill="white"
        />
        <path
          d="M112.517 26.5547V29.4472H114.842V31.4339H112.517V38.1721C112.517 39.2188 112.983 39.7066 114.004 39.7066C114.28 39.7018 114.556 39.6825 114.83 39.6486V41.6235C114.37 41.7093 113.904 41.7482 113.436 41.7395C110.962 41.7395 109.997 40.8101 109.997 38.4397V31.4339H108.22V29.4472H109.997V26.5547H112.517Z"
          fill="white"
        />
        <path
          d="M116.188 35.5591C116.188 31.7134 118.453 29.2969 121.985 29.2969C125.528 29.2969 127.783 31.7134 127.783 35.5591C127.783 39.4153 125.54 41.8213 121.985 41.8213C118.43 41.8213 116.188 39.4153 116.188 35.5591ZM125.226 35.5591C125.226 32.921 124.017 31.3641 121.985 31.3641C119.952 31.3641 118.744 32.9329 118.744 35.5591C118.744 38.2077 119.952 39.7528 121.985 39.7528C124.017 39.7528 125.226 38.2077 125.226 35.5591Z"
          fill="white"
        />
        <path
          d="M129.851 29.4464H132.244V31.5268H132.302C132.464 30.877 132.845 30.3028 133.38 29.9007C133.915 29.4986 134.573 29.293 135.242 29.3185C135.531 29.3175 135.819 29.3489 136.102 29.4121V31.7588C135.737 31.6473 135.356 31.5961 134.974 31.6072C134.61 31.5924 134.247 31.6567 133.909 31.7956C133.572 31.9345 133.269 32.1447 133.02 32.4119C132.772 32.679 132.584 32.9968 132.47 33.3433C132.356 33.6898 132.319 34.0568 132.36 34.4193V41.6689H129.851L129.851 29.4464Z"
          fill="white"
        />
        <path
          d="M147.669 38.0798C147.331 40.2986 145.17 41.8213 142.406 41.8213C138.85 41.8213 136.643 39.439 136.643 35.6171C136.643 31.7833 138.862 29.2969 142.3 29.2969C145.682 29.2969 147.809 31.6198 147.809 35.3257V36.1853H139.176V36.3369C139.136 36.7867 139.192 37.2399 139.341 37.6663C139.49 38.0926 139.728 38.4825 140.039 38.8099C140.35 39.1374 140.727 39.3949 141.145 39.5654C141.563 39.7359 142.013 39.8155 142.464 39.7989C143.057 39.8545 143.652 39.7172 144.16 39.4074C144.669 39.0977 145.064 38.632 145.286 38.0798L147.669 38.0798ZM139.188 34.4319H145.298C145.321 34.0274 145.26 33.6227 145.118 33.2431C144.977 32.8634 144.759 32.5171 144.477 32.2258C144.196 31.9345 143.857 31.7046 143.482 31.5504C143.108 31.3962 142.705 31.3211 142.3 31.3298C141.892 31.3273 141.487 31.4058 141.109 31.5607C140.731 31.7156 140.387 31.9439 140.098 32.2323C139.808 32.5207 139.579 32.8636 139.423 33.2411C139.267 33.6186 139.187 34.0233 139.188 34.4319Z"
          fill="white"
        />
        <path
          d="M51.0654 12.2884C51.5914 12.2507 52.1192 12.3302 52.6107 12.5211C53.1023 12.712 53.5453 13.0097 53.9079 13.3926C54.2705 13.7755 54.5436 14.2341 54.7075 14.7353C54.8714 15.2365 54.922 15.7678 54.8557 16.291C54.8557 18.8644 53.4648 20.3437 51.0654 20.3437H48.1558V12.2884H51.0654ZM49.4069 19.2045H50.9256C51.3015 19.227 51.6776 19.1653 52.0266 19.0241C52.3757 18.8829 52.6888 18.6656 52.9433 18.3881C53.1977 18.1106 53.3871 17.7798 53.4976 17.4199C53.6081 17.0599 53.637 16.6799 53.5821 16.3074C53.633 15.9364 53.6012 15.5587 53.489 15.2014C53.3768 14.8441 53.187 14.516 52.9331 14.2407C52.6792 13.9654 52.3675 13.7497 52.0205 13.609C51.6734 13.4682 51.2996 13.406 50.9256 13.4268H49.4069V19.2045Z"
          fill="white"
        />
        <path
          d="M56.2689 17.3006C56.2307 16.9011 56.2764 16.498 56.4031 16.1172C56.5298 15.7365 56.7347 15.3863 57.0046 15.0894C57.2745 14.7924 57.6035 14.5551 57.9705 14.3927C58.3375 14.2304 58.7344 14.1465 59.1357 14.1465C59.537 14.1465 59.9339 14.2304 60.3009 14.3927C60.6679 14.5551 60.9969 14.7924 61.2668 15.0894C61.5368 15.3863 61.7416 15.7365 61.8683 16.1172C61.995 16.498 62.0407 16.9011 62.0025 17.3006C62.0414 17.7005 61.9963 18.1041 61.8699 18.4855C61.7436 18.8669 61.5388 19.2177 61.2688 19.5152C60.9988 19.8128 60.6696 20.0506 60.3022 20.2133C59.9348 20.376 59.5375 20.4601 59.1357 20.4601C58.7339 20.4601 58.3366 20.376 57.9692 20.2133C57.6018 20.0506 57.2726 19.8128 57.0026 19.5152C56.7326 19.2177 56.5278 18.8669 56.4015 18.4855C56.2751 18.1041 56.23 17.7005 56.2689 17.3006ZM60.7685 17.3006C60.7685 15.9829 60.1765 15.2123 59.1377 15.2123C58.0949 15.2123 57.5082 15.9829 57.5082 17.3006C57.5082 18.6289 58.0949 19.3935 59.1377 19.3935C60.1766 19.3935 60.7685 18.6236 60.7685 17.3006Z"
          fill="white"
        />
        <path
          d="M69.6239 20.3426H68.3794L67.123 15.8655H67.0281L65.777 20.3426H64.5443L62.8687 14.2637H64.0855L65.1745 18.9023H65.2641L66.5139 14.2637H67.6649L68.9147 18.9023H69.0096L70.0933 14.2637H71.293L69.6239 20.3426Z"
          fill="white"
        />
        <path
          d="M72.7023 14.264H73.8572V15.2296H73.9468C74.0989 14.8828 74.3554 14.5921 74.6806 14.3979C75.0057 14.2038 75.3834 14.1159 75.7609 14.1466C76.0567 14.1244 76.3537 14.169 76.6299 14.2771C76.9061 14.3852 77.1544 14.5541 77.3565 14.7713C77.5585 14.9884 77.7092 15.2482 77.7972 15.5315C77.8852 15.8147 77.9084 16.1142 77.865 16.4076V20.3428H76.6653V16.7089C76.6653 15.732 76.2408 15.2461 75.3535 15.2461C75.1527 15.2368 74.9522 15.271 74.7658 15.3463C74.5794 15.4217 74.4116 15.5365 74.2737 15.6828C74.1358 15.8291 74.0312 16.0035 73.9671 16.194C73.9029 16.3846 73.8807 16.5867 73.902 16.7866V20.3429H72.7023L72.7023 14.264Z"
          fill="white"
        />
        <path
          d="M79.7766 11.8906H80.9763V20.3426H79.7766V11.8906Z"
          fill="white"
        />
        <path
          d="M82.644 17.3007C82.6058 16.9012 82.6516 16.4981 82.7783 16.1173C82.905 15.7365 83.1099 15.3864 83.3798 15.0894C83.6498 14.7924 83.9788 14.5551 84.3459 14.3927C84.7129 14.2304 85.1098 14.1465 85.5111 14.1465C85.9125 14.1465 86.3094 14.2304 86.6764 14.3927C87.0434 14.5551 87.3725 14.7924 87.6424 15.0894C87.9124 15.3864 88.1173 15.7365 88.244 16.1173C88.3707 16.4981 88.4164 16.9012 88.3782 17.3007C88.4171 17.7006 88.3719 18.1043 88.2455 18.4857C88.1191 18.8671 87.9144 19.2178 87.6443 19.5154C87.3743 19.8129 87.045 20.0507 86.6777 20.2134C86.3103 20.3761 85.9129 20.4602 85.5111 20.4602C85.1093 20.4602 84.712 20.3761 84.3446 20.2134C83.9772 20.0507 83.6479 19.8129 83.3779 19.5154C83.1079 19.2178 82.9031 18.8671 82.7767 18.4857C82.6503 18.1043 82.6051 17.7006 82.644 17.3007ZM87.1436 17.3007C87.1436 15.983 86.5516 15.2124 85.5128 15.2124C84.47 15.2124 83.8833 15.983 83.8833 17.3007C83.8833 18.629 84.47 19.3936 85.5128 19.3936C86.5516 19.3936 87.1436 18.6237 87.1436 17.3007Z"
          fill="white"
        />
        <path
          d="M89.6412 18.6236C89.6412 17.5294 90.456 16.8986 91.9022 16.8089L93.5489 16.714V16.1893C93.5489 15.5472 93.1243 15.1847 92.3043 15.1847C91.6346 15.1847 91.1705 15.4306 91.0374 15.8604H89.8759C89.9985 14.8162 90.9807 14.1465 92.3597 14.1465C93.8837 14.1465 94.7433 14.9052 94.7433 16.1893V20.3428H93.5884V19.4885H93.4935C93.3008 19.7949 93.0303 20.0447 92.7095 20.2124C92.3887 20.3801 92.0291 20.4596 91.6676 20.4429C91.4124 20.4695 91.1544 20.4422 90.9104 20.363C90.6664 20.2838 90.4417 20.1543 90.2508 19.9828C90.0598 19.8114 89.907 19.6019 89.802 19.3678C89.697 19.1337 89.6422 18.8802 89.6412 18.6236ZM93.5489 18.1042V17.596L92.0644 17.6909C91.2272 17.7469 90.8475 18.0317 90.8475 18.5676C90.8475 19.1147 91.3221 19.4331 91.9748 19.4331C92.166 19.4524 92.3592 19.4331 92.5428 19.3763C92.7264 19.3195 92.8967 19.2263 93.0435 19.1023C93.1904 18.9783 93.3108 18.8261 93.3976 18.6546C93.4844 18.4831 93.5359 18.2959 93.5489 18.1042Z"
          fill="white"
        />
        <path
          d="M96.32 17.3005C96.32 15.3797 97.3075 14.1628 98.8433 14.1628C99.2233 14.1453 99.6003 14.2363 99.9304 14.4252C100.261 14.614 100.53 14.893 100.708 15.2293H100.797V11.8906H101.997V20.3426H100.847V19.3822H100.752C100.561 19.7163 100.282 19.9918 99.9458 20.1787C99.6093 20.3657 99.228 20.457 98.8434 20.4428C97.2969 20.4429 96.32 19.226 96.32 17.3005ZM97.5593 17.3005C97.5593 18.5899 98.1671 19.3657 99.1835 19.3657C100.195 19.3657 100.82 18.5787 100.82 17.3058C100.82 16.0389 100.188 15.2406 99.1835 15.2406C98.1736 15.2406 97.5593 16.0217 97.5593 17.3005Z"
          fill="white"
        />
        <path
          d="M106.961 17.3006C106.922 16.9011 106.968 16.498 107.095 16.1172C107.221 15.7365 107.426 15.3863 107.696 15.0894C107.966 14.7924 108.295 14.5551 108.662 14.3927C109.029 14.2304 109.426 14.1465 109.827 14.1465C110.229 14.1465 110.625 14.2304 110.992 14.3927C111.359 14.5551 111.688 14.7924 111.958 15.0894C112.228 15.3863 112.433 15.7365 112.56 16.1172C112.687 16.498 112.732 16.9011 112.694 17.3006C112.733 17.7005 112.688 18.1041 112.561 18.4855C112.435 18.8669 112.23 19.2177 111.96 19.5152C111.69 19.8128 111.361 20.0506 110.994 20.2133C110.626 20.376 110.229 20.4601 109.827 20.4601C109.426 20.4601 109.028 20.376 108.661 20.2133C108.293 20.0506 107.964 19.8128 107.694 19.5152C107.424 19.2177 107.219 18.8669 107.093 18.4855C106.967 18.1041 106.922 17.7005 106.961 17.3006ZM111.46 17.3006C111.46 15.9829 110.868 15.2123 109.829 15.2123C108.786 15.2123 108.2 15.9829 108.2 17.3006C108.2 18.6289 108.786 19.3935 109.829 19.3935C110.868 19.3935 111.46 18.6236 111.46 17.3006Z"
          fill="white"
        />
        <path
          d="M114.304 14.264H115.459V15.2296H115.548C115.7 14.8828 115.957 14.5921 116.282 14.3979C116.607 14.2038 116.985 14.1159 117.362 14.1466C117.658 14.1244 117.955 14.169 118.231 14.2771C118.508 14.3852 118.756 14.5541 118.958 14.7713C119.16 14.9884 119.311 15.2482 119.399 15.5315C119.487 15.8147 119.51 16.1142 119.466 16.4076V20.3428H118.267V16.7089C118.267 15.732 117.842 15.2461 116.955 15.2461C116.754 15.2368 116.554 15.271 116.367 15.3463C116.181 15.4217 116.013 15.5365 115.875 15.6828C115.737 15.8291 115.633 16.0035 115.569 16.194C115.504 16.3846 115.482 16.5867 115.503 16.7866V20.3429H114.304V14.264Z"
          fill="white"
        />
        <path
          d="M126.246 12.75V14.2912H127.563V15.3017H126.246V18.4275C126.246 19.0643 126.508 19.3431 127.105 19.3431C127.258 19.3426 127.411 19.3334 127.563 19.3154V20.3147C127.347 20.3533 127.129 20.3738 126.91 20.376C125.576 20.376 125.045 19.9067 125.045 18.7347V15.3016H124.079V14.2911H125.045V12.75H126.246Z"
          fill="white"
        />
        <path
          d="M129.201 11.8906H130.39V15.2406H130.485C130.645 14.8905 130.908 14.5981 131.24 14.4032C131.572 14.2084 131.955 14.1205 132.339 14.1516C132.633 14.1356 132.927 14.1847 133.2 14.2955C133.473 14.4062 133.719 14.5759 133.919 14.7924C134.119 15.0088 134.268 15.2667 134.357 15.5477C134.446 15.8287 134.472 16.1258 134.432 16.4179V20.3426H133.232V16.7138C133.232 15.7429 132.779 15.2511 131.932 15.2511C131.725 15.2342 131.518 15.2625 131.324 15.3342C131.13 15.4058 130.954 15.519 130.808 15.6658C130.662 15.8126 130.55 15.9894 130.48 16.184C130.41 16.3786 130.383 16.5862 130.401 16.7923V20.3426H129.201L129.201 11.8906Z"
          fill="white"
        />
        <path
          d="M141.428 18.7015C141.265 19.2571 140.912 19.7377 140.43 20.0592C139.948 20.3807 139.369 20.5226 138.794 20.4602C138.393 20.4708 137.995 20.3941 137.627 20.2354C137.259 20.0767 136.93 19.8399 136.663 19.5414C136.396 19.2428 136.197 18.8897 136.08 18.5066C135.963 18.1234 135.931 17.7194 135.986 17.3225C135.932 16.9244 135.965 16.5195 136.082 16.1352C136.199 15.7509 136.396 15.3962 136.662 15.095C136.928 14.7939 137.255 14.5533 137.622 14.3897C137.989 14.2261 138.387 14.1432 138.788 14.1466C140.48 14.1466 141.5 15.3022 141.5 17.2111V17.6297H137.208V17.6969C137.189 17.92 137.217 18.1446 137.29 18.3562C137.363 18.5678 137.479 18.7618 137.632 18.9258C137.784 19.0897 137.969 19.22 138.175 19.3083C138.381 19.3966 138.603 19.4409 138.827 19.4384C139.114 19.4729 139.404 19.4212 139.662 19.29C139.919 19.1588 140.132 18.9539 140.273 18.7015L141.428 18.7015ZM137.208 16.7424H140.278C140.293 16.5384 140.265 16.3335 140.197 16.1408C140.128 15.9481 140.02 15.772 139.879 15.6237C139.738 15.4754 139.567 15.3582 139.378 15.2797C139.189 15.2011 138.986 15.163 138.782 15.1677C138.574 15.1651 138.368 15.204 138.176 15.2822C137.984 15.3605 137.809 15.4764 137.663 15.6231C137.516 15.7699 137.4 15.9445 137.322 16.1368C137.244 16.329 137.205 16.5349 137.208 16.7424Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_22168_272790">
          <rect
            width="161.546"
            height="54"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
