import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import "./Wellness.less";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment-timezone';
import titleData from "./statistic-title.json";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const WellnessHistoryInsightChart = ({ modalData, userMeasurement, modalTitle, timezone }) => {
  const [summaryData, setSummeryData] = useState({});
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);



  const formatValue = value => {
    if (value === null || value === undefined) return "-";
    if (typeof value === "number") {
      const roundedValue = Math.round(value * 100) / 100; // Round to 2 decimal places
      return roundedValue.toString().length > 5
        ? roundedValue.toFixed(2).slice(0, 5) // Truncate if longer than 5 characters
        : roundedValue.toString();
    }
    return value.toString().slice(0, 5); // For non-numeric values
  };

  useEffect(() => {
    if (modalData.length > 0) {
      const userTimezone = timezone;

      const todayStart = moment().tz(userTimezone).startOf('day').valueOf(); // Start of today in milliseconds
      const todayEnd = moment().tz(userTimezone).endOf('day').valueOf(); // End of today in milliseconds
      const yesterdayStart = moment().tz(userTimezone).subtract(1, 'days').startOf('day').valueOf(); // Start of yesterday
      const yesterdayEnd = moment().tz(userTimezone).subtract(1, 'days').endOf('day').valueOf(); // End of yesterday

      // Filter data for today and yesterday
      const todayData = modalData.filter(item => {
        const timestamp = moment(item.utc_timestamp_inms).tz(userTimezone).valueOf();
        return timestamp >= todayStart && timestamp <= todayEnd;
      });

      const yesterdayData = modalData.filter(item => {
        const timestamp = moment(item.utc_timestamp_inms).tz(userTimezone).valueOf();
        return timestamp >= yesterdayStart && timestamp <= yesterdayEnd;
      });

      // Extract metrics dynamically from the first data item
      const metrics = modalData[0] && modalData[0].data ? Object.keys(modalData[0].data) : [];

      const results = {};

      // Check data availability and calculate summary
      if (todayData.length > 0 && yesterdayData.length > 0) {
        // Combine data from today and yesterday
        const combinedValues = [...todayData, ...yesterdayData].map(item => item.data);
        metrics.forEach(metric => {
          const values = combinedValues.map(item => item[metric]).filter(v => v !== null && v !== undefined);
          results[metric] = {
            HIGH: formatValue(Math.max(...values)),
            LOW: formatValue(Math.min(...values)),
            AVG: formatValue(values.reduce((sum, value) => sum + value, 0) / values.length),
          };
        });
      } else if (todayData.length > 0) {
        // Use only today's data
        const todayValues = todayData.map(item => item.data);
        metrics.forEach(metric => {
          const values = todayValues.map(item => item[metric]).filter(v => v !== null && v !== undefined);
          results[metric] = {
            HIGH: formatValue(Math.max(...values)),
            LOW: formatValue(Math.min(...values)),
            AVG: formatValue(values.reduce((sum, value) => sum + value, 0) / values.length),
          };
        });
      } else if (yesterdayData.length > 0) {
        // Use only yesterday's data
        const yesterdayValues = yesterdayData.map(item => item.data);
        metrics.forEach(metric => {
          const values = yesterdayValues.map(item => item[metric]).filter(v => v !== null && v !== undefined);
          results[metric] = {
            HIGH: formatValue(Math.max(...values)),
            LOW: formatValue(Math.min(...values)),
            AVG: formatValue(values.reduce((sum, value) => sum + value, 0) / values.length),
          };
        });
      } else {
        // No data for today or yesterday
        metrics.forEach(metric => {
          results[metric] = "-";
        });
      }
      console.log("results", results);
      setSummeryData(results);
    } else {
      let results = {};
      if (modalTitle === 'homesight_glucose_meter') {
        results['glucose_concentration'] = " - ";
      }
      if (modalTitle === 'homesight_weight_scale') {
        results['weight'] = " - ";
      }
      if (modalTitle === 'homesight_thermometer') {
        results['temperature'] = " - ";
      }
      if (modalTitle === 'homesight_pulse_oximeter') {
        results['oxygen'] = " - ";
        results['pulse_rate'] = " - ";
      }
      if (modalTitle === 'homesight_blood_pressure_monitor') {
        results['systolic'] = " - ";
        results['diastolic'] = " - ";
        results['pulse'] = " - ";
      }
      console.log("results--else", results);
      setSummeryData(results);
    }
  }, [modalData]);

  useEffect(() => {
    if (modalData.length > 0) {
      const userTimezone = timezone;

      const generateRandomColor = () => {
        const randomValue = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
        const red = randomValue(50, 200);
        const green = randomValue(50, 200);
        const blue = randomValue(50, 200);
        return `rgb(${red}, ${green}, ${blue})`;
      };

      const groupedData = modalData.reduce((acc, item) => {
        const date = moment(item.utc_timestamp_inms).tz(userTimezone).format("ddd M/D");
        if (!acc[date]) {
          acc[date] = {};
          Object.keys(item.data).forEach((key) => {
            acc[date][key] = [];
          });
        }
        Object.keys(item.data).forEach((key) => {
          acc[date][key].push(item.data[key]);
        });
        return acc;
      }, {});

      const endDate = moment().tz(userTimezone).endOf("day");
      const startDate = moment(endDate).subtract(6, "days").startOf("day");

      const allDates = [];
      for (let date = moment(startDate); date.isSameOrBefore(endDate); date.add(1, "days")) {
        allDates.push(date.tz(userTimezone).format("ddd M/D"));
      }

      allDates.forEach((date) => {
        if (!groupedData[date]) {
          groupedData[date] = {};
          Object.keys(modalData[0].data).forEach((key) => {
            groupedData[date][key] = [null];
          });
        }
      });

      const labels = allDates.map((date) => {
        const parsedDate = moment.tz(date, "ddd M/D", userTimezone); // Parse the date in the correct timezone
        const isToday = parsedDate.isSame(moment().tz(userTimezone), "day"); // Compare with today's date in the same timezone
        return isToday ? `Today, ${date}` : date; // Label for display
      });


      const datasets = Object.keys(modalData[0].data).map((metric) => ({
        label: titleData[userMeasurement][metric],
        data: allDates.map((date) => {
          const values = groupedData[date]?.[metric] || [];
          return values.length ? formatValue(values[0]) : null; // Take the first value if available
        }),
        borderColor:
          metric === "systolic"
            ? "#FF6B6B"
            : metric === "diastolic"
              ? "#36A2EB"
              : metric === "pulse"
                ? "#4BC0C0"
                : generateRandomColor(),
        backgroundColor: "white",
        borderWidth: 1.5,
        pointRadius: 5,
        pointHoverRadius: 6,
        tension: 0.4,
      }));

      const transformedData = {
        labels, // Display-friendly labels
        datasets,
      };

      setGraphData(transformedData);
    } else {

      const userTimezone = timezone;

      const endDate = moment().tz(userTimezone).endOf("day");
      const startDate = moment(endDate).subtract(6, "days").startOf("day");

      const allDates = [];
      for (let date = moment(startDate); date.isSameOrBefore(endDate); date.add(1, "days")) {
        allDates.push(date.tz(userTimezone).format("ddd M/D"));
      }

      const labels = allDates.map((date) => {
        const isToday = moment(date, "ddd M/D").tz(userTimezone).isSame(moment().tz(userTimezone), "day");
        return isToday ? `Today, ${date}` : date; // Label for display
      });

      if (modalTitle === "homesight_glucose_meter") {
        const datasets = [
          {
            label: "mg/dl",
            data: allDates.map(() => null), // Default data points are null
            borderColor: "#FF6B6B", // Customize the color
            backgroundColor: "white",
            borderWidth: 1.5,
            pointRadius: 5,
            pointHoverRadius: 6,
            tension: 0.4,
          },
        ];
        const transformedData = {
          labels, // Display-friendly labels
          datasets,
        };
        setGraphData(transformedData);
      }

      if (modalTitle === "homesight_weight_scale") {
        const datasets = [
          {
            label: titleData[userMeasurement]['weight'],
            data: allDates.map(() => null), // Default data points are null
            borderColor: "#FF6B6B", // Customize the color
            backgroundColor: "white",
            borderWidth: 1.5,
            pointRadius: 5,
            pointHoverRadius: 6,
            tension: 0.4,
          },
        ];
        const transformedData = {
          labels, // Display-friendly labels
          datasets,
        };
        setGraphData(transformedData);
      }

      if (modalTitle === "homesight_thermometer") {
        const datasets = [
          {
            label: titleData[userMeasurement]['temperature'],
            data: allDates.map(() => null), // Default data points are null
            borderColor: "#FF6B6B", // Customize the color
            backgroundColor: "white",
            borderWidth: 1.5,
            pointRadius: 5,
            pointHoverRadius: 6,
            tension: 0.4,
          },
        ];
        const transformedData = {
          labels, // Display-friendly labels
          datasets,
        };
        setGraphData(transformedData);
      }

      if (modalTitle === "homesight_pulse_oximeter") {
        const datasets = [
          {
            label: titleData[userMeasurement]['spo2'],
            data: allDates.map(() => null), // Default data points are null
            borderColor: "#FF6B6B", // Customize the color
            backgroundColor: "white",
            borderWidth: 1.5,
            pointRadius: 5,
            pointHoverRadius: 6,
            tension: 0.4,
          },
          {
            label: titleData[userMeasurement]['pulse_rate'],
            data: allDates.map(() => null), // Default data points are null
            borderColor: "#FF6B6B", // Customize the color
            backgroundColor: "white",
            borderWidth: 1.5,
            pointRadius: 5,
            pointHoverRadius: 6,
            tension: 0.4,
          },
        ];
        const transformedData = {
          labels, // Display-friendly labels
          datasets,
        };
        setGraphData(transformedData);
      }
      if (modalTitle === "homesight_blood_pressure_monitor") {
        const datasets = [
          {
            label: titleData[userMeasurement]['systolic'],
            data: allDates.map(() => null), // Default data points are null
            borderColor: "#FF6B6B", // Customize the color
            backgroundColor: "white",
            borderWidth: 1.5,
            pointRadius: 5,
            pointHoverRadius: 6,
            tension: 0.4,
          },
          {
            label: titleData[userMeasurement]['diastolic'],
            data: allDates.map(() => null), // Default data points are null
            borderColor: "#FF6B6B", // Customize the color
            backgroundColor: "white",
            borderWidth: 1.5,
            pointRadius: 5,
            pointHoverRadius: 6,
            tension: 0.4,
          },
          {
            label: titleData[userMeasurement]['pulse'],
            data: allDates.map(() => null), // Default data points are null
            borderColor: "#FF6B6B", // Customize the color
            backgroundColor: "white",
            borderWidth: 1.5,
            pointRadius: 5,
            pointHoverRadius: 6,
            tension: 0.4,
          },
        ];
        const transformedData = {
          labels, // Display-friendly labels
          datasets,
        };
        setGraphData(transformedData);
      }


      

    }
    setLoading(false);
  }, [modalData]);


  // Mock Data for demonstration
  // const data = {
  //   labels: [
  //     "Tue 2/21",
  //     "Wed 2/22",
  //     "Thu 2/23",
  //     "Fri 2/24",
  //     "Sat 2/25",
  //     "Sun 2/26",
  //     "Mon 2/27",
  //     "Today, Tue 2/28",
  //   ],
  //   datasets: [
  //     {
  //       label: "Systolic mmHg",
  //       data: [136, 128, 136, 128, 136, 128, 136, 115],
  //       borderColor: "#FF6B6B",
  //       backgroundColor: "white",
  //       borderWidth: 1.5,
  //       pointRadius: 5,
  //       pointHoverRadius: 6,
  //       tension: 0.4, // Smooth line curves
  //     },
  //     {
  //       label: "Diastolic mmHg",
  //       data: [85, 90, 85, 90, 85, 90, 85, 72],
  //       borderColor: "#36A2EB",
  //       backgroundColor: "white",
  //       borderWidth: 1.5,
  //       pointRadius: 5,
  //       pointHoverRadius: 6,
  //       tension: 0.4,
  //     },
  //     {
  //       label: "Pulse beats/min",
  //       data: [82, 78, 82, 78, 82, 78, 82, 62],
  //       borderColor: "#4BC0C0",
  //       backgroundColor: "white",
  //       borderWidth: 1.5,
  //       pointRadius: 5,
  //       pointHoverRadius: 6,
  //       tension: 0.4,
  //     },
  //   ],
  // };

  // Graph options with correct configuration for showing numbers on top of every tick
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 14,
            family: "Arial",
          },
          color: "#005D98",
          usePointStyle: true,
        },
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        color: " #113E57",
        anchor: function (context) {
          if (context.datasetIndex === context.chart.data.datasets.length - 1) {
            return "start"; // Always place the last dataset's labels at the end
          }
          return "end";
        },
        align: function (context) {
          const index = context.dataIndex;
          const dataset = context.dataset;
          const data = dataset.data;
          if (context.datasetIndex === context.chart.data.datasets.length - 1) {
            return "bottom"; // Always align the last dataset's labels at the end
          }
          if (index > 0 && data[index] === data[index - 1]) {
            return "start"; // Adjust alignment if overlapping with previous value
          }
          return "top";
        },
        font: {
          size: 12,
          family: "Euclid Circular A",
          style: "normal",
          weight: "700",
          size: "12px",
          height: "13px",
          z: 1,
        },
        formatter: function (value) {
          return value;
        },
      }
    },
    scales: {
      x: {
        grid: {
          color: "#005D98", // Set the color of the grid lines
          drawTicks: false,
        },
        ticks: {
          color: "#005D98", // Set the color of the tick labels
          font: {
            size: 12,
            family: "Arial",
          },
        },
        border: {
          color: "#005D98",
          dash: [3, 5],
        },
      },
      y: {
        grid: {
          color: "#005D98", // Set the color of the grid lines
          lineWidth: 1, // Set the width of the grid line
          drawTicks: false,
          z: 100,
        },
        ticks: {
          color: "#005D98", // Set the color of the tick labels
          font: {
            size: 12,
            family: "Arial",
          },
        },
        border: {
          dash: [3, 5],
        },
        beginAtZero: true,
        suggestedMax: 160, // Suggested max value to match the range of the data
      },
    },
  };


  return (
    <div className="modal-insight-chart-tab-content">
      <div className="modal-insight-chart-container">
        <div className="modal-insight-chart-summary-card">
          <div className="modal-insight-chart-summary-title">
            Today and yesterday's summary
          </div>
          <div className="modal-insight-chart-summary-row">
            <div className="modal-insight-chart-summary-item">
              <div className="modal-insight-chart-summary-item-label">
                <div className="modal-insight-chart-label"></div>
              </div>
              <div className="modal-insight-chart-summary-item-value-header">
                <div className="modal-insight-chart-value avg-header">Avg</div>
                <div className="modal-insight-chart-value low-header">Low</div>
                <div className="modal-insight-chart-value high-header">High</div>
              </div>

            </div>
            {
              Object.keys(summaryData).map((item) => {
                console.log('item', item)
                return (
                  <div className="modal-insight-chart-summary-item" key={item}>
                    <div className="modal-insight-chart-summary-item-label">
                      <div className="modal-insight-chart-label">{titleData[userMeasurement][item]}</div>
                    </div>
                    <div className="modal-insight-chart-summary-item-value">
                      <div className="modal-insight-chart-value avg">{summaryData[item]['AVG'] ?? summaryData[item]} </div>
                      {/* <div className="modal-insight-chart-value avg">136 <label>↑</label></div> */}
                      <div className="modal-insight-chart-value low">{summaryData[item]['LOW'] ?? summaryData[item]}</div>
                      <div className="modal-insight-chart-value high">{summaryData[item]['HIGH'] ?? summaryData[item]}</div>
                    </div>

                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="modal-horizontal-divider"></div>
        <div className="modal-insight-chart-chart-card">
          {
            loading ? <Spin spinning={loading} style={{ margin: "70px -20px" }} > </Spin > : graphData ? <Line options={options} data={graphData} /> : <p>Data Not found.</p>
          }

        </div>
      </div>
    </div>
  );
};

export default WellnessHistoryInsightChart;
