import React from "react";
import { BloodPressureIcon, CloseIcon } from "../Common/Icons";
import "./Wellness.less";
import { Modal, Tabs, Spin } from "antd";
import WellnessHistoryTableData from "./WellnessHistoryTableData";
import WellnessHistoryInsightChart from "./WellnessHistoryInsightChart";
import WellnessIconByTitle from "./WellnessIconByTitle";
import titleData from "./statistic-title.json";


const WellnessHistoryModal = ({ showHistoryModal,
  closeHistoryModal,
  modalLoading,
  modalData,
  modalTitle,
  userMeasurement,
  deviceObj,
  timezone  }) => {
  console.log('wellness_modal_modal_table_Data', modalData);
  console.log('wellness_modal_deviceObj', deviceObj);

  return (
    <Modal
      open={showHistoryModal}
      onOk={closeHistoryModal}
      onCancel={closeHistoryModal}
      footer={null}
      closable={false}
      centered
      className="wellness-modal-container"
    >
      <div className="modal-container">
        <div className="wellness-modal-header-container">
          <div className="wellness-header-icon-title">
            <div className="wellness-header-icon">
              <WellnessIconByTitle title={modalTitle} style={{ fill: "#113E57", width: "44px", height: "44px" }} />
            </div>
            <div className="wellness-modal-header-info">
              <div className="wellness-header-title">{titleData['device_title'][modalTitle]} History</div>
              <div className="wellness-header-sub-title">Health Reading</div>
            </div>

          </div>
          <div className="close-button" onClick={closeHistoryModal}>
            <CloseIcon className="close-icon" />
          </div>
        </div>

        <div className="tabs-container">
          <Tabs defaultActiveKey="1" className="wellness-tabs" tabPosition="top">
            <Tabs.TabPane tab="Table Data" key="1">
              <WellnessHistoryTableData
                modalLoading={modalLoading}
                modalData={modalData}
                userMeasurement={userMeasurement}
                deviceObj={deviceObj}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Insights and Chart" key="2">
              <WellnessHistoryInsightChart 
                modalLoading={modalLoading}
                modalData={modalData}
                userMeasurement={userMeasurement}
                modalTitle={modalTitle}
                timezone={timezone} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </Modal>
  );
};

export default WellnessHistoryModal;
