import React from "react";

export const RCBatteryHighIcon = (props) => {
  const {
    className = "",
    style = {},
    disabled = false,
    onClick,
    connectionStatus,
  } = props;
  return (
    <svg
      className={className}
      style={style}
      width="63"
      height="60"
      viewBox="0 0 63 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="30" cy="30" rx="30" ry="30" fill="#F5F5F5" />
      {connectionStatus === "CONNECTED" ? (
        <circle cx="52.5" cy="49.5" r="7.5" fill="#4BD37B" />
      ) : (
        <svg>
          <circle
            cx="52.5"
            cy="49.5"
            r="7.5"
            fill="white"
            stroke="grey"
            strokeWidth="1"
          />
          <line x1="49" y1="46" x2="56" y2="53" stroke="grey" strokeWidth="1" />
          <line x1="56" y1="46" x2="49" y2="53" stroke="grey" strokeWidth="1" />
        </svg>
      )}
      <mask
        id="mask0_18487_10634"
        // style="mask-type:alpha"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="43"
        y="17"
        width="20"
        height="20"
      >
        <rect x="43" y="17" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_18487_10634)">
        <path
          d="M48.6654 35.3346C48.4293 35.3346 48.2313 35.2548 48.0716 35.0951C47.9119 34.9353 47.832 34.7374 47.832 34.5013V21.168C47.832 20.9319 47.9119 20.7339 48.0716 20.5742C48.2313 20.4145 48.4293 20.3346 48.6654 20.3346H50.332V18.668H53.6654V20.3346H55.332C55.5681 20.3346 55.7661 20.4145 55.9258 20.5742C56.0855 20.7339 56.1654 20.9319 56.1654 21.168V34.5013C56.1654 34.7374 56.0855 34.9353 55.9258 35.0951C55.7661 35.2548 55.5681 35.3346 55.332 35.3346H48.6654Z"
          fill="#4BD37B"
        />
      </g>
      <path
        d="M30.0019 15C26.1661 15 23.043 18.1231 23.043 21.959V38.041C23.043 41.8769 26.1661 45 30.0019 45C33.8378 45 36.9609 41.8769 36.9609 38.041V21.959C36.9609 18.1231 33.8378 15 30.0019 15ZM35.0171 38.054C35.0171 40.8143 32.7622 43.0691 30.0019 43.0691C27.2417 43.0691 24.9868 40.8143 24.9868 38.054V21.959C24.9868 19.1987 27.2417 16.9438 30.0019 16.9438C32.7622 16.9438 35.0171 19.1987 35.0171 21.959V38.041V38.054ZM33.0473 25.7689H31.3885C31.2849 25.7689 31.1942 25.6782 31.1942 25.5745V23.9158C31.1942 23.7603 31.1294 23.6307 31.0386 23.527C30.935 23.4233 30.8054 23.3715 30.6499 23.3715H29.0818C28.9263 23.3715 28.7967 23.4363 28.6931 23.527C28.5894 23.6307 28.5376 23.7603 28.5376 23.9158V25.5745C28.5376 25.6782 28.4469 25.7689 28.3432 25.7689H26.6844C26.5289 25.7689 26.3993 25.8337 26.2957 25.9244C26.192 26.0151 26.1402 26.1577 26.1402 26.3132V27.8812C26.1402 28.0367 26.205 28.1663 26.2957 28.27C26.3864 28.3736 26.5289 28.4255 26.6844 28.4255H28.3432C28.4469 28.4255 28.5376 28.5162 28.5376 28.6199V30.2786C28.5376 30.4341 28.6024 30.5637 28.6931 30.6674C28.7967 30.7711 28.9263 30.8229 29.0818 30.8229H30.6499C30.8054 30.8229 30.935 30.7581 31.0386 30.6674C31.1423 30.5767 31.1942 30.4341 31.1942 30.2786V28.6199C31.1942 28.5162 31.2849 28.4255 31.3885 28.4255H33.0473C33.2028 28.4255 33.3324 28.3607 33.4361 28.27C33.5397 28.1663 33.5916 28.0367 33.5916 27.8812V26.3132C33.5916 26.1577 33.5268 26.0281 33.4361 25.9244C33.3324 25.8207 33.2028 25.7689 33.0473 25.7689ZM29.8594 28.3218C29.1855 28.3218 28.6412 27.7775 28.6412 27.1037C28.6412 26.4298 29.1855 25.8855 29.8594 25.8855C30.5333 25.8855 31.0775 26.4298 31.0775 27.1037C31.0775 27.7775 30.5333 28.3218 29.8594 28.3218Z"
        fill="#113E57"
      />
    </svg>
  );
};
