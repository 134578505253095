import React, { useEffect, useState } from "react";
import { BloodPressureIcon } from "../Common/Icons";
import "./Wellness.less";
import WellnessViewHistory from "./WellnessViewHistory";



const WellnessListItem = ({ showViewHistoryModal, title, day, timeDifference, time, data, device, deviceBrand, deviceModel, deviceuuid, showViewHistory= false, eventName }) => {
    console.log('wellness__list__item',deviceuuid);
    
    return (
        <>
            <div className="wellness-item-with-history">
               { showViewHistory ? <WellnessViewHistory showViewHistoryModal={showViewHistoryModal} title={title}  deviceBrand={deviceBrand} deviceModel={deviceModel} eventName={eventName} /> : '' }
                <div className="wellness-item-content">
                    <div className="wellness-item-content-header">
                        <div className="wellness-item-content-day-info"> {day}</div>
                        <div className="wellness-item-content-divider" >
                            <svg width="2" height="56" viewBox="0 0 2 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0V56" stroke="#C9C9C9" />
                            </svg>
                        </div>
                        <div className="wellness-item-content-time-info" >{time} {` ( ${timeDifference} )`}</div>
                    </div>
                    <div className="wellness-item-content-statistic">
                        {
                            data.map((item,index)=>{
                                return(
                                    <div className="wellness-item-content-statistic-info" key={index}>
                                        <div className="wellness-item-content-statistic-info-label">{item.display_label}</div>
                                        <div className="wellness-item-content-statistic-info-value">{item.display_value}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="wellness-item-content-device">
                        <div className="wellness-item-content-device-title">{device}</div>
                        <div className="wellness-item-content-device-model-info">
                            <div className="wellness-item-content-device-model-title">{deviceBrand}</div>
                            <svg width="2" height="32" viewBox="0 0 2 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0V56" stroke="#C9C9C9" />
                            </svg>
                            <div className="wellness-item-content-device-model-model">  {deviceModel}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WellnessListItem;