/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Skeleton, Tooltip } from "antd";
import {
  Actions,
  DateFormat,
  PhotoResolution,
  sessionType,
} from "@commscopemycloud/humaui/Utilities/Constants";
import MainPage from "../../MainPage";
import {
  ContactIcon1,
  HSCalendar,
  AddPlusIcon,
  CheckIcon,
  NewScheduleIcon,
} from "../Common/Icons";
import {
  getDateFormat,
  getTimeFormat,
} from "@commscopemycloud/humaui/Utilities/DateTime";
import {
  getProfilePicUrl,
  getUsername,
  isSocial,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { getContacts } from "./Contacts";
import { HSDatePicker } from "../Common/HSWidget";
import { UserImage } from "./UserImage";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";
import { useDispatch, useSelector } from "react-redux";
import {
  Access,
  KnownActionsList,
  checkRoleAccess,
  isActionAllowed,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import moment from "moment";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";

const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CHECK_INTERVAL_MS = 20000;
const SHOW_STATUS_BEFORE_MINUTES = 15;
const ONGOING_MEETING_DISPLAY_TIME_HOURS = 4;
const SHOW_JOIN_STATUS_TILL_MINUTES = ONGOING_MEETING_DISPLAY_TIME_HOURS * 60;

const ListSchedule = (props) => {
  const {
    onlyContent,
    parentBread,
    selectedDate,
    setSelectedDate,
    scheduleList,
    fetchingScheduleList,
    onActionClick,
    contacts,
    userInfo,
    userHubsData,
    rolename,
    hubRecord,
    openSubMenu = false,
    loginUserTimeformat,
  } = props;

  const trans = useSelector(translator);
  const dispatch = useDispatch();
  const resourcesMap = useSelector(getProfilePics());
  const header = parentBread || [];
  const userTimezone = LocalTimezone;
  const currentUser = useSelector(getCurrentUser);
  const useruuid = useMemo(() => currentUser?.useruuid, [currentUser]);
  const permissions = useSelector(getPermissions());

  const [buttonStatus, setButtonStatus] = useState(false);

  const allowAddEvent = useMemo(
    () =>
      (rolename === undefined ||
        isActionAllowed(
          rolename,
          KnownActionsList.create_events,
          permissions
        )) &&
      hubRecord,
    [rolename, permissions, hubRecord]
  );

  const allowAddTask = useMemo(
    () => checkRoleAccess(Access.beta.labsPreview) && hubRecord,
    [hubRecord]
  );

  const formatList = useCallback((scheduleList) => {
    const selectedDateTime = new Date(selectedDate);
    let filteredList = scheduleList.filter((event) => {
      const eventDate = new Date(event.event_timestamp);
      return eventDate.getTime() >= selectedDateTime.getTime();
    });

    const list = filteredList.reduce((acc, event) => {
      const dateObj = getDateFormat(event.event_timestamp, userTimezone);
      const date = dateObj.date;

      if (!acc[date]) {
        acc[date] = [];
      }

      const duplicate = acc[date].some(
        (e) => e.schedule_eventuuid === event.schedule_eventuuid
      );

      if (!duplicate) {
        acc[date].push(event);
      }

      return acc;
    }, {});

    return Object.values(list).sort((a, b) => {
      return new Date(a[0]?.event_timestamp) - new Date(b[0]?.event_timestamp);
    });
  }, [selectedDate, userTimezone]);

  const formattedList = useMemo(() => formatList(scheduleList), [scheduleList, formatList]);

  const handleDateSelect = useCallback((date) => {
    setSelectedDate(date);
  }, [setSelectedDate]);

  const contactImage = useCallback((contact) => {
    let displayName = getUsername(contact);
    const url = getProfilePicUrl(
      contact.useruuid,
      resourcesMap,
      PhotoResolution.R64
    );
    return <UserImage name={displayName} url={url} />;
  }, [resourcesMap]);

  const renderDateListItem = useCallback((item) => {
    let dateObj = getDateFormat(item.event_timestamp, userTimezone);
    return (
      <div
        key={dateObj.dayDateformat}
        className={
          dateObj.dayDateformat.startsWith("Today")
            ? "schedule-list-date-newui schedule-list-today-newui"
            : "schedule-list-date-newui"
        }
      >
        <div className="schedule-date-newui">{dateObj.dayDateformat}</div>
      </div>
    );
  }, [userTimezone]);

  const renderInvitedList = useCallback((invitedUseruuid) => {
    let invitedContacts = getContacts(invitedUseruuid, [
      ...(contacts ?? []),
      ...(userHubsData ?? []),
      ...(userInfo ? [userInfo] : []),
    ]);

    const count = invitedContacts.length;

    const invitedContactsData = invitedContacts
      .slice(0, 9)
      .map((contact, index) => (
        <div key={index} className="user-image-container">
          {contact && contactImage(contact)}
        </div>
      ));

    return { invitedContactsData, count };
  }, [contacts, userHubsData, userInfo, contactImage]);


  const handleButtonClick = useCallback((item) => {
    if (item.event_type?.toLowerCase() === sessionType.zoom) {
      window.open(item.meeting_url);
    } else {
      dispatch(
        startVideoCall({
          meeting_id: item.schedule_eventuuid,
          eventInfo: item,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setButtonStatus((prevStatus) => !prevStatus);
    }, 2000);
    setButtonStatus((prevStatus) => !prevStatus);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const renderButtonStatus = useCallback((item) => {
    if (item.event_type?.toLowerCase() === "reminder") {
      return null;
    }
    if (!item.invited_contacts.includes(useruuid)) {
      return null;
    }

    const now = moment();
    const eventTime = moment(item.event_timestamp);
    const diff = eventTime.diff(now, "minute");

    if (diff > 5 && diff <= SHOW_STATUS_BEFORE_MINUTES) {
      return (
        <div className="status" onClick={() => handleButtonClick(item)}>
          About to Start
        </div>
      );
    } else if (diff <= 5 && diff >= -SHOW_JOIN_STATUS_TILL_MINUTES) {
      return (
        <div className="status" onClick={() => handleButtonClick(item)}>
          Join
        </div>
      );
    } else {
      return null;
    }
  }, [handleButtonClick, useruuid]);

  const renderNoList = useCallback(() => {
    let currentDate = getDateFormat(moment(), userTimezone);
    return (
      <div
        className={
          currentDate.dayDateformat.startsWith("Today")
            ? "schedule-list-date-newui schedule-list-today-newui"
            : "schedule-list-date-newui"
        }
      >
        <div className="schedule-date-newui">{currentDate.dayDateformat}</div>
        {formattedList.length === 0 && (
          <div className="note-box no-events">
            <CheckIcon style={{ marginRight: "10px" }} />
            <span>{trans("NOUPCOMINGEVENTS")}</span>
          </div>
        )}
      </div>
    );
  }, [formattedList, userTimezone, trans]);

  const renderScheduleList = useCallback(() => {
    return (
      <div className="schedule-list-container-newui">
        <div className="schedule-actions-newui">
          {!fetchingScheduleList && allowAddEvent && (
            <Tooltip title="ADD EVENT">
              <div className="add-event-icon">
                <AddPlusIcon
                  onClick={onActionClick(Actions.addEvent, userInfo)}
                />
              </div>
            </Tooltip>
          )}
          {!fetchingScheduleList && allowAddTask && (
            <Tooltip title={trans("ADDTASK")}>
              <div>
                <AddPlusIcon
                  className="add-task-icon"
                  onClick={onActionClick(Actions.addTask, userInfo)}
                />
              </div>
            </Tooltip>
          )}

          <div className="date-picker-container">
            <HSDatePicker
              DateFormat={DateFormat}
              timeZone={userTimezone}
              defaultValue={selectedDate}
              onDateSelect={handleDateSelect}
            />
          </div>
        </div>
        {fetchingScheduleList ? (
          <div className="schedule-container-newui skeleton-container-newui">
            <Skeleton active avatar paragraph={false} />
          </div>
        ) : formattedList.length === 0 ? (
          renderNoList()
        ) : (
          formattedList.map((list, index) => (
            <div key={"schedule_day_" + index} className="schedule-list-data">
              {renderDateListItem(list[0])}
              {list.map((item) => (
                <div
                  key={item.schedule_eventuuid}
                  className="schedule-container-newui"
                >
                  <div className="schedule-list-row1-newui">
                    <div
                      onClick={onActionClick(Actions.startEvent, item)}
                      className="container-1"
                    >
                      <div
                        title={userTimezone}
                        className="schedule-eventtime-newui"
                      >
                        {(loginUserTimeformat && loginUserTimeformat?.is24hoursformat) ?
                          getTimeFormat(item.event_timestamp, userTimezone, loginUserTimeformat?.is24hoursformat) :
                          getTimeFormat(item.event_timestamp, userTimezone, loginUserTimeformat?.is24hoursformat)}
                      </div>
                      {item.recurring ? (
                        <HSCalendar />
                      ) : (
                        <div
                          className="empty-action"
                          style={{ width: "30px", marginLeft: "10px" }}
                        ></div>
                      )}
                      <div
                        className={`${
                          item.event_type?.toLowerCase() === sessionType.zoom
                            ? "zoomeventtype"
                            : item.event_type?.toLowerCase() ===
                              sessionType.reminder
                            ? "remindereventtype"
                            : "hseventype"
                        }`}
                      >
                        {item.event_type?.toLowerCase() === sessionType.zoom
                          ? trans("ZOOMCALL")
                          : item.event_type?.toLowerCase() ===
                            sessionType.reminder
                          ? "Calendar Reminder"
                          : trans("VIDEOCALL")}
                      </div>
                      <div className="schedule-eventname-newui">
                        {item.event_name}
                      </div>
                    </div>
                    <div className="container-2">
                      {item?.schedule_eventuuid &&
                        moment(item?.event_timestamp).isSame(
                          moment(),
                          "day"
                        ) && <div>{renderButtonStatus(item)}</div>}
                    </div>
                  </div>
                  <div className="schedule-list-row2-newui">
                    <div>
                      <ContactIcon1 className="account-icon" />
                    </div>
                    {renderInvitedList(item.invited_contacts).count > 0 && (
                      <div className="invited-count">
                        {renderInvitedList(item.invited_contacts).count}
                      </div>
                    )}
                    <div className="invited-list">
                      {
                        renderInvitedList(item.invited_contacts)
                          .invitedContactsData
                      }
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    );
  }, [fetchingScheduleList, 
      allowAddEvent, 
      allowAddTask, 
      formattedList, 
      onActionClick, 
      renderDateListItem, 
      renderButtonStatus, 
      renderInvitedList, 
      handleDateSelect, 
      trans, 
      userInfo, 
      selectedDate, 
      userTimezone ]);

  const headerSection = useCallback(() => {
    return (
      <div className="schedule-header-newui">
        <div className="schedule-title-newui">
          <div className="schedule-text-newui">
            {openSubMenu ? (
              <div className="hub-schedule-header">
                <div className="hub-icon">
                  <NewScheduleIcon className="hub-schedule-icon" />
                </div>
                <div className="hub-schedule-title">{trans("SCHEDULE")}</div>
              </div>
            ) : (
              trans("MYSCHEDULE")
            )}
          </div>
        </div>
      </div>
    );
  }, [openSubMenu, trans]);

  return onlyContent ? (
    <div className="list-schedule-container-newui page-content-wrapper">
      {headerSection()}
      <div className={`${!openSubMenu ? "schedule-content-newui" : ""}`}>
        {renderScheduleList()}
      </div>
    </div>
  ) : (
    <MainPage
      hasSider={!openSubMenu}
      header={header}
      noModals={!!openSubMenu}
    >
      <div className="list-schedule-container-newui page-content-wrapper">
        {headerSection()}
        <div className={`${!openSubMenu ? "schedule-content-newui" : ""}`}>
          {renderScheduleList()}
        </div>
      </div>
    </MainPage>
  );
};

export default ListSchedule;