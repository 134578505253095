import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "antd";

const NonRecurringTaskModal = ({ isModalOpen = false , onConfirm=()=>{}, onCancel=()=>{}, modalText= "delete", modalHeader="Delete task", showDeleteMsg = false}) => {
  return (
    <div className="">
         <Modal
          title={modalHeader}
          centered
          open={isModalOpen}
          footer={null}
          closable={false}
          className="task-modal-conatiner"
        >
          <div className="taskmodal">
            <div className={`${showDeleteMsg ? "" : "taskmodifydescription"} taskmodaldescription`}>
              <p>
                You are about to {modalText} this task.
                <br />
               {showDeleteMsg &&"It will be removed from the schedule."}
              </p>
              <span>Are you sure?</span>
            </div>
            <div className="taskmodalbutton">
              <Button className="custom-no-button" onClick={onCancel}>
                <span className="custom-no-label">Cancel</span>
              </Button>
              <Button className="custom-yes-button" onClick={onConfirm}>
                <span className="custom-yes-label">Yes</span>
              </Button>
            </div>
          </div>
        </Modal>
    </div>
  );
};

NonRecurringTaskModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default NonRecurringTaskModal;
