import React from "react";

export const NewCancelIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.4881 2 2 6.4881 2 12C2 17.5119 6.4881 22 12 22C17.5119 22 22 17.5119 22 12C22 6.4881 17.5119 2 12 2ZM12 19.9405C7.61905 19.9405 4.05952 16.381 4.05952 12C4.05952 7.61905 7.60714 4.05952 12 4.05952C16.3929 4.05952 19.9405 7.61905 19.9405 12C19.9405 16.381 16.3929 19.9405 12 19.9405Z"
        fill="#005D98"
      />
      <path
        d="M13.5478 8.09119L12.0001 9.63888L10.4524 8.09119C9.79762 7.4364 8.74598 7.4364 8.09119 8.09119C7.4364 8.74598 7.4364 9.79762 8.09119 10.4524L9.63888 12.0001L8.09119 13.5478C7.4364 14.2026 7.4364 15.2542 8.09119 15.909C8.74598 16.5638 9.79762 16.5638 10.4524 15.909L12.0001 14.3613L13.5478 15.909C14.2026 16.5638 15.2542 16.5638 15.909 15.909C16.5638 15.2542 16.5638 14.2026 15.909 13.5478L14.3613 12.0001L15.909 10.4524C16.5638 9.79762 16.5638 8.74598 15.909 8.09119C15.2542 7.4364 14.2026 7.4364 13.5478 8.09119Z"
        fill="#005D98"
      />
    </svg>
  );
};
