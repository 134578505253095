import React from "react";

export const NewUpdateIcon = (props) => {
  const { className = "", disabled = false, onClick, style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.6001 12.0002L10.5334 14.8002L16.4001 9.2002"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path d="M12 2C6.4881 2 2 6.4881 2 12C2 17.5119 6.4881 22 12 22C17.5119 22 22 17.5119 22 12C22 6.4881 17.5119 2 12 2ZM12 19.9405C7.61905 19.9405 4.05952 16.381 4.05952 12C4.05952 7.61905 7.60714 4.05952 12 4.05952C16.3929 4.05952 19.9405 7.61905 19.9405 12C19.9405 16.381 16.3929 19.9405 12 19.9405Z" />
    </svg>
  );
};
