import React from "react";
import "./Wellness.less";
import WellnessViewHistory from "./WellnessViewHistory";
import PropTypes from "prop-types";
import { Tooltip } from "antd";


const WellnessListItem = ({ showViewHistoryModal, showSupportDeviceModal, title, day, timeDifference, time, data, device, deviceBrand, deviceModel, deviceuuid, showViewHistory= false, eventName }) => {
    const truncateText = (text) => {
        if (!text) return;
        return text?.length <= 15 ? text : text?.substring(0, 15) + "...";
    };
    return (
        <>
            <div className="wellness-item-with-history">
               { showViewHistory ? <WellnessViewHistory showViewHistoryModal={showViewHistoryModal} showSupportDeviceModal={showSupportDeviceModal} title={title}  deviceBrand={deviceBrand} deviceModel={deviceModel} eventName={eventName} /> : '' }
                <div className="wellness-item-content">
                    <div className="wellness-item-content-header">
                        <div className="wellness-item-content-day-info"> {day}</div>
                        <div className="wellness-item-content-divider" >
                            <svg width="2" height="56" viewBox="0 0 2 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0V56" stroke="#C9C9C9" />
                            </svg>
                        </div>
                        <div className="wellness-item-content-time-info" >{time} {` ( ${timeDifference} )`}</div>
                    </div>
                    <div className="wellness-item-content-statistic">
                        {
                            data.map((item,index)=>{
                                return(
                                    <div className="wellness-item-content-statistic-info" key={index}>
                                        <div className="wellness-item-content-statistic-info-label">{item.display_label}</div>
                                        <div className="wellness-item-content-statistic-info-value">{item.display_value}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="wellness-item-content-device">
                        <div className="wellness-item-content-device-title">{device}</div>
                        <div className="wellness-item-content-device-model-info">
                            <div className="wellness-item-content-device-model-title">
                                <Tooltip placement="bottom" title={deviceBrand?.length > 15 ? deviceBrand : ""}>
                                    <span className="wellness-item-content-device-model-title">{truncateText(deviceBrand)}</span>
                                </Tooltip></div>
                            <svg width="2" height="32" viewBox="0 0 2 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0V56" stroke="#C9C9C9" />
                            </svg>
                            <div className="wellness-item-content-device-model-model">
                                <Tooltip placement="bottom" title={deviceModel?.length > 15 ? deviceModel : ""}>
                                    <span className="wellness-item-content-device-model-model">{truncateText(deviceModel)}</span>
                                </Tooltip></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

WellnessListItem.propTypes = { 
    showViewHistoryModal: PropTypes.func,
    title: PropTypes.string,
    day: PropTypes.string,
    timeDifference: PropTypes.string,
    time: PropTypes.string,
    data: PropTypes.array,
    device: PropTypes.string,
    deviceBrand: PropTypes.string,
    deviceModel: PropTypes.string,
    deviceuuid: PropTypes.string,
    showViewHistory: PropTypes.bool,
    eventName: PropTypes.string
}

export default WellnessListItem;