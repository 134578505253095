import React, { useEffect, useState, } from "react";
import "./Wellness.less";
import WellnessList from "./WellnessList";
import WellnessHistoryModal from "./WellnessHistoryModal";
import WellnessHeader from "./WellnessHeader";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import sampleData from './sample.json';
import { getUserInfo } from "@commscopemycloud/humaui/Store/dataStore";
import {
  celsiusToFahrenheit,
  fahrenheitToCelsius,
  kilogramsToPounds,
  poundsToKilograms
} from "../../utilities/helpers/commonHelper";
import { message } from "antd";
import moment from 'moment-timezone';


let unitTitleData;
const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


const Wellness = ({ hubRecord, timezone, deviceStatus, providerid }) => {

  const userTimezone = timezone ? timezone?.split(" ")[0] : LocalTimezone;
  const userInfo = useSelector(getUserInfo(hubRecord?.useruuid));
  const trans = useSelector(translator);
  const userEventsApi = useSelector((state) => state.apis.userEventsApi);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [wellnessListData, setWellnessListData] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalData, setModalData]= useState([]);
  const [modalTitle,setModalTitle] = useState('');
  const [currentDevice, setCurrentDevice] = useState({});
  console.log('wellness__userInfo', userInfo);

  useEffect(() => {
    if (!unitTitleData) {
      import("./statistic-title.json")
        .then((data) => {
          unitTitleData = data.default || data;
        })
        .catch((err) => console.error("Error loading statistic-title.json:", err));
    }
  }, []);

  function getStatisticUnitTitle(val) {
    const valKey = val.toLowerCase();
    const userDefinedMeasurement = userInfo?.measurement || '';
    return unitTitleData?.[userDefinedMeasurement]?.[valKey] || "";
  }

  function getUserDefinedUnitVal(value, key) {
    if (!value || !key) {
      console.error('Invalid arguments provided.');
      return null;
    }

    const userDefinedMeasurement = userInfo?.measurement || '';
    const valueUnitType = value.unit_type || '';

    if (!userDefinedMeasurement || userDefinedMeasurement === '' || valueUnitType === '') {
      return value.value;
    }

    if (String(userDefinedMeasurement) === String(valueUnitType)) {
      return value.value;
    }

    if (userDefinedMeasurement === 'metric' && valueUnitType === 'imperial') {
      if (key === 'homesight_weight_scale') return poundsToKilograms(value.value);
      if (key === 'homesight_thermometer') return fahrenheitToCelsius(value.value);
    }

    if (userDefinedMeasurement === 'imperial' && valueUnitType === 'metric') {
      if (key === 'homesight_weight_scale') return kilogramsToPounds(value.value);
      if (key === 'homesight_thermometer') return celsiusToFahrenheit(value.value);
    }

    return value.value;
  }

  function formatTimestamp(timestamp, timezone) {

    if(timestamp == null || timestamp == undefined){
      return {day:'-',time:'-',difference:'-'}
    } 
    const now = moment().tz(timezone);
    const inputTime = moment.tz(timestamp, timezone);
    const userDefinedTimeFormat = userInfo?.is24hoursformat

    let time = '';
    if (userDefinedTimeFormat)
      time = inputTime.format('HH:mm'); // Format time as '09:40'
    else
      time = inputTime.format('h:mm A'); // Format time as '9:40 AM'


    const difference = moment.duration(inputTime.diff(now));
    let differenceText;

    if (difference.asMilliseconds() < 0) {
      // Past data
      const pastDifference = moment.duration(now.diff(inputTime));
      differenceText =
        pastDifference.asMinutes() < 1
          ? 'Just now'
          : pastDifference.asMinutes() < 60
            ? `${Math.round(pastDifference.asMinutes())} min ago`
            : pastDifference.asHours() < 24
              ? `${Math.round(pastDifference.asHours())} hours ago`
              : `${Math.round(pastDifference.asDays())} days ago`;
    } else {
      // Future data
      differenceText =
        difference.asMinutes() < 1
          ? 'Later'
          : difference.asMinutes() < 60
            ? `In ${Math.round(difference.asMinutes())} min`
            : difference.asHours() < 24
              ? `In ${Math.round(difference.asHours())} hours`
              : `In ${Math.round(difference.asDays())} days`;
    }

    let day;
    if (now.isSame(inputTime, 'day')) {
      day = 'Today';
    } else if (now.subtract(1, 'day').isSame(inputTime, 'day')) {
      day = 'Yesterday';
    } else {
      day = inputTime.format('ddd M/D'); // Format as 'Sun 12/1'
    }

    return {
      day,
      time,
      difference: differenceText,
    };
  }

  function processRawData(data) {
    const processedData = [];

    Object.keys(data).forEach((key) => {
      const entry = data[key][0];
      let dateTimeObj = formatTimestamp(entry.utc_timestamp_inms, userTimezone);
      let day = dateTimeObj['day'];
      let time = dateTimeObj['time'];
      let timeDifference = dateTimeObj['difference'];

      const processedEntry = {
        title: key,
        day,
        time,
        timeDifference,
        data: Object.entries(entry.data).map(([dataKey, value]) => ({
          display_label: getStatisticUnitTitle(String(dataKey)),
          display_value: value.unit === "C" ? `${String(getUserDefinedUnitVal(value, key)).slice(0, 5)}°` : String(getUserDefinedUnitVal(value, key)).slice(0, 5)
        })),
        device: {
          title: "Device used",
          brand: entry.device_manufacturer,
          model: entry.device_model,
          uuid:entry.deviceuuid
        },
        event_name: entry.eventname
      };

      processedData.push(processedEntry);
    });
    console.log('wellness__processed__data',processedData);
    return processedData;
  }

  useEffect(() => {
    console.log('wellness__useeffect');
    const useruuid = hubRecord.useruuid ?? '';
    const fetchUserMedicalAccessories = () => {
      const errorCallback = (errors) => {
        console.error("Error fetching wellness api information:", errors);

      };
      const successCallback = (data) => {
        const response = data['user_accessories'];
        const wellnessMappedData = processRawData(response)
        setWellnessListData(wellnessMappedData);
        setLoading(false);
      };

      try {
        if (!useruuid) return;
        userEventsApi.getUserMedicalAccessories(
          useruuid,
          {},
          apiCallback({
            translator: trans,
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        );
      } catch (error) {
        console.log('error__catch', error)
        errorCallback(error);
      }
    };

    fetchUserMedicalAccessories();
  }, [userEventsApi, hubRecord, trans, userInfo]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  function processUserEvents(userEvents,title) {
    return userEvents.sort((a, b) => new Date(b.utc_timestamp_inms) - new Date(a.utc_timestamp_inms)).map(event => {
        const outputObj = {};
        let data ={};

        const timeobj = formatTimestamp(event.utc_timestamp_inms,userTimezone);
  
        outputObj['useruuid'] = event['useruuid'];
        outputObj['deviceuuid'] = event['deviceuuid'];
        outputObj['formatted_date']= timeobj['day'],
        outputObj['formatted_time'] = timeobj['time']
        outputObj['modelnumber'] = event['modelnumber'];
        outputObj['timeDiffText'] = timeobj['difference'];
        outputObj['utc_timestamp_inms'] = event['utc_timestamp_inms'];
  
        if(title ==  "homesight_blood_pressure_monitor" ){
            data['systolic'] = event['systolic'];
            data['diastolic'] = event['diastolic'];
            data['pulse_rate'] = event['pulse_rate'];
        }
  
        if(title ==  "homesight_pulse_oximeter"){
          data['spo2'] = event['spo2']
          data['pulse_rate'] = event['pulse_rate']
        }
  
  
        if(title=='homesight_thermometer'){
          data['temperature'] = event['temperature'];
        }
  
        if(title ==  "homesight_weight_scale"){
          data['weight'] = event['weight'];
        }

        if(title ==  "homesight_glucose_meter"){
          data['glucose_concentration'] = event['glucose_concentration'];
        }
  
        outputObj['data'] = data;
  
        
        return outputObj;
      });
  }

  const handelShowModal = (title, deviceBrand, deviceModel, eventType) => {
    // const eventType = getEventTypeByTitle(title);
    console.log('device_brand',deviceBrand);
    console.log('device_model',deviceModel);
    const currentDeviceObj = {deviceBrand, deviceModel };
    setCurrentDevice(currentDeviceObj);
    const errorCallback = (error) => {
      //   setFetchingData(false);
        message.error("Error fetching medical history!");
        console.error("Error fetching medical history:", error);
   
      };
      const successCallback = (data) => {
      //   setFetchingData(false);
        console.log("fetch__history__result", data);
        const finalData =processUserEvents(data['user_events'], title);
        setModalData(finalData);
        setModalTitle(title);
        setModalLoading(false);
        console.log('final__data', finalData);
     
      };
      const startDate = moment()
                      .subtract(30, "days")
                      .utcOffset("+00:00")
                      .format("YYYY-MM-DDT00:00:00Z");

      const endDate = moment().utcOffset("+00:00").format("YYYY-MM-DDTHH:mm:ssZ");
      try {
          userEventsApi.userEventsByTypesAndTimeRange(
              hubRecord.useruuid,
              startDate,
              endDate,
              {
                eventtypes: [eventType],
              },
          apiCallback({
            translator: trans,
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        );
      } catch (error) {
        errorCallback(error);
      }
      setShowModal(true);
      setModalLoading(true);
  }

  return (
    <>
      <WellnessHistoryModal
        showHistoryModal={showModal}
        modalLoading={modalLoading} 
        modalData={modalData}
        modalTitle={modalTitle}
        closeHistoryModal={handleModalClose}
        userMeasurement={userInfo?.measurement}
        deviceObj={currentDevice}
        timezone={userTimezone}
        />
      <div style={{ padding: "40px 40px 0px 40px" }}>
        <WellnessHeader />
        <WellnessList showViewHistoryModal={handelShowModal} 
                      mappedData={wellnessListData}
                      loading={loading} />
      </div>
    </>
  )
};

export default Wellness;
