import React from "react";

export const CallIconNew = (props) => {
  const { className = "", disabled = false, onClick, style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      onClick={disabled ? null : onClick}
      width="72" height="56" viewBox="0 0 72 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="72" height="56" rx="28" fill="#D9ECFA"/>
      <path d="M43.7799 29.9386C42.8295 28.8117 40.5362 28.4291 39.6375 28.4291C39.3689 28.4291 39.1416 28.4498 38.9247 28.5015C38.2326 28.6463 37.716 29.0805 37.3132 29.494C36.2078 28.7393 35.2471 27.7882 34.493 26.6819C34.9062 26.2787 35.3401 25.7618 35.4847 25.0691C35.7326 23.8906 35.1851 21.1922 34.0591 20.2204C33.6046 19.8379 32.9848 19.6001 32.4063 19.6001H32.365H30.1853C29.4518 19.6001 28.77 19.9103 28.2742 20.4479C27.8196 20.9544 27.5717 21.6161 27.6027 22.2881C27.613 26.134 29.0696 29.6904 31.6935 32.3164C34.3071 34.932 37.84 36.3794 41.6416 36.4001C41.6932 36.4001 41.7449 36.4001 41.7862 36.4001C42.468 36.4001 43.0775 36.1623 43.563 35.7178C43.7283 35.5627 43.8729 35.3869 43.9969 35.2008C44.2551 34.7976 44.3997 34.3221 44.3997 33.8362V31.6341C44.4101 31.0344 44.1931 30.4141 43.7903 29.9386H43.7799ZM42.6023 33.8362C42.6023 34.0429 42.5093 34.2497 42.344 34.4048C42.1994 34.5392 42.0134 34.6115 41.7862 34.6115C41.7552 34.6115 41.7242 34.6115 41.6932 34.6115C38.3565 34.6115 35.2471 33.3503 32.9641 31.0551C30.6605 28.7496 29.3898 25.6378 29.3898 22.2261C29.3692 22.0193 29.4415 21.8022 29.5964 21.6471C29.7514 21.4817 29.958 21.3887 30.1646 21.3887H32.3753C32.3753 21.3887 32.3856 21.3887 32.3959 21.3887C32.5509 21.3887 32.7575 21.461 32.8918 21.5747C33.4186 22.0296 33.8938 23.9836 33.7389 24.697C33.6666 25.0278 33.305 25.3586 32.9538 25.6791C32.8298 25.7928 32.7162 25.8962 32.6026 26.0099C32.3856 26.2271 32.334 26.5682 32.4889 26.8474C33.5943 28.791 35.2058 30.4038 37.1582 31.51C37.2615 31.572 37.3855 31.6031 37.4991 31.6031C37.6747 31.6031 37.8607 31.5307 37.995 31.4066C38.0983 31.2929 38.2119 31.1792 38.3255 31.0551C38.6458 30.7036 38.9763 30.3418 39.3069 30.2694C39.3999 30.2487 39.5135 30.2384 39.6478 30.2384C40.5156 30.2384 42.0444 30.6519 42.437 31.1171C42.561 31.2619 42.6229 31.448 42.6229 31.6547V33.8568L42.6023 33.8362Z" fill="#113E57"/>
      </svg>
      
  );
};
