import React, { useState, useMemo, useEffect } from "react";
import {
  useUserAccessories,
  useUserEvents,
  useWeatherData,
} from "./EnvironmentalHooks";
import {
  convertTemperature,
  sortByModelAndLocation,
} from "./EnvironmentalUtils";
import {
  EnvironmentalIcon,
  TemperatureIcon,
  HumidityIcon,
  AmbientLightIcon,
} from "../Common/Icons";
import EnvironmentalCard from "./EnvironmentalCard";
import { Modal, Spin } from "antd";
import "./Environmental.less";
import MotionActivity from "../MotionActivity/MotionActivity";
import TrendChart from "./TrendChart";
import {
  Access,
  checkRoleAccess,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { DefaultTimezone } from "@commscopemycloud/humaui/Utilities/Constants";
import { get_utc_offset } from "../MotionActivity/MotionActivityUtils";

const Environmental = ({
  userInfo,
  hubRecord,
  timezone,
  deviceStatus,
  loginUserTimeformat,
}) => {
  const [tempUnit, setTempUnit] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTrend, setSelectedTrend] = useState(null);
  const [pastDataProcessed, setPastDataProcessed] = useState({});
  const [showPastTrend, setShowPastTrend] = useState(false);
  const [deviceUuidToShowTrend, setDeviceUuidToShowTrend] = useState(null);
  const userAccessoriesDeviceuuid = useUserAccessories(hubRecord);
  const { userEventsData, dataProcessed } = useUserEvents(
    hubRecord,
    userAccessoriesDeviceuuid,
    tempUnit,
    setTempUnit
  );
  const { postalcodeStatus, outdoorWeather, outdoorWeatherFetched } =
    useWeatherData(userInfo, hubRecord, tempUnit);

  const timezoneOffset = useMemo(
    () => get_utc_offset(timezone, DefaultTimezone),
    [timezone]
  );

  const allowShowTrend = useMemo(
    () => checkRoleAccess(Access.beta.labsPreview) && hubRecord,
    [hubRecord]
  );

  const handleTempUnitChange = (unit) => {
    setTempUnit(unit);
  };

  const showTrend = (trend) => {
    if (!allowShowTrend) return;
    setIsModalOpen(true);
    setSelectedTrend(trend);
  };

  useEffect(() => {
    if (
      pastDataProcessed?.outdoorDataFetched &&
      pastDataProcessed?.trendDataFetched &&
      !!deviceUuidToShowTrend
    ) {
      setShowPastTrend(true);
    }
  }, [pastDataProcessed]);

  return (
    <div style={{ padding: "10px" }}>
      <div className="header-container">
        <div className="header-icon-title">
          <div className="header-icon">
            <EnvironmentalIcon
              style={{ fill: "#113E57", width: "29px", height: "32px" }}
            />
          </div>
          <div className="header-title">Environmental</div>
        </div>
        <div className="header-filters">
          <div className="header-filter-content">
            <div className="header-filter-title">Temperature</div>
            <div
              className="header-filter-icon"
              style={{ cursor: allowShowTrend ? "pointer" : null }}
              onClick={() => showTrend("temp")}
            >
              <TemperatureIcon style={{ height: "44px" }} />
            </div>
          </div>
          <div className="header-filter-content">
            <div className="header-filter-title">Humidity</div>
            <div
              className="header-filter-icon"
              style={{ cursor: allowShowTrend ? "pointer" : null }}
              onClick={() => showTrend("humidity")}
            >
              <HumidityIcon />
            </div>
          </div>
          <div className="header-filter-content">
            <div className="header-filter-title">Ambient Light</div>
            <div
              className="header-filter-icon"
              style={{ cursor: allowShowTrend ? "pointer" : null }}
              onClick={() => showTrend("light")}
            >
              <AmbientLightIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="date-toggle-container">
        <div className="date-container">
          <div className="date-title">Latest Conditions</div>
          <div className="date-content">
            {new Date().toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </div>
        </div>
        <div className="toggle-container">
          <button
            className="temperature-button"
            style={{
              backgroundColor:
                tempUnit === "F" ? "rgba(63, 169, 245, 1)" : "grey",
              borderRadius: "30px 0 0 30px",
            }}
            onClick={() => handleTempUnitChange("F")}
          >
            F{"°"}
          </button>
          <button
            className="temperature-button"
            style={{
              backgroundColor:
                tempUnit === "C" ? "rgba(63, 169, 245, 1)" : "grey",
              borderRadius: "0 30px 30px 0",
            }}
            onClick={() => handleTempUnitChange("C")}
          >
            C{"°"}
          </button>
        </div>
      </div>
      <Spin
        spinning={!dataProcessed || !outdoorWeatherFetched}
        style={{ margin: "70px -20px" }}
      >
        {dataProcessed && outdoorWeatherFetched && (
          <div className="cards-container">
            <EnvironmentalCard
              location="Outdoor"
              temperatureVal={convertTemperature(
                outdoorWeather?.temp,
                outdoorWeather?.unit,
                tempUnit
              )}
              humidityVal={outdoorWeather?.humidity ?? null}
              outdoorWeatherIcon={outdoorWeather?.icon}
              grey={!Object.keys(outdoorWeather).length}
              timezone={timezone.split(" ")[0]}
              time={
                Object.keys(outdoorWeather).length ? outdoorWeather?.time : null
              }
              postalCodeValid={postalcodeStatus}
              allowShowTrend={allowShowTrend}
            />
            {userEventsData &&
              Object.entries(userEventsData)
                .sort(sortByModelAndLocation)
                .map(([deviceUuid, eventData]) => {
                  const currentDate = new Date().toISOString().split("T")[0];
                  const eventDataExists = eventData.data !== undefined;

                  const eventDate = eventDataExists
                    ? new Date(eventData?.data?.timeStamp)
                        .toISOString()
                        .split("T")[0]
                    : null;

                  const grey = currentDate !== eventDate;

                  const convertedTemperature = eventDataExists
                    ? Math.floor(
                        convertTemperature(
                          eventData?.data?.temperature,
                          eventData?.data?.tempUnit,
                          tempUnit
                        )
                      )
                    : null;

                  const humidityVal = eventDataExists
                    ? Math.floor(eventData?.data?.humidity)
                    : null;

                  return (
                    <EnvironmentalCard
                      key={deviceUuid}
                      deviceUuid={deviceUuid}
                      location={userAccessoriesDeviceuuid[deviceUuid]?.location}
                      deviceId={
                        eventDataExists ? eventData?.data?.deviceId : null
                      }
                      batteryStatus={
                        userAccessoriesDeviceuuid[deviceUuid]?.batteryLevel
                      }
                      temperatureVal={convertedTemperature}
                      humidityVal={humidityVal}
                      lightVal={
                        eventDataExists ? eventData?.data?.ambientLight : null
                      }
                      connectionStatus={
                        userAccessoriesDeviceuuid[deviceUuid]?.pairedStatus &&
                        userAccessoriesDeviceuuid[deviceUuid]?.connectionStatus
                      }
                      time={eventDataExists ? eventData?.data?.timeStamp : null}
                      trend={eventDataExists ? eventData?.data?.trend : null}
                      timezone={timezone.split(" ")[0]}
                      grey={grey}
                      setPastDataProcessed={setPastDataProcessed}
                      useruuid={hubRecord.useruuid}
                      timezoneOffset={timezoneOffset}
                      setDeviceUuidToShowTrend={setDeviceUuidToShowTrend}
                      allowShowTrend={allowShowTrend}
                    />
                  );
                })}
          </div>
        )}
      </Spin>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          footer={null}
          onCancel={() => setIsModalOpen(false)}
          style={{ minWidth: "1200px" }}
        >
          <div
            className="trend-modal"
            style={{
              minHeight: `${
                Object.keys(userAccessoriesDeviceuuid).length * 280
              }px`,
            }}
          >
            <MotionActivity
              isTrend={true}
              hubRecord={hubRecord}
              timezone={timezone}
              deviceStatus={deviceStatus}
              loginUserTimeformat={loginUserTimeformat}
            />
          </div>
        </Modal>
      )}
      {showPastTrend && (
        <Modal
          open={showPastTrend}
          footer={null}
          onCancel={() => {
            setShowPastTrend(false);
            setDeviceUuidToShowTrend(null);
          }}
          style={{ minWidth: "1200px" }}
        >
          <TrendChart
            outdoorData={pastDataProcessed.outdoorDataFetched}
            indoorData={pastDataProcessed.trendDataFetched}
            deviceUuidToShowTrend={deviceUuidToShowTrend}
            tempUnit={tempUnit}
          />
        </Modal>
      )}
    </div>
  );
};

export default Environmental;
