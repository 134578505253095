import React from "react";

export const NewDoneIcon = (props) => {
  const {
    className = "",
    style = {},
    disabled = false,
    onClick,
    label,
  } = props;

  const width = label === "Unpaired" ? 115 : 92;
  return (
    <>
      <svg
        className={className}
        style={style}
        width={width}
        height="36"
        viewBox={`0 0 ${width} 36`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={width} height="36" rx="18" fill="#4BD37B" />
        <path
          d="M18.333 17.9993L20.7775 20.3327L25.6663 15.666"
          stroke="#0A0A0A"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M22.0003 9.66602C17.4071 9.66602 13.667 13.4061 13.667 17.9993C13.667 22.5926 17.4071 26.3327 22.0003 26.3327C26.5936 26.3327 30.3337 22.5926 30.3337 17.9993C30.3337 13.4061 26.5936 9.66602 22.0003 9.66602ZM22.0003 24.6164C18.3495 24.6164 15.3833 21.6501 15.3833 17.9993C15.3833 14.3486 18.3396 11.3823 22.0003 11.3823C25.661 11.3823 28.6174 14.3486 28.6174 17.9993C28.6174 21.6501 25.661 24.6164 22.0003 24.6164Z"
          fill="#0A0A0A"
        />
        <text
          x="42"
          y="23"
          font-family="Arial"
          font-size="14"
          font-weight="700"
          fill="#0A0A0A"
          color="#0A0A0A"
        >
          {label}
        </text>
      </svg>
    </>
  );
};