/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import "../styles/VideoCall.less";
import Error from "./Common/Error";
import PageNotFound from "./Common/PageNotFound";
import HomesightLoading from "./Common/HomesightLoading";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  initKeycloak,
  keycloak,
} from "@commscopemycloud/humaui/Services/KeycloakService";
import {
  getCurrentUser,
  loginSuccess,
} from "@commscopemycloud/humaui/Store/authStore";
import { formatKeycloakUserInfo } from "@commscopemycloud/humaui/Services/UserService";
import {
  setAccessToken,
  setApiBaseURL,
} from "@commscopemycloud/humaui/Store/apiStore";
import { onLanguageChange } from "@commscopemycloud/humaui/Utilities/Translation";
import { WebSocketProvider } from "./WebSocket/WebsocketContext";
import {
  getUserInfo,
  storeUserInfo,
  storeSiteIconFiles,
  storeSiteIconManifest
} from "@commscopemycloud/humaui/Store/dataStore";
import { storePermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
import {
  NavigationKeys,
  UserTypes,
} from "@commscopemycloud/humaui/Utilities/Constants";
import UserHubs from "./UserHubs/UserHubs";
import Schedule from "./Schedule/Schedule";
import Devices from "./Devices/Devices";
import EditUserProfile from "./UserProfile/EditUserProfile";
import Staff from "./Staff/Staff";
import AboutPage from "./About/AboutPage";
import Notification from "./Notification/Notification";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { useMainContext } from "../MainContext";
import Maintenance from "./Common/Maintenance";
import { checkForMaintenance } from "./WebSocket/WebsocketHelper";
import useUserInfo from "./Hooks/useUserInfo";
import JSZip from 'jszip';
import { AppStorageKeys } from "@commscopemycloud/humaui/Utilities/Constants";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";

let keyc = null;
let t_refreshed = 0;


const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);
const CATEGORY_ICON_S3_BUCKET_LINK = ENV_VARS.CATEGORY_ICON_S3_BUCKET_LINK;

const AppRoutes = () => {
  const { protocol, hostname } = window.location;
  const hostURL = protocol + "//" + hostname;
  const { menuClickKey } = useMainContext();
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [kcError, setKcError] = useState(false);
  const [refreshToken, setRefreshToken] = useState(0);
  const [authenticated, setAuthenticated] = useState(false);
  const [announcement, setAnnouncement] = useState({});
  const trans = useSelector(translator);
  const permissionApi = useSelector((state) => state.apis.permissionApi);
  const maintenanceApi = useSelector((state) => state.apis.maintenanceApi);

  const currentUser = useSelector(getCurrentUser);
  const useruuid = useMemo(() => currentUser?.useruuid, [currentUser]);
  const userInfo = useSelector(getUserInfo(useruuid));
  const [userDetails] = useUserInfo({ useruuid: currentUser.useruuid });

  const fetchPermissions = () => {
    const errorCallback = (error) => {
      console.error("Error fetching permissions", error);
    };
    const successCallback = (data) => {
      console.info("Permissions: fetch result", data);
      dispatch(storePermissions(data));
    };

    try {
      permissionApi.listPermission(
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };
  
  useEffect(() => {
    async function downloadIcons() {
      try {
        const response = await fetch(CATEGORY_ICON_S3_BUCKET_LINK);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const zip = new JSZip();
        const loadedZip = await zip.loadAsync(blob);
        console.log("Files in zip:", Object.keys(loadedZip.files));

        dispatch(storeSiteIconFiles({files:loadedZip}));

        const manifestFile = loadedZip.file("manifest.json");
        if (!manifestFile) {
          throw new Error("manifest.json not found in zip archive");
        }
        const manifestContent = await manifestFile.async("string");
        const manifest = JSON.parse(manifestContent);
        dispatch(storeSiteIconManifest({manifest:manifest}));
      } catch (error) {
        console.error("Error downloading or processing zip:", error);
      }
    }
    downloadIcons();
  }, [dispatch]);
  
  

  useEffect(() => {
    console.info("hostname:", hostname);
    const realm = hostname.split(".")[0];
    console.info("FQDN realm:", realm);
    try {
      keyc = keycloak(realm, hostURL);
      keyc.onTokenExpired = () => {
        keyc
          .updateToken(30)
          .success((keyc) => {
            t_refreshed = 1;
            setRefreshToken(1);
            console.info("Successfully refreshed token:", t_refreshed, keyc);
          })
          .error((error) => {
            console.error("Error getting a new token", error);
            keyc.logout();
          });
      };

      initKeycloak()
        .then((kc) => {
          console.info("keycloak login success:", kc);
          // localStorage.clear(); // clear local storage after login
          localStorage.removeItem("providerData");
          setAuthenticated(true);
          const userInfo = formatKeycloakUserInfo(kc);
          console.debug("CurrentUser:", userInfo);
          /* actions for login success */
          setToken(userInfo.token);
          dispatch(setApiBaseURL(hostURL));
          dispatch(setAccessToken(userInfo.token));
          dispatch(loginSuccess(userInfo));
          dispatch(storeUserInfo(userInfo));
          onLanguageChange();
        })
        .catch((error) => {
          setKcError(true);
        });
    } catch (error) {
      message.error("Keycloak authentication error!");
      console.error("Keycloak authentication error:", error);
      setKcError(true);
    }
  }, []);

  useEffect(() => {
    console.debug("Refresh useeffect called: ", t_refreshed);
    if (t_refreshed === 0) return;
    setRefreshToken(0);
    t_refreshed = 0;
    console.debug("Refresh token useeffect");
    setAuthenticated(true);
    const userInfo = formatKeycloakUserInfo(keyc);
    /* actions for login success */
    setToken(userInfo.token);
    dispatch(setAccessToken(userInfo.token));
    onLanguageChange();
  }, [refreshToken]);

  useEffect(() => {
    if (authenticated) {
      fetchPermissions();
      if (!!Object.keys(userDetails).length) {
        checkForMaintenance(
          userDetails,
          setAnnouncement,
          maintenanceApi,
          trans
        );
      }
    }
  }, [authenticated, userDetails]);

  if (kcError) {
    return (
      <Error
        message="Login Failed!"
        description="Error initilizing Keycloak."
      />
    );
  } else if (!authenticated) {
    return <HomesightLoading />;
  }

  const hasAccess = (userType) => {
    return userType === UserTypes.providerAdmin || userType === UserTypes.staff;
  };

  return (
    <WebSocketProvider
      hostname={hostname}
      token={token}
      setAnnouncement={setAnnouncement}
    >
      {!!announcement?.message && (
        <Maintenance
          type={announcement?.type}
          message={announcement?.message}
          onClose={() => setAnnouncement({})}
        />
      )}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <div key={menuClickKey}>
              <UserHubs />
            </div>
          }
        />
        <Route
          exact
          path={"/" + NavigationKeys.accounts}
          element={
            <div key={menuClickKey}>
              <UserHubs />
            </div>
          }
        />
        <Route
          exact
          path={"/" + NavigationKeys.schedule}
          element={
            <div key={menuClickKey}>
              <Schedule />
            </div>
          }
        />
        <Route
          exact
          path={"/" + NavigationKeys.staff}
          element={
            hasAccess(userInfo.usertype) ? (
              <div key={menuClickKey}>
                <Staff />
              </div>
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          exact
          path={"/" + NavigationKeys.about}
          element={
            <div key={menuClickKey}>
              <AboutPage />
            </div>
          }
        />
        <Route
          exact
          path={"/" + NavigationKeys.devices}
          element={
            hasAccess(userInfo.usertype) ? (
              <div key={menuClickKey}>
                <Devices />
              </div>
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          exact
          path={"/" + NavigationKeys.profile}
          element={
            <div key={menuClickKey}>
              <EditUserProfile />
            </div>
          }
        />
        <Route
          exact
          path={"/" + NavigationKeys.notification}
          element={
            <div key={menuClickKey}>
              <Notification />
            </div>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </WebSocketProvider>
  );
};

export default AppRoutes;
