import React from "react";

export const NewRestartIcon = (props) => {
  const {
    className = "",
    disabled = false,
    onClick,
    style = {},
    fillColor,
  } = props;
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2128 11.3936V7.48936C11.2128 7.03191 11.5532 6.65957 11.9681 6.65957C12.383 6.65957 12.7234 7.03191 12.7234 7.48936V11.3936C12.7234 11.8511 12.383 12.2234 11.9681 12.2234C11.5532 12.2234 11.2128 11.8511 11.2128 11.3936ZM14.6489 9.29787C15.3617 10.0106 15.7553 10.9681 15.7553 11.9787C15.7553 14.0638 14.0532 15.7766 11.9574 15.7766C9.8617 15.7766 8.15957 14.0745 8.15957 11.9787C8.15957 10.9574 8.55319 10.0106 9.2766 9.29787L8.20213 8.2234C7.20213 9.2234 6.64894 10.5638 6.64894 11.9787C6.64894 14.9043 9.03191 17.2872 11.9574 17.2872C14.883 17.2872 17.266 14.9043 17.266 11.9787C17.266 10.5638 16.7128 9.2234 15.7128 8.2234L14.6383 9.29787H14.6489ZM21.9894 12C21.9894 17.5106 17.5 22 11.9894 22C6.47872 22 2 17.5106 2 12C2 6.48936 6.48936 2 12 2C17.5106 2 22 6.48936 22 12H21.9894ZM20.1596 12C20.1596 7.48936 16.5106 3.84043 12 3.84043C7.48936 3.84043 3.84043 7.48936 3.84043 12C3.84043 16.5106 7.48936 20.1596 12 20.1596C16.5106 20.1596 20.1596 16.5106 20.1596 12Z"
      fill={fillColor}/>
    </svg>
  );
};
