import React, { useState } from "react";
import { DatePicker, Space, ConfigProvider } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/en-gb";
import locale from "antd/es/locale/en_GB";
import { ScheduledIcon } from "./Icons";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const CustomDateRangePicker = ({ onDateChange, selectedDates, disabled, repeatValue = "" }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(selectedDates || []);

  const disabledPastAndFutureDates = (current) => {
    const today = dayjs().startOf("day");
    const maxDate = dayjs().add(1, "year").endOf("day");
    if (current && dayjs(current).isBefore(today)) {
      return true;
    }
    if (current && dayjs(current).isAfter(maxDate)) {
      return true;
    }
    return false;
  };

  const handleDateChange = (dates) => {
    setValue(dates || []);
    if (onDateChange) {
      onDateChange(dates);
    }
  };

  const handleOpenChange = (isOpen) => {
    if (isOpen && value?.[0] && !value?.[1]) {
      setOpen(true);  // Keep the picker open with start date selected
    } else {
      setOpen(isOpen);
    }
  };

  const handleBlur = () => {
    if (value?.[0] && !value?.[1]) {
      setOpen(false);
    }
  };

  return (
    <ConfigProvider locale={locale}>
      <Space direction="vertical" size={12}>
        <div className="custom-rangepicker-container">
          <span className="custom-prefix-icon">
            <ScheduledIcon />
          </span>
          <RangePicker
            value={value}
            onChange={handleDateChange}
            format="ddd, MMM Do"
            disabledDate={disabledPastAndFutureDates}
            onOpenChange={handleOpenChange}
            open={open}
            allowClear={true}
            suffixIcon={null}
            placeholder={["Start Date", "End Date"]}
            separator={"to"}
            onBlur={handleBlur}
            disabled={disabled}
          />
        </div>
      </Space>
    </ConfigProvider>
  );
};

export default CustomDateRangePicker;
