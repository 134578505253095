/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import dayjs from "dayjs";
import MainPage from "../../MainPage";
import { Form, Input, TimePicker, message, Select, Spin } from "antd";
import {
  Actions,
  DateFormat,
  EventTypes,
  labelMapping
} from "@commscopemycloud/humaui/Utilities/Constants";
import { SingleDatePicker, HSDayPicker } from "../Common/HSWidget";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { ButtonTypes } from "../Common/CustomButton";
import { StringFormats } from "@commscopemycloud/humaui/Utilities/Constants";
import { formatString, getUsername, objectSort } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import {
  CloseIcon,
  AddTaskIcon,
  NewCloseIcon,
  NewUpdateIcon,
  SaveChangesIcon,
  DeleteIcon,
  CallIcon,
  NewNotificationIcon,
  AttachmentLinkIcon,
  EditIcon,
} from "../Common/Icons";
import CustomDateRangePicker from "../Common/CustomDateRangePicker";
import NewCustomButton from "../Common/NewCustomButton";
import NonRecurringTaskModal from "./NonRecurringTaskModal";
import RecurringTaskModal from "./RecurringTaskModal";
import TaskModal from "./TaskModal";
import ContactSelectList from "../Common/ContactSelectList";
import { getContacts } from "../Schedule/Contacts";

const currentSiteIconZip = state => state.data.siteIconFiles;
const currentSiteIconManifest = state => state.data.siteIconManifest;

export const TaskForm = (props) => {
  const {
    onlyContent,
    action,
    hubRecord,
    userInfo,
    taskRecord,
    onActionClick,
    openSubMenu = false,
    showHeader = false,
    loginUserTimeformat,
    eventRecord,
    closeModalHandler,
    showModal,
    userTimezone,
    contacts,
    fetchingContacts,
    staffList,
    fetchingStaffList,
    selectedUser,
    reFetchList,
  } = props;
  console.debug("Task js Props", props);
  const [form] = Form.useForm();
  const trans = useSelector(translator);
  const taskApi = useSelector((state) => state.apis.taskApi);
  const userApi = useSelector((state) => state.apis.userApi);
  const scheduleApi = useSelector((state) => state.apis.scheduleApi);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const siteIconZip = useSelector(currentSiteIconZip);
  const siteIconManifest = useSelector(currentSiteIconManifest);

  useEffect(() => {
    async function buildCategoryOptions() {
      if (!siteIconZip || !siteIconManifest || typeof siteIconZip.file !== "function") {
        console.error('Invalid Zip file.')
        return;
      }
      const options = [];
      for (const mainCat of Object.keys(siteIconManifest)) {
        const subcats = siteIconManifest[mainCat];
        for (const subcat of Object.keys(subcats)) {
          const fileName = subcats[subcat];
          const file = siteIconZip.file(fileName);
          if (!file) {
            console.error(`File ${fileName} not found in zip for subcategory "${subcat}"`);
            continue;
          }
          try {
            const blob = await file.async("blob");
            let blobToUse = blob;
            if (!blob.type || blob.type === "") {
              blobToUse = new Blob([blob], { type: "image/svg+xml" });
            }
            const url = URL.createObjectURL(blobToUse);
            const subcatDisplay = formatString(subcat, StringFormats.titleCase);
            const mainCatDisplay = formatString(mainCat, StringFormats.titleCase);
            options.push({
              value: `${subcat}`,
              label: (
                <div style={{ display: "flex" }}>
                  <img
                    src={url}
                    alt={subcatDisplay}
                    style={{
                      width: 57,
                      height: 41,
                      marginTop: 10
                    }}
                  />
                  <div className="category-subCategory-dropdown">
                    <span>
                      {subcatDisplay}
                    </span>
                    <span style={{ fontSize: "14px", color: "#666" }}>
                      {mainCatDisplay}
                    </span>
                  </div>
                </div>
              ),
              displayText: subcatDisplay,
            });
          } catch (error) {
            console.error(`Error loading file ${fileName} for subcategory "${subcat}":`, error);
          }
        }
      }
      options.sort((a, b) => a.displayText.localeCompare(b.displayText));
      setCategoryOptions(options);
    }
    buildCategoryOptions();
  }, [siteIconZip, siteIconManifest]);

  const ReminderDuration = [5, 10, 15, 30, 60];

  const FormattedReminderDuration = ReminderDuration.map((d) => ({
    value: d,
    label: labelMapping[d] || `${d} minutes`,
  }));
 
  // Filter the EventTypes object to include only "zoom" and "schedule"
  const SortedEventTypes = Object.keys(EventTypes)
  .filter((key) => key === "zoom" || key === "schedule")
  .map((key) => ({ key, value: EventTypes[key] }))
  .sort(objectSort("value"));

  const formatDateTime = (dateMomentObj, hours = 0, minutes = 0) => {
    return moment
      .utc()
      .year(dateMomentObj.year())
      .month(dateMomentObj.month())
      .date(dateMomentObj.date())
      .hours(hours)
      .minutes(minutes)
      .seconds(0)
      .milliseconds(0)
      .format();
  };

  const FormItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const LocalTimezone = userTimezone ??  Intl.DateTimeFormat().resolvedOptions().timeZone;

  // const isEditForm = useMemo(() => action === Actions.editTask, [action]);
  const isReminderAction =
    action === Actions.addReminderEvent || action === Actions.editReminderEvent;
    
  const isEditForm = useMemo(() => {
    if (
      action === Actions.editTask ||
      action === Actions.editEvent ||
      action === Actions.editReminderEvent
    ) {
      return true;
    }
    return false;
  }, [action]);

  const isCategoryAllowed = useMemo(() => {
    if (
      action === Actions.editTask ||
      action === Actions.addTask 
    ) {
      return true;
    }
    return false;
  }, [action]);

  const isEventCallTypeAllowed = useMemo(() => {
    if (
      action === Actions.addEvent ||
      action === Actions.editEvent ||
      action === Actions.addReminderEvent ||
      action === Actions.editReminderEvent
    ) {
      return true;
    }
    return false;
  }, [action]);

  const getFormText = (action, type) => {
    const texts = {
      add_event: { header: "Call details", placeholder: "Add a name" },
      edit_event: { header: "Call details", placeholder: "Add a name" },
      reminder_event: { header: "Reminder details", placeholder: "Reminder name" },
      edit_reminder_event: { header: "Reminder details", placeholder: "Reminder name" },
      add_task: { header: "Task Information", placeholder: "Task name" },
      edit_task: { header: "Task Information", placeholder: "Task name" },
      default: { header: "Task Information", placeholder: "Task name" },
    };
  
    return texts[action] ? texts[action][type] : texts.default[type];
  };
  // Define a function to get headers
  const getModalHeaderOnActions = (action, eventRecord, formData) => {
      switch (action) {
      case "add_event":
      case "edit_event":
        return (
          <div className="form-title">
            <span>
              {isEditForm
                ? `Edit ${formData?.event_name} Call`
                : "Create call"}
            </span>
          </div>
        );
      case "reminder_event":
      case "edit_reminder_event":
        return (
          <div className="form-title">
            <span>
              {isEditForm
                ? `Edit ${formData?.event_name} reminder`
                : "Create reminder"}
            </span>
          </div>
        );
      case "add_task":
      case "edit_task":
        return (
          <div className="form-title">
            <span>
              {isEditForm ? `Edit ${formData?.event_name} task` : "Create task"}
            </span>
          </div>
        );
      default:
        return action;
    }
  };
  const isEventRecurring = useMemo(() => {
    return eventRecord?.repeat && ["daily", "custom", "weekly"].includes(eventRecord?.repeat);
  }, [eventRecord]);
  const [checkedDays, setCheckedDays] = useState(
    isEditForm ? eventRecord?.days_of_week : []
  );
  const TaskRepeats = useMemo(() => {
    return {
      none: "Doesn't repeat",
      daily: "Daily",
      weekly: `Weekly ${checkedDays?.length ? `(${checkedDays[0].charAt(0).toUpperCase() + checkedDays[0].slice(1).toLowerCase()})` : ""}`,
      custom: "Customize",
    };
  }, [checkedDays]);
  
  const handleClose = () => {
    closeModalHandler(true);
};
  const username = useMemo(() => getUsername(hubRecord), [hubRecord]);

  const SortedRepeats = Object.keys(TaskRepeats)
    .map((key) => ({ value: key, label: TaskRepeats[key] }));

  const [processing, setProcessing] = useState(false);
  const [isReoccuring, setIsRecouring] = useState(
    taskRecord?.recurring || false
  );
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));

  const [contactsList, setContactsList] = useState([]);
  const [userContactList, setUserContactList] = useState([]);
  const [initialContactsList, setInitialContactsList] = useState([]);
  const [initialUserContactList, setInitialUserContactList] = useState([]);
  const [loading, setLoading] = useState(true);

  const sortedContactList = useMemo(
    () => contactsList.sort(objectSort("firstname")),
    [contactsList]
  );
  const sortedUserContactList = useMemo(
    () => userContactList.sort(objectSort("firstname")),
    [userContactList]
  );

  function convertToUserTimezone(dateString, timeString, timezone, extract) {
    const datePartOnly = dateString?.split("T")[0];
    const utcDateTimeString = `${datePartOnly}T${timeString}Z`;
    const utcDateTime = new Date(utcDateTimeString);
    if (isNaN(utcDateTime)) {
      console.error("Invalid Date-Time:", datePartOnly, timeString);
      return "";
    }
    const userDateTime = dayjs.utc(utcDateTime).tz(timezone);
    const formattedDate = userDateTime.format("YYYY-MM-DD");
    const formattedTime = userDateTime.format("HH:mm:ss");
    return extract === "date" ? formattedDate : extract === "time" ? formattedTime : { date: formattedDate, time: formattedTime };
  }

  function revertUTCtoUserTimezone(eventRecord, userTimezone) {
    const startDateTime = convertToUserTimezone(eventRecord?.event_startdate, eventRecord?.event_starttime, userTimezone, "full");
    const endDate = convertToUserTimezone(eventRecord?.event_enddate, eventRecord?.event_starttime, userTimezone, "date");
    const endTime = convertToUserTimezone(eventRecord?.event_startdate, eventRecord?.event_endtime, userTimezone, "time");
    return {
      startDate: startDateTime.date,
      startTime: startDateTime.time,
      endDate: endDate,
      endTime: endTime
    };
  }

  const localEvent = isEditForm ? revertUTCtoUserTimezone(eventRecord, LocalTimezone) : null;
  const getEventTypeKey = (value) => {
    return Object.keys(EventTypes).find(key => EventTypes[key] === value);
  };
  const [formData, setFormData] = useState({
    reminder: isEditForm && eventRecord?.reminder ? eventRecord?.reminder / 60 : 30,
    category: isEditForm ? eventRecord?.category : '--Choose Category--',
    repeat: isEditForm ? eventRecord?.recurring ? eventRecord?.repeat : 'none' : TaskRepeats.none,
    eventType : isEditForm ? EventTypes[eventRecord?.event_type]: isReminderAction ? EventTypes.reminder : EventTypes.schedule,
    url: eventRecord?.meeting_url ?? "",
    event_name: isEditForm ? eventRecord?.event_name : "",
    event_description: isEditForm ? eventRecord?.event_description : "",
    starttime: isEditForm && localEvent
      ? dayjs(localEvent.startTime, "HH:mm:ss")
      : null,
    endtime: isEditForm && localEvent
      ? dayjs(localEvent.endTime, "HH:mm:ss")
      : null,
  });
  const [activeTab, setActiveTab] = useState("schedule");
  const [selectedRangeDates, setSelectedRangeDates] = useState(() => {
    if (isEditForm && localEvent?.startDate) {
      if (localEvent?.endDate) {
        return [
          dayjs(localEvent.startDate, "YYYY-MM-DD").startOf("day"),
          dayjs(localEvent.endDate, "YYYY-MM-DD").endOf("day"),
        ];
      } else {
        return [
          dayjs(localEvent.startDate, "YYYY-MM-DD").startOf("day"),
          dayjs(localEvent.startDate, "YYYY-MM-DD").endOf("day")
        ];
      }
    }
    return [];
  });

  useEffect(() => {    
    let { invited_contacts, userHubsData, useruuid } = eventRecord || {};
    const canInviteList = [...contacts, ...(staffList ?? [])];
    const contactList = !isEditForm
      ? canInviteList.filter(
          (item) => useruuid !== item.useruuid && item.emailVerified === true
        )
      : canInviteList.filter(
          (item) =>
            !invited_contacts?.includes(item.useruuid) &&
            useruuid !== item.useruuid &&
            item.emailVerified === true
        );
    const uniqueContactList = [
      ...new Map(contactList.map((a) => [a.useruuid, a])).values(),
    ];
    setUserContactList(uniqueContactList);
    const list = [];
    if (eventRecord && Object.keys(eventRecord).length > 0) {
      list.push(eventRecord);
    } else {
      list.push(hubRecord);
    }
    const invitedContacts = isEditForm
      ? getContacts(invited_contacts, [
          ...(contacts ?? []),
          ...(userHubsData ?? []),
          ...(userInfo ? [userInfo] : []),
          ...(staffList ?? []),
        ])
      : list;
      setContactsList(invitedContacts);
      setInitialContactsList(invitedContacts);
      setInitialUserContactList(uniqueContactList)
    if (!fetchingContacts && !fetchingStaffList)
      invitedContacts
        .filter((item) => !("firstname" in item))
        .forEach((item) => {
          fetchData(item.useruuid);
        });
    setLoading(false);
  }, [eventRecord, contacts, staffList, fetchingContacts, fetchingStaffList]);


  const addContact = (contact) => {
    setContactsList((prevList) => {
      // Check for duplicates using useruuid
      if (!prevList.some((item) => item.useruuid === contact.useruuid)) {
        return [...prevList, contact];
      }
      return prevList; // No change if contact is already in the list
    });

    setUserContactList((prevList) =>
      prevList.filter((item) => item.useruuid !== contact.useruuid)
    );
  };

  const removeCard = (contact) => {
    setContactsList((prevList) =>
      prevList.filter((item) => item.useruuid !== contact.useruuid)
    );
  };
  const [initialFormData] = useState(formData);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isSaveChangesModalVisible, setIsSaveChangesModalVisible] = useState(false);  

  const handleSaveChanges = () => {
    setIsSaveChangesModalVisible(true);
  };
  const handleSaveChangesModalCancel = () => {
    setIsSaveChangesModalVisible(false);
  };    
  const handleSaveChangesModalConfirm = () => {
    setIsSaveChangesModalVisible(false);
    form.setFieldsValue(initialFormData);
    setFormData(initialFormData);
    setContactsList(initialContactsList);
    setUserContactList(initialUserContactList);
    setSelectedDate(dayjs(eventRecord?.event_startdate));
  };

  const handleDeleteClick = () => {
    setIsDeleteModalVisible(true);
  };
  const handleModalConfirm = () => {
    setIsDeleteModalVisible(false);
    deleteTasks();
  };
  const handleModalCancel = () => {
    setIsDeleteModalVisible(false);
  };
  const deleteTasks = () => {
    setProcessing(false);
    const errorCallback = (error) => {
      setProcessing(false);
      message.error("Error deleting Event!");
      console.error("Error deleting Event:", error);
    };
    const successCallback = (data) => {
      setProcessing(false);
      message.success("Event deleted successfully!");
      console.info("Event deleted successfully:", data);
      reFetchList();
      closeModalHandler();
      onActionClick(null, null, activeTab, true)();
    };
    try {
      setProcessing(true);
      if (isEventRecurring) {
        if ([Actions.editEvent, Actions.editReminderEvent].includes(action)) {
          scheduleApi.cancelAllRecurringEvent(
            eventRecord?.useruuid,
            eventRecord?.schedule_eventuuid,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
        } else {
          taskApi.cancelAllRecurringTasks(
            eventRecord?.useruuid,
            eventRecord?.schedule_eventuuid,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
        }
      } else {
        if ([Actions.editEvent, Actions.editReminderEvent].includes(action)) {
          scheduleApi.cancelEvent(
            eventRecord?.useruuid,
            eventRecord?.schedule_eventuuid,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
        } else {
          taskApi.cancelTask(
            eventRecord?.useruuid,
            eventRecord?.schedule_eventuuid,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
        }
      }
    } catch (error) {
      errorCallback(error);
    }
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
    onActionClick(null, null, key)();
  };
  const get_utc_offset = (timeZone) => {
    return Intl.DateTimeFormat(navigator.language, {
      timeZoneName: "longOffset",
      timeZone,
    })
      .formatToParts()
      .find((i) => i.type === "timeZoneName").value;
  };
  const timezonedisplay = LocalTimezone + ` (${get_utc_offset(LocalTimezone)})`;

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleDateChange = (dates) => {
    setSelectedRangeDates(dates);
  };
  useEffect(() => {
    if (isEditForm) {
      const combineDateTime = (dateStr, timeStr) => {
        const datePartOnly = dateStr?.split("T")[0];
        return `${datePartOnly}T${timeStr}Z`;
      };
      const eventStartDate = combineDateTime(eventRecord?.event_startdate, eventRecord?.event_starttime)
      setSelectedDate(eventStartDate)
    }
  }, [eventRecord, action])
  const handleDateSelect = (date) => {
    if (!date || !date.isValid()) {
      console.error('Invalid date passed to handleDateSelect:', date);
      return;
    }
    const normalizedDate = date.startOf('day').toDate();
    setSelectedDate(normalizedDate);
    form.setFieldsValue({ startDate: normalizedDate });
  };
  const disablePreviousDates = (current) => {
    return current && current.isBefore(dayjs().startOf('day'), 'day');
  };
  const renderDatePicker = () => {
    if (formData?.repeat === TaskRepeats.none || formData?.repeat === "none") {
      return (
        <Form.Item label={"Date*"} name="date">
          <SingleDatePicker
            DateFormat={DateFormat}
            timeZone={userTimezone}
            defaultValue={selectedDate}
            onDateSelect={handleDateSelect}
            value={selectedDate ? dayjs(selectedDate).tz(userTimezone, true) : null}
          />
        </Form.Item>
      );
    } else {
      return (
        <Form.Item label={"Date*"} name="date" initialValues={{ date: [] }}>
          <CustomDateRangePicker
            onDateChange={handleDateChange}
            selectedDates={selectedRangeDates}
            disabled={isEditForm}
          />
        </Form.Item>
      );
    }
  };

  useEffect(() => {
    const { event_name, starttime, endtime, repeat, category, eventType, url } = formData;
    const normalizedRepeat = repeat === "Doesn't repeat" ? "none" : repeat || "none";
    const areRequiredFieldsFilled = !!(
      event_name &&
      starttime &&
      endtime &&
      (!isCategoryAllowed || (category && category.trim() !== "" && category !== "--Choose Category--")) &&
      (!isEventCallTypeAllowed || eventType)
    );
    const isRepeatValid =
      normalizedRepeat === "weekly"
        ? checkedDays?.length === 1 && selectedRangeDates?.length === 2
        : normalizedRepeat === "custom"
          ? checkedDays?.length > 0 && selectedRangeDates?.length === 2
          : normalizedRepeat === "daily"
            ? selectedRangeDates?.length === 2
            : true;
    const isContactSelected = sortedContactList.length > 0;
    const zoomUrlPattern = /https:\/\/[\w-]*\.?zoom.us\/(j|my)\/[\d\w?=-]+/;
    const isZoomUrlValid = eventType === EventTypes.zoom ? zoomUrlPattern.test(url.trim()) : true

    setIsCreateButtonDisabled(!(areRequiredFieldsFilled && isRepeatValid && isContactSelected && isZoomUrlValid));
  }, [formData, sortedContactList, selectedRangeDates, checkedDays, isCategoryAllowed, isEventCallTypeAllowed]);
  
  useEffect(() => {
    const isFormChanged = Object.keys(formData).some((key) => {
      if ((isEditForm && selectedDate != eventRecord?.event_startdate)) {
        return true;
      }
      if (key === "starttime" || key === "endtime") {
        return !dayjs(formData[key]).isSame(dayjs(initialFormData[key]), 'minute');
      } else {
        return formData[key] !== initialFormData[key];
      }
    });
    setIsSaveButtonEnabled(isFormChanged);
  }, [formData, initialFormData, selectedDate]);

  const handleValueChange = (name, value) => {
    if (name === "starttime") {
      const newEndTime = value ? value.add(1, "hour") : null;
      setFormData((prev) => ({ ...prev, starttime: value, endtime: newEndTime }));
      form.setFieldsValue({ endtime: newEndTime });
    } else if (name === "repeat") {
      setCheckedDays([]);
      setFormData((prev) => ({ ...prev, repeat: value }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };
  
  const handleOnDayChange = useCallback((days) => {
    setCheckedDays((prev) => {
      if (
        prev.length === days.length &&
        prev.every((val, idx) => val === days[idx])
      ) {
        return prev;
      }
      return days;
    });
  }, []);

  const fetchData = (mUseruuid) => {
    const errorCallback = (error) => {
      message.error("Error fetching user information!");
      console.error("Error fetching user information:", error);
    };
    const successCallback = (data) => {
      console.info("UserInfo: fetch result", data);
      setContactsList((prevState) =>
        prevState.map((item) => {
          if (item.useruuid === data?.user?.useruuid) return data.user;
          else return item;
        })
      );
    };
    try {
      if (!mUseruuid) return;
      userApi.getUserById(
        mUseruuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const handleSendClick = () => {
    form
      .validateFields()
      .then(() => onFormSubmit())
      .catch((error) => {
        console.error('Form Field Error', error);
        message.error("Please fill in all required fields")
      });
  };
  const onFormSubmit = () => {
    const errorCallback = (error) => {
      setProcessing(false);
      const msg = `Error ${isEditForm ? "Updating" : "Creating"} Event!`;
      message.error(msg);
      console.error(msg, error);
    };
    const successCallback = (data) => {
      setProcessing(false);
      reFetchList();
      closeModalHandler();
      const msg = `Event ${isEditForm ? "Updated" : "Created"} Successfully!`;
      message.success(msg);
      console.info(msg, data);
    };
    try {
      setProcessing(true);
      const userUuid = hubRecord.useruuid;
      const formattedDates = selectedRangeDates.map((date) =>
        moment(date.$d).format("YYYY-MM-DD")
      );
      const eventTypeKey = getEventTypeKey(formData?.eventType);
      const startTime24 = dayjs(formData?.starttime.$d).format("HH:mm");
      const endTime24 = dayjs(formData?.endtime.$d).format("HH:mm");
      const opts = {
        [isEventCallTypeAllowed ? 'createEvent' : 'createTask']: {
            contacts : isEventCallTypeAllowed ? contactsList.map((contact) => ({
              useruuid: contact.useruuid,
            })): [{ useruuid: userUuid }],
            ...(isEventCallTypeAllowed && {
              event_type:
                formData?.eventType === EventTypes.zoom
                  ? "zoom"
                  : formData?.eventType === EventTypes.reminder
                  ? "reminder"
                  : formData?.eventType === EventTypes.schedule
                  ? "schedule"
                  : ""              
            }),    
          ...(formData?.eventType === EventTypes.zoom) && {zoom : {url: formData?.url}},    
          event_name: formData?.event_name,
          event_description: formData?.event_description,
          ...(isCategoryAllowed && { category: formData?.category }),
          event_startdate: selectedDate
            ? dayjs(selectedDate).format("YYYY-MM-DD")
            : null,
          ...(formData?.repeat &&
            formData?.repeat !== TaskRepeats.none &&
            formData?.repeat !== "none" && {
              repeat: formData?.repeat,
              days_of_week: checkedDays,
              event_enddate: formattedDates[1],
              event_startdate: formattedDates[0],
            }),
          timezone: timezonedisplay,
          reminder:
            formData?.reminder > 0 ? formData?.reminder * 60 : formData?.reminder,
          snoozable: formData?.reminder > 0 ? true : false,
          event_starttime: startTime24,
          event_endtime: endTime24,
          ...(isEventCallTypeAllowed && {schedule_eventuuid: eventRecord?.schedule_eventuuid})
        },
      };
      if (isEditForm) {
        if (action === Actions.editTask) {
          taskApi.updateTask(
            userUuid,
            eventRecord?.schedule_eventuuid,
            opts,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
        } else if (
          [Actions.editEvent, Actions.editReminderEvent].includes(action)
        ) {
          scheduleApi.udpateEvent(
            userUuid,
            eventRecord?.schedule_eventuuid,
            opts,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
        }
      } else {
        if (action === Actions.addTask) {
          taskApi.createTask(
            userUuid,
            opts,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
        } else if (
          [Actions.addEvent, Actions.addReminderEvent].includes(action)
        ) {
          scheduleApi.createEvent(
            userUuid,
            opts,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
        }
      }
    } catch (error) {
      errorCallback(error);
    }
  };
  const renderDeleteModal = () => {
    if (!isDeleteModalVisible) return null;
    return isEventRecurring ? (
      <RecurringTaskModal
        isModalOpen={isDeleteModalVisible}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
        modalHeader={`Delete periodic ${eventRecord?.event_type === "reminder" ? "reminder" : eventRecord?.event_type === "tasks" ? "task" : "call"}`}
      />
    ) : (
      <NonRecurringTaskModal
        isModalOpen={isDeleteModalVisible}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
        showDeleteMsg={true}
        modalHeader={`Delete ${eventRecord?.event_type === "reminder" ? "reminder" : eventRecord?.event_type === "tasks" ? "task" : "call"}`}
        modalType= {eventRecord?.event_type === "reminder" ? "reminder" : eventRecord?.event_type === "tasks"? "task" : "call"}
        actionType = "delete"
        className="recurring-event-modal"
      />
    );
  };
  const renderNonRecurringModal = () => {
    if (!isSaveChangesModalVisible) return null;
    return (
      <NonRecurringTaskModal
        isModalOpen={isSaveChangesModalVisible}
        onConfirm={handleSaveChangesModalConfirm}
        onCancel={handleSaveChangesModalCancel}
        showDeleteMsg={false}
        modalHeader="Unsaved changes"
        saveChangesAction={true}
      />
    );
  };
  const render = (
    <div className="taskform-container">
      <Spin size="large" spinning={processing} tip={trans("PROCESSING")}>
        {renderDeleteModal()}
        {renderNonRecurringModal()}
        <Form
          form={form}
          initialValues={formData}
          {...FormItemLayout}
          className="taskform-container"
        >
          <div className="taskform-card">
            <div className="task-title">
              {getModalHeaderOnActions(action, eventRecord, formData)}
              <div>
                <CloseIcon
                  style={{ height: "20px", width: "20px", cursor: "pointer", fill: "#4E758E"}}
                  onClick={() => handleClose()}
                />
              </div>
            </div>
            <div className="form-items-container">
              <div className="column-1" style={{ width: "825px"}}>
                <span className="general-info-text">{getFormText(action, "header")}</span>
                <div className="column-1-border">
                  {isCategoryAllowed && (
                    <div className="form-group">
                      <Form.Item
                        name="category"
                        label="Category*"
                        className="form-group-select"
                      >
                        <Select
                          className="select-type"
                          options={categoryOptions}
                          onChange={(e) => handleValueChange("category", e)}
                          value={formData?.category}
                          defaultValue={formData?.category}
                        />
                      </Form.Item>
                    </div>
                  )}
                  <div className="form-group" style={{ marginTop: "0px" }}>
                    <Form.Item
                      label={
                        action === Actions.addEvent ||
                        action === Actions.editEvent
                          ? "Name the call*"
                          : "Name*"
                      }
                      name="event_name"
                    >
                      <Input
                        placeholder={getFormText(action, "placeholder")}
                        name="event_name"
                        onChange={handleInputChange}
                        defaultValue={formData?.event_name}
                      />
                    </Form.Item>
                  </div>
                  {isEventCallTypeAllowed && (
                    <Form.Item
                      label={
                        action === Actions.addEvent ||
                        action === Actions.editEvent
                          ? "Call type*"
                          : "Event type*"
                      }
                      name="eventType"
                      className="form-group-select"
                    >
                      <Select
                        disabled={isEditForm}
                        onChange={(e) => handleValueChange("eventType", e)}
                        defaultValue={EventTypes.schedule}
                        value={formData?.eventType}
                        name="eventType"
                        className="select-type"
                      >
                        {action === Actions.addReminderEvent ||
                        action === Actions.editReminderEvent ? (
                          <Select.Option
                            key="reminder"
                            value={EventTypes.reminder}
                          >
                            {EventTypes.reminder}
                          </Select.Option>
                        ) : (
                          SortedEventTypes.map((option) => (
                            <Select.Option
                              key={option.key}
                              value={option.value}
                            >
                              {option.value}
                            </Select.Option>
                          ))
                        )}
                      </Select>
                    </Form.Item>
                  )}
                  {form.getFieldValue("eventType") === EventTypes.zoom && (
                    <div className="event-zoom-container">
                      <div className="zoom-card">
                        <div
                          className="form-group"
                          style={{ marginTop: "30px" }}
                        >
                          <Form.Item
                            label={""}
                            name="url"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the URL.",
                              },
                              {
                                pattern: /https:\/\/[\w-]*\.?zoom.us\/(j|my)\/[\d\w?=-]+/,
                                message:
                                  "Please enter a valid Zoom Meeting URL.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="paste meeting link here"
                              className="zoom-url-display"
                              style={{ width: "100%" }}
                              name="url"
                              onChange={(e) => handleInputChange(e)}
                              defaultValue={formData?.url} 
                              prefix={Actions.editEvent === "edit_event" && <AttachmentLinkIcon />} // Add attachment icon here
                              suffix={Actions.editEvent === "edit_event" && <EditIcon />} // Add edit button here
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="form-row" style={{ marginTop: "25px" }}>
                    <Form.Item
                      name="repeat"
                      className="form-group-select"
                      label={"Repeats*"}
                    >
                      <Select
                        onChange={(e) => handleValueChange("repeat", e)}
                        defaultValue={TaskRepeats.none}
                        value={formData?.repeat}
                        name="repeat"
                        className="select-type"
                        options={SortedRepeats}
                        disabled={isEditForm}
                      />
                    </Form.Item>
                  </div>
                  {formData?.repeat === "custom" && (
                    <div className="form-row flex-space-between">
                      <div
                        className="form-group flex-row"
                        style={{
                          gap: "16px",
                          alignItems: "baseline",
                          marginBottom: "-30px",
                        }}
                      >
                        <Form.Item name="days_of_week">
                          <HSDayPicker
                            onDayChange={handleOnDayChange}
                            name="days_of_week"
                            days={checkedDays}
                            allowMultiple={true}
                            disabled={isEditForm}
                            repeatKey={formData?.repeat}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}
                  {formData?.repeat === "weekly" && (
                    <div className="form-row flex-space-between">
                      <div
                        className="form-group flex-row"
                        style={{
                          gap: "16px",
                          alignItems: "baseline",
                          marginBottom: "-30px",
                        }}
                      >
                        <Form.Item name="days_of_week">
                          <HSDayPicker
                            onDayChange={handleOnDayChange}
                            name="days_of_week"
                            days={checkedDays}
                            allowMultiple={false}
                            disabled={isEditForm}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}
                  {renderDatePicker()}
                  <div
                    className="form-group"
                    style={{ display: "flex", marginTop: "0px", gap: "30px" }}
                  >
                    <Form.Item
                      label={"Start time*"}
                      name="starttime"
                      rules={[
                        {
                          type: "object",
                          message: trans("PLEASESELECTTIME"),
                        },
                      ]}
                    >
                      <TimePicker
                        name="starttime"                        
                        style={{ width: "300px"}}
                        allowClear={false}
                        required={true}
                        format={userInfo?.is24hoursformat ? "HH:mm" : "h:mm a"}
                        size="large"
                        onChange={(value) =>
                          handleValueChange("starttime", value)
                        }
                        minuteStep={15}
                        disabled={isEditForm && isEventRecurring}
                        suffixIcon={
                          <span
                            style={{
                              color:
                                isEditForm && isEventRecurring
                                  ? "rgba(0, 0, 0, 0.25)"
                                  : "black",
                            }}
                          >
                            {timezonedisplay}
                          </span>
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label={"End time*"}
                      name="endtime"
                      rules={[
                        {
                          type: "object",
                          message: trans("PLEASESELECTTIME"),
                        },
                      ]}
                    >
                      <TimePicker
                        name="endtime"
                        style={{ width: "300px"}}
                        allowClear={false}
                        required={true}
                        format={userInfo?.is24hoursformat ? "HH:mm" : "h:mm a"}
                        size="large"
                        onChange={(value) =>
                          handleValueChange("endtime", value)
                        }
                        minuteStep={15}
                        disabled={
                          !formData?.starttime || (isEditForm && isEventRecurring)
                        }
                        disabledTime={(current) => {
                          if (!formData?.starttime) return {};
                          const start = formData?.starttime;
                          const startHour = start.hour();
                          const startMinute = start.minute();
                          const disabledHours = () => {
                            const hours = [];
                            for (let i = 0; i < startHour; i++) {
                              hours.push(i);
                            }
                            return hours;
                          };
                          const disabledMinutes = (selectedHour) => {
                            if (selectedHour === startHour) {
                              const minutes = [];
                              for (let i = 0; i <= startMinute; i++) {
                                minutes.push(i);
                              }
                              return minutes;
                            }
                            return [];
                          };
                          return {
                            disabledHours,
                            disabledMinutes,
                          };
                        }}
                        suffixIcon={
                          <span
                            style={{
                              color:
                                isEditForm && isEventRecurring
                                  ? "rgba(0, 0, 0, 0.25)"
                                  : "black",
                            }}
                          >
                            {timezonedisplay}
                          </span>
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="form-row ">
                    <Form.Item
                      name="reminder"
                      className="form-group-select"
                      label={"Time between reminders*"}
                    >
                      <Select
                        onChange={(e) => handleValueChange("reminder", e)}
                        value={formData?.reminder}
                        name="reminder"
                        className="select-type"
                        options={FormattedReminderDuration}
                      />
                    </Form.Item>
                    <div className="reminder-text">
                      Applies if the user chooses to snooze an alert.
                    </div>
                  </div>
                  <div className="form-group">
                    <Form.Item name="event_description" label={"Description"}>
                      <Input.TextArea
                        placeholder={trans("ADDDESCRIPTION")}
                        name="event_description"
                        onChange={handleInputChange}
                        defaultValue={formData?.event_description}
                        className="form-description"
                      />
                    </Form.Item>
                  </div>
                  <div className="manadatory-text">
                    *These fields are mandatory.
                  </div>
                </div>
              </div>
              {isEventCallTypeAllowed && <div className="column-2">
                <span className="general-info-text">{`${trans(
                  "PARTICIPANTS"
                )}`}</span>
                <ContactSelectList
                  fetchingContacts={fetchingContacts || fetchingStaffList}
                  selectedListHeader={""}
                  selectionListHeader={trans("ADDCONTACTS")}
                  selectedContactList={sortedContactList}
                  selectionContactList={sortedUserContactList}
                  onAddClick={(item) => addContact(item)}
                  onRemoveClick={(item) => removeCard(item)}
                  showRelationship={false}
                  selectedUser={selectedUser || username}
                  hubRecord={hubRecord}
                  starteventAction={openSubMenu ? false : true}
                />
              </div>}
            </div>
            <div className="taskform-action-button-container">
              {isEditForm ? (
                <>
                  <Form.Item>
                    <NewCustomButton
                      className={"delete-form"}
                      label={`Delete ${action === Actions.editEvent ? "Call": action === Actions.editReminderEvent? "Reminder": "Task"}`}
                      icon={<DeleteIcon className="delete-icon" />}
                      onClick={handleDeleteClick}
                    />
                  </Form.Item>
                  <Form.Item>
                    <NewCustomButton
                      className={"cancel-form-icon"}
                      label={trans("CANCEL")}
                      icon={<NewCloseIcon className="cancel-icon" />}
                      onClick={handleSaveChanges}
                    />
                  </Form.Item>
                  <Form.Item>
                    <NewCustomButton
                      disabled={!isSaveButtonEnabled}
                      type={ButtonTypes.primary}
                      label={"Save changes"}
                      icon={
                        <NewUpdateIcon
                          className={
                            !isSaveButtonEnabled
                              ? "save-icon-disabled"
                              : "save-icon-enabled"
                          }
                        />
                      }
                      onClick={handleSendClick}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item>
                    <NewCustomButton
                      className={"cancel-form-icon"}
                      label={trans("CANCEL")}
                      icon={<NewCloseIcon className="cancel-icon" />}
                      onClick={handleClose}
                    />
                  </Form.Item>
                  <Form.Item>
                    <NewCustomButton
                      disabled={isCreateButtonDisabled}
                      type={ButtonTypes.primary}
                      label={"Create"}
                      icon={
                        <NewUpdateIcon
                          className={
                            !isCreateButtonDisabled
                              ? "update-icon-enabled"
                              : "update-icon-disabled"
                          }
                        />
                      }
                      onClick={handleSendClick}
                    />
                  </Form.Item>
                </>
              )}
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );

  return onlyContent ? (
    <TaskModal showModal={showModal} closeModalHandler={closeModalHandler}>
      {render}
    </TaskModal>
  ) : (
    <MainPage
      hasSider={showHeader ? false : true}
      activeKey={activeTab}
      openSubMenu={openSubMenu}
      onChange={handleTabChange}
      hubUserRole={hubRecord?.rolename}
    >
      {render}
    </MainPage>
  );
};

TaskForm.propTypes = {
  onlyContent: PropTypes.bool,
  action: PropTypes.string,
  hubRecord: PropTypes.object,
  userInfo: PropTypes.object,
  taskRecord: PropTypes.object,
  onActionClick: PropTypes.func,
  openSubMenu: PropTypes.bool,
  showHeader: PropTypes.bool,
  loginUserTimeformat: PropTypes.shape({
    is24hoursformat: PropTypes.bool,
  }),
  eventRecord: PropTypes.object,
  closeModalHandler: PropTypes.func,
  showModal: PropTypes.bool,
  userTimezone: PropTypes.string,
  reFetchList: PropTypes.func,
};

export default React.memo(TaskForm);