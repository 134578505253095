import React from "react";

export const ScheduleIconNew = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg className={"custom-icon " +className} style={style}
    width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.20398 10.8188H6.2448C6.06252 10.8188 5.91476 10.9666 5.91476 11.1489V12.1081C5.91476 12.2904 6.06252 12.4381 6.2448 12.4381H7.20398C7.38626 12.4381 7.53403 12.2904 7.53403 12.1081V11.1489C7.53403 10.9666 7.38626 10.8188 7.20398 10.8188Z" fill={className}/>
    <path d="M7.20398 13.1807H6.2448C6.06252 13.1807 5.91476 13.3284 5.91476 13.5107V14.4699C5.91476 14.6522 6.06252 14.7999 6.2448 14.7999H7.20398C7.38626 14.7999 7.53403 14.6522 7.53403 14.4699V13.5107C7.53403 13.3284 7.38626 13.1807 7.20398 13.1807Z" fill={className}/>
    <path d="M7.20398 15.5425H6.2448C6.06252 15.5425 5.91476 15.6902 5.91476 15.8725V16.8317C5.91476 17.014 6.06252 17.1617 6.2448 17.1617H7.20398C7.38626 17.1617 7.53403 17.014 7.53403 16.8317V15.8725C7.53403 15.6902 7.38626 15.5425 7.20398 15.5425Z" fill={className}/>
    <path d="M9.55555 10.8188H8.59636C8.41408 10.8188 8.26632 10.9666 8.26632 11.1489V12.1081C8.26632 12.2904 8.41408 12.4381 8.59636 12.4381H9.55555C9.73782 12.4381 9.88559 12.2904 9.88559 12.1081V11.1489C9.88559 10.9666 9.73782 10.8188 9.55555 10.8188Z" fill={className}/>
    <path d="M9.55555 13.1807H8.59636C8.41408 13.1807 8.26632 13.3284 8.26632 13.5107V14.4699C8.26632 14.6522 8.41408 14.7999 8.59636 14.7999H9.55555C9.73782 14.7999 9.88559 14.6522 9.88559 14.4699V13.5107C9.88559 13.3284 9.73782 13.1807 9.55555 13.1807Z" fill={className}/>
    <path d="M9.55555 15.5425H8.59636C8.41408 15.5425 8.26632 15.6902 8.26632 15.8725V16.8317C8.26632 17.014 8.41408 17.1617 8.59636 17.1617H9.55555C9.73782 17.1617 9.88559 17.014 9.88559 16.8317V15.8725C9.88559 15.6902 9.73782 15.5425 9.55555 15.5425Z" fill={className}/>
    <path d="M11.897 10.8188H10.9378C10.7555 10.8188 10.6077 10.9666 10.6077 11.1489V12.1081C10.6077 12.2904 10.7555 12.4381 10.9378 12.4381H11.897C12.0792 12.4381 12.227 12.2904 12.227 12.1081V11.1489C12.227 10.9666 12.0792 10.8188 11.897 10.8188Z" fill={className}/>
    <path d="M11.897 13.1807H10.9378C10.7555 13.1807 10.6077 13.3284 10.6077 13.5107V14.4699C10.6077 14.6522 10.7555 14.7999 10.9378 14.7999H11.897C12.0792 14.7999 12.227 14.6522 12.227 14.4699V13.5107C12.227 13.3284 12.0792 13.1807 11.897 13.1807Z" fill={className}/>
    <path d="M14.238 10.8188H13.2788C13.0965 10.8188 12.9487 10.9666 12.9487 11.1489V12.1081C12.9487 12.2904 13.0965 12.4381 13.2788 12.4381H14.238C14.4202 12.4381 14.568 12.2904 14.568 12.1081V11.1489C14.568 10.9666 14.4202 10.8188 14.238 10.8188Z" fill={className}/>
    <path d="M11.4738 18.5956H4.7286V9.59168H17.9715V12.0154C18.3016 12.0567 18.6213 12.1289 18.941 12.2217C19.2608 12.3145 19.5599 12.428 19.859 12.5621V5.98185C19.859 5.48678 19.6733 5.06392 19.302 4.71325C18.9307 4.36258 18.4872 4.18724 17.9612 4.18724H17.0124V3.3312C17.0124 2.80519 16.5895 2.38232 16.0635 2.38232C15.5375 2.38232 15.1146 2.80519 15.1146 3.3312V4.18724H7.54427V3.3312C7.54427 2.80519 7.1214 2.38232 6.5954 2.38232C6.06939 2.38232 5.64652 2.80519 5.64652 3.3312V4.18724H4.69765C4.18196 4.18724 3.72815 4.36258 3.35686 4.71325C2.98556 5.06392 2.79991 5.48678 2.79991 5.98185V18.5956C2.79991 19.0907 2.98556 19.5136 3.35686 19.8642C3.72815 20.2149 4.17165 20.3902 4.69765 20.3902H12.3608C12.1545 20.1221 11.9792 19.8333 11.8245 19.5342C11.6698 19.2351 11.546 18.9154 11.4326 18.5853L11.4738 18.5956ZM4.7286 5.98185H17.9715V7.78676H4.7286V5.98185Z" fill={className}/>
    <path d="M16.8477 12.9126C14.4445 12.9126 12.4952 14.8619 12.4952 17.265C12.4952 19.6681 14.4445 21.6175 16.8477 21.6175C19.2508 21.6175 21.2001 19.6681 21.2001 17.265C21.2001 14.8619 19.2508 12.9126 16.8477 12.9126ZM18.9517 19.4309C18.6423 19.7403 18.271 19.4516 18.271 19.4516L16.3835 17.657V14.8825C16.3835 14.8825 16.466 14.47 16.8992 14.47C17.3324 14.47 17.4046 14.8825 17.4046 14.8825V17.265L18.9929 18.7708C18.9929 18.7708 19.2611 19.1215 18.962 19.4309H18.9517Z" fill={className}/>
    </svg>
    
  );
};
