import React from "react";

export const ShieldIconCheck = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0456 14.8793H13.9308L13.3746 11.7855C13.699 11.6233 13.9424 11.3916 14.1278 11.0903C14.3132 10.789 14.4059 10.453 14.4059 10.0822C14.4059 9.54921 14.2205 9.09731 13.8381 8.72652C13.4673 8.35573 13.0154 8.15874 12.4824 8.15874C11.9494 8.15874 11.4975 8.34414 11.1267 8.72652C10.7559 9.09731 10.5589 9.54921 10.5589 10.0822C10.5589 10.453 10.6516 10.789 10.837 11.0903C11.0224 11.3916 11.2773 11.6233 11.5902 11.7855L11.034 14.8793H11.0456ZM12.494 21.5999C10.2692 21.0437 8.43845 19.7691 6.99005 17.7761C5.54165 15.7831 4.82324 13.57 4.82324 11.1367V5.28512L12.494 2.3999L20.1763 5.28512V11.1367C20.1763 13.57 19.4579 15.7831 18.0095 17.7761C16.5611 19.7691 14.7303 21.0437 12.5056 21.5999H12.494ZM12.494 19.5837C14.1625 19.0507 15.5298 17.9963 16.619 16.4204C17.7082 14.833 18.2528 13.0717 18.2528 11.1367V6.60606L12.494 4.45084L6.73513 6.59447V11.1251C6.73513 13.0601 7.27973 14.8214 8.36893 16.4088C9.45812 17.9963 10.837 19.0507 12.494 19.5721V19.5837Z"
      />
    </svg>
  );
};
