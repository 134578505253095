import React from "react";
import { BloodPressureIcon , PulseOxygenIcon , BodyTemperatureIcon , BodyWeightIcon, GlucoseMeterIcon  } from "../Common/Icons";
import "./Wellness.less";



const WellnessIconByTitle = ({title, style }) => {
    console.log('WellnessIconByTitle',title);

    const medicalIcon = ()=>{
        switch(title) {
            case 'homesight_blood_pressure_monitor':
              return <BloodPressureIcon style={style} />
            case 'homesight_pulse_oximeter':
              return <PulseOxygenIcon   style={style} />
            case 'homesight_thermometer' :
              return <BodyTemperatureIcon style={style} />
            case 'homesight_weight_scale':
                return <BodyWeightIcon style={style} />
            case 'homesight_glucose_meter':
              return <BodyWeightIcon style={style} />
             
            
          }
    }
    return (
        <>
          {medicalIcon()}
                        
        </>
    )
}

export default WellnessIconByTitle;