import React, { useEffect, useState } from "react";
import MainPage from "../../MainPage";
import { useSelector } from "react-redux";
import { Divider, Form, Spin } from "antd";
import {
  CloseIcon,
  EditIcon,
} from "../Common/Icons";
import PropTypes from "prop-types";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import "../../styles/Task.less";
import TaskCustomCalendar from "./TaskCustomCalendar";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import RecurringTaskModal from "./RecurringTaskModal";
import NonRecurringTaskModal from "./NonRecurringTaskModal";
import { Actions } from "@commscopemycloud/humaui/Utilities/Constants";
import {
  getTimeZoneFormat,
  toDateTimeByTimeZone,
  formatEventTimes
} from "@commscopemycloud/humaui/Utilities/DateTime";
import TaskModal from "./TaskModal";

dayjs.extend(utc);
dayjs.extend(timezone);
const currentSiteIconZip = state => state.data.siteIconFiles;
const currentSiteIconManifest = state => state.data.siteIconManifest;

const ViewTask = (props) => {
  // console.log("view task :", props);
  const {
    onlyContent,
    action,
    hubRecord,
    userInfo,
    taskRecord,
    onActionClick,
    openSubMenu = false,
    showHeader = false,
    loginUserTimeformat,
    eventRecord,
    userHubsData,
    selectedSchedulelistDate,
    closeModalHandler,
    showModal,
    userTimezone
  } = props;

  const [form] = Form.useForm();
  const trans = useSelector(translator);
  const taskApi = useSelector((state) => state.apis.taskApi);
  // Track initial form data (to compare changes)
  const [formData, setFormData] = useState("");
  const [activeTab, setActiveTab] = useState("schedule");
  const [processing, setProcessing] = useState(false);
  const [fetchTaskData, setFetchTaskData] = useState([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const siteIconZip = useSelector(currentSiteIconZip);
  const siteIconManifest = useSelector(currentSiteIconManifest);

  const handleEditClick = () => {
    setIsEditModalVisible(true); // Show the delete confirmation modal
  };
  const timeZone = getTimeZoneFormat(userInfo.timezone);
  const taskDate = toDateTimeByTimeZone(
    eventRecord?.event_timestamp,
    timeZone,
    userInfo?.is24hoursformat
  ).format("MM/DD/YY");
  const handleEditModalConfirm = () => {
    setIsEditModalVisible(false); // Close the modal
    onActionClick(Actions.editTask, {
      ...eventRecord,
      userHubsData,
    })();
  };

  useEffect(() => {
    async function buildCategoryOptions() {
      if (!siteIconZip || !siteIconManifest || typeof siteIconZip.file !== "function") {
        console.error('Invalid Zip file.');
        return;
      }
      const options = [];
      for (const mainCat of Object.keys(siteIconManifest)) {
        const subcats = siteIconManifest[mainCat];
        for (const subcat of Object.keys(subcats)) {
          const fileName = subcats[subcat];
          const file = siteIconZip.file(fileName);
          if (!file) {
            console.error(`File ${fileName} not found in zip for subcategory "${subcat}"`);
            continue;
          }
          try {
            const blob = await file.async("blob");
            let blobToUse = blob;
            if (!blob.type || blob.type === "") {
              blobToUse = new Blob([blob], { type: "image/svg+xml" });
            }
            const url = URL.createObjectURL(blobToUse);
            options.push({
              value: `${subcat}`,
              icon: (
                <img
                  src={url}
                  alt={subcat}
                  style={{
                    width: 45,
                    height: 50,
                    verticalAlign: "middle",
                  }}
                />
              ),
            });
          } catch (error) {
            console.error(`Error loading file ${fileName} for subcategory "${subcat}":`, error);
          }
        }
      }
      options.sort((a, b) => a.value.localeCompare(b.value));
      setCategoryOptions(options);
    }
    buildCategoryOptions();
  }, [siteIconZip, siteIconManifest]);

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false); // Simply close the modal
  };
  const processTaskStatusDate = (tasks) => {
    return tasks.map(task => {
      if (!task.recurring) {
        // Non-recurring task
        return {
          startDate: task.event_startdate,
          startTime: task.event_starttime,
          taskStatus: task.task_status,
        };
      } else {
        // Recurring task
        return {
          eventTimestamp: task.event_timestamp,
          taskStatus: task.task_status,
        };
      }
    });
  };
  const fetchData = () => {
    const errorCallback = (error) => {
      // message.error("Error fetching user information!");
      console.error("Error fetching user information:", error);
    };
    const successCallback = (data) => {
      console.log("UserIFetchtask: fetch result", data);
      const processedData = processTaskStatusDate(data?.tasks || []);
      setFetchTaskData(processedData);
    };
    try {
      if (!eventRecord?.schedule_eventuuid) return;
      taskApi.fetchTask(
        eventRecord?.schedule_eventuuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const FormItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
    onActionClick(null, null, key)();
  };
  useEffect(() => {
    fetchData();
  }, [eventRecord?.schedule_eventuuid]);

  const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formatEventDates = (
    startDateStr,
    starttime,
    endDateStr = null,
    endtime = null,
    timeZone = null,
    userInfo
  ) => {
    const combineDateTime = (dateStr, timeStr) => {
      const datePartOnly = dateStr?.split("T")[0];
      return `${datePartOnly}T${timeStr}Z`;
    };

    startDateStr = combineDateTime(startDateStr, starttime);
    if (endDateStr)
      endDateStr = combineDateTime(endDateStr, endtime);
    const startDate = timeZone
      ? dayjs(startDateStr).tz(timeZone)
      : dayjs(startDateStr);

    const endDate = endDateStr
      ? timeZone
        ? dayjs(endDateStr).tz(timeZone)
        : dayjs(endDateStr)
      : null;

    const formattedStartDate = startDate.format("dddd, MMMM D");
    const formattedEndDate = endDate ? endDate.format("dddd, MMMM D") : null;

    return endDate
      ? `${formattedStartDate} - ${formattedEndDate}`
      : formattedStartDate;
  };

  const renderEditModal = () => {
    if (!isEditModalVisible) return null;
    return eventRecord?.repeat &&
      ["daily", "custom", "weekly"].includes(eventRecord?.repeat) ? (
      <RecurringTaskModal
        isModalOpen={isEditModalVisible}
        onConfirm={handleEditModalConfirm}
        onCancel={handleEditModalCancel}
        modalHeader={"Modify periodic task"}
      />
    ) : (
      <NonRecurringTaskModal
        isModalOpen={isEditModalVisible}
        onConfirm={handleEditModalConfirm}
        onCancel={handleEditModalCancel}
        modalHeader={"Modify task"}
        modalType={"task"}
        actionType={ "modify"} 
      />
    );
  };

  const render = (
    <div className="view-taskform-container">
      <Spin size="large" spinning={processing} tip={trans("PROCESSING")}>
        {renderEditModal()}
        <Form
          form={form}
          initialValues={formData}
          {...FormItemLayout}
          className="view-taskform-container"
        >
          <div className="view-taskform-card">
            <div className="view-task-title">
              <div className="view-form-title">
                {(() => {
                  const option = categoryOptions.find(
                    (opt) => opt.value === eventRecord?.category
                  );
                  return option ? option?.icon : "";
                })()}
                <span>{eventRecord?.event_name}</span>
              </div>
              <div className="view-task-action">
                <EditIcon
                  style={{
                    height: "23px",
                    width: "23px",
                    cursor: "pointer",
                    fill: "#005D98",
                    stroke: "#005D98",
                  }}
                  onClick={handleEditClick}
                />
                <CloseIcon
                  className="icon-style"
                  style={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                    fill: "#005D98",
                    stroke: "#005D98",
                  }}
                  onClick={closeModalHandler}
                />
              </div>
            </div>
            <Divider type="horizontal" className="divider" />
            <div className="view-task-information">
              <div className="view-task-info-title">Task details</div>
              <div className="view-task-info-data">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    {formatEventDates(
                      eventRecord?.event_startdate,
                      eventRecord?.event_starttime,
                      eventRecord?.event_enddate,
                      eventRecord?.event_endtime,
                      timeZone,
                      userInfo?.is24hoursformat
                    )}
                  </span>
                  <span>
                    {formatEventTimes(
                      eventRecord?.event_startdate,
                      eventRecord?.event_starttime,
                      eventRecord?.event_startdate,
                      eventRecord?.event_endtime,
                      timeZone,
                      userInfo?.is24hoursformat
                    )}
                  </span>
                </div>
                <div className="view-task-sub-header-info">
                  <div className="view-task-info-header">
                    Repeats:
                    <span className="view-task-infovalue">
                      {eventRecord && eventRecord?.repeat
                        ? eventRecord?.repeat.charAt(0).toUpperCase() + eventRecord?.repeat.slice(1).toLowerCase()
                        : "Doesn't Repeat"}
                    </span>
                  </div>
                  <div className="view-task-info-header">
                    Reminder:
                    <span className="view-task-infovalue">
                      {eventRecord?.reminder / 60} minutes
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="view-task-status-info">
              <div className="view-task-info-title">Status Information</div>
              <div className="view-task-status-calendar">
                <TaskCustomCalendar
                  selectedSchedulelistDate={selectedSchedulelistDate}
                  taskDate={taskDate}
                  taskStatus={eventRecord?.task_status}
                  fetchTaskData={fetchTaskData}
                  timeZone={timeZone}
                />
                <div className="view-task-statushead">
                  {" "}
                  Legend:
                  <span
                    className="view-task-status"
                    style={{ background: "#4BD37B", color: "#027B0E" }}
                  >
                    Completed
                  </span>
                  <span
                    className="view-task-status"
                    style={{ background: "#808080", color: "#FFFFFF" }}
                  >
                    Scheduled
                  </span>
                  <span
                    className="view-task-status"
                    style={{ background: "#F9C030", color: "#113E57" }}
                  >
                    In progress
                  </span>
                  <span
                    className="view-task-status"
                    style={{ background: "#F46363", color: "#0A0A0A" }}
                  >
                    Missed
                  </span>
                </div>
              </div>
            </div>
            <div className="view-task-decription">
              <div className="view-task-info-title">Task description</div>
              <p
                name="event_description"
                className="task-description"
              >
                {eventRecord?.event_description}
              </p>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );

  return onlyContent ? (
    <TaskModal showModal={showModal} closeModalHandler={closeModalHandler}>
      {render}
    </TaskModal>
  ) : (
    <MainPage
      hasSider={showHeader ? false : true}
      activeKey={activeTab}
      openSubMenu={openSubMenu}
      onChange={handleTabChange}
      hubUserRole={hubRecord?.rolename}
    >
      {render}
    </MainPage>
  );
};

ViewTask.propTypes = {
  onlyContent: PropTypes.bool,
  action: PropTypes.string,
  hubRecord: PropTypes.object,
  userInfo: PropTypes.object,
  taskRecord: PropTypes.object,
  onActionClick: PropTypes.func,
  openSubMenu: PropTypes.bool,
  showHeader: PropTypes.bool,
  loginUserTimeformat: PropTypes.shape({
    is24hoursformat: PropTypes.bool,
  }),
  eventRecord: PropTypes.object,
  userHubsData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedSchedulelistDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  closeModalHandler: PropTypes.func,
  showModal: PropTypes.bool,
  userTimezone: PropTypes.string,
};

export default ViewTask;
