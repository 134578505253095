import React from "react";
import { BloodPressureIcon , PulseOxygenIcon , BodyTemperatureIcon , BodyWeightIcon, GlucoseMeterIcon  } from "../Common/Icons";
import "./Wellness.less";
import PropTypes from "prop-types";

const WellnessIconByTitle = ({title, style }) => {
    const medicalIcon = ()=>{
        switch(title) {
            case 'blood_pressure':
              return <BloodPressureIcon style={style} />
            case 'pulse_oximeter':
              return <PulseOxygenIcon   style={style} />
            case 'thermometer' :
              return <BodyTemperatureIcon style={style} />
            case 'weight_scale':
                return <BodyWeightIcon style={style} />
            case 'glucose_meter':
              return <GlucoseMeterIcon style={style} />
             
            
          }
    }
    return (
        <>
          {medicalIcon()}
                        
        </>
    )
}

WellnessIconByTitle.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
};

export default WellnessIconByTitle;